/* style font */
.ladiui .item-title {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: var(--main-default) !important;
}

.block {
    display: block;
}

.t-145 {
    top: 145px !important;
}

.p-0 {
    padding: 0px !important;
}

.p-2 {
    padding: 2px !important;
}

.p-16 {
    padding: 16px !important;
}

.p-24 {
    padding: 24px !important;
}

/* padding top  */

.pt-0 {
    padding-top: 0 !important;
}

.pt-8 {
    padding-top: 8px !important;
}

.pt-12 {
    padding-top: 12px !important;
}

.pt-16 {
    padding-top: 16px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-24 {
    padding-top: 24px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

/* padding right */

.pr-0 {
    padding-right: 0 !important;
}

.pr-4 {
    padding-right: 4px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-16 {
    padding-right: 16px !important;
}

.pr-18 {
    padding-right: 18px !important;
}

.pr-24 {
    padding-right: 24px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-32 {
    padding-right: 32px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

/* padding bottom  */

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-4 {
    padding-bottom: 4px !important;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-12 {
    padding-bottom: 12px !important;
}

.pb-16 {
    padding-bottom: 16px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-24 {
    padding-bottom: 24px !important;
}

/* padding left  */
.pl-0 {
    padding-left: 0 !important;
}

.pl-4 {
    padding-left: 4px !important;
}

.pl-16 {
    padding-left: 16px !important;
}

.pl-24 {
    padding-left: 24px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.w-unset {
    width: unset !important;
    padding: 0px !important;
}

.w-auto {
    width: auto !important;
}

.w-30p {
    width: 30% !important;
}

.w-50-p {
    width: 50% !important;
}

.w-60-p {
    width: 60% !important;
}

.w-80-p {
    width: 80% !important;
}

.w-100-p {
    width: 100% !important;
}

.w-44 {
    width: 44px !important;
}

.w-50 {
    width: 50px !important;
}

.w-80 {
    width: 80px !important;
}

.w-100 {
    width: 100px !important;
}

.w-120 {
    width: 120px !important;
}

.w-150 {
    width: 150px !important;
}

.w-160 {
    width: 160px !important;
}

.w-175 {
    width: 175px !important;
}

.w-180 {
    width: 180px !important;
}

.w-190 {
    width: 190px !important;
}

.w-200 {
    width: 200px !important;
}

.w-220 {
    width: 220px !important;
}

.w-250 {
    width: 250px !important;
}

.w-270 {
    width: 270px !important;
}

.w-290 {
    width: 290px !important;
}

.w-300 {
    width: 300px !important;
}

.w-400 {
    width: 400px !important;
}

.w-424 {
    width: 424px !important;
}

.w-440 {
    width: 440px !important;
}

.w-500 {
    width: 500px !important;
}

.w-553 {
    width: 553px !important;
}

.w-576 {
    width: 576px !important;
}

.w-578 {
    width: 578px !important;
}

.w-600 {
    width: 600px !important;
}

.w-800 {
    width: 800px !important;
}

.w-900 {
    width: 900px !important;
}

.min-w-30 {
    min-width: 30px !important;
}

.pd-0 {
    padding: 0px !important;
}

.pd-3 {
    padding: 3px !important;
}

.m-12 {
    margin: 12px !important;
}

.m-0 {
    margin: 0px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-3 {
    margin-left: 3px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-12 {
    margin-left: 12px !important;
}

.ml-16 {
    margin-left: 16px !important;
}

.ml-18 {
    margin-left: 18px !important;
}

.ml-24 {
    margin-left: 24px !important;
}

.ml-32 {
    margin-left: 32px !important;
}

.ml-64 {
    margin-left: 64px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-14 {
    margin-top: 14px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-18 {
    margin-top: 18px !important;
}

.mt-19 {
    margin-top: 19px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-22 {
    margin-top: 22px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-32 {
    margin-top: 32px !important;
}

.mt-34 {
    margin-top: 34px !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-4 {
    margin-right: 4px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-11 {
    margin-right: 11px;
}

.mr-12 {
    margin-right: 12px !important;
}

.mr-14 {
    margin-right: 14px !important;
}

.mr-16 {
    margin-right: 16px !important;
}

.mr-18 {
    margin-right: 18px;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-24 {
    margin-right: 24px !important;
}

.mr-28 {
    margin-right: 28px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-4 {
    margin-bottom: 4px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.mb-27 {
    margin-bottom: 27px !important;
}

.lh-32 {
    line-height: 32px;
}

.bold-500 {
    font-weight: 500 !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.box-shadow-none {
    box-shadow: unset !important;
}

.display-none {
    display: none !important;
}

.r-0 {
    right: 0px !important;
}

.pd-5 {
    padding: 5px !important;
}

.t-40 {
    top: 40px !important;
}

.t-300 {
    top: 300px !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-line-through {
    text-decoration: line-through;
}

.border-none {
    border: none !important;
}

.m-0 {
    margin: 0px !important;
}

.pos-rel {
    position: relative !important;
}

.bt-0 {
    bottom: 0px !important;
}

.bt-36 {
    bottom: 36px !important;
}

.pointer {
    cursor: pointer;
}

.flex-item-start {
    align-items: flex-start !important;
}

.wp-50 {
    width: 49% !important;
}

.flex-start {
    justify-content: flex-start !important;
}

.flex-end {
    justify-content: end !important;
}

.flex {
    display: flex !important;
    /* justify-content: space-between; */
}

.space-between {
    justify-content: space-between;
}

.items-baseline {
    align-items: baseline !important;
}

.w100 {
    width: 100% !important;
}

.my-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.my-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.py-12 {
    padding: 12px 0px !important;
}

.m-0-12 {
    margin: 0px 12px;
}

.h-auto {
    height: auto !important;
}

.relative {
    position: relative !important;
}

.absolute {
    position: absolute;
}

.cursor-pointer {
    cursor: pointer !important;
}

.color-default {
    color: var(--main-primary) !important;
}

.py-24 {
    padding: 24px 0px !important;
}

.items-center {
    align-items: center !important;
}

.items-end {
    align-items: end !important;
}

.transparent {
    background-color: transparent !important;
}

.t-317 {
    top: 317px !important;
}

.boder-search {
    background: #ffffff;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
}

.px-5 {
    padding: 0px 5px !important;
}

.inline-block {
    display: inline-block !important;
}

.h-36 {
    height: 36px !important;
}

.no-border {
    border: none !important;
}

.justify-content-center {
    justify-content: center !important;
}

.space-nowrap {
    white-space: nowrap !important;
}

.position-initial {
    position: initial !important;
}

.bold-400 {
    font-weight: 400 !important;
}

.input_err {
    border: 1px solid #9d4f45 !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.justify-start {
    justify-content: flex-start !important;
}

.text-w500 {
    font-weight: 500;
}

.text-w600 {
    font-weight: 600;
}

.text-primary {
    color: var(--main-primary);
}

.text-sub-title {
    color: #9FA7B1;
    font-size: 12px;
    line-height: 20px;
}

.text-12 {
    font-size: 12px !important;
}

.text-14 {
    font-size: 14px !important;
}

.text-16 {
    font-size: 16px !important;
}

.text-18 {
    font-size: 18px !important;
}

.font-500 {
    font-weight: 500 !important;
}

.gap-4 {
    gap: 4px !important;
}

.gap-5 {
    gap: 5px !important;
}

.gap-8 {
    gap: 8px !important;
}

.gap-12 {
    gap: 12px !important;
}

.gap-16 {
    gap: 16px;
}

.gap-20 {
    gap: 20px !important;
}

.gap-24 {
    gap: 24px !important;
}

.ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: var(--line);
    -webkit-box-orient: vertical;
}

.text-placeholder {
    color: #a6a6a6;
    color: var(--text-text-placeholder, #a6a6a6);
    text-align: center;
    line-height: 20px;
}

.text-placeholder,
.text-support-info {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.text-left {
    text-align: left !important;
}

.text-nowrap {
    white-space: nowrap;
}

.text-secondary {
    color: #566577;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.align-items-start {
    align-items: flex-start !important;
}

.pointer-events-none {
    pointer-events: none;
}

.justify-center {
    justify-content: center !important;
}

.hr-01 {
    width: 100%;
    border-bottom: 0.5px solid #cfd3d8;
}

.align-items-center {
    align-items: center !important;
}

.top-10 {
    top: 10px !important;
}

.right-10 {
    right: 10px !important;
}

.size-13 {
    width: 13px !important;
    height: 13px !important;
    -webkit-mask-size: 13px !important;
}

.line-height-24 {
    line-height: 24px !important;
}

.position-unset {
    position: unset !important;
    max-height: none !important;
    overflow: unset !important;
}

.mx-auto {
    margin: 0px auto !important;
}

.display-flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.height-40 {
    height: 40px !important;
}

.height-100p {
    height: 100% !important;
}

.px-24 {
    padding: 0 24px !important;
}

.top-8 {
    top: 8px !important;
}

.scroll-mt-150 {
    scroll-margin-top: 150px;
}

.min-h-auto {
    min-height: auto !important;
}

.input-no-focus:focus {
    border: none !important;
}

.w-max-content {
    width: max-content !important;
}

.max-w-200px {
    width: 200px !important;
}