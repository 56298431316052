.timeline {
    /* padding: 0px calc(18% - 100px); */
}

.timeline .top .btn-submit-comment {
    height: 32px !important;
}

.timeline .noavatar {
    background: #f3f4f5;
    color: #202020;
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    /* padding-top: 25%; */
    font-size: 14px;
}

.timeline .avatar {
    width: 40px;
    height: 40px;
    border-radius: 8px;
}

.timeline .ladiui-input-group {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    display: inline-block;
}

.timeline .ladiui-input-group input {
    width: 100% !important;
    border-right: 0px;
    border-radius: 4px 0px 0px 4px;
    border: 0px;
}

.timeline .ladiui-input-group .wrapper-input {
    display: inline-block;
    width: 100%;
}

/* .timeline .ladiui-input-group .ladiui-input-group-prepend {
    padding: 5px;
    display: inline-block;
    width: 100%;
} */

.timeline .ladiui-input-group .ladiui-input-group-prepend span {
    float: right;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend span .action {
    margin-right: 8px;
    width: 50px;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend span .action:last-child {
    margin-right: 0px;
}

.timeline .container {
    max-width: 350px;
    max-height: 630px;
    overflow: hidden;
    margin: 30px auto 0;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    font-family: 'Open Sans', sans-serif;
}

.timeline .navbar {
    background: #b97cfc;
    color: #fff;
    padding: 1em 0.5em;
}

.timeline .navbar a {
    color: #fff;
    text-decoration: none;
    font-size: 1.3em;
    float: left;
}

.timeline .navbar span {
    font-size: 1.1em;
    font-weight: 300;
    display: block;
    text-align: center;
}

.timeline .profile-pic {
    width: 30px;
    height: 30px;
    display: inline-block;
    float: right;
    position: relative;
}

.timeline .profile-pic img {
    width: 100%;
    border-radius: 50%;
}

.timeline .notification {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 2px;
    right: 2px;
    background: #f93b69;
}

.timeline .header {
    background: url(https://unsplash.it/1080/720?image=1044);
    background-size: cover;
    color: #fff;
    position: relative;
}

.timeline .color-overlay {
    padding: 3em 2em;
    box-sizing: border-box;
    background: rgba(123, 94, 155, 0.5);
}

.timeline .actionbutton {
    position: absolute;
    background: #f93b69;
    width: 50px;
    height: 50px;
    font-size: 3em;
    font-weight: 300;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -25px;
    right: 20px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.timeline .day-number {
    font-size: 4em;
    display: inline-block;
    margin-right: 15px;
}

.timeline .date-right {
    display: inline-block;
}

.timeline .day-name {
    font-size: 1.6em;
}

.timeline .month {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.6em;
    letter-spacing: 2px;
    margin-top: 2px;
}

.timeline .content {
    /* max-height: 400px; */
    overflow-y: unset;
    padding: 0px 0px 24px !important;
    min-height: unset !important;
}

.timeline .content.show {
    max-height: unset;
}

.timeline .content>ul {
    /* padding: 1em 0 0 0; */
    margin: 0;
    list-style: none;
    position: relative;
}

.timeline .content>ul li {
    padding: 16px 12px;
}

.timeline .content>ul::before {
    content: ' ';
    height: 90%;
    width: 1px;
    background-color: #d3c9fb;
    position: absolute;
    top: 0;
    left: 32px;
    z-index: 1;
}

.timeline .content>ul>li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.timeline .content>ul>li .item-avt-left {
    display: flex;
    align-items: flex-start;
    z-index: 2;
    justify-content: start;
    width: 100%;
}

.timeline .content>ul>li .item-time-right {
    display: flex;
    /* width: 150px; */
    align-items: center;
    justify-content: space-between;
}

.timeline .content>ul>li .item-time-right .ladiui-btn-dropdown {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid var(--border-02);
    background: #ffffff;
}

.timeline .content>ul>li .item-time-right .ladiui.icon-ldp-dot {
    width: 16px;
    height: 16px;
}

.timeline .content>ul>li .item-time-right .ladiui.dropdown-menu {
    right: 0px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(186, 186, 186, 0.3);
    width: 200px;
}

.timeline .content>ul>li .item-avt-left .ladiui-input-group {
    padding: 12px;
}

.timeline li>div {
    display: inline-block;
    /* margin: 1em 0; */
    vertical-align: middle;
    margin-right: 5px;
    /* z-index: 2; */
    position: relative;
    /* border: 3px solid #ffffff; */
}

.timeline .bullet {
    width: 1em;
    height: 1em;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    margin-right: 1em;
    position: relative;
}

.timeline .bullet.pink {
    border: 2px solid #f93b69;
}

.timeline .bullet.green {
    border: 2px solid #b0e8e2;
}

.timeline .bullet.orange {
    border: 2px solid #eb8b6e;
}

.timeline .time {
    /* width: 15%; */
    font-size: 12px;
    padding-top: 0.25em;
    color: var(--main-secondary);
}

.timeline .desc {
    width: 100%;
}

.timeline h3 {
    margin: 0;

    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.timeline h4 {
    margin-top: 4px;
    color: var(--text-secondary);
    font-size: 14px;
}

/* .timeline .avatar::before {
    content: ' ';
    height: 72px;
    width: 1px;
    background-color: #d3c9fb;
    position: absolute;
    top: -16;
    left: 20px;
    z-index: 1;
} */

.timeline .avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.timeline .credits,
.timeline .video {
    position: absolute;
    bottom: 10px;
    color: #808080;
    font-size: 100%;
    text-decoration: underline;
}

.timeline .credits {
    left: 10px;
}

.timeline .video {
    right: 10px;
}

.timeline .showmore {
    text-align: center;
}

.timeline .ladiui.button[disabled] {
    color: #6c7293;
    border-color: #ebedf2;
    background-color: #f4f5f8;
}

.timeline .top div {
    display: inline-block;
}

.timeline .top .input-text>input::placeholder {
    font-size: 13px;
    /* font-style: italic; */
}

.timeline .top .avatar {
    /* margin: 1em 0.5em 1em -2px; */
    /* margin: 0px 5px 5px -2px; */
    vertical-align: middle;
    display: inherit;
    width: 40px;
    height: 40px;
}

/* .timeline .top {
    padding: 0 0 0 1.5em;
} */

.timeline .top .input-text {
    width: 70%;
    /* margin-right: 15px; */
    margin-left: 7px;
}

.timeline .top input {
    vertical-align: middle;
}

.timeline .top .user-avatar {
    border-radius: 8px;
    width: 40px;
    height: 40px;
}

.timeline .input-history .input-custom {
    border: unset !important;
    box-shadow: unset !important;
    padding: 10px 0px !important;
}

.timeline .input-history .input-custom:focus {
    border: unset !important;
}

.timeline .input-history {
    width: 100% !important;
    position: relative;
    /* height: 60px; */
    /* border: 1px solid #f1f3f4; */
}

.timeline .input-history .comment {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background-color: var(--main-primary);
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    margin-top: 0px !important;
    text-align: center;
}

.timeline .input-history .comment span {
    display: block;
    margin-top: 2px;
}

.timeline .expand .item {
    display: none;
    height: 100%;
    padding: 10px 0px;
}

.timeline .expand .item.show {
    display: block !important;
}

.timeline .top .comment {
    float: right;
    margin-top: 1em;
}

.timeline .expand {
    cursor: pointer;
}

.timeline .expand .title i {
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
    transition: all 100ms;
}

.timeline .expand .title i.show {
    rotate: 90deg;
}

.timeline .expand .item .key {
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.timeline .expand .item .value {
    color: var(--text-secondary);
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend button {
    height: 30px !important;
    padding: 2px 5px !important;
}

.timeline .row-actions {
    vertical-align: bottom;
}

.timeline .row-actions ul {
    right: 0px;
}

.timeline .list-comment-history ul li {
    padding: 0px !important;
}

.timeline .top {
    display: flex !important;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    padding: 16px 12px;
    border: 1px solid var(--border-02);
    background: #ffffff;
}