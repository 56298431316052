:root {
    --icon-primary: #566577;
    --icon-primary-2: #3c72f9;
    --icon-on-colors: #ffffff;
    --icon-danger: #e01a1a;

    --support-brand: #1c00c2;
    --button-primary: #1c00c2;

    --button-primary-hover: #1500a6;
    --layer-brand: #f4f2fc;
    --main-white: #ffffff;

    --main-primary: #1c00c2;

    --button-hover-button-primary-hover: #1500a6;

    --button-danger: #e01a1a;

    /* text */
    --text-primary: #0e233c;
    --text-secondary: #566577;
    --text-danger: #c01322;
    --text-brand: #1c00c2;
    --text-default: #202020;
    --full-color-warning-700: #ac5c00;

    /* boder */
    --border-01: #cfd3d8;
    --border-01-50p: #cfd3d880;
    --border-02: #e7e9eb80;
    --border-02-100p: #e7e9eb;
    --border-brand: #5036da;
    --border-dange: #ec5d4f;

    /* --background-background */
    --background-02: #f3f4f5;

    /* --button */
    --button-default-button-primary: #1c00c2;

    --layer-layer-warning-layer-warning-hover: #fef2cb;
}
