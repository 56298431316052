.page-checkout .checkout-header {
    padding: 0px 0px 24px;
}

.page-checkout .block-filter {
    padding: 16px 0px 20px !important;
}

.page-checkout .block-search .block-filter-button {
    width: 410px !important;
}

.page-checkout .block-search .ladiui.search-group {
    width: calc(100% - 430px) !important;
}

.page-checkout .action-record-table {
    left: 50px !important;
}

.checkout-content {
    background: var(--background-02);
    min-height: calc(100vh - 92px);
}

.checkout-content .content-max-width {
    padding: 24px;
}

.checkout-url::after {
    content: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-fill-cart.svg);
    width: 16px;
    height: 16px;
}

.checkout-content .checkout-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.checkout-content .checkout-details .product-list-payment,
.checkout-content .checkout-details .product-list-payment .product-list,
.checkout-content .checkout-details .product-list-payment .payments {
    width: 775px;
}

.checkout-content .checkout-details .customer-tracking,
.checkout-content .checkout-details .customer-tracking .customer,
.checkout-content .checkout-details .customer-tracking .tracking {
    width: 375px;
}

.page-checkout-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;
    border-bottom: 1px solid #E7E9EB;

    position: sticky;
    top: 0px;
    background: #ffffff;
    z-index: 20;
    min-width: 1200px;
}

.page-checkout-head .page-checkout-back-name {
    width: 33.33%;
}

.page-checkout-head .page-checkout-name {
    max-width: 400px;
}

.page-checkout-head .page-checkout-name-input {
    width: 400px;
}

.page-checkout-head .page-checkout-name-input input {
    padding: 5px 12px !important;
}

.page-checkout-head .page-type-view-type {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 33.33%;
}

.page-checkout-head .page-type-view-type .page-type {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 32px;
}

.page-checkout-head .page-type-view-type .page-type .btn-page-view {
    background-color: var(--secondary-color);
}

.page-checkout-head .page-type-view-type .icon-desktop-mobile {
    display: flex;
    align-items: center;
    gap: 4px;

    border-radius: 4px;
    /* background: #f3f4f5; */
    padding: 2px;
    height: 32px;
    width: 60px;
}

.page-checkout-head .page-type-view-type .icon-desktop-mobile .icon-item {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-checkout-head .page-type-view-type .icon-desktop-mobile .icon-item.active .ladiui.icon-new {
    border-radius: 3px;
    /* background: var(--layer-layer-01, #fff); */
    /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08); */
    background-color: var(--main-primary);

}

.page-checkout-head .head-actions .page-checkout-link {
    color: #1C00C2;
    cursor: pointer;
}

.page-checkout-head .head-actions {
    width: 33.33%;
    justify-content: flex-end !important;
}

.page-checkout-head .head-actions .page-checkout-link .new-ldicon-link2 {
    background-color: #1C00C2;
}

.page-checkout-body {
    background: var(--background-02);
}

.page-checkout-content {
    display: block;
    position: relative;
    padding: 24px;
    /* display: flex;
    align-items: flex-start; */
}

.page-checkout-content .content-popup {
    background: #ffffff;
    position: absolute;
    z-index: 10;
    top: 24px;
    left: 24px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
}

.page-checkout-content .content-popup .content-header-mini {
    border-radius: 8px;

    background: var(--Background-background-01, #FFF);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
    padding: 12px;
    cursor: pointer;
}

.page-checkout-content .content-popup .content-header-mini .mini-title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-checkout-content .content-popup .content-header {
    background: var(--Full-Color-Grey-50, #F3F4F5);
    padding: 16px;
    color: var(--Text-text-primary, #0E233C);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    position: sticky;
    top: 56px;
    z-index: 3;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;


    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-checkout-content .content-popup .content-header .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    text-transform: uppercase;
}

/* .page-checkout-content .content-popup .content-header .new-ldicon-close-fill-2 {
    position: absolute;
    top: 16px;
    right: 16px;
} */

.page-checkout-content .content-left {
    width: 420px;

}

.page-checkout-content .content-right {
    /* width: calc(100% - 420px); */
    width: 100%;
    height: calc(100vh - 100px);
    position: sticky;
    top: 75px;
    padding: 0px 24px;
}

.page-checkout-content .content-right .content-right-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 1080px;
}

.page-checkout-body .menu-list {
    display: flex;
    flex-direction: column;
}

/* .page-checkout-body .menu-list .icon-new {
    width: 20px !important;
    height: 20px !important;
} */

.page-checkout-body .menu-list .icon-new.ldicon-chevron-right {
    width: 16px !important;
    height: 16px !important;
}

.page-checkout-body .menu-list .menu-item {
    padding: 16px;
    border-bottom: 1px solid var(--border-02-100p);
    cursor: pointer;
    height: 78px;
    /* border-left: 3px solid transparent; */
}

.page-checkout-body .menu-list .menu-item:hover {
    /* border-left: 3px solid #1c00c2; */
    background: #f4f2fc;
}

.page-checkout-body .menu-list .menu-item .menu-title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-checkout-body .menu-list .menu-item:hover .menu-title {
    color: var(--text-brand);
}

.page-checkout-config-item {
    /* height: calc(100vh - 76px); */
    /* overflow: overlay; */
}

.page-checkout-config-item .title-header {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-checkout-config-item .header {
    padding: 20px 24px !important;
    border-right: 1px solid #e7e9eb;
    border-bottom: 1px solid #e7e9eb;
    background: #ffffff;
    z-index: 3;
    position: sticky;
    top: 104px;
}

.page-checkout-config-item .header .icon-back {
    border-radius: 8px;
    border: 1px solid #e7e9eb;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.page-checkout-config-item .header .icon-back:hover {
    background: #f4f2fc;
}

.page-checkout-config-item .body {
    padding: 20px 24px;
}

.config-body.not-active {
    opacity: 0.4;
}

.config-body .config-header {
    /* padding: 20px 24px; */
    padding: 20px 24px 16px 24px;
    text-transform: uppercase;
}

.config-body .config-require {
    text-align: center;
    width: 120px;
}

.config-body .config-list .config-item {
    border-top: 0.5px solid var(--border-02);
    padding: 16px 24px;
    cursor: pointer;
}

.config-body .config-list .config-item:hover {
    background: var(--full-color-primary-50, #f4f2fc);
}

.custom-field-tyle-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 24px;
}

.custom-field-tyle-list .custom-field-tyle-item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    cursor: pointer;

    border-radius: 4px;
    border: 1px solid #e7e9eb;
}

.custom-field-tyle-list .custom-field-tyle-item.active {
    border: 1px solid #5036da;
}

.gateway-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 24px;
}

.gateway-list .gateway-item {
    border-radius: 4px;
    border: 1px solid #e7e9eb;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.gateway-list .gateway-item .gateway-item-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
    width: 100%;
}

.gateway-list .gateway-item .gateway-item-head .gateway-open-method {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.gateway-list .gateway-item .gateway-item-head .new-ldicon-arrow-up {
    transition: all 200ms;
}

.gateway-list .gateway-item .gateway-item-head:hover,
.gateway-list .gateway-item .gateway-item-head.active {
    background: #f4f2fc;
}

.gateway-list .gateway-item .gateway-item-head:hover .gateway-name,
.gateway-list .gateway-item .gateway-item-head.active .gateway-name {
    color: var(--text-brand);
}

.gateway-list .gateway-item .gateway-item-head:hover .new-ldicon-arrow-up,
.gateway-list .gateway-item .gateway-item-head.active .new-ldicon-arrow-up {
    background: var(--text-brand);
    /* rotate: 180deg; */
}

.gateway-list .gateway-item .gateway-item-head.active .new-ldicon-arrow-up {
    rotate: 180deg;
}

.method-list {
    padding: 4px 0px;
}

.method-list .method-item {
    padding: 16px 16px 16px 52px;
}

.widget-menu .widget-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px 24px;
    border-bottom: 1px solid #e7e9eb;
    cursor: pointer;
}

.widget-menu .widget-menu-item:hover {
    background: #f4f2fc;
}

.widget-menu .widget-menu-item .widget-menu-label {
    color: #566577;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.widget-menu .icon-new {
    width: 20px !important;
    height: 20px !important;
}

.widget-menu .new-ldicon-footer-sticky {
    rotate: 90deg;
}

.widget-config-item {
    padding: 20px 24px;
    /* height: calc(100vh - 150px);
    overflow: auto; */
}

.preview-store-logo {
    width: 48px;
    height: 48px;

    border-radius: 50%;
}

.preview-store-logo img {
    width: 48px;
    height: 48px;

    border-radius: 50%;
}

.preview-store-logo .store-logo-overlay {
    align-items: center;
    text-align: center;
    width: 48px;
    height: 48px;
    top: 0;
    z-index: 2;
    position: absolute;
    display: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
}

.preview-store-logo:hover .store-logo-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-store-logo:hover .store-logo-overlay .ladiui.dropdown-menu {
    right: unset !important;
}

.text-setting {
    margin-top: 24px;
    padding-top: 24px;

    border-top: 1px solid #e7e9eb;
}

.carts-item-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    margin-top: 20px;
}

.carts-item-list .carts-item-item {
    border-radius: var(--spacing-0px, 0px);
    border: 1px solid #e7e9eb;
    border-radius: 4px;
    /* background:  #f4f2fc; */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.carts-item-list .carts-item-item .carts-item-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;

    border-radius: 4px;
    /* border: 1px solid #e7e9eb; */
    background: #f3f4f5;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.carts-item-list .carts-item-item .carts-item-head .gateway-name {
    display: flex;
    align-items: center;
    gap: 8px;
}

.carts-item-list .carts-item-item .carts-item-head .gateway-name i {
    width: 20px !important;
    height: 20px !important;
}

.carts-item-list .carts-item-item .carts-item-head:hover,
.carts-item-list .carts-item-item .carts-item-head.action {
    background: #f4f2fc;
}

.carts-item-list .carts-item-item .carts-item-head:hover .gateway-name,
.carts-item-list .carts-item-item .carts-item-head.action .gateway-name {
    color: var(--text-brand);
}

.carts-item-list .carts-item-item .carts-item-head .gateway-name .name {
    word-wrap: anywhere;
}

.btn-add-section {
    margin-top: 20px;
    padding: 14px 16px;
    border-radius: 4px;
    border: 1px dashed #5036da;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    cursor: pointer;
}

.btn-config-item {
    margin-top: 24px;
    padding-top: 24px;

    border-top: 1px solid #e7e9eb;
}

.btn-tang-giam {
    border-radius: 4px;
    border: 1px solid #e7e9eb;
    height: 36px;
}

.btn-tang-giam input {
    height: 34px !important;
    margin: 0px !important;
    border: unset !important;
}

.btn-tang-giam .btn-icon {
    background: #f3f4f5;
    width: 34px;
    height: 34px;
    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.btn-tang-giam .btn-icon:hover {
    background: #f3f4f590;
}

.action-add-content {
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
    padding: 8px;

    position: relative;
    z-index: 3;
}

.action-add-content label {
    padding: 8px;
}

.add-content-new {
    display: flex;
    flex-direction: column;
}

.add-content-new .add-content-item {
    /* width: 100px; */
    /* height: 100px; */
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}

.add-content-new .add-content-item .icon-antion {
    border-radius: 4px;
    border: 1px solid #cfd3d8;

    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
}

.add-content-new .add-content-item .icon-antion i {
    width: 24px !important;
    height: 24px !important;
}

.add-content-new .add-content-item:hover {
    background: #f3f4f5;
}

.popover-action-page-checkout {
    top: unset !important;
    right: -360px !important;
    left: unset !important;
    bottom: 0px;
    width: 350px;
}

.popover-action-page-checkout.add-channel {
    right: -280px !important;
}

.container-image {
    margin-top: 8px;
    padding: 20px 16px;
}

.container-image .version-upload-image {
    width: 100% !important;
    min-height: 100px !important;
    height: unset !important;
}

.container-image .version-upload-image img {
    max-height: 500px;
}

.container-image .group-upload-image {
    width: 100% !important;
    height: 100% !important;
}

.action-delete {
    border-top: 1px solid #e7e9eb;
    padding: 20px 16px 16px 16px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background: #ffffff;
}

.product-list-body {
    padding: 20px 0px;
}

.product-list-body .product-empty .new-ldicon-home {
    width: 64px !important;
    height: 64px !important;
}

.product-list-add {
    border-radius: 4px;
    padding: 14px 16px;
    border: 1px dashed #5036da;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 12px 24px;
    cursor: pointer;
}

/* .product-list-add  */

.product-list {}

.product-list .product-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 16px 24px;
    cursor: pointer;
}

.product-list .product-item .product-name {
    width: calc(100% - 70px);
}

.product-list .product-item .product-price {
    width: 100px;
    white-space: nowrap;
    text-align: end;
}

.product-list .product-item .product-delete {
    width: 16px;
}

.product-list .product-item:hover {
    background: #f4f2fc;
}

.product-list .product-item .product-info {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.product-list .product-item .product-image {
    width: 52px;
    height: 52px;
    border-radius: 4px;
}

.type-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 20px 24px;
}

.type-header .type-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #e7e9eb;
    width: 100%;
    cursor: pointer;
}

.type-header .type-item.active {
    border: 1px solid #5036da;
}

.color-change {
    width: 56px;
    height: 24px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #e7e9eb;

    cursor: pointer;
}

.change-color-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: fit-content;
    border: 1px solid #cfd3d8;
    border-radius: 8px;
}

.page-footer .change-color-block .popover-picker {
    top: 45px !important;
}

.change-color-block .popover-picker {
    top: unset !important;
    z-index: 2;

}

.change-color-block .ladiui.form-control.color-input {
    border: unset !important;
    width: 100px !important;
    height: 20px !important;
    border-radius: 0px 0px 8px 8px !important;
    text-align: center;
}

.change-color-block .ladiui.form-control.color-input:focus {
    border: unset !important;
}

.config-language {
    padding: 20px 24px 12px 24px;
}

.config-more {
    padding: 12px 24px;
}

.page-checkout-home-body {
    padding: 0px 24px 24px 24px;
}

.page-checkout-search {
    padding: 24px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.page-checkout-search .ladiui.dropdown {
    width: 274px !important;
}

.page-checkout-search .ladiui.search-group {
    width: calc(100% - 260px);
}

.page-checkout-search .ladiui.search-group.store {
    width: 100%;
}

.page-checkout-table {
    position: relative;
}

.page-checkout-table .action-record-table {
    left: 32px !important;
}

.checkout-config-selected-box {
    padding: 20px 24px 16px;
}

.checkout-config-selected-box .checkout-config-selected-box-item {
    border-radius: 4px;
    border: 1px solid #e7e9eb;
    padding: 12px;
    width: 100%;
    cursor: pointer;

    display: flex;
    align-items: center;
}

.checkout-config-selected-box .checkout-config-selected-box-item.active {
    border: 1px solid #5036da;
}

.checkout-config-share-select {
    padding: 16px 24px 12px;
}

.background-config {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 12px;
}

.background-config .background-config-item {
    cursor: pointer;
}

.background-config .background-config-item .background {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #e7e9eb;
    /* width: 108px; */
    /* height: 67px; */
}

.background-config .background-config-item.active .background {
    border: 1px solid #1c00c2;
}

.background-config .background-config-item.active .text-14 {
    color: var(--text-brand);
}

.gradient-directions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gradient-directions .gradient-direction-item {
    border: 1px solid #e7e9eb;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.gradient-directions .gradient-direction-item.active {
    background: #f3f4f5;
}

.gradient-directions .gradient-direction-item .icon-new {
    width: 20px !important;
    height: 20px !important;
}

.channel-list .section-channel {
    padding: 20px;
}

.container-product-info {
    padding: 20px 20px 16px;
    background: #ffffff;
}

.container-product-info .block-display-type .block-content .block-item.left {
    padding: 16px;
}

.container-product-info .block-display-type .block-content .block-item.left .list-pr-exam .pr-item .top {
    width: 40px;
    height: 40px;
}

.container-product-info .block-display-type .block-content .block-item.left .list-pr-exam .pr-item .bottom {
    width: 40px;
}

.container-product-info .block-display-type .block-content .block-item.right {
    padding: 16px;
}

.page-checkout-dns {
    height: calc(100vh - 100px);
}

.btn-add-payment {
    margin: 0px 24px;
    padding: 12px 0px 20px;
    border-radius: 4px;
    /* border: 1px dashed #5036da; */
    display: flex;
    align-items: center;
    justify-content: start;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

.icon-desktop-mobile-content {
    border-left: 1px solid #e7e9eb;
    padding-left: 16px;
}

.redirect-url {
    border-radius: 4px;
    border: 1px solid #cfd3d8;
    padding: 12px;
    width: 100%;
}

.redirect-btn {
    border: 1px solid #1c00c2;
}

.redirect-or {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.redirect-or-boder {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #e7e9eb;
}

.path-input {
    width: 100%;
    position: relative;
}

.path-input .icon-new {
    position: absolute;
    top: 12px;
    right: 10px;
}

.product-btn-add {
    position: sticky;
    bottom: 0px;

    background: #ffffff;
    padding: 12px 0px;
}

.checkout-edit-url {
    border-radius: var(--border-radius-04, 4px);
    border: 1px solid var(--Border-Border-border-01, #CFD3D8);
    background: var(--Layer-layer-01, #FFF);

    padding: 12px 36px 12px 12px;
    height: 40px;
    width: 100%;
    position: relative;
    margin-top: 8px;
}

.checkout-edit-url .new-ldicon-copy {
    position: absolute;
    right: 12px;
}

.coppy-btn {
    border-radius: 4px;
    background: #F3F4F5;

    width: 42px;
    height: 42px;

    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.coppy-btn.disabled {
    cursor: none;
}

.btn-select-domain-store {
    width: calc(100% - 50px);
}

.style-switch {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    margin-bottom: 20px;
}

.style-switch .ladiui.switch {
    margin: 0px;
}

.action-table {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

.action-table .open-page {
    width: 24px;
    height: 24px;
}