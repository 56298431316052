/* ẩn khi làm xoá khi upload */
/* iframe {
    display: none !important;
} */

.page-order {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

.page-order .page-right {
    width: calc(100vw - 294px);
    max-width: 1260px;
    margin: 0px auto;
}

.page-order .page-right .page-content .block-content {
    padding: 0px 24px;
}

.page-order .menu-left {
    width: 230px;
    background: var(--background-background-02, #f3f4f5);
    border-radius: var(--spacing-0-px, 0px);
    border-right: 0.5px solid var(--border-border-border-01, #cfd3d8);
}

.page-order .menu-left .title {
    padding: 24px;
    color: #0e233c;
    font-size: 20px;
    font-weight: 600;
}

.page-order .menu-left .menu-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 12px 24px 32px;
    /* min-height: calc(100vh - 65px); */
}

.page-order .menu-left .menu-list .menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    color: var(--text-secondary);
    cursor: pointer;
}

.page-order .menu-left .menu-list .menu-item span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.page-order .menu-left .menu-list .menu-item.active {
    color: var(--text-brand);
}

.page-order .menu-left .menu-list .menu-item.active i {
    background-color: var(--text-brand);
}

.menu-left .list-order-tags {
    margin: 0px 24px;
    padding-top: 24px;
    border-top: 1px solid var(--border-02-100p);
}

.menu-left .list-order-tags .tag-item {
    border-radius: 3px;
    background: #e5e5e5;
    -ms-word-break: break-all;
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    max-width: 180px;
}

.menu-left .list-order-tags .tag-item.active {
    background: var(--full-color-primary-500, #1c00c2);
    color: #ffffff;
}

.menu-left .list-order-tags .input-search-tag {
    margin-top: 16px;
    width: 100%;
    border-bottom: 1px solid var(--border-border-border-01, #cfd3d8);

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    background: transparent;
}

.page-order .page-content {
    padding: 0px 0px 80px 0px !important;
}

/* .page-order .page-content .DateRangePicker_picker {
    right: 0px;
} */

.page-content .header-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 24px 24px;
}

.page-content .header-page .heading h1 {
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

.page-content .header-page .heading p {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
}

.page-content .header-page .button-action {
    display: flex;
    gap: 16px;
}

.page-content .header-page .button-action .btn-export-order {
    padding: 10px 16px;
    min-width: 60px;
    border-radius: 4px;
    border: 1px solid var(--button-primary);
    background: #ffffff;
    cursor: pointer;

    color: var(--text-brand);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
}

.page-content .header-page .button-action .btn-export-order:hover {
    border: 1px solid var(--button-primary-hover);
    background: var(--layer-brand);
}

.page-content .header-page .button-action .btn-order-create {
    padding: 10px 16px;
    min-width: 60px;
    border-radius: 4px;
    border: unset;
    background: var(--button-primary);
    cursor: pointer;

    color: var(--text-brand);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    display: flex;
    align-items: center;
    gap: 8px;
}

.page-content .header-page .button-action .btn-order-create:hover {
    background: var(--button-hover-button-primary-hover);
}

.page-order .page-content .block-filter {
    padding: 16px 24px 20px 24px;
}

.page-integrate .block-filter {
    padding: 16px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.page-order .page-content .block-filter .block-search {
    display: flex;
    gap: 16px;
}

.page-order .page-content .block-filter .block-search .ladiui.search-field {
    height: 40px;
}

.page-order .page-content .block-filter .block-search .block-filter-button {
    display: flex;
    align-items: center;
    width: 397px;
    gap: 12px;
}

.page-order .page-content .block-filter .block-search .btn-advanced-filter {
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--background-02);
    width: 140px;

    color: var(--text-primary);
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    letter-spacing: unset;

    border: unset;
}

.page-order .page-content .block-filter .block-search .btn-advanced-filter div {
    white-space: nowrap;
}

.page-order .page-content .block-filter .block-search .ladiui.search-group {
    width: calc(100% - 413px);
}

.page-course .block-filter .block-search .ladiui.search-group {
    /* width: calc(100% - 332px) !important; */
}

.page-order .page-content .block-filter .block-search .DateRangePickerInput {
    /* height: 40px;
    border-radius: 4px; */
    display: flex;
    align-items: center;
}

.page-order .page-content .block-filter .block-search .DateRangePickerInput input {
    height: 100%;
}

.page-order .page-content .block-filter .block-search .DateRangePickerInput_clearDates__small {
    top: 50%;
}

.block-condition-v2 {
    display: flex;
    gap: 8px;
}

.block-condition-v2 .background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: auto;
    z-index: 1000;
}

.block-condition-v2 .conditions .item-condition .tag-delete {
    border-radius: 50% !important;
    background-color: #566577 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    padding: 3px !important;
    opacity: 0.5 !important;
}

.block-condition-v2 .conditions .item-condition .tag-delete:hover {
    opacity: 0.8 !important;
}

.block-condition-v2 .conditions .item-condition .tag-delete .ldicon-close {
    background-color: #ffffff;
    width: 10px;
    height: 10px;
}

.block-condition-v2 .add-item-conditions .add-condition-title {
    width: 100%;
    text-align: center;
    border: none;
    color: var(--main-primary);
    cursor: pointer;
    font-size: 14px;
    padding: 8px;
    line-height: 20px;
}

.block-condition-v2 .save-condition .add-condition-title {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 20px;

    border: none;
    color: var(--main-primary);
    cursor: pointer;
    padding: 8px;

    display: flex;
    align-items: center;
    gap: 8px;
}

.block-condition-v2 .save-condition .add-condition-title .ldicon-save {
    background-color: var(--support-brand) !important;
    width: 14px !important;
    height: 14px !important;
}

.block-condition-v2 .add-item-conditions .add-condition {
    position: absolute;
    left: 0;
    top: 0;
    width: 225px;
    z-index: 1001;
}

.block-condition-v2 .add-item-conditions .add-condition .ladiui.filter-box {
    padding: 16px;
    width: 282px;
    background-color: var(--main-white);
    box-shadow: 0 -1px 38px rgba(0, 0, 0, 0.05), 0 4px 7px rgba(0, 0, 0, 0.12);
    z-index: 900000090;
    animation: fadedropdown 0.15s ease-in-out;
    transition: height 0.15s;
}

.block-condition-v2 .add-item-conditions .add-condition .list-condition {
    max-height: 500px;
    overflow: auto;
}

.block-condition-v2 .add-item-conditions .dropdown-toggle.select-dropdown {
    width: 250px;
    color: var(--text-default);
}

.block-condition-v2 .add-item-conditions .footer-list-filter-condition {
    margin-top: 12px;
}

.block-condition-v2 .add-item-conditions .condition-item .input-tag {
    width: 250px;
}

.block-condition-v2 .add-condition .condition-item {
    margin-top: 12px;
}

.block-condition-v2 .add-condition .condition-item .ladiui.tags .ladiui.form-control {
    border-radius: unset !important;
    border: unset !important;
}

.block-condition-v2 .conditions {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.block-condition-v2 .conditions .item-condition {
    padding: 8px;
    border-radius: 4px;
    background: var(--background-02);
    display: flex;
    align-items: center;
    gap: 8px;
}

.block-condition-v2 .conditions .item-condition .name-condition,
.block-condition-v2 .conditions .item-condition .name-item-multi-selected,
.block-condition-v2 .conditions .item-condition .name-selected {
    color: var(--text-default);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.1px;
}

/* content-save-condition */
.content-save-condition .list-item-v2 {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.content-save-condition .list-item-v2 .item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.content-save-condition .list-item-v2 .item label {
    cursor: pointer;
    color: var(--text-primary);
    font-size: 14px;
    line-height: 20px;
}

.ladiui.dropdown-menu.filter-list {
    width: calc(100% - 50px);
}

.block-content .order-tab .item-filter-new {
    display: flex;
    align-items: center;
    gap: 4px;

    position: relative;
}

.item-filter-new .filter-actions {
    flex-direction: column;
    right: 0px !important;
    padding: 0px !important;
}

.item-filter-new .filter-actions.show {
    display: block !important;
}

.item-filter-new .filter-actions.hide {
    display: none !important;
}

.item-filter-new .filter-actions li a {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.item-filter-new .filter-actions li a span {
    font-size: 14px;
    line-height: 20px;
}

.item-filter-new .ladiui.dropdown-toggle {
    min-width: unset;
    /* padding-right: 32px !important; */
    border: unset;
}

.item-filter-new .ladiui.dropdown .ladiui.btn {
    padding-right: 26px !important;
    padding: 0px;
}

.item-filter-new .ladiui.dropdown-toggle::after {
    margin: 17px 14px 14px 6px;
}

/* table-detail-order-detail */

.table-detail-order-detail {
    min-height: 0px;
    height: auto;
    transition-duration: 266ms;

    display: block;
    padding: 20px 24px;
    background: var(--background-02);
    height: auto;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    /* max-width: 1146px; */
}

.table-detail-order-detail .orđer-info {
    display: flex;
    width: 100%;
}

.table-detail-order-detail .orđer-info .table-detail-products {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 50%;
}

.table-detail-order-detail .orđer-info .table-detail-products .order-total {
    color: var(--text-primary);
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-name-image {
    display: flex;
    align-items: center;
    gap: 12px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-image {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-name {
    overflow: hidden;
    color: var(--text-primary);
    text-overflow: ellipsis;
    font-size: 14px;
    max-width: 420px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-options {
    color: var(--text-secondary);
    font-size: 12px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-price {
    color: var(--text-text-primary);
    text-align: right;
    font-size: 14px;
    font-weight: 500;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-quantity {
    color: var(--text-secondary);
    text-align: right;
    font-size: 12px;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info {
    display: block;
    padding: 0px 20px;
    width: 50%;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info .customer-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info .customer-info .customer-info-item {
    display: flex;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info .customer-info .customer-info-item .info-label {
    max-width: 120px;
    width: -webkit-fill-available;

    color: var(--text-secondary);
    font-size: 14px;
    line-height: 20px;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info .customer-info .customer-info-item .info-value {
    width: -webkit-fill-available;

    /* overflow: hidden; */
    color: var(--text-primary);
    /* text-overflow: ellipsis; */
    font-size: 14px;
    line-height: 20px;
    white-space: break-spaces;
}

.table-detail-order-detail .order-action {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 16px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--border-02-100p);
}

.table-detail-order-detail .order-action .action-left {
    display: flex;
    align-items: center;
    gap: 16px;
}

.table-detail-order-detail .order-action .action-left .action-item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.table-detail-order-detail .order-action .action-right {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    border-radius: 4px;
    border: 1px solid var(--button-default-button-primary);
    padding: 14px;
    height: 32px;
    color: var(--button-default-button-primary);
}

.table-detail-order-detail .order-action .action-right i {
    background-color: var(--button-default-button-primary);
}

.order-cancel .order-reference-no {
    color: rgba(108, 114, 147, 0.56) !important;
    text-decoration: line-through;
}

.table-wrap {
    overflow-x: auto;
}

.table-wrap table {
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: auto;
    width: 100%;
    border: none;
    border-radius: 0.5rem;
    white-space: nowrap;
}

.table-wrap table tr>th.fix-column,
.table-wrap table tr>td.fix-column {
    position: sticky;
    left: 0;
    background: #ffffff;
}

/* table tr>th.fix-column-right,
table tr>td.fix-column-right {
    position: sticky;
    right: 0;
    background: #ffffff;
} */

.icon-caret-down-fill.turn-right {
    rotate: -90deg;
}

.dropdown-filter-more {
    border: none !important;
    width: fit-content !important;
}

.duplucate-order {
    border-radius: 4px;
    background: var(--layer-layer-warning-layer-warning, #fffaec);
    color: var(--support-support-warning, #ef9300);
    font-size: 12px;
    padding: 2px 4px;
    line-height: normal;
}

.table-empty {
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-empty .new-ldicon-search {
    width: 100px;
    height: 100px;
    background: #e7e9eb;
}

.page-max {
    width: calc(100vw - 294px);
    height: 100vh;
    overflow: auto;
}

/* .table-scroll .action-record-table .ladiui.dropdown-toggle {
    min-width: unset;
} */

.table-order-tag .action-record-table {
    left: 32px;
}

.order-action .ladiui.dropdown-menu {
    width: 168px;
}

.checkout-content .container-place .ladiui.checkout-url.form-control:disabled {
    background-color: #ffffff !important;
}

.table-order .created_at {
    white-space: normal !important;
}

.table-order .order-id {
    line-height: 1.5;
}