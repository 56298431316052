@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, button, textarea, select {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
a {
    text-decoration: none;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
/*--------------------------------

LadiIcons Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
    font-family: 'LadiIcons';
    src: url(/static/media/LadiIcons.879856c1.eot);
    src: url(/static/media/LadiIcons.879856c1.eot) format('embedded-opentype'), url(/static/media/LadiIcons.6e3206de.woff2) format('woff2'), url(/static/media/LadiIcons.bb325b2e.woff) format('woff'), url(/static/media/LadiIcons.d344f17a.ttf) format('truetype'), url(/static/media/LadiIcons.ee3f0afe.svg) format('svg');
    font-weight: normal;
    font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.ladi-icon {
  display: inline-block;
  font: normal normal normal 1em/1 'LadiIcons';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.ladi-icon-sm {
  font-size: 0.8em;
}
.ladi-icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.ladi-icon-16 {
  font-size: 16px;
}
.ladi-icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.ladi-icon-bg-square,
.ladi-icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.ladi-icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.ladi-icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.ladi-icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.ladi-icon-ul > li > .ladi-icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.ladi-icon-is-spinning {
  animation: ladi-icon-spin 2s infinite linear;
}
@keyframes ladi-icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.ladi-icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  transform: rotate(90deg);
}
.ladi-icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  transform: rotate(180deg);
}
.ladi-icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  transform: rotate(270deg);
}
.ladi-icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  transform: scale(-1, 1);
}
.ladi-icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  transform: scale(1, -1);
}
/*------------------------
  font icons
-------------------------*/

.icon-home::before {
    content: "\ea03";
}

.icon-dots::before {
    content: "\ea04";
}

.icon-c-add::before {
    content: "\ea05";
}

.icon-img::before {
    content: "\ea06";
}

.icon-button::before {
    content: "\ea07";
}

.icon-text::before {
    content: "\ea08";
}

.icon-paragraph::before {
    content: "\ea09";
}

.icon-list-bullet::before {
    content: "\ea0a";
}

.icon-shape-line::before {
    content: "\ea0b";
}

.icon-shape-rectangle::before {
    content: "\ea0c";
}

.icon-shape-star::before {
    content: "\ea0d";
}

.icon-html::before {
    content: "\ea0e";
}

.icon-video-player::before {
    content: "\ea0f";
}

.icon-slider::before {
    content: "\ea10";
}

.icon-gallery::before {
    content: "\ea11";
}

.icon-form::before {
    content: "\ea12";
}

.icon-time-clock::before {
    content: "\ea13";
}

.icon-section::before {
    content: "\ea14";
}

.icon-popup::before {
    content: "\ea15";
}

.icon-app-store::before {
    content: "\ea16";
}

.icon-triangle-down::before {
    content: "\ea17";
}

.icon-triangle-up::before {
    content: "\ea18";
}

.icon-publish::before {
    content: "\ea19";
}

.icon-cloud-download::before {
    content: "\ea1a";
}

.icon-preview::before {
    content: "\ea1b";
}

.icon-b-preview::before {
    content: "\ea1c";
}

.icon-undo::before {
    content: "\ea1d";
}

.icon-redo::before {
    content: "\ea1e";
}

.icon-settings::before {
    content: "\ea1f";
}

.icon-history::before {
    content: "\ea20";
}

.icon-fonts::before {
    content: "\ea21";
}

.icon-align-justify::before {
    content: "\ea22";
}

.icon-align-center::before {
    content: "\ea23";
}

.icon-align-left-2::before {
    content: "\ea24";
}

.icon-mobile::before {
    content: "\ea25";
}

.icon-pc::before {
    content: "\ea26";
}

.icon-back-arrow::before {
    content: "\ea27";
}

.icon-e-remove::before {
    content: "\ea28";
}

.icon-data-upload::before {
    content: "\ea29";
}

.icon-dots-to-close::before {
    content: "\ea2a";
}

.icon-floppy-disk::before {
    content: "\ea2b";
}

.icon-cross-vertical::before {
    content: "\ea2c";
}

.icon-hyperlink::before {
    content: "\ea2d";
}

.icon-link-broken::before {
    content: "\ea2e";
}

.icon-text-color::before {
    content: "\ea2f";
}

.icon-bold::before {
    content: "\ea30";
}

.icon-italic::before {
    content: "\ea31";
}

.icon-underline::before {
    content: "\ea32";
}

.icon-strikethrough::before {
    content: "\ea33";
}

.icon-crop::before {
    content: "\ea34";
}

.icon-duplicate::before {
    content: "\ea35";
}

/* .icon-bin::before {
    content: "\ea36";
} */

.icon-lock::before {
    content: "\ea37";
}

.icon-unlocked::before {
    content: "\ea38";
}

.icon-arrow-up::before {
    content: "\ea39";
}

.icon-arrow-down::before {
    content: "\ea3a";
}

.icon-phone::before {
    content: "\ea3b";
}

.icon-email::before {
    content: "\ea3c";
}

.icon-notify::before {
    content: "\ea3d";
}

.icon-pen-tool::before {
    content: "\ea3e";
}

.icon-themes::before {
    content: "\ea3f";
}

.icon-tag::before {
    content: "\ea40";
}

.icon-domain::before {
    content: "\ea41";
}

.icon-menu::before {
    content: "\ea42";
}

.icon-apps::before {
    content: "\ea43";
}

.icon-zoom::before {
    content: "\ea44";
}

.icon-double-arrow-right::before {
    content: "\ea45";
}

.icon-double-arrow-left::before {
    content: "\ea46";
}

.icon-up-arrow::before {
    content: "\ea47";
}

.icon-right-arrow::before {
    content: "\ea48";
}

.icon-left-arrow::before {
    content: "\ea49";
}

.icon-down-arrow::before {
    content: "\ea4a";
}

.icon-page-setting::before {
    content: "\ea4b";
}

.icon-bill::before {
    content: "\ea4c";
}

.icon-orders::before {
    content: "\ea4d";
}

.icon-products::before {
    content: "\ea4e";
}

.icon-customers::before {
    content: "\ea4f";
}

.icon-users::before {
    content: "\ea50";
}

.icon-discounts::before {
    content: "\ea51";
}

.icon-store::before {
    content: "\ea52";
}

.icon-ring::before {
    content: "\ea53";
}

.icon-maps::before {
    content: "\ea54";
}

.icon-edit::before {
    content: "\ea55";
}

.icon-intergration::before {
    content: "\ea56";
}

.icon-c-warning::before {
    content: "\ea57";
}

.icon-c-question::before {
    content: "\ea58";
}

.icon-crown::before {
    content: "\ea59";
}

.icon-print::before {
    content: "\ea5b";
}

.icon-hot-key::before {
    content: "\ea5c";
}

.icon-keyboard::before {
    content: "\ea5d";
}

.icon-blog::before {
    content: "\ea5e";
}

.icon-chat-support::before {
    content: "\ea5f";
}

.icon-b-chat::before {
    content: "\ea60";
}

.icon-logo-facebook::before {
    content: "\ea61";
}

.icon-logout::before {
    content: "\ea62";
}

.icon-key::before {
    content: "\ea63";
}

.icon-access-key::before {
    content: "\ea64";
}

.icon-project::before {
    content: "\ea65";
}

.icon-affilate::before {
    content: "\ea66";
}

.icon-referral::before {
    content: "\ea67";
}

.icon-dock-bottom::before {
    content: "\ea68";
}

.icon-dock-right::before {
    content: "\ea69";
}

.icon-dock-left::before {
    content: "\ea6a";
}

.icon-dock-top::before {
    content: "\ea6b";
}

.icon-format-left::before {
    content: "\ea6c";
}

.icon-format-right::before {
    content: "\ea6d";
}

.icon-align-left::before {
    content: "\ea6e";
}

.icon-align-right::before {
    content: "\ea6f";
}

.icon-align-top::before {
    content: "\ea70";
}

.icon-align-bottom::before {
    content: "\ea71";
}

.icon-distribute-vertical::before {
    content: "\ea72";
}

.icon-distribute-horizontal::before {
    content: "\ea73";
}

.icon-align-center-vertical::before {
    content: "\ea74";
}

.icon-align-center-horizontal::before {
    content: "\ea75";
}

.icon-caps-all::before {
    content: "\ea76";
}

.icon-check-single::before {
    content: "\ea77";
}

.icon-d-check::before {
    content: "\ea78";
}

.icon-g-check::before {
    content: "\ea79";
}

.icon-delivery::before {
    content: "\ea7a";
}

.icon-palette::before {
    content: "\ea7b";
}

.icon-border-radius::before {
    content: "\ea7c";
}

.icon-sync::before {
    content: "\ea7d";
}

.icon-copy::before {
    content: "\ea7e";
}

.icon-flip-vertical::before {
    content: "\ea7f";
}

.icon-flip-horizontal::before {
    content: "\ea80";
}

.icon-triangle-right::before {
    content: "\ea81";
}

.icon-triangle-left::before {
    content: "\ea82";
}

.icon-refresh::before {
    content: "\ea83";
}

.icon-filter::before {
    content: "\ea84";
}

.icon-mask::before {
    content: "\ea85";
}

.icon-backward::before {
    content: "\ea86";
}

.icon-forward::before {
    content: "\ea87";
}

.icon-rotate::before {
    content: "\ea88";
}

.icon-grid-view::before {
    content: "\ea89";
}

.icon-element-repeater::before {
    content: "\ea8a";
}

.icon-spin-lucky::before {
    content: "\ea8b";
}

.icon-ab-testing::before {
    content: "\ea8c";
}

.icon-report::before {
    content: "\ea8d";
}

.icon-inbox::before {
    content: "\ea8e";
}

.icon-at-sign::before {
    content: "\ea8f";
}

.icon-send-message::before {
    content: "\ea90";
}

.icon-chat-user::before {
    content: "\ea91";
}

.icon-credit-card::before {
    content: "\ea92";
}

.icon-re-open::before {
    content: "\ea93";
}

.icon-add-user::before {
    content: "\ea94";
}

.icon-image::before {
    content: "\ea95";
}

.icon-attach::before {
    content: "\ea96";
}

.icon-smile::before {
    content: "\ea97";
}

.icon-share::before {
    content: "\ea98";
}

.icon-visit-link::before {
    content: "\ea99";
}

.icon-notes::before {
    content: "\ea9a";
}

.icon-logo-fb-simple::before {
    content: "\ea9b";
}

.icon-campaign-flow::before {
    content: "\ea9c";
}

.icon-logo-messenger::before {
    content: "\ea9d";
}

.icon-cart-add::before {
    content: "\ea9e";
}

.icon-cart::before {
    content: "\ea9f";
}

.icon-shopping-cart::before {
    content: "\eaa0";
}

.icon-basket::before {
    content: "\eaa1";
}

.icon-pin::before {
    content: "\eaa2";
}

.icon-move-item::before {
    content: "\eaa4";
}

.icon-move-2::before {
    content: "\eaa5";
}

.icon-remove-background::before {
    content: "\eaa6";
}

.icon-status::before {
    content: "\eaa7";
}

.icon-align-right-2::before {
    content: "\eaa8";
}

.icon-antenna::before {
    content: "\eaa9";
}

.icon-broadcast::before {
    content: "\eaaa";
}

.icon-c-delete::before {
    content: "\eaab";
}

.icon-input::before {
    content: "\eaac";
}

.icon-sms::before {
    content: "\eaad";
}

.icon-layer::before {
    content: "\eaae";
}

.icon-discount-2::before {
    content: "\eaaf";
}


body {
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    width: 100%;
    font-size: 14px;
    color: var(--main-secondary);
}

.icon-menu-dashboards {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
}

.icon-menu-affiliates {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
}

.icon-menu-commissions {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
}

.icon-menu-purchase {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
}

.icon-menu-campains {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
}

.icon-menu-invoices {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
}

/* .icon-menu-reports {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
} */

.icon-menu-sources {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
}

.icon-menu-apps {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-cartbon.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-cartbon.svg) no-repeat 50% 50%;
}

.icon-menu-settings {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-setting.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-setting.svg) no-repeat 50% 50%;
}

.icon-menu-orders {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-order.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-product.svg) no-repeat 50% 50%;
}

.icon-menu-products {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-product.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-product.svg) no-repeat 50% 50%;
}

.icon-menu-payments {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-payment.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-payment.svg) no-repeat 50% 50%;
}

.icon-menu-courses {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/ldicon-course.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/ldicon-course.svg) no-repeat 50% 50%;
}

.icon-menu-customers {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-customer.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/menu-icon-customer.svg) no-repeat 50% 50%;
}

.icon-menu-discounts {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-discount.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-discount.svg) no-repeat 50% 50%;
}

.icon-menu-reports {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-report.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-report.svg) no-repeat 50% 50%;
}

.icon-menu-integrates {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-integrate.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-menu-integrate.svg) no-repeat 50% 50%;
}

th.sort {
    position: relative;
    cursor: pointer;
}

th.sort::before {
    content: '\ea18';
    font-family: LadiIcons;
    font-size: 7px;
    position: absolute;
    right: 5px;
    top: -3px;
    bottom: 0;
    height: 14px;
}

th.sort.sort-desc::before {
    content: '';
}

th.sort::after {
    content: '\ea17';
    font-family: LadiIcons;
    font-size: 7px;
    position: absolute;
    right: 5px;
    top: 3px;
    bottom: 0;
    height: 0;
}

th.sort.sort-asc::after {
    content: '';
}

.hidden {
    display: none !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.clearfix {
    clear: both;
}

.ladiui.item.bg-form.bg-commissionn-plan {
    background-color: #f1f3f4;
}

.group-input-custom {
    position: relative;
}

.ladiui.btn-group.btn-group-custom {
    position: absolute;
    top: 6px;
    right: 12px;
    background: #f1f3f4;
    max-width: 60px;
    border-radius: 2px;
    text-align: center;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-menu {
    min-width: 0px;
    max-width: 60px;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-toggle {
    max-width: 60px;
    min-width: 34px;
    padding: 5px;
    height: 20px;
    line-height: 20px;
    background: #f1f3f4;
}

.ladiui.btn-group.btn-group-custom .btn-group {
    max-width: 60px;
    min-width: 34px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-toggle:after {
    margin: 5px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-item {
    padding: 5px;
}

#create-ladishare-comission-plan .ladiui.switch {
    margin: -3px 10px 0px 10px !important;
}

.box-commission-plan {
    margin-top: 32px;
}

.box-commission-plan .card-header {
    font-size: 16px !important;
}

.ladiui.badge {
    border-radius: 2px;
    height: 20px;
    padding: 2px 6px 2px 6px;
    color: #fff;
    font-size: 12px;
}

.ladiui.badge.badge-success {
    background-color: #41b983;
}

.ladiui.badge.badge-warning {
    background-color: #e89d35;
}

/* .ladiui.card {
    border: 1px solid #F1F3F4;
}

.ladiui.card-header {
    background-color: #F1F3F4;
    border-bottom-color: #F1F3F4;
} */

.box-commission-plan .ladiui.btn-group {
    padding: 0;
}

.box-commission-plan .ladiui.btn-group .ladiui-btn-dropdown.dropdown-toggle {
    transform: rotate(90deg);
    margin-right: -8px;
}

.ladiui.table-list-commission-plan-payment {
    margin-top: 10px;
    min-width: 0;
}

.ladiui.table-list-commission-plan-payment tr {
    border-bottom: 1px solid #eaeaea;
}

.ladiui.table-list-commission-plan-payment tbody tr:last-child {
    border: none;
}

.ladiui.table-list-commission-plan-payment th,
.ladiui.small-label {
    font-size: 14px;
    font-weight: 400;
    color: #727272 !important;
}

.ladiui.content-left {
    width: 318px;
    max-width: 318px;
}

.ladiui.content-right {
    width: calc(100% - 318px);
    max-width: calc(100% - 318px);
    min-width: auto;
}

.ladiui .info-affiliate-detail .info-email {
    color: #727272;
}

.ladiui.group-info {
    padding: 12px 0;
}

.ladiui.group-info p {
    padding-top: 8px;
}

.ladiui.group-info p span {
    color: #41b983;
}

.ladiui.divider {
    margin: 12px -24px;
    border: 1px solid #f1f3f4;
}

.color-grey {
    color: #727272;
}

.ladiui.box-statistics {
    border-radius: 2px;
    height: 94px;
    border: 1px solid #f1f3f4;
    width: calc((100% - 72px) / 4);
    margin: 12px 0;
    padding: 16px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: inherit;
}

.ladiui.box-statistics .decrease,
.ladiui.box-statistics .increase {
    font-size: 10px;
}

.ladiui.box-statistics .increase {
    color: #41b983;
}

.ladiui.box-statistics .decrease {
    color: #d94f45;
}

.ladiui.box-statistics.box-bg-grey {
    background-color: #f1f3f4;
    height: 82px;
    width: calc((100% - 48px) / 3);
}

.ladiui.new-tab-custom {
    position: relative;
    z-index: 1000;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    background-color: #fff;
    transition: width 0.3s;
    overflow: hidden;
}

.ladiui.new-tab-custom:hover {
    display: flex;
    width: auto;
}

.ladiui.action-history {
    margin-top: 14px;
}

.ladiui.affiliate-timeline-note {
    margin-top: 40px;
    align-items: baseline;
    justify-content: space-between;
}

.ladiui.affiliate-timeline-note > div {
    width: calc((100% - 24px) / 2);
}

.ladiui.affiliate-timeline-note .box {
    border: 1px solid #f1f3f4;
    padding: 16px;
    margin-top: 8px;
    border-radius: 2px;
}

.ladiui.affiliate-timeline-note .box.box-note {
    /* min-height: 150px; */
    white-space: pre-wrap;
}

.ladiui.content-main {
    height: auto;
    min-height: 91.5vh;
}

.ladiui.table-scroll {
    overflow: auto;
}

.ladiui.table {
    min-width: auto;
}

.icon-circle {
    background-color: #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ladiui.table-vertical td {
    color: var(--text-primary);
}

.color-1 {
    color: var(--main-secondary) !important;
}

.color-2 {
    color: var(--main-default) !important;
}

.ladiui.subtitle-2.content-tag-text.active {
    color: var(--main-primary) !important;
}

.ladiui.title-text-form-lever2.margin-label {
    margin: 12px 68px 12px 0;
}

.edit-info-affiliate {
    color: var(--main-primary);
}

.edit-info-affiliate i {
    background-color: var(--main-primary) !important;
}

.filter-condtion-area {
    position: relative;
}

.list-filter-condition {
    display: none;
    position: absolute;
    min-width: 100px;
    background: #ffffff;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    border-radius: 2px;
    padding: 16px;
    margin-top: 10px;
    opacity: 1;
    z-index: 1;
}

.list-filter-condition.show {
    display: block;
}

/* .list-filter-condition label {
    width: 80px;
} */

.list-filter-condition .ladiui.dropdown-toggle {
    min-width: 110px;
}

.list-filter-condition input {
    min-width: 170px;
}

.ladiui.content-main-tool.align-item-baseline {
    height: auto !important;
    margin-top: 0;
}

.align-item-baseline {
    align-items: baseline !important;
}

.result-filter-condition {
    position: absolute;
}

.result-filter-condition span {
    margin-top: 12px;
    margin-right: 12px;
}

.ladiui.btn-tag {
    background: #f1f3f4;
    border-radius: 2px;
    color: var(--main-default);
    font-size: 12px;
    line-height: 24px;
    padding: 0 6px;
    /* white-space: pre; */
}

.ladiui.general-setting-content label {
    min-width: 110px;
}

.ladiui.general-setting-content {
    max-width: 600px;
    position: relative;
}

.ladiui.note-content {
    position: relative;
}

.ladiui.note-content button.btn-link {
    position: absolute;
    top: -5px;
    right: -5px;
}

.affiliate-setting-content,
.email-notify-setting-content {
    width: 614px;
}

.affiliate-setting-content > div.flex-row,
.email-notify-setting-content > div.flex-row {
    align-items: baseline;
    justify-content: space-between;
}

.affiliate-setting-content > div.flex-row .label-setting label {
    font-size: 16px;
}

.affiliate-setting-content > div.flex-row .label-setting div {
    color: #6d6d6d;
}

.ladiui.summary {
    background: #f1f3f4;
    border-radius: 2px;
}

.ladiui.summary td {
    padding: 10px 18px 10px 0;
}

.ladiui.summary td span.total-record {
    color: #afafaf;
}

#modal-detail-commission {
    color: var(--main-default);
}

#modal-detail-commission .item-detail-commission {
    padding: 10px 0;
    flex: 1 0 31%;
    margin: 5px;
}

#modal-detail-commission .item-detail-commission label {
    color: #afafaf;
    font-weight: 400;
}

#modal-detail-commission .flex-item-detail-commission {
    flex: 1 0 31%;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-menu,
.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle,
.ladiui.modal .dropdown-custom .btn-group {
    width: 175px !important;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ladiui.ladi-check-all-action a {
    font-weight: normal;
}

.ladiui.list.inline {
    margin: 0;
    padding: 0;
    display: inline-block;
}

.ladiui.list.inline>li {
    display: inline-block;
}

.ladiui.list.inline>li>a {
    padding: 10px;
}

.ladiui.breadcrumbs {
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 15px 0;
}

.ladiui.breadcrumbs>li {
    display: inline-block;
    padding: 5px;
}

.ladiui.breadcrumbs>li:first-child:before {
    content: '';
    margin: 0;
}

.ladiui.breadcrumbs>li:before {
    font: normal normal normal 1em/1 'LadiIcons';
    content: '\ea21';
    margin-left: 0;
    margin-right: 15px;
    font-size: 8px;
    color: #8697a8;
}

.ladiui.breadcrumbs>li,
.ladiui.breadcrumbs>li>a {
    color: #313942;
    font-size: 14px;
}

.ladiui.breadcrumbs>li.active {
    font-weight: 700;
}

.ladiui-input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

/* .ladiui-input-group-prepend {
	margin-left: -1px
} */

.ladiui-input-group .ladiui.input {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.ladiui-input-group-text {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 26px;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    border-right: 0;
}

.ladiui-input-group>.ladiui-input-group-append:last-child>.ladiui-input-group-text:not(:last-child),
.ladiui-input-group>.ladiui-input-group-append:not(:last-child)>.ladiui-input-group-text,
.ladiui-input-group>.ladiui-input-group-prepend>.ladiui-input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ladiui-input-group>.ladiui.input:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ladiui.select {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    height: 40px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    transition: border-color 0.15s ease-out;
}

.ladiui.textarea {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    min-height: 120px;
    border-radius: 4px;
    transition: border-color 0.15s ease-out, box-shadow 0.15s ease-out;
}

.ladiui.form-group {
    /* margin-bottom: 24px; */
    width: 100%;
}

.clearfix {
    clear: both;
}

/* .ladiui.input.focus,
.ladiui.input:focus,
.ladiui.select.dropdown.focus,
.ladiui.textarea:focus {
	border-color: #1853fa;
	outline: 0
} */

.ladiui.dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: 0;
    text-align: left;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    -webkit-tap-highlight-color: transparent;
}

.ladiui.select.dropdown .menu {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    width: auto;
    outline: 0;
    margin: 2px -1px;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    /* box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15); */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    transition: opacity 0.1s ease;
}

.ladiui.dropdown>.text {
    display: inline-block;
    transition: none;
    padding: 6px 30px 6px 12px;
    width: 100%;
    z-index: 100;
    position: relative;
}

.ladiui.select.dropdown {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    left: 0;
    top: 1px;
    transform: rotateZ(0);
    background: #fff;
    display: inline-block;
    line-height: 27px;
    border: 1px solid #ebedf2;
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    position: relative;
}

.ladiui.select.dropdown:after {
    content: '\ea4a';
    width: 30px;
    height: 30px;
    position: absolute;
    font-family: 'LadiIcons';
    top: 5px;
    right: -8px;
    font-size: 13px;
}

.ladiui.dropdown .menu.open {
    display: block;
    left: 0;
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.4s ease;
}

.ladiui.dropdown .menu {
    cursor: auto;
    position: absolute;
    display: none;
    outline: 0;
    top: 100%;
    min-width: -webkit-max-content;
    min-width: max-content;
    margin: 0;
    padding: 0 0;
    background: #fff;
    font-size: 1em;
    text-shadow: none;
    text-align: left;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    transition: opacity 0.1s ease;
    z-index: 11;
    will-change: transform, opacity;
    transition: opacity 0.6s, visibility 0.6s;
}

.ladiui.dropdown .menu>.item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.78571429rem 1.14285714rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    -webkit-touch-callout: none;
}

/* .ladiui.input,
.ladiui.select {
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	-webkit-box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	color: #313942
} */

/* .ladiui.input.error,
.ladiui.select.error {
	border-color: #d50000;
} */

.ladiui.fixed-sidebar .ladiui-aside {
    position: fixed;
    width: 200px;
    top: 57px;
}

.ladiui.fixed-sidebar .ladiui-main {
    width: calc(100% - 200px);
    float: right;
    padding-bottom: 40px;
}

.ladiui.datepicker {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    left: 0;
    transform: rotateZ(0);
    background: #fff;
    display: inline-block;
    line-height: 25px;
    border: 1px solid #ebedf2;
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    position: relative;
    padding: 6px 30px 6px 12px;
}

.ladiui.datepicker:after {
    content: '\ea6b';
    width: 30px;
    height: 30px;
    position: absolute;
    font-family: 'LadiIcons';
    top: 5px;
    right: -5px;
    font-size: 16px;
}

.ladiui.datepicker .date-value {
    color: #313942;
}

/* .ladiui.dropdown-menu { */
/* position: relative;
	display: inline-block;
	background: #fff;
	border-radius: 5px;
	z-index: 1;
	top: 2px
} */

.ladiui.dropdown-menu.rounded {
    border: 1px solid #ebedf2;
}

.ladiui.dropdown-menu-item {
    color: #313942;
    height: 38px;
    padding: 0 12px;
    border: none;
    cursor: pointer;
    line-height: 38px;
}

.ladiui.dropdown-menu-item i {
    font-size: 13px;
    margin-left: 4px;
}

.ladiui.dropdown-submenu {
    display: none;
    position: absolute;
    border-radius: 5px;
    min-width: 160px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    background: #fff;
    top: 43px;
}

.ladiui.dropdown-submenu a {
    color: #313942;
    padding: 6px 12px;
    text-decoration: none;
    display: block;
    text-align: left;
    line-height: 34px;
}

.ladiui.dropdown-submenu a:hover {
    background-color: #ebedf2;
    border: none;
}

/* .ladiui-modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0px;
	left: 0px;
	overflow: hidden;
	opacity: 1;
	transition: all 150ms linear;
	z-index: 15;
	outline: 0;
	display: none;
	background-color: rgba(0, 0, 0, 0.1);
}

.ladiui-modal-content {
	top: calc(5% - 5px);
	left: 0;
	right: 0;
	max-width: 900px;
	margin: 0 auto;
	box-shadow: 0 5px 100px rgba(6, 21, 40, .5);
	background: #fff;
	border-radius: 8px;
	z-index: 21;
	position: relative;
}

.ladiui-modal-header {
	padding: 15px 20px;
	border-bottom: 1px solid rgba(0, 0, 0, .02);
	background: rgba(6, 21, 40, .05);
	position: relative
} */

.ladiui-modal-header i.icon-e-remove {
    font-size: 12px;
}

.ladiui-modal-title {
    font-size: 16px;
    font-weight: 600;
}

.ladiui-close-modal {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 18px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

.ladiui-modal-header h3 {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}

.ladiui-modal-nav {
    border-bottom: 1px solid rgba(6, 21, 40, 0.05);
    height: 55px;
    position: relative;
    padding-top: 10px;
}

.ladiui-modal-container {
    padding: 20px;
    vertical-align: top;
    background: #fff;
    display: inline-block;
    width: calc(100% - 225px);
}

.ladiui-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(53, 53, 53, 0.16);
    z-index: 14;
    height: 100%;
    width: 100%;
}

.ladiui-modal-backdrop.no-bg {
    background: transparent;
}

.visible {
    display: block !important;
}

.hide {
    display: none !important;
}

.ladiui-tabs {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.ladiui-tabs li {
    float: left;
    list-style: none;
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.ladiui-tabs li {
    display: inline-block;
}

/* .ladiui-tabs li.active a {
	color: #1853fa;
	border-bottom: 2px solid #1853fa;
	padding-bottom: 5px
} */

/* .ladiui-tabs li a {
	color: #313942;
	text-decoration: none
} */

.ladiui-tab-content {
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 72vh;
}

.ladiui-tab-item {
    display: none;
}

.ladiui-tab-item.active {
    display: block;
}

.tox .tox-notification {
    display: none !important;
}

.ladiui-col-4 {
    width: 40%;
    float: left;
}

.ladiui-col-8 {
    width: 60%;
    float: right;
}

.ladiui-widget {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.ladiui-widget .ladiui-widget-head {
    height: 40px;
    background: rgba(235, 240, 243, 0.1);
}

.ladiui-widget .ladiui-widget-head h3 {
    line-height: 40px;
    display: block;
    padding: 0;
    margin: 0;
    font-size: 15px;
    text-indent: 20px;
}

.ladiui-widget-body ul {
    margin: 0;
    padding: 0;
}

.ladiui-widget-body>ul>li:last-child {
    border-bottom: 0;
}

.ladiui-widget-body>ul>li {
    border-bottom: 1px solid #ebeef0;
    box-sizing: border-box;
}

.ladiui-widget-body>ul>li>a {
    background: #fff;
    color: #313942;
    box-shadow: none;
    transition: none;
    display: block;
    padding: 10px 12px;
    font-size: 15px;
    font-weight: 500;
    line-height: 45px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.ladiui-form-group>.ladi-col {
    display: block;
}

/* .ladiui-button-upload input[type=file] {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 100%;
	height: 40px
} */

.ladiui-select-options {
    height: 40px;
    border-radius: 4px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
    color: #313942;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    position: relative;
    min-width: 90px;
    z-index: 5;
}

.ladiui-select-options>ul {
    display: none;
    position: absolute;
    top: 41px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    background: #fff;
    border-radius: 4px;
    z-index: 5;
    min-width: 90px;
    width: 100%;
}

.ladiui-select-options>.ladiui-select-init {
    display: block;
    cursor: pointer;
    width: 100%;
    text-indent: 15px;
    border-radius: 4px;
    padding-right: 25px;
    line-height: 38px;
    -webkit-user-select: none;
    user-select: none;
}

.ladiui-select-options>.ladiui-select-init:hover,
.ladiui-select-options>.ladiui-select-init:focus,
.ladiui-select-options:hover,
.ladiui-select-options:focus {
    border-color: #1853fa;
    outline: 0;
    transition: border-color 0.15s ease-out;
}

.ladiui-select-options>.ladiui-select-init:after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    top: 0px;
    right: 10px;
    position: absolute;
    z-index: -1;
}

.ladiui-dropdown>a {
    position: relative;
}

.ladiui-select-options>ul {
    padding: 0px;
    margin: 0px;
}

.ladiui-select-options>ul>li {
    list-style: none;
    cursor: pointer;
}

.ladiui-select-options>ul>li>a {
    cursor: pointer;
    display: block;
    color: #313942;
    width: 100%;
    padding: 0px 12px;
    line-height: 40px;
}

.ladiui-select-options>ul>li>a:hover {
    background: #f8fafb;
}

/* Animations to fade the snackbar in and out */

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

.ladiui-row>.col-50:first-child {
    margin-left: 0px;
}

.ladiui-row>.col-50:last-child {
    margin-right: 0px;
    float: right;
}

.ladiui-alert.error {
    font-size: 12px;
    color: #d50000;
    margin-top: 2px;
}

.ladiui-alert.error::before {
    content: '*';
    color: #d50000;
    padding-right: 5px;
}

.ladiui-spinner {
    margin: 2px auto 0;
    width: 70px;
    text-align: center;
}

.ladiui-spinner>div {
    width: 12px;
    height: 12px;
    background-color: #1853fa;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.8s infinite ease-out both;
}

.ladiui-spinner .bounce1 {
    animation-delay: -0.32s;
}

.ladiui-spinner .bounce2 {
    animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

/*---------------LADIUI CARD-----------------*/

/* .ladiui.card {
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
	background-color: #ffffff;
	border-radius: 4px;
}

.ladiui.card .card-header {
	padding: 15px 20px;
	border-bottom: 1px solid #eaedf0;
	background: rgba(169, 194, 209, 0);
	;
	position: relative;
}

.ladiui.card .card-header h3 {
	padding: 0px;
	margin: 0px;
	font-size: 15px;
	font-weight: 500;
} */

.flex {
    display: flex;
}

.ladiui-row>.col {
    width: 100%;
    margin: 24px;
}

.ladiui-row>.col:first-child {
    margin-left: 0px;
}

.ladiui-row>.col:last-child {
    margin-right: 0px;
}

.fluid {
    padding: 0px !important;
    margin: 0px;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* [type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
} */

/* [type="radio"]:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: -1px;
	top: -1px;
	width: 18px;
	height: 18px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
	color: #fff;
} */

/* [type="radio"]:checked+label:before {
	content: '';
	position: absolute;
	left: -1px;
	top: -1px;
	width: 18px;
	height: 18px;
	border: 1px solid #2977dd;
	border-radius: 100%;
	background: #2977dd;
	color: #fff;
} */

/* [type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
	content: '';
	width: 5px;
	height: 5px;
	position: absolute;
	background: #fff;
	top: 6px;
	left: 7px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
} */

/* [type="radio"]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

[type="radio"]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
} */

.loader {
    position: relative;
}

/* .loader:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
} */

body.loader::before {
    top: calc(50vh - 20px);
    position: fixed;
    z-index: 100;
}

.ladiui-modal-body {
    padding: 15px 20px;
}

.ladiui-modal-content.confirm {
    max-width: 400px;
}

.ladiui-modal-content.confirm .button {
    min-width: 90px;
}

.ladiui-page-loader .background {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: 104px;
    position: relative;
    margin-bottom: 15px;
}

.ladiui-page-loader {
    padding: 12px;
    max-width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 3px;
    padding-bottom: 20px;
}

.ladiui-page-loader .background div {
    background: #fff;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
}

.ladiui-page-loader div.a {
    height: 40px;
    left: 40px;
    right: auto;
    top: 0;
    width: 8px;
}

.ladiui-page-loader div.b {
    height: 8px;
    left: 48px;
    top: 0;
}

.ladiui-page-loader div.c {
    left: 90%;
    top: 8px;
}

.ladiui-page-loader div.d {
    height: 12px;
    left: 48px;
    top: 14px;
}

.ladiui-page-loader div.e {
    left: 95%;
    top: 26px;
}

.ladiui-page-loader div.f {
    height: 10px;
    left: 48px;
    top: 32px;
}

.ladiui-page-loader div.g {
    height: 20px;
    top: 40px;
}

.ladiui-page-loader div.h {
    left: 85%;
    top: 60px;
}

.ladiui-page-loader div.i {
    height: 13px;
    top: 66px;
}

.ladiui-page-loader div.j {
    left: 440px;
    top: 79px;
}

.ladiui-page-loader div.k {
    height: 13px;
    top: 85px;
}

.ladiui-page-loader div.l {
    left: 178px;
    top: 98px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.ladiui.icon::before {
    font-family: 'LadiIcons';
    font-size: 12px;
    padding-right: 5px;
    top: 1px;
    position: relative;
}

/* .ladiui-link {
	color: #1853fa !important;
}

.ladiui-link:hover {
	text-decoration: underline;
} */

.no-shadow {
    box-shadow: none;
    -webkit-box-shadow: none;
}

::placeholder {
    color: #b3b3b3;
    opacity: 1;
    color: #9FA7B1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

:-ms-input-placeholder {
    color: #b3b3b3;
    font-weight: 300;
}

::-ms-input-placeholder {
    color: #b3b3b3;
    font-weight: 300;
}
:root {
    --icon-primary: #566577;
    --icon-primary-2: #3c72f9;
    --icon-on-colors: #ffffff;
    --icon-danger: #e01a1a;

    --support-brand: #1c00c2;
    --button-primary: #1c00c2;

    --button-primary-hover: #1500a6;
    --layer-brand: #f4f2fc;
    --main-white: #ffffff;

    --main-primary: #1c00c2;

    --button-hover-button-primary-hover: #1500a6;

    --button-danger: #e01a1a;

    /* text */
    --text-primary: #0e233c;
    --text-secondary: #566577;
    --text-danger: #c01322;
    --text-brand: #1c00c2;
    --text-default: #202020;
    --full-color-warning-700: #ac5c00;

    /* boder */
    --border-01: #cfd3d8;
    --border-01-50p: #cfd3d880;
    --border-02: #e7e9eb80;
    --border-02-100p: #e7e9eb;
    --border-brand: #5036da;
    --border-dange: #ec5d4f;

    /* --background-background */
    --background-02: #f3f4f5;

    /* --button */
    --button-default-button-primary: #1c00c2;

    --layer-layer-warning-layer-warning-hover: #fef2cb;
}

/* ẩn khi làm xoá khi upload */
/* iframe {
    display: none !important;
} */

.page-order {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

.page-order .page-right {
    width: calc(100vw - 294px);
    max-width: 1260px;
    margin: 0px auto;
}

.page-order .page-right .page-content .block-content {
    padding: 0px 24px;
}

.page-order .menu-left {
    width: 230px;
    background: var(--background-background-02, #f3f4f5);
    border-radius: var(--spacing-0-px, 0px);
    border-right: 0.5px solid var(--border-border-border-01, #cfd3d8);
}

.page-order .menu-left .title {
    padding: 24px;
    color: #0e233c;
    font-size: 20px;
    font-weight: 600;
}

.page-order .menu-left .menu-list {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding: 12px 24px 32px;
    /* min-height: calc(100vh - 65px); */
}

.page-order .menu-left .menu-list .menu-item {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    border-radius: 4px;
    color: var(--text-secondary);
    cursor: pointer;
}

.page-order .menu-left .menu-list .menu-item span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.page-order .menu-left .menu-list .menu-item.active {
    color: var(--text-brand);
}

.page-order .menu-left .menu-list .menu-item.active i {
    background-color: var(--text-brand);
}

.menu-left .list-order-tags {
    margin: 0px 24px;
    padding-top: 24px;
    border-top: 1px solid var(--border-02-100p);
}

.menu-left .list-order-tags .tag-item {
    border-radius: 3px;
    background: #e5e5e5;
    -ms-word-break: break-all;
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    max-width: 180px;
}

.menu-left .list-order-tags .tag-item.active {
    background: var(--full-color-primary-500, #1c00c2);
    color: #ffffff;
}

.menu-left .list-order-tags .input-search-tag {
    margin-top: 16px;
    width: 100%;
    border-bottom: 1px solid var(--border-border-border-01, #cfd3d8);

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    background: transparent;
}

.page-order .page-content {
    padding: 0px 0px 80px 0px !important;
}

/* .page-order .page-content .DateRangePicker_picker {
    right: 0px;
} */

.page-content .header-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 24px 24px;
}

.page-content .header-page .heading h1 {
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

.page-content .header-page .heading p {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
}

.page-content .header-page .button-action {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
}

.page-content .header-page .button-action .btn-export-order {
    padding: 10px 16px;
    min-width: 60px;
    border-radius: 4px;
    border: 1px solid var(--button-primary);
    background: #ffffff;
    cursor: pointer;

    color: var(--text-brand);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
}

.page-content .header-page .button-action .btn-export-order:hover {
    border: 1px solid var(--button-primary-hover);
    background: var(--layer-brand);
}

.page-content .header-page .button-action .btn-order-create {
    padding: 10px 16px;
    min-width: 60px;
    border-radius: 4px;
    border: unset;
    background: var(--button-primary);
    cursor: pointer;

    color: var(--text-brand);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.page-content .header-page .button-action .btn-order-create:hover {
    background: var(--button-hover-button-primary-hover);
}

.page-order .page-content .block-filter {
    padding: 16px 24px 20px 24px;
}

.page-integrate .block-filter {
    padding: 16px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 12px;
    gap: 12px;
}

.page-order .page-content .block-filter .block-search {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
}

.page-order .page-content .block-filter .block-search .ladiui.search-field {
    height: 40px;
}

.page-order .page-content .block-filter .block-search .block-filter-button {
    display: flex;
    align-items: center;
    width: 397px;
    grid-gap: 12px;
    gap: 12px;
}

.page-order .page-content .block-filter .block-search .btn-advanced-filter {
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--background-02);
    width: 140px;

    color: var(--text-primary);
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
    letter-spacing: unset;

    border: unset;
}

.page-order .page-content .block-filter .block-search .btn-advanced-filter div {
    white-space: nowrap;
}

.page-order .page-content .block-filter .block-search .ladiui.search-group {
    width: calc(100% - 413px);
}

.page-course .block-filter .block-search .ladiui.search-group {
    /* width: calc(100% - 332px) !important; */
}

.page-order .page-content .block-filter .block-search .DateRangePickerInput {
    /* height: 40px;
    border-radius: 4px; */
    display: flex;
    align-items: center;
}

.page-order .page-content .block-filter .block-search .DateRangePickerInput input {
    height: 100%;
}

.page-order .page-content .block-filter .block-search .DateRangePickerInput_clearDates__small {
    top: 50%;
}

.block-condition-v2 {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.block-condition-v2 .background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: auto;
    z-index: 1000;
}

.block-condition-v2 .conditions .item-condition .tag-delete {
    border-radius: 50% !important;
    background-color: #566577 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    padding: 3px !important;
    opacity: 0.5 !important;
}

.block-condition-v2 .conditions .item-condition .tag-delete:hover {
    opacity: 0.8 !important;
}

.block-condition-v2 .conditions .item-condition .tag-delete .ldicon-close {
    background-color: #ffffff;
    width: 10px;
    height: 10px;
}

.block-condition-v2 .add-item-conditions .add-condition-title {
    width: 100%;
    text-align: center;
    border: none;
    color: var(--main-primary);
    cursor: pointer;
    font-size: 14px;
    padding: 8px;
    line-height: 20px;
}

.block-condition-v2 .save-condition .add-condition-title {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 20px;

    border: none;
    color: var(--main-primary);
    cursor: pointer;
    padding: 8px;

    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.block-condition-v2 .save-condition .add-condition-title .ldicon-save {
    background-color: var(--support-brand) !important;
    width: 14px !important;
    height: 14px !important;
}

.block-condition-v2 .add-item-conditions .add-condition {
    position: absolute;
    left: 0;
    top: 0;
    width: 225px;
    z-index: 1001;
}

.block-condition-v2 .add-item-conditions .add-condition .ladiui.filter-box {
    padding: 16px;
    width: 282px;
    background-color: var(--main-white);
    box-shadow: 0 -1px 38px rgba(0, 0, 0, 0.05), 0 4px 7px rgba(0, 0, 0, 0.12);
    z-index: 900000090;
    animation: fadedropdown 0.15s ease-in-out;
    transition: height 0.15s;
}

.block-condition-v2 .add-item-conditions .add-condition .list-condition {
    max-height: 500px;
    overflow: auto;
}

.block-condition-v2 .add-item-conditions .dropdown-toggle.select-dropdown {
    width: 250px;
    color: var(--text-default);
}

.block-condition-v2 .add-item-conditions .footer-list-filter-condition {
    margin-top: 12px;
}

.block-condition-v2 .add-item-conditions .condition-item .input-tag {
    width: 250px;
}

.block-condition-v2 .add-condition .condition-item {
    margin-top: 12px;
}

.block-condition-v2 .add-condition .condition-item .ladiui.tags .ladiui.form-control {
    border-radius: unset !important;
    border: unset !important;
}

.block-condition-v2 .conditions {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
}

.block-condition-v2 .conditions .item-condition {
    padding: 8px;
    border-radius: 4px;
    background: var(--background-02);
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.block-condition-v2 .conditions .item-condition .name-condition,
.block-condition-v2 .conditions .item-condition .name-item-multi-selected,
.block-condition-v2 .conditions .item-condition .name-selected {
    color: var(--text-default);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.1px;
}

/* content-save-condition */
.content-save-condition .list-item-v2 {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.content-save-condition .list-item-v2 .item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.content-save-condition .list-item-v2 .item label {
    cursor: pointer;
    color: var(--text-primary);
    font-size: 14px;
    line-height: 20px;
}

.ladiui.dropdown-menu.filter-list {
    width: calc(100% - 50px);
}

.block-content .order-tab .item-filter-new {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;

    position: relative;
}

.item-filter-new .filter-actions {
    flex-direction: column;
    right: 0px !important;
    padding: 0px !important;
}

.item-filter-new .filter-actions.show {
    display: block !important;
}

.item-filter-new .filter-actions.hide {
    display: none !important;
}

.item-filter-new .filter-actions li a {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    width: 100%;
}

.item-filter-new .filter-actions li a span {
    font-size: 14px;
    line-height: 20px;
}

.item-filter-new .ladiui.dropdown-toggle {
    min-width: unset;
    /* padding-right: 32px !important; */
    border: unset;
}

.item-filter-new .ladiui.dropdown .ladiui.btn {
    padding-right: 26px !important;
    padding: 0px;
}

.item-filter-new .ladiui.dropdown-toggle::after {
    margin: 17px 14px 14px 6px;
}

/* table-detail-order-detail */

.table-detail-order-detail {
    min-height: 0px;
    height: auto;
    transition-duration: 266ms;

    display: block;
    padding: 20px 24px;
    background: var(--background-02);
    height: auto;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    /* max-width: 1146px; */
}

.table-detail-order-detail .orđer-info {
    display: flex;
    width: 100%;
}

.table-detail-order-detail .orđer-info .table-detail-products {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;

    width: 50%;
}

.table-detail-order-detail .orđer-info .table-detail-products .order-total {
    color: var(--text-primary);
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-name-image {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-image {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-name {
    overflow: hidden;
    color: var(--text-primary);
    text-overflow: ellipsis;
    font-size: 14px;
    max-width: 420px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-options {
    color: var(--text-secondary);
    font-size: 12px;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-price {
    color: var(--text-text-primary);
    text-align: right;
    font-size: 14px;
    font-weight: 500;
}

.table-detail-order-detail .orđer-info .table-detail-products .product-info .product-quantity {
    color: var(--text-secondary);
    text-align: right;
    font-size: 12px;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info {
    display: block;
    padding: 0px 20px;
    width: 50%;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info .customer-info {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    margin-top: 16px;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info .customer-info .customer-info-item {
    display: flex;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info .customer-info .customer-info-item .info-label {
    max-width: 120px;
    width: -webkit-fill-available;

    color: var(--text-secondary);
    font-size: 14px;
    line-height: 20px;
}

.table-detail-order-detail .orđer-info .table-detail-customer-info .customer-info .customer-info-item .info-value {
    width: -webkit-fill-available;

    /* overflow: hidden; */
    color: var(--text-primary);
    /* text-overflow: ellipsis; */
    font-size: 14px;
    line-height: 20px;
    white-space: break-spaces;
}

.table-detail-order-detail .order-action {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    grid-gap: 16px;
    gap: 16px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--border-02-100p);
}

.table-detail-order-detail .order-action .action-left {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.table-detail-order-detail .order-action .action-left .action-item {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

.table-detail-order-detail .order-action .action-right {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;

    border-radius: 4px;
    border: 1px solid var(--button-default-button-primary);
    padding: 14px;
    height: 32px;
    color: var(--button-default-button-primary);
}

.table-detail-order-detail .order-action .action-right i {
    background-color: var(--button-default-button-primary);
}

.order-cancel .order-reference-no {
    color: rgba(108, 114, 147, 0.56) !important;
    text-decoration: line-through;
}

.table-wrap {
    overflow-x: auto;
}

.table-wrap table {
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: auto;
    width: 100%;
    border: none;
    border-radius: 0.5rem;
    white-space: nowrap;
}

.table-wrap table tr>th.fix-column,
.table-wrap table tr>td.fix-column {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background: #ffffff;
}

/* table tr>th.fix-column-right,
table tr>td.fix-column-right {
    position: sticky;
    right: 0;
    background: #ffffff;
} */

.icon-caret-down-fill.turn-right {
    rotate: -90deg;
}

.dropdown-filter-more {
    border: none !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.duplucate-order {
    border-radius: 4px;
    background: var(--layer-layer-warning-layer-warning, #fffaec);
    color: var(--support-support-warning, #ef9300);
    font-size: 12px;
    padding: 2px 4px;
    line-height: normal;
}

.table-empty {
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-empty .new-ldicon-search {
    width: 100px;
    height: 100px;
    background: #e7e9eb;
}

.page-max {
    width: calc(100vw - 294px);
    height: 100vh;
    overflow: auto;
}

/* .table-scroll .action-record-table .ladiui.dropdown-toggle {
    min-width: unset;
} */

.table-order-tag .action-record-table {
    left: 32px;
}

.order-action .ladiui.dropdown-menu {
    width: 168px;
}

.checkout-content .container-place .ladiui.checkout-url.form-control:disabled {
    background-color: #ffffff !important;
}

.table-order .created_at {
    white-space: normal !important;
}

.table-order .order-id {
    line-height: 1.5;
}
.page-content.order-create-v2 {
    padding: 0;
}

.order-create-v2 .order-breadcrumbs {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;

    color: var(--text-secondary);
    font-size: 16px;
    line-height: 24px;
}

.order-create-v2 .header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1220px;
    margin: 0px auto;
}

.order-create-v2 .header-o {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 100;
    border-bottom: 0.5px solid var(--border-01-50p);
}

.order-create-v2 .order-create {
    background: #E7E9EB;
}

.order-create-v2 .content .order-info {
    display: flex;
    align-items: flex-start;
    /* justify-content: space-between; */
    margin-top: 20px;
}

.order-create-v2 .content .order-info .order-product-payment {
    /* min-width: 775px; */
    margin-right: 20px;
}

.order-create-v2 .content .order-info .order-product-payment .ladiui.container-place {
    width: 710px;
}

.order-create-v2 .content .order-info .order-customer-info-tags {
    width: 375px;
}

/* .order-create-v2 .content .order-info .order-customer-info-tags .order-customer-info,
.order-create-v2 .content .order-info .order-customer-info-tags .order-tags,
.order-create-v2 .content .order-info .order-customer-info-tags .order-note,
.order-create-v2 .content .order-info .order-product-payment .order-products,
.order-create-v2 .content .order-info .order-product-payment .order-payment {
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    border: 0.5px solid var(--border-02);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
} */

.order-products .product-search {
    margin: 12px 0px;
    z-index: 15;
}

.order-products .ladiui.icon.icon-search {
    top: 2px !important;
    left: 0px !important;
    right: unset !important;
    margin: 10px 10px;
}

.order-products .ladiui.search-field {
    padding-left: 36px !important;
    position: unset;
    height: 40px;
}

.product-variant-search {
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(32, 32, 32, 0.15);
    width: 100%;
    position: absolute;
    max-height: 0px;
    overflow: auto;
    z-index: 10;
}

.product-variant-search.show {
    max-height: 540px;
}

/* .product-variant-search.hide {
    display: block;
} */
.product-variant-search .variant-page.footer {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 16px;
    gap: 16px;
}

.product-variant-search .variant-page.footer .ldicon-chevron-right,
.product-variant-search .variant-page.footer .ldicon-chevron-left {
    position: unset !important;
    width: 16px;
    height: 16px;
}

.product-variant-search .variant-page.footer i.disable {
    opacity: 0.3;
}

.product-variant-search .product-custom-btn {
    padding: 16px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

.product-variant-search .product-custom-btn .new-ldicon-add-circle {
    position: unset !important;
}

.product-variant-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: block;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    height: calc(500px - 80px);
    overflow: auto;
}

.product-variant-list .product-variant-item {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    border-top: 0.5px solid var(--border-border-border-02, #e7e9eb);
}

.product-variant-list .product-variant-item:hover {
    background: var(--layer-layer-brand-layer-brand, #f4f2fc);
}

.product-variant-list .product-variant-item:hover .variant-name,
.product-variant-list .product-variant-item:hover .variant-price {
    color: var(--text-brand);
}

.product-variant-list .product-variant-item .image-name-option {
    display: flex;
    align-items: center;
}

.product-variant-list .product-variant-item .image-name-option .name-option {
    max-width: 500px;
}

.product-variant-list .product-variant-item .image-name-option img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}

.order-products .product-empty {
    width: 176px;
    margin: 40px auto;
    text-align: center;
}

.order-products .product-empty i {
    width: 64px;
    height: 64px;
}

.variant-selected-list {}

.variant-selected-list .variant-selected-item {
    padding: 12px 0px;
}

.variant-selected-list .variant-selected-item .variant-image-name-option {
    width: 40%;
    padding-right: 24px;
}

.variant-selected-list .variant-selected-item .variant-image-name-option .variant-image {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}

.variant-selected-list .variant-selected-item .variant-quatity {
    width: 20%;
    padding-right: 24px;
    display: flex;
    justify-content: center;
}

.variant-quantity-up-down {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid var(--border-01);
    width: 80px;
    height: 40px;
}

.variant-quantity-up-down input {
    width: 28px;
}

.variant-quantity-up-down .variant-up-down {
    display: flex;
    flex-direction: column;
}

.variant-selected-list .variant-selected-item .variant-price-discount {
    width: 17%;
    padding-right: 24px;
    text-align: right;
}

.variant-selected-list .variant-selected-item .variant-total {
    width: 20%;
    text-align: right;
    padding-right: 24px;
    color: var(--text-primary);
}

.variant-selected-list .variant-selected-item .variant-delete {
    width: 3%;
    text-align: right;
}

.variant-selected-list .variant-selected-item.boder-top {
    border-top: 0.5px solid var(--border-02);
}

.price-box .popover-discount {
    padding: 20px 16px;
}

.price-box .popover-discount .popover-discount-type-value {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    text-align: left;
}

.price-box .popover-discount .popover-discount-type-value .discount-type {
    width: 140px;
}

.price-box .popover-discount .popover-discount-type-value .discount-type .ladiui.dropdown-toggle {
    min-width: 140px;
}

.price-box .popover-discount .popover-discount-type-value .discount-value {
    width: 212px;
}

.price-box .actions {
    padding: 12px 16px;
    border-top: 0.5px solid var(--border-02);

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-assign .dropdown-toggle {
    background: transparent;
    border: unset !important;
    min-width: unset;
    color: var(--text-secondary);
}

.order-assign .ladiui.btn-outline-light:hover {
    background-color: transparent;
}

.order-assign .ladiui.dropdown-toggle::after {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down.svg) no-repeat center;
    right: -10px;
    top: 2px !important;
}

.order-payment .payment-title {
    padding-bottom: 12px;
}

.order-payment .payment-info {
    padding: 12px 0px 24px 0px;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.order-payment .payment-info .paymnet-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 12px;
    gap: 12px;
}

.price-box .popover-shipping {
    padding: 20px;
}

.price-box .popover-shipping .list-item-v2 {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.price-box .popover-shipping .list-item-v2 .item {
    display: flex;
    align-items: center;
}

.price-box .popover-shipping .shiping-fee {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.price-box .popover-shipping .shipping-fee-name {
    width: 65%;
}

.order-customer-info .order-customer-title {
    padding-bottom: 12px;
}

.order-customer-info .customer-search {
    margin: 12px 0px;
}

.customers-search-list {
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(32, 32, 32, 0.15);
    width: 100%;
    position: absolute;
    max-height: 0px;
    overflow: auto;
    z-index: 15;
}

.customers-search-list.show {
    max-height: 500px;
}

/* .product-variant-search.hide {
    display: block;
} */

.customers-search-list .customer-create-btn {
    padding: 16px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

.customers-search-list .customer-create-btn i {
    position: unset !important;
}

.customer-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: block;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    max-height: calc(500px - 50px);
    overflow: auto;
}

.customer-list .customer-item {
    padding: 12px 16px !important;
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;

    width: 100%;
    cursor: pointer;
    border-top: 0.5px solid var(--border-02);
}

.customer-list .customer-item:hover {
    background: var(--layer-brand);
}

.customer-list .customer-item:hover div {
    color: var(--text-brand);
}

.order-customer-info .customer-name {
    color: var(--text-brand) !important;
    padding: 12px 0px 20px;
}

.order-customer-info .customer-info-detail .customer-item {
    padding: 20px 0px;
    border-top: 0.5px solid var(--border-02);
}

.order-customer-info .customer-info-detail .customer-item.customer-billing {
    padding-bottom: 0px;
}

.order-customer-info .customer-info-detail .customer-item .customer-action {
    cursor: pointer;
}

.order-customer-info .customer-info-detail .customer-item .item-detail {
    display: flex;
    align-items: start;
}

.order-customer-info .customer-info-detail .customer-item .item-detail .item-label {
    width: 100px;
}

.order-customer-info .customer-info-detail .customer-item .item-detail .item-value {
    width: 226px;

    color: var(--text-primary);
    font-size: 14px;
    line-height: 20px;
}

.order-customer-info .customer-empty {
    width: 190px;
    margin: 40px auto;
    text-align: center;
}

.order-customer-info .customer-empty i {
    width: 64px;
    height: 64px;
}

.customer-address .customer-address-label {
    position: relative;
}

.search-order-tag {
    margin: 12px 0px;
}

.order-tag-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
}

.order-tag-list .order-tag-list-item {
    border-radius: 4px;
    padding: 4px 8px;

    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.order-note-value {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--border-01);
    width: 100%;
}

.order-head-actions {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.order-head-actions .ldicon-link {
    rotate: -45deg;
    width: 16px !important;
    height: 16px !important;
}

.price-box.popover-checkout {
    top: 42px !important;
    width: 600px;
}

.popover-checkout .url-checkout {
    border-radius: 4px;
    padding: 10px 12px;
    border: 1px solid var(--border-01);
}

.popover-checkout .url-checkout .url {
    width: 300px;
    line-break: anywhere;
}

.popover-checkout .checkout-config-list {
    width: 100% !important;
}

.popover-checkout .checkout-config-list .ladiui.dropdown-text {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 16px !important;
}

.order-custom-fields {
    margin-top: 16px;
    border-top: 1px solid var(--border-02);
    padding-top: 16px;
}

.custom-fields .ladiui-label {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 4px;
}

.order-note {
    position: relative;
}

.modal-refund-content {
    background: var(--background-02);
    padding: 20px 24px;
}
.shipping-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
}

.shipping-content .product-table-item {
    display: flex;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
    padding: 12px 0px;
}
.shipping-content .product-table-item.variant {
    border-top: 0.5px solid var(--border-02);
}
.shipping-content .product-table-item .variant-image-name-option {
    width: 340px;
    text-align: left;
}
.shipping-content .product-table-item .variant-quatity {
    width: 80px;
    text-align: center;
}
.shipping-content .product-table-item .variant-weight {
    width: 120px;
    text-align: right;
}

.shipping-content .shipping-unit {
    width: 627px;
}
.shipping-content .shipping-address-info {
    width: 374px;
}

.shipping-content .product-table-item .variant-image-name-option .variant-image {
    border-radius: 4px;
    width: 48px;
    height: 48px;
}

.vtpost-form,
.ghtk-form,
.vnpost-form,
.ghn-form {
    padding: 16px 0px;
    border-top: 1px solid var(--border-02);
}

.shipping-address-ghn {
    padding: 20px 0px;
    border-top: 1px solid var(--border-02);
}


.btn-order-table {
    padding: 0px 10px;
    font-size: 14px;
    line-height: 32px;
}

.btn-add-product {
    color: var(--main-primary);
    font-size: 14px;
    line-height: 20px;
}

.center-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
}

.footer-order {
    padding-top: 24px;
    border-top: 1px solid #f1f3f4;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.footer-order .item-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
}
/* .footer-order #dropdown-customer-field {
    width: 455px !important;
} */

.w-49-p {
    width: 49% !important;
}

.title-lable {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.footer-lable {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
}

.item-product-add-new {
    padding-top: 20px;
    cursor: pointer;
}

.item-product-add-new:first-child {
    padding-top: 0px;
}

.item-product-add-new .product-name:hover {
    color: var(--main-primary);
}

.list-product {
    overflow: auto;
    max-height: 450px;
    padding: 5px;
}

.w-70 {
    width: 70px;
}

.w-310 {
    width: 310px;
}

.item-variant {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: solid 1px #f1f3f4;
    padding: 24px 0px;
}

.item-variant:first-child {
    padding-top: 0px !important;
}

.item-variant-name-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 82px;
}

.ladiui-order-left .item-variant-name-info .item-title {
    margin-bottom: 14px;
}

.item-variant-price {
    display: flex;
    border: solid 1px #f1f3f5;
    margin-top: -10px;
}

.item-variant-price-input {
    text-align: center;
    border: none !important;
    width: 53px !important;
}

.item-variant-list-option {
    display: flex;
    flex-direction: column;
}

.info-customer {
    background: #ffffff;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    /* padding: 20px !important; */
}

.order-status {
    background: #41b983;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    font-size: 12px;
    padding: 2px 8px;
    color: #ffffff;
}

.btn-action-bc {
    border: 1px solid var(--main-primary);
    width: 32px;
    height: 32px;
    text-align: center;
}

.title-16 {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: var(--main-default) !important;
}

.order-status.Canceled {
    background: #dfe4e8 !important;
    color: #000000;
}

.order-status.refund-all {
    background: #dfe4e8 !important;
    color: #000000;
}

.ladiui-order-right {
    width: 330px;
    position: absolute;
    /* top: 199px; */
    right: 24px;
}

.ladiui-order-right .more-info {
    display: grid;
}

.w-name-product {
    width: calc(100% - 610px) !important;
}

.email-check-send {
    position: absolute !important;
    left: 30px !important;
}

.list-product-type {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.list-product-type .item-product {
    width: 49%;
    background: #ffffff;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    padding: 24px 16px;
    margin-bottom: 16px;
}

.list-product-type .item-product:hover {
    background-color: #f3f4f5;
}

.list-product-type .item-product .block-icon .img{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f5;
    border-radius: 50%;
    margin-right: 20px;
}
.list-product-type .item-product:hover .block-icon .img {
    background-color: #fff;
}

.list-product-type .item-product .text-content h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: var(--text-text-primary, #0E233C);
    margin-bottom: 8px;
}

.list-product-type .item-product .text-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #566577;
}

.input-price::after {
    background-size: cover;
    content: 'đ';
    height: 8px;
    margin: 14px 14px 14px 16px;
    position: absolute;
    right: 0;
    width: 8px;
}

.product-create {
    width: 620px;
    margin: auto;
    margin-top: 80px;
}

.sub-icon-group {
    position: absolute;
    right: 12px;
    top: 16px;
    color: #727272;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.ladiui.tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: #ffffff;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    padding: 4px 8px;
    /* padding: 0px 8px; */
}

.tags .tag {
    width: unset;
    margin-right: 10px;
}

.btn-coppy {
    margin-left: 10px;
}

/* style font */
.ladiui .item-title {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: var(--main-default) !important;
}

.block {
    display: block;
}

.t-145 {
    top: 145px !important;
}

.p-0 {
    padding: 0px !important;
}

.p-2 {
    padding: 2px !important;
}

.p-16 {
    padding: 16px !important;
}

.p-24 {
    padding: 24px !important;
}

/* padding top  */

.pt-0 {
    padding-top: 0 !important;
}

.pt-8 {
    padding-top: 8px !important;
}

.pt-12 {
    padding-top: 12px !important;
}

.pt-16 {
    padding-top: 16px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-24 {
    padding-top: 24px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

/* padding right */

.pr-0 {
    padding-right: 0 !important;
}

.pr-4 {
    padding-right: 4px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-16 {
    padding-right: 16px !important;
}

.pr-18 {
    padding-right: 18px !important;
}

.pr-24 {
    padding-right: 24px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-32 {
    padding-right: 32px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

/* padding bottom  */

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-4 {
    padding-bottom: 4px !important;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-12 {
    padding-bottom: 12px !important;
}

.pb-16 {
    padding-bottom: 16px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-24 {
    padding-bottom: 24px !important;
}

/* padding left  */
.pl-0 {
    padding-left: 0 !important;
}

.pl-4 {
    padding-left: 4px !important;
}

.pl-16 {
    padding-left: 16px !important;
}

.pl-24 {
    padding-left: 24px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.w-unset {
    width: unset !important;
    padding: 0px !important;
}

.w-auto {
    width: auto !important;
}

.w-30p {
    width: 30% !important;
}

.w-50-p {
    width: 50% !important;
}

.w-60-p {
    width: 60% !important;
}

.w-80-p {
    width: 80% !important;
}

.w-100-p {
    width: 100% !important;
}

.w-44 {
    width: 44px !important;
}

.w-50 {
    width: 50px !important;
}

.w-80 {
    width: 80px !important;
}

.w-100 {
    width: 100px !important;
}

.w-120 {
    width: 120px !important;
}

.w-150 {
    width: 150px !important;
}

.w-160 {
    width: 160px !important;
}

.w-175 {
    width: 175px !important;
}

.w-180 {
    width: 180px !important;
}

.w-190 {
    width: 190px !important;
}

.w-200 {
    width: 200px !important;
}

.w-220 {
    width: 220px !important;
}

.w-250 {
    width: 250px !important;
}

.w-270 {
    width: 270px !important;
}

.w-290 {
    width: 290px !important;
}

.w-300 {
    width: 300px !important;
}

.w-400 {
    width: 400px !important;
}

.w-424 {
    width: 424px !important;
}

.w-440 {
    width: 440px !important;
}

.w-500 {
    width: 500px !important;
}

.w-553 {
    width: 553px !important;
}

.w-576 {
    width: 576px !important;
}

.w-578 {
    width: 578px !important;
}

.w-600 {
    width: 600px !important;
}

.w-800 {
    width: 800px !important;
}

.w-900 {
    width: 900px !important;
}

.min-w-30 {
    min-width: 30px !important;
}

.pd-0 {
    padding: 0px !important;
}

.pd-3 {
    padding: 3px !important;
}

.m-12 {
    margin: 12px !important;
}

.m-0 {
    margin: 0px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-3 {
    margin-left: 3px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-12 {
    margin-left: 12px !important;
}

.ml-16 {
    margin-left: 16px !important;
}

.ml-18 {
    margin-left: 18px !important;
}

.ml-24 {
    margin-left: 24px !important;
}

.ml-32 {
    margin-left: 32px !important;
}

.ml-64 {
    margin-left: 64px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-14 {
    margin-top: 14px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-18 {
    margin-top: 18px !important;
}

.mt-19 {
    margin-top: 19px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-22 {
    margin-top: 22px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-32 {
    margin-top: 32px !important;
}

.mt-34 {
    margin-top: 34px !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-4 {
    margin-right: 4px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-11 {
    margin-right: 11px;
}

.mr-12 {
    margin-right: 12px !important;
}

.mr-14 {
    margin-right: 14px !important;
}

.mr-16 {
    margin-right: 16px !important;
}

.mr-18 {
    margin-right: 18px;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-24 {
    margin-right: 24px !important;
}

.mr-28 {
    margin-right: 28px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-4 {
    margin-bottom: 4px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.mb-27 {
    margin-bottom: 27px !important;
}

.lh-32 {
    line-height: 32px;
}

.bold-500 {
    font-weight: 500 !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.box-shadow-none {
    box-shadow: unset !important;
}

.display-none {
    display: none !important;
}

.r-0 {
    right: 0px !important;
}

.pd-5 {
    padding: 5px !important;
}

.t-40 {
    top: 40px !important;
}

.t-300 {
    top: 300px !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-line-through {
    text-decoration: line-through;
}

.border-none {
    border: none !important;
}

.m-0 {
    margin: 0px !important;
}

.pos-rel {
    position: relative !important;
}

.bt-0 {
    bottom: 0px !important;
}

.bt-36 {
    bottom: 36px !important;
}

.pointer {
    cursor: pointer;
}

.flex-item-start {
    align-items: flex-start !important;
}

.wp-50 {
    width: 49% !important;
}

.flex-start {
    justify-content: flex-start !important;
}

.flex-end {
    justify-content: end !important;
}

.flex {
    display: flex !important;
    /* justify-content: space-between; */
}

.space-between {
    justify-content: space-between;
}

.items-baseline {
    align-items: baseline !important;
}

.w100 {
    width: 100% !important;
}

.my-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.my-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.py-12 {
    padding: 12px 0px !important;
}

.m-0-12 {
    margin: 0px 12px;
}

.h-auto {
    height: auto !important;
}

.relative {
    position: relative !important;
}

.absolute {
    position: absolute;
}

.cursor-pointer {
    cursor: pointer !important;
}

.color-default {
    color: var(--main-primary) !important;
}

.py-24 {
    padding: 24px 0px !important;
}

.items-center {
    align-items: center !important;
}

.items-end {
    align-items: end !important;
}

.transparent {
    background-color: transparent !important;
}

.t-317 {
    top: 317px !important;
}

.boder-search {
    background: #ffffff;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
}

.px-5 {
    padding: 0px 5px !important;
}

.inline-block {
    display: inline-block !important;
}

.h-36 {
    height: 36px !important;
}

.no-border {
    border: none !important;
}

.justify-content-center {
    justify-content: center !important;
}

.space-nowrap {
    white-space: nowrap !important;
}

.position-initial {
    position: initial !important;
}

.bold-400 {
    font-weight: 400 !important;
}

.input_err {
    border: 1px solid #9d4f45 !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.justify-start {
    justify-content: flex-start !important;
}

.text-w500 {
    font-weight: 500;
}

.text-w600 {
    font-weight: 600;
}

.text-primary {
    color: var(--main-primary);
}

.text-sub-title {
    color: #9FA7B1;
    font-size: 12px;
    line-height: 20px;
}

.text-12 {
    font-size: 12px !important;
}

.text-14 {
    font-size: 14px !important;
}

.text-16 {
    font-size: 16px !important;
}

.text-18 {
    font-size: 18px !important;
}

.font-500 {
    font-weight: 500 !important;
}

.gap-4 {
    grid-gap: 4px !important;
    gap: 4px !important;
}

.gap-5 {
    grid-gap: 5px !important;
    gap: 5px !important;
}

.gap-8 {
    grid-gap: 8px !important;
    gap: 8px !important;
}

.gap-12 {
    grid-gap: 12px !important;
    gap: 12px !important;
}

.gap-16 {
    grid-gap: 16px;
    gap: 16px;
}

.gap-20 {
    grid-gap: 20px !important;
    gap: 20px !important;
}

.gap-24 {
    grid-gap: 24px !important;
    gap: 24px !important;
}

.ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: var(--line);
    -webkit-box-orient: vertical;
}

.text-placeholder {
    color: #a6a6a6;
    color: var(--text-text-placeholder, #a6a6a6);
    text-align: center;
    line-height: 20px;
}

.text-placeholder,
.text-support-info {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.text-left {
    text-align: left !important;
}

.text-nowrap {
    white-space: nowrap;
}

.text-secondary {
    color: #566577;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.align-items-start {
    align-items: flex-start !important;
}

.pointer-events-none {
    pointer-events: none;
}

.justify-center {
    justify-content: center !important;
}

.hr-01 {
    width: 100%;
    border-bottom: 0.5px solid #cfd3d8;
}

.align-items-center {
    align-items: center !important;
}

.top-10 {
    top: 10px !important;
}

.right-10 {
    right: 10px !important;
}

.size-13 {
    width: 13px !important;
    height: 13px !important;
    -webkit-mask-size: 13px !important;
}

.line-height-24 {
    line-height: 24px !important;
}

.position-unset {
    position: unset !important;
    max-height: none !important;
    overflow: unset !important;
}

.mx-auto {
    margin: 0px auto !important;
}

.display-flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.height-40 {
    height: 40px !important;
}

.height-100p {
    height: 100% !important;
}

.px-24 {
    padding: 0 24px !important;
}

.top-8 {
    top: 8px !important;
}

.scroll-mt-150 {
    scroll-margin-top: 150px;
}

.min-h-auto {
    min-height: auto !important;
}

.input-no-focus:focus {
    border: none !important;
}

.w-max-content {
    width: -webkit-max-content !important;
    width: max-content !important;
}

.max-w-200px {
    width: 200px !important;
}
#sidebar-left .ladiui.flex-row-menu-item {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

#sidebar-left .ladiui.menu-item {
    padding: 4px;
    border-left: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sidebar-left .logo {
    display: flex;
    margin: 16px auto;
    align-items: center;
    justify-content: center;
}

#sidebar-left .menu .tooltip {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

#sidebar-left .ladiui.menu-item.active>.tooltip,
#sidebar-left .ladiui.menu-item:hover>.tooltip {
    background-color: #f4f2fc;
}

#sidebar-left .ladiui.nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-left: 1px solid #e7e9eb;
    border-right: 1px solid #e7e9eb;
    width: 60px;
}

#sidebar-left .tooltip .tooltiptext.right:after {
    top: 9px !important;
}

#sidebar-left .account-tool-setting {
    display: flex;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
    justify-content: start;
    flex-direction: column;
    padding-bottom: 24px;
}

#sidebar-left .account-tool-setting .tool-help-fix-top .list-tool {
    left: 60px !important;
    bottom: 75px !important;
}

#sidebar-left .account-tool-setting .ladiui.account.dropdown-menu {
    left: 60px !important;
    bottom: 20px !important;
}

/* -------------------------------------------tyle phần tích hợp------------------------------------------------------------------ */
.ladiui.content-main-full.max-w-1220 {
    max-width: 1220px;
    padding: 0;
}

.ladiui.content-list-landingpage {
    display: block !important;
}

.content-list-landingpage.content-list-landingpage-v3 {
    padding: 24px;
    min-height: calc(100vh + 50px);
}

.sticky-header-template {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4;
}

.header-actions {
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
    padding: 24px 24px 0;
}

.header-actions,
.header-actions-top {
    display: flex;
    justify-content: flex-start;
}

.header-actions-top {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
}

.header-actions-top .widget-left {
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
}

.ladiui.alert.create-flow-alert {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.header-actions-top .widget-left .title-heading h3 {
    display: flex;
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.header-actions-top .widget-left .title-desc p {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #566577;
}

.tabs-v3 {
    padding-bottom: 5px;
}

.w-full {
    width: 100% !important;
}

.tabs-v3 .ladiui.nav-tabs {
    border-bottom: 1px solid #e7e9eb;
}

.ladiui.tab-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.flex-row-start-start {
    align-items: flex-start;
}

.flex-row-center-start,
.flex-row-start-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.list-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-row-gap: 12px;
    grid-row-gap: 12px;
    row-gap: 12px;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
    grid-column-gap: 12px;
    column-gap: 12px;
    flex-wrap: wrap;
}

.list-block-item {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    padding: 4px 8px;
    align-items: center;
    border-radius: 4px;
    background-color: #f4f4f4;
    cursor: pointer;
    color: #566577;
    color: var(--text-text-secondary, var(--text-text-secondary, #566577));
}

.list-block-item.active {
    border: 1px solid #a895f8;
    padding: 3px 7px;
}

.list-block-item.active,
.list-block-item:not([class*='title']):hover {
    background-color: #f4f2fc;
    color: var(--main-primary);
}

.grid-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-columns: var(--column, 1fr 1fr);
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
    grid-column-gap: 8px;
    column-gap: 8px;
    grid-row-gap: 8px;
    grid-row-gap: 8px;
    row-gap: 8px;
}

.select-box-item,
.select-box-item-none-hover {
    display: flex;
    width: 100%;
    padding: 16px;
    align-items: flex-start;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
    border-radius: 8px;
    border-radius: var(--spacing-2, 8px);
}

.select-box-item:hover {
    cursor: pointer;
}

.select-box-item-border,
.select-box-item:hover {
    background: #f4f2fc;
    background: var(--full-color-primary-50, #f4f2fc);
}

#system-template .select-box-item-icon,
.select-box-item-icon {
    border: 0.5px solid #cfd3d8;
    background-color: #fff;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    border-radius: var(--spacing-2, 8px);
}

#system-template .select-box-item-icon {
    width: 64px;
    height: 64px;
}

.select-box-item:hover .select-box-item-icon {
    background: #fff;
}

#system-template .select-box-item-icon img {
    width: 40px;
    height: 40px;
}

.select-box-item-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
}

.ladiui.caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.ladiui.modal-header.custom-v4 {
    padding: 20px 24px;
    border-bottom: 1px solid #e7e9eb;
    justify-content: flex-start;
    align-items: flex-start;
}

.no-data-wrapper {
    border-radius: 4px;
    height: 334px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon-form-integration {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 8px;
}

.select-box-item-icon img {
    width: 24px;
    height: 24px;
}

.ladiui.table-vertical th {
    vertical-align: middle;
    height: 20px;
    padding: 16px 12px;
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
}

.ladiui.table-vertical td {
    padding: 16px 12px;
}

.ladiui.table-vertical.header .checkall {
    width: 40px;
}

.line-dotted {
    width: 100%;
    border-top: 1px dashed #566577;
    border-top: 1px dashed var(--text-text-secondary, #566577);
}

.connect-count:hover div {
    color: #1c00c2;
}

.list-steps-integrate {
    margin-bottom: 24px;
}

.list-steps-integrate ul {
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 24px; */
}

.list-steps-integrate ul>li {
    display: flex;
    align-items: center;
}

.list-steps-integrate ul>li::after {
    content: '';
    background-image: url('https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg');
    border-radius: 16px;
    margin: 0px 24px;
    width: 16px;
    height: 16px;
    background-size: cover;
}

.list-steps-integrate ul>li:last-child::after {
    display: none;
}

.list-steps-integrate ul>li .step-number {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #e7e9eb;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #566577;
}

#modal-form-account .block-info-connect-account {
    margin-bottom: 24px;
}

#modal-form-account .block-info-connect-account .icon-logo-connect {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 24px;
    gap: 24px;
}

#modal-form-account .block-info-connect-account h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0e233c;
    text-align: center;
    margin-top: 24px;
}

#modal-form-account .block-info-connect-account p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #566577;
    text-align: center;
    margin-top: 4px;
}

/* #modal-form-account, #modal-setting-form-config-account {
    animation: none;
} */
#modal-form-account .ladiui.modal-content,
#modal-setting-form-config-account .ladiui.modal-content {
    animation: none;
}

/* -----------------------------------------------------------------style trang sản phẩm----------------------------------------------------------------------------------------------- */
.page-product-v2 {}

.page-product-v2 .page-content .header-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 24px 24px;
}

.page-product-v2 .page-content .header-page .heading h3 {
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

.page-product-v2 .page-content .header-page .heading p {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.btn.btn-import-export {
    padding: 10px 16px;
    min-width: 60px;
    border-radius: 4px;
    border: 1px solid var(--button-primary) !important;
    background: #ffffff;
    cursor: pointer;
    color: var(--text-brand);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
}

.btn.btn-import-export:hover {
    background: var(--layer-brand);
}

.page-product-v2 .page-content .block-filter-search {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    padding: 0;
}

.page-product-v2 .page-content .block-filter-search .ladiui.search-width {
    min-width: 314px;
}

.page-product-v2 .page-content .block-filter-search .btn-advanced-filter {
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--background-02);
    width: 140px;
    color: var(--text-primary);
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

#page-content-product .block-content .product-tab ul li {
    display: flex;
    align-items: center;
}

.block-content .product-tab .item-filter-new {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    grid-gap: 4px;
    gap: 4px;
    position: relative;
}

#page-product-new {
    display: block;
}

.menu-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
}

.menu-header .content {
    max-width: 1220px;
    padding: 16px 24px 0px;
    margin: 0 auto;
}

.top-menu-header {
    padding-top: 24px;
}

.menu-header .top-menu-header ul {
    display: flex;
    align-items: center;
}

.menu-header .top-menu-header ul>li {
    display: flex;
    align-items: center;
}

.menu-header .top-menu-header ul>li:first-child::after {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url('https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg');
    background-size: cover;
    margin: 0 8px;
}

.menu-header .title-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-header .title-action h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.menu-header .title-action button {
    position: relative;
    /* top: 12px; */
}

#page-product-new .page-content-product-new {
    background-color: #E7E9EB;
}

#page-product-new .page-content-product-new .page-content {
    background-color: transparent;
}

.page-content-product-new .page-product-info {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
}

@media (min-width: 978px) {
    .page-content-product-new .page-product-info .content-left {
        width: calc(100% - 395px);
    }

    .page-content-product-new .page-product-info .content-right {
        width: 100%;
        max-width: 375px;
    }
}

@media (max-width: 977px) {
    .page-content-product-new .page-product-info .content-left {
        width: calc(100%);
    }

    .page-content-product-new .page-product-info .content-right {
        width: 100%;
    }

}



.block-content-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.block-content-item h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ladiui.form-group .ladiui-label.new {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
    padding-bottom: 4px !important;
}

.block-content-item .block-info-help {
    padding-top: 16px;
    border-top: 0.5px solid var(--border-border-border-02, #e7e9eb);
    margin-top: 12px;
}

.block-content-item .block-info-help span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
}

/* .list-image-product-v2 .image-none .ladiui-button-image input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
} */

.block-content-item .block-list-images .list-image-product-v2 .image-none .ladiui-button-image {
    width: 100%;
    padding: 48px 24px;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    background: var(--full-color-grey-50, #f3f4f5);
    box-shadow: 0px 0px 0px 3px #fff inset;
}

.block-content-item .block-list-images .list-image-product-v2 .image-none .ladiui-button-image .text-default {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-content-item .block-list-images .list-image-product-v2 .image-none .ladiui-button-image .rec-size {
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
    color: var(--text-secondary);
}

.block-content-item .block-list-images .list-image-product-v2 .list-images {
    /* display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px; */
    display: grid;
    grid-template-columns: repeat(auto-fill, 134px);
    /* grid-auto-rows: 50px; */
    grid-gap: 12px;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image {
    width: 134px;
    height: 134px;
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 0px 4px #fff inset;
    overflow: hidden;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image img {
    width: 100%;
    object-fit: cover;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .ladiui-button-upload-image {
    width: 134px;
    height: 134px;
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 0px 4px #fff inset;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .block-icon-star {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 40px;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image:hover .block-icon-star {
    display: block;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image:hover .block-delete-img {
    display: block;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image:hover .block-reup-img {
    display: block;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image:first-child {
    grid-column: span 2;
    width: 100%;
    height: 280px;
    grid-row: span 2;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .block-delete-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 3px;
}


.block-content-item .block-list-images .list-image-product-v2 .list-images .block-reup-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 75px;
}


.block-content-item .block-list-images .item-image:hover .ladi-icon.icon-bin {
    background-color: var(--main-white);
}

.block-content-item .advanced-settings {
    display: block;
    margin-top: 12px;
}

.block-content-item .advanced-settings .item {
    display: flex;
    align-items: center;
    padding: 12px 0;
}

.block-content-item .advanced-settings .item span {
    color: var(--text-text-primary, var(--text-text-primary, #0e233c));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ladiui.dropdown-toggle.custom-weight::after {
    top: 0px !important;
    margin: 14px 8px 14px 12px;
}

.page-content-product-variants .block-content-item .product-variant-none {
    padding: 48px;
    display: flex;
    flex-direction: column;
    text-align: center;
    grid-gap: 20px;
    grid-gap: 20px;
    gap: 20px;
    border-top: 1px solid #f1f3f4;
    margin-top: 20px;
}

.page-content-product-variants .block-content-item .product-variant-none h3 {
    color: var(--text-text-secondary, #566577);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.page-content-product-variants .block-content-item .product-variant-none p {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.page-content-product-variants .block-content-item .product-variant-none button {
    display: flex;
    width: 600px;
    min-width: 60px;
    padding: 10px var(--spacing-16px, 16px);
    justify-content: center;
    align-items: center;
    grid-gap: var(--spacing-8px, 8px);
    gap: var(--spacing-8px, 8px);
    border-radius: var(--Border, 4px);
    border: 1px dashed var(--border-border-brand-border-brand, #5036da);
    background: var(--layer-layer-01, #fff);
    margin: 0 auto;
    color: #1c00c2;
    cursor: pointer;
}

.page-content-product-variants .block-content-item .product-variant-none button .icon-ldp-add {
    width: 18px !important;
    height: 18px !important;
    background-color: #1c00c2;
}

.page-content-product-variants .block-content-item .product-variant-none {
    padding: 48px;
    display: flex;
    flex-direction: column;
    text-align: center;
    grid-gap: 20px;
    grid-gap: 20px;
    gap: 20px;
    border-top: 1px solid #f1f3f4;
    margin-top: 20px;
}

.page-content-product-variants .block-content-item .product-variant-none h3 {
    color: var(--text-text-secondary, #566577);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.page-content-product-variants .block-content-item .product-variant-none p {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.block-content-item .block-table-list-variants {
    margin-top: 12px;
    border-top: 1px solid #f1f3f4;
}

.block-content-item .block-list-selected-product-upsells .none-product {
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-content-item .block-list-selected-product-upsells .none-product .img-text span {
    color: var(--text-text-secondary, #566577);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
}

.block-content-item .block-list-selected-product-upsells .block-list table .image-product .img img {
    object-fit: cover;
    margin-left: 12px;
}

.popover-image-product {
    background-color: #fff;
    left: 0 !important;
    padding: 20px 16px;
    border-radius: 8px;
    width: 400px;
    z-index: 99999;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.popover-image-product .action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-top: 24px;
    border-top: 1px solid #f1f3f4;
}

.product-tag-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
}

.product-tag-list .product-tag-list-item {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    grid-gap: 8px;
    gap: 8px;
    background-color: #f3f4f5;
    border-radius: 8px;
}

.ladiui.btn {
    height: 42px;
}

.block-panel-ticket-v4 {
    margin-top: 20px;
    border-top: 1px solid var(--border-border-border-02, #e7e9eb);
}

.block-panel-ticket-v4 .block-no-ticket {
    padding: 45px 0;
    text-align: center;
}

.block-panel-ticket-v4 .block-no-ticket {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.block-panel-ticket-v4 .block-no-ticket h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-secondary);
}

.block-panel-ticket-v4 .block-no-ticket button {
    display: flex;
    width: 100%;
    padding: 10px var(--spacing-16px, 16px);
    justify-content: center;
    align-items: center;
    grid-gap: var(--spacing-8px, 8px);
    gap: var(--spacing-8px, 8px);
    border-radius: var(--Border, 4px);
    border: 1px dashed var(--border-border-brand-border-brand, #5036da);
    background: var(--layer-layer-01, #fff);
    color: #1c00c2;
    cursor: pointer;
}

.block-panel-ticket-v4 .block-no-ticket button .icon-ldp-add {
    width: 18px !important;
    height: 18px !important;
    background-color: #1c00c2;
}

.block-panel-ticket-v4 #modal-event .ladiui.modal-body {
    background-color: #f3f4f5;
}

#tab-event-code-management .block-content-item .header-top {
    padding-bottom: 16px;
    border-bottom: 1px solid #f3f4f5;
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .header-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .header-action .pull-left {
    width: 100%;
    margin-right: 16px;
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .header-action .pull-left .ladiui.search-keyword {
    width: 100% !important;
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .ladi-card .block-user-info p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-secondary);
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .ladi-card .block-user-info .user-name {
    color: #0e233c;
}

.block-panel-package-v4 {
    margin-top: 20px;
    border-top: 1px solid var(--border-border-border-02, #e7e9eb);
}

.block-panel-package-v4 .block-no-package {
    padding: 45px 0;
    text-align: center;
}

.block-panel-package-v4 .block-no-package {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.block-panel-package-v4 .block-no-package h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-secondary);
}

.block-panel-package-v4 .block-no-package button {
    display: flex;
    width: 100%;
    padding: 10px var(--spacing-16px, 16px);
    justify-content: center;
    align-items: center;
    grid-gap: var(--spacing-8px, 8px);
    gap: var(--spacing-8px, 8px);
    border-radius: var(--Border, 4px);
    border: 1px dashed var(--border-border-brand-border-brand, #5036da);
    background: var(--layer-layer-01, #fff);
    color: #1c00c2;
    cursor: pointer;
}

.block-panel-package-v4 .block-no-package button .icon-ldp-add {
    width: 18px !important;
    height: 18px !important;
    background-color: #1c00c2;
}

.block-panel-package-v4 #modal-event .ladiui.modal-body {
    background-color: #f3f4f5;
}

.block-ckeck-ticket-status .sold::before {
    content: '';
    display: inline-block;
    background: #248a00;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(36, 138, 0, 0.2);
    background-clip: padding-box;
}

.block-ckeck-ticket-status .not-sold::before {
    content: '';
    display: inline-block;
    background: #bcbcbc;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(188, 188, 188, 0.2);
    background-clip: padding-box;
}

.block-package-cancel::before {
    content: '';
    display: inline-block;
    background: #e01a1a;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #ef930033;
    background-clip: padding-box;
}

#modal-import-package-seat-v4 {
    width: calc(100% - 48px) !important;
}

.ldicon-drag-drop {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.icon-product-sub-menu {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.page-product-v2 .menu-list .menu-item.active i {
    background-color: #1c00c2;
}

.block-nodata-search-product {
    padding: 174px 0 !important;
}

.block-nodata-search-product .icon-search {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-search.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-search.svg);
    background-color: #e7e9eb;
    width: 90px;
    height: 90px;
    display: inline-block;
    margin-bottom: 24px;
}

.block-nodata-search-product h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-secondary);
    margin-bottom: 12px;
}

.block-nodata-search-product p {
    font-weight: 400;
    font-size: 14px;
    margin-top: 12px;
    line-height: 24px;
    color: var(--text-secondary);
}

.ladiui.tab-pane {
    margin-top: 0 !important;
}

.product-status-v4 .active::before {
    content: '';
    display: inline-block;
    background: #248a00;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(36, 138, 0, 0.2);
    background-clip: padding-box;
}

.product-status-v4 .inactive::before {
    content: '';
    display: inline-block;
    background: #bcbcbc;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(188, 188, 188, 0.2);
    background-clip: padding-box;
}

.new-ldicon-custom-field {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-custom-field.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-custom-field.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-tag {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-tag.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-tag.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-review {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-review.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-review.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-template {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-template.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-template.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-warehouse {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-warehouse.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-warehouse.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-box-sub-menu {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-box.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-box.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#modal-export-data-by-type .block-content {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    gap: 20px;
}

#modal-export-data-by-type .block-content .content-item .block-list {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

#modal-export-data-by-type .block-content .content-item .block-list .item {
    display: flex;
    align-items: center;
}

#modal-export-data-by-type .block-content .content-item h3 {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
    line-height: 20px;
}

#modal-import-data-by-type .block-content {
    /* display: grid;
    grid-template-columns: auto auto;
    gap: 20px; */
}

#modal-import-data-by-type .block-content .content-item .block-list {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

#modal-import-data-by-type .block-content .content-item .block-list .item {
    display: flex;
    align-items: center;
}

#modal-import-data-by-type .block-content .content-item h3 {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
    line-height: 20px;
}

.add-quantity-inventory-v4 .qty-popup {
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #f3f4f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
}

.new-ldicon-control {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-control.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-control.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.add-quantity-inventory-v4 {
    display: flex;
    justify-content: end;
}

.add-quantity-inventory-v4 .qty-popup .icon-show-popup {
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;
}

.add-quantity-inventory-v4 .qty-popup .icon-show-popup:hover {
    background-color: #e7e9eb;
}

.popover-qty-inventory {
    background-color: #fff;
    left: auto !important;
    right: 0 !important;
    padding: 20px 16px;
    border-radius: 8px;
    width: 400px;
    z-index: 99999;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.popover-qty-inventory .popover-add-qty-inventory .content-header {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 16px;
    gap: 16px;
}

.popover-add-qty-inventory .content-header .btn-group {
    padding: 4px;
    border-radius: 4px;
    background-color: #f3f4f5;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 8px;
    gap: 8px;
}

.popover-add-qty-inventory .content-header .btn-group .btn-item {
    padding: 6px auto;
    width: 100%;
    cursor: pointer;
}

.popover-add-qty-inventory .content-header .btn-group .btn-item.active {
    background-color: #fff;
    color: #0e233c;
}

.popover-add-qty-inventory .product-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.popover-add-qty-inventory .action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid #e7e9eb;
}

.page-category-v4 .menu-header {
    padding: 24px 24px 24px 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
}

.page-category-v4 .menu-header .content {
    max-width: 1220px;
    padding: 0 24px;
    margin: 0 auto;
}

.page-category-v4 .menu-header .top-menu-header ul {
    display: flex;
    align-items: center;
}

.page-category-v4 .menu-header .top-menu-header ul li {
    display: flex;
    align-items: center;
}

.page-category-v4 .menu-header .top-menu-header ul>li:first-child:after {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    background-size: cover;
    margin: 0 8px;
}

.page-category-v4 .menu-header .title-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.page-category-v4 .menu-header .title-action h3 {
    color: var(--text-text-primary, #0e233c);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}

.page-category-v4 .page-content-category {
    background-color: #f3f4f5;
}

.page-category-v4 .page-content-category .page-category-info {
    display: flex;
    flex-direction: row;
}

.page-category-v4 .page-content-category .page-category-info .content-left {
    width: calc(100% - 395px);
}

.page-category-v4 .page-content-category .page-category-info .content-right {
    width: 100%;
    max-width: 375px;
    margin-left: 20px;
}

.block-list-products-category-v4 .no-products {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 64px 0;
}

.block-list-products-category-v4 .no-products .new-ldicon-template {
    width: 64px;
    height: 64px;
}

.block-list-products-category-v4 .list-products {
    margin-top: 8px;
}

.block-content-item .block-upload-image-category {
    display: flex;
    padding: 48px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    /* flex: 1 0 0; */
    align-self: stretch;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    background: var(--full-color-grey-50, #f3f4f5);
    box-shadow: 0px 0px 0px 3px #fff inset;
}

.block-content-item .block-upload-image-category .image {
    width: 24px;
    height: 24px;
}

.block-content-item .block-upload-image-category .image img {
    width: 100%;
    object-fit: contain;
}

.new-ldicon-photo {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-photo.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-photo.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.block-content-item .block-upload-image-category .text-add-image {
    display: flex;
    align-items: center;
}

.block-content-item .list-image-product-reviews .image-none .ladiui-button-image {
    width: 100%;
    padding: 48px 24px;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    background: var(--full-color-grey-50, #f3f4f5);
    box-shadow: 0px 0px 0px 3px #fff inset;
}

.block-content-item .list-image-product-reviews .image-none .ladiui-button-image .text-default {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-content-item .list-image-product-reviews .image-none .ladiui-button-image .rec-size {
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
    color: var(--text-secondary);
}

.block-content-item .list-image-product-reviews .list-images {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
}

.block-content-item .list-image-product-reviews .list-images .image-item {
    width: 134px;
    height: 134px;
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 0px 4px #fff inset;
    overflow: hidden;
    position: relative;
}

.block-content-item .list-image-product-reviews .list-images .image-item .img {
    width: 100%;
    height: 100%;
}

.block-content-item .list-image-product-reviews .list-images .image-item .action-group {
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    position: absolute;
    top: 10px;
    display: none;
    right: 10px;
}

.block-content-item .list-image-product-reviews .list-images .image-item:hover .action-group {
    display: flex;
}

.block-content-item .list-image-product-reviews .list-images .image-item .action-group .block-delete-img {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
}

.block-content-item .list-image-product-reviews .action-group .ladi-icon.icon-bin {
    background-color: #fff;
}

#modal-add-product-review .ladiui.modal-content .ladiui.modal-body {
    background-color: #f3f4f5;
}

.block-content-item .list-image-product-reviews .list-images .image-item .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.block-content-item .list-image-product-reviews .list-images .ladiui-button-upload-image {
    width: 134px;
    height: 134px;
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: url(<path-to-image>), #d3d3d3 50% / cover no-repeat;
    box-shadow: inset 0 0 0 4px #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.popover-image-category {
    background-color: #fff;
    right: 0 !important;
    left: auto !important;
    padding: 20px 16px;
    border-radius: 8px;
    width: 400px;
    z-index: 99999;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.popover-image-category .action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-top: 24px;
    border-top: 1px solid #f1f3f4;
}

.block-content-item .block-show-image-category .image {
    width: 100%;
    height: 132px;
    position: relative;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    background: var(--full-color-grey-50, #f3f4f5);
    box-shadow: 0px 0px 0px 3px #fff inset;
    overflow: hidden;
}

.block-content-item .block-show-image-category .image .action-group {
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    position: absolute;
    top: 10px;
    display: none;
    right: 10px;
}

.block-content-item .block-show-image-category .image:hover .action-group {
    display: flex;
}

.block-content-item .block-show-image-category .image .action-group .block-icon-upload {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
}

.block-content-item .block-show-image-category .image .action-group .block-delete-img {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
}

.block-show-image-category .action-group .ladi-icon.icon-bin {
    background-color: #fff;
}

#modal-add-product-review .ladiui.modal-content .ladiui.modal-body {
    background-color: #f3f4f5;
}

#modal-update-batch .content-header {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
}

#modal-update-batch .content-header .btn-group {
    padding: 4px;
    border-radius: 4px;
    background-color: #f3f4f5;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
}

#modal-update-batch .content-header .btn-group .btn-item.active {
    background-color: #fff;
    color: #0e233c;
}

#modal-update-batch .content-header .btn-group .btn-item {
    padding: 6px auto;
    width: 100%;
    cursor: pointer;
}

#page-report-new .menu-header {
    padding: 24px 24px 24px 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
    /* min-width: 1110px; */
}

#page-report-new .menu-header .content {
    max-width: 1220px;
    padding: 0 24px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#page-report-new .menu-header .content .title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

#page-report-new .menu-header .content .title span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    color: #566577;
}

#page-report-new .menu-header .actions {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    flex-wrap: wrap;
}

#page-report-new .page-content-report {
    background-color: #e7e9eb;
}

#page-report-new .page-content-report .list-block-overviews {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
}

#page-report-new .page-content-report .list-block-overviews .block-item {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 20px;
    gap: 20px;
    /* flex: 1 0 0; */
    width: 25%;
    border-radius: var(--border-radius-08, 8px);
    border: 0.5px solid var(--border-border-border-02, #e7e9eb);
    background: var(--layer-layer-01, #fff);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.new-ldicon-money-fill {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-money-fill.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-money-fill.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-wallet-fill {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-wallet-fill.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-wallet-fill.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-arrow-up-fill {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-up-fill.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-up-fill.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-arrow-down-fill {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down-fill.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down-fill.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#page-report-new .page-content-report .list-block-overviews .block-item .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 4px;
    gap: 4px;
    width: 100%;
}

#page-report-new .page-content-report .list-block-overviews .block-item .title .icon {
    margin-right: 8px;
    padding: 4px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f2fc;
}

#page-report-new .page-content-report .list-block-overviews .block-item .total-overview {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

#page-report-new .page-content-report .list-block-overviews .block-item .total-overview span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-secondary);
}

#page-report-new .page-content-report .page-content .content-overview {
    display: flex;
    flex-direction: row;
}

#page-report-new .page-content-report .page-content .content-overview .content-left {
    width: calc(100% - 395px);
}

#page-report-new .page-content-report .page-content .content-overview .content-right {
    width: 100%;
    max-width: 375px;
    margin-left: 20px;
}

#page-report-new .page-content-report .page-content .content-overview .content-right .block-list-staffs {
    display: block;
}

.block-content-item .block-list-staffs .staff-item {
    padding: 12px 0px;
    border-bottom: 1px solid #f3f4f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.block-content-item .block-list-staffs .staff-item:last-child {
    border-bottom: none;
}

.block-content-item .block-list-staffs .staff-item .info {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.block-content-item .block-list-staffs .staff-item .info .image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
}

.block-content-item .block-list-staffs .staff-item .info .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.block-content-item .block-list-staffs .staff-item .info .name p {
    color: var(--link-link-primary, #2b57d6);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.block-content-item .block-list-staffs .staff-item .info .name span {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.block-content-item .block-list-staffs .staff-item .total-price {
    display: flex;
    align-items: end;
    flex-direction: column;
}

.block-content-item .block-list-staffs .staff-item .total-price strong {
    color: var(--text-text-primary, #0e233c);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.block-content-item .block-list-staffs .staff-item .total-price span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.percent-up {
    color: var(--full-color-success-600, #35a500);
}

.percent-down {
    color: var(--support-support-danger, #e01a1a);
}

.page-discount-v4 .menu-header .content {
    max-width: 1220px;
    /* padding: 0 24px; */
    margin: 0 auto;
}

.page-discount-v4 .ladiui.page-content {
    padding: 0px;
}

.page-discount-v4 .menu-header .content .title-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-discount-v4 .menu-header .content .title-actions .title-desc h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.page-discount-v4 .menu-header .content .title-actions .title-desc p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.page-discount-v4 .menu-header .content .title-actions .actions {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.page-discount-v4 .menu-header .content .block-filter-search {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    padding: 16px 0px 20px;
}

.page-discount-v4 .menu-header .content .block-filter-search .ladiui.search-group {
    width: calc(100% - 638px);
}

.page-discount-v4 .menu-header .content .block-filter-search .list-filter {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.block-filter-search .list-filter .date-filter {
    width: 250px;
}

/* ------------------------------------------style tạo mới, chỉnh sửa mã khuyến mãi----------------------------------------------------------------- */
.page-discount-code-v4 {
    background-color: #E7E9EB;
}

.page-discount-code-v4 .page-header {
    padding: 0px 24px 24px 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
}

.page-discount-code-v4 .page-header .content-header {
    max-width: 1220px;
    padding: 0 24px;
    margin: 0 auto;
}

.page-discount-code-v4 .page-header .content-header .sub-menu ul {
    display: flex;
    align-items: center;
}

.page-discount-code-v4 .page-header .content-header .sub-menu ul li {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.page-discount-code-v4 .page-header .content-header .sub-menu ul li:first-child::after {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    background-size: cover;
    margin: 0 8px;
}

.page-discount-code-v4 .page-header .content-header .title-action {
    display: flex;
    align-items: center;
    padding-top: 24px;
    justify-content: space-between;
}

.page-discount-code-v4 .page-header .content-header .title-action h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.page-discount-code-v4 .page-content {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    overflow: initial !important;
}

.page-discount-code-v4 .page-content .content-left {
    width: calc(100% - 416px);
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}

.page-discount-code-v4 .page-content .content-right {
    width: 100%;
    max-width: 396px;
}

.page-discount-code-v4 .page-content .content-right .content {
    position: -webkit-sticky;
    position: sticky;
    top: 154px;
}

.page-discount-code-v4 .page-content .content-left .block-content-item .group-item-check {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.page-discount-code-v4 .page-content .content-left .block-discount-type .group-item-check .item {
    width: 50%;
}

.page-discount-code-v4 .page-content .content-left .block-content-item .group-item-check .item {
    padding: 12px 16px;
    border: 1px solid #e7e9eb;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.page-discount-code-v4 .page-content .content-left .block-generation-form .group-item-check .item {
    width: 33%;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value .option {
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 4px;
    background-color: #f3f4f5;
    width: 100%;
    max-width: 210px;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value .option .item {
    padding: 10px 24px;
    border-radius: 4px;
    cursor: pointer;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value .option .item.active {
    background-color: #fff;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value .value {
    width: calc(100% - 226px);
}

.page-discount-code-v4 .page-content .content-left .block-conditions .list-conditions {
    margin-top: 20px;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .list-conditions span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #566577;
    display: block;
    margin-bottom: 16px;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .list-conditions .discount-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .list-conditions .discount-item:last-child {
    margin-bottom: 0;
}

.page-discount-code-v4 .page-content .content-right .block-over-view .content {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    margin-top: 16px;
}

.page-discount-code-v4 .page-content .content-right .block-over-view .block-item {
    display: flex;
    align-items: center;
}

.page-discount-code-v4 .page-content .content-right .block-over-view .block-item label {
    padding-bottom: 0 !important;
}

.page-discount-code-v4 .page-content .content-right .block-detail-discount {
    margin-top: 24px;
}

.page-discount-code-v4 .page-content .content-right .block-detail-discount .overview-content {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    margin-top: 16px;
}

.discount-status-v4 {
    display: flex;
}

.discount-status-v4 .active:before {
    content: '';
    display: inline-block;
    background: #248a00;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(36, 138, 0, 0.2);
    background-clip: padding-box;
}

.discount-status-v4 .expried:before {
    content: '';
    display: inline-block;
    background: #e01a1a;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgb(224, 26, 26, 0.2);
    background-clip: padding-box;
}

.discount-status-v4 .pending:before {
    content: '';
    display: inline-block;
    background: #bcbcbc;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid hsla(0, 0%, 73.7%, 0.2);
    background-clip: padding-box;
}

/* ---------------------------------------------------------------style các màn trang cài đặt v4----------------------------------------------------------------- */

/* -----------Cài đặt chung--------------------------- */
.page-general-setting-v4 .content {
    margin-top: 24px;
}

.page-general-setting-v4 .header-title {
    padding: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

.page-general-setting-v4 .header-title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0E233C;
}

.page-general-setting-v4 .header-title p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    color: #566577;
}

.page-general-setting-v4 .content .list-blocks .block-item {
    display: flex;
    align-items: flex-start;
}

.page-general-setting-v4 .content .list-blocks .block-item .title-block {
    width: 100%;
    max-width: 200px;
    margin-right: 24px;
}

.page-general-setting-v4 .content .list-blocks .block-item .title-block h3 {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-general-setting-v4 .content .list-blocks .block-item .title-block p {
    margin-top: 8px;
    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.page-general-setting-v4 .content .list-blocks .block-item .content-block {
    width: calc(100% - 200px);
}

.line-space-block {
    width: 100%;
    height: 1px;
    background-color: #e7e9eb;
    margin: 24px 0;
}

.page-general-setting-v4 .action-footer {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
}

/* --------------------------------style trang chính sách cửa hàng------------------------------------------- */


.page-store-policy .content .list-blocks .block-item {
    display: flex;
    align-items: flex-start;
}

.page-store-policy .content .list-blocks .block-item .title-block {
    width: 100%;
    max-width: 200px;
    margin-right: 24px;
}

.page-store-policy .content .list-blocks .block-item .title-block h3 {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-store-policy .content .list-blocks .block-item .title-block p {
    margin-top: 8px;
    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.page-store-policy .content .list-blocks .block-item .content-block {
    width: calc(100% - 200px);
}

.page-store-policy .action-footer {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
}

#modal-address .ladisales-location .ladiui.dropdown {
    position: initial !important;
}

#modal-address .ladisales-location .ladiui.dropdown .ladiui.dropdown-menu {
    width: calc(50% - 24px) !important;
}

/* -------------------------------style trang tracking---------------------------------------------- */
.page-setting-tracking-v4 {}

.page-setting-tracking-v4 .header-title {
    /* padding: 24px 0; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

.page-setting-tracking-v4 .header-title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.page-setting-tracking-v4 .header-title p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.page-setting-tracking-v4 .content .list-blocks .block-item {
    display: flex;
    align-items: flex-start;
}

.page-setting-tracking-v4 .content .list-blocks .block-item .title-block {
    width: 100%;
    max-width: 200px;
    margin-right: 24px;
}

.page-setting-tracking-v4 .content .list-blocks .block-item .title-block h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.page-setting-tracking-v4 .content .list-blocks .block-item .title-block p {
    margin-top: 8px;
    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.page-setting-tracking-v4 .content .list-blocks .block-item .content-block {
    width: calc(100% - 200px);
}

.page-setting-tracking-v4 .action-footer {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
}

/* ------------------------style trang quản lý domain------------------------------- */
#page-setting-api .ladiui.table {
    border: 1px solid #f3f4f5;
}

#page-setting-api .ladiui.table thead {
    background-color: #f3f4f5;
}

#page-setting-api .ladiui.table thead>tr>th:first-child {
    padding-left: 12px !important;
}

#page-setting-api .ladiui.table tbody>tr>td:first-child {
    padding-left: 12px !important;
}

/* ----------------------------------icon menu setting------------------------------------------- */
.setting-page-general .setting-content .setting-item .text {
    display: flex;
    align-items: center;
}

.setting-page-general .setting-content .setting-item.active .text i {
    background-color: #1c00c2;
}

/* -----------------------------style trang cài đặt tài khoản----------------------------------- */
#modal-info-create-account .block-list-scopes {
    margin-top: 24px;
}

#modal-info-create-account .block-list-scopes h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
}

#modal-info-create-account .block-list-scopes .lists {
    display: flex;
    flex-direction: column;
    border: 1px solid #e7e9eb;
}

#modal-info-create-account .block-list-scopes .lists .list-item {
    border-bottom: 1px solid #e7e9eb;
}

#modal-info-create-account .block-list-scopes .lists .list-item .scope-name {
    padding: 16px;
    background-color: #f3f4f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#modal-info-create-account .block-list-scopes .lists .list-item .list-permissions .list-permission-item {
    padding: 16px 16px 16px 32px;
    background-color: #fff;
    border-bottom: 1px solid #e7e9eb;
    cursor: pointer;
}

/* -------------------------------------------style trang cài đặt quản lý thông báo----------------------------------------------------------------------- */

.page-setting-notification-v4 .header-title {
    /* padding-bottom: 24px; */
}

.page-setting-notification-v4 .header-title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.page-setting-notification-v4 .header-title p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.page-setting-notification-v4 .content .list-blocks .block-item {
    display: flex;
    align-items: flex-start;
}

.page-setting-notification-v4 .content .list-blocks .block-item .title-block {
    width: 100%;
    max-width: 200px;
    margin-right: 24px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .title-block h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .title-block p {
    margin-top: 8px;
    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block {
    width: calc(100% - 200px);
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table {
    border: 1px solid #f1f3f4;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table thead {
    background-color: #f3f4f5;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table thead th:first-child {
    padding-left: 12px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table tbody td:first-child {
    padding-left: 12px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table tbody tr:last-child {
    border-bottom: none !important;
}
.package-vriant {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.package-vriant .package-variant-lb {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}

.package-vriant .package-unit-lb {
    white-space: nowrap;
}

/* .option-variant-items{
    display: flex;
    flex-direction: column;
    gap: 20px;
} */

.option-variant-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 24px;
    gap: 24px;

    width: 100%;
    padding: 12px 0px;
}

.option-variant-item.option-variant-header {
    padding: 20px 0px 12px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px solid var(--Border-Border-border-02, #E7E9EB);
}

.option-variant-item .option-variant-sku {
    width: 180px;
}

.option-variant-item .option-variant-num-unit {
    width: 80px;
}

.option-variant-item .option-variant-price {
    width: 130px;
}

.option-variant-item .option-variant-add {
    width: 140px;
}

.option-variant-item .option-variant-inventory {
    width: 140px;
    display: flex;
    align-items: center;
}

.btn-add-new {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 10px 0px;
    cursor: pointer;
    color: #1C00C2;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.page-course-edit {
    background: #E7E9EB;
    /* background: #fff; */
}

.page-course-edit .menu-course-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
}

.page-course-edit .menu-course-header .title-action{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1220px;
    /* padding: 16px 24px; */
    margin: 0px auto;
    /* border-bottom: 1px solid #E7E9EB; */
}

.ls-header-course-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1220px;
    padding: 16px 24px;
    margin: 0px auto;
    border-bottom: 1px solid #E7E9EB;
    padding-bottom: 0;
}

.ls-header-course-menu h1 {
    font-size: 20px;
    font-weight: 500;
}

.block-featured-course label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.setting-content-domain-course .action-record-table,
.table-list-course .action-record-table {
    left: 32px;
}


.page-setting-course .right-content {
    height: 100vh;
    overflow: auto;
    padding-top: 0px !important;
}

.page-setting-course .right-content .block-title-action {
    padding-top: 24px;
    padding-bottom: 24px;
}

.page-setting-course .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.block-custom-interface .list-item .item .item-content {
    padding: 11px 12px;
    background-color: #f1f3f4;
    border-radius: 2px;
}

.ls-field-header {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 8px;
}

#tabProductCourseEdit.open {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 20px;
    gap: 20px;
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail {
    width: 100%;
    height: 316px;
    border-radius: 2px;
    position: relative;
    background-color: #f1f3f4;
}

#tabProductCourseEdit .ladiui-product-course-left .ls-custom-thumnail {
    width: unset;
    height: 400px;
    aspect-ratio: 800 / 800;
}

#tabProductCourseEdit .ladiui-product-course-right .image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

#tabProductCourseEdit .ladiui-product-course-left .image {
    width: 100%;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 4px;
}

#tabProductCourseEdit .thumbnail .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay {
    margin-top: 60px;
    text-align: center;
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail:hover .block-upload-image .block-overlay {
    /* margin-top: 130px; */
    margin-top: unset;
    margin: 50% auto;
    text-align: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#tabProductCourseEdit .ladiui-product-course-left-fixed {
    width: 340px !important;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}

#tabProductCourseEdit .ladiui-product-course-right-fixed {
    width: calc(100% - 340px) !important;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}

.choose-content-custom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
}

.choose-content-custom .list-box-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
}

.choose-content-custom .list-box-content .box-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    background-color: #F3F4F5;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
}

.choose-content-custom .list-box-content .box-content.active {
   border: 1px solid var(--main-primary);
}

#modal-choose-type-lesson .modal-footer-custom {
    border-top: 1px solid var(--border-02);
    padding: 20px 24px;
}

.ls-thumbnail-lesson {
    min-width: 240px;
    max-width: 240px;
    aspect-ratio: 240 / 135;
    height: auto;
    position: relative;
    background-color: #F3F4F5;
    border-radius: 4px;
}

.ls-thumbnail-lesson img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
}

.ls-thumbnail-lesson .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
}

.ls-thumbnail-lesson:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

.ls-thumbnail-lesson:hover .block-upload-image .block-overlay {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}

#tabProductCourseEdit .chapter-list-item:last-child {
    margin-bottom: 0;
}

/* css lai phan danh sach khoa hoc */
.block-content-product-course-custom .icon-title {
    font-weight: 500;
    font-size: 16px;
}

.block-content-product-course-custom .chapter-list-item {
    border: none !important;
}

.block-content-product-course-custom .title-chapter {
    background-color: #fff !important;
    padding: 0 !important;
    margin-bottom: 16px;
}

.block-content-product-course-custom .ls-add-lesson {
    border: 1px dashed #CFD3D8;
    padding: 8px;
    border-radius: 4px;
}

.block-content-product-course-custom .ls-add-lesson:hover {
    border: 1px dashed #1C00C2;
    background: var(--layer-brand);
}

.block-content-product-course-custom .ls-lesson-image {
    width: 112px;
    height: 63px;
    background-color: gray;
    border-radius: 4px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.block-content-product-course-custom .ls-lesson-image img{
    width: -webkit-fill-available;
    object-fit: cover;
    border-radius: 4px;
}

.block-content-product-course-custom .list-lesson {
    grid-gap: 12px;
    gap: 12px;
    display: flex !important;
    flex-direction: column;
}


.block-content-product-course-custom .lesson-item {
    padding: 0 !important;
    border-bottom: none !important;
}

.block-content-product-course-custom .ls-link-hover:hover {
    font-weight: 500;
}

.block-content-product-course-custom .ls-btn-add-module {
    border: 1px dashed #1C00C2 !important;
}

.block-content-product-course-custom .ladiui-product-course-left {
    width: 300px;
}

.block-content-product-course-custom .ladiui-product-course-right {
    width: calc(100% - 300px);
}

.block-content-product-course-custom .ladiui-product-course-left.minimize {
    width: 224px;
}

.block-content-product-course-custom .ladiui-product-course-right.minimize {
    width: calc(100% - 224px);
}

.block-content-product-course-custom .ls-course-right-col {
    height: -webkit-fill-available;
}

.block-content-product-course-custom .search-group-right-search input{
    padding-right: 32px !important;
    padding-left: 12px !important;
}

.block-content-product-course-custom .search-group-right-search i.icon-search{
    right: 5px !important;
    left: unset !important;
}

.block-content-product-course-custom  .list-chapters .chapter-list-item:last-child {
    margin-bottom: 0 !important;
}

.block-content-product-course-custom .list-chapters .chapter-list-item .title-chapter {
    align-items: flex-start !important;
}

.block-content-product-course-custom .list-chapters {
    max-height: unset !important;
    overflow: unset !important;
}

.ls-component-loading {
    position: absolute !important;
    background-size: 80px 80px !important;
    background-repeat: no-repeat;
    left: 0px !important;
    top: 0;
    width: 100% !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30%;
}

.block-content-product-course-custom .lesson-item:not(.selected) {
    opacity: 0.7;
}

.block-content-product-course-custom .lesson-item.selected {
    font-weight: 500;
}

.block-content-product-course-custom .lesson-item:hover {
    opacity: 1;
    font-weight: 500;
    cursor: pointer;
}

.block-content-product-course-custom .lesson-item:hover .ls-link-hover{
    opacity: 1;
    font-weight: 500;
    cursor: pointer;
    color: var(--main-primary);
}

.ls-uploaded-video {
    display: flex;
    background-color: #F3F4F5;
    border-radius: 4px;
    height: 72px;
    width: 100%;
    justify-content: start;
    align-items: center;
    padding: 8px 16px;
    grid-gap: 8px;
    gap: 8px;
}

.ls-uploaded-video-1 {
    display: flex;
    background-color: #F4F2FC;
    border-radius: 4px;
    height: 72px;
    width: 100%;
    justify-content: start;
    align-items: center;
    padding: 8px 16px;
    grid-gap: 8px;
    gap: 8px;
    border: 1px dashed #A895F8;
}

.ls-uploaded-video-2 {
    display: flex;
    background-color: #000;
    opacity: 0.6;
    border-radius: 4px;
    height: 72px;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    grid-gap: 8px;
    gap: 8px;
    text-align: center;
}

.ls-uploaded-video-3 {
    display: flex;
    background-color: #F3F4F5;
    border-radius: 4px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px 16px;
    grid-gap: 8px;
    gap: 8px;
}

.ls-icon-video {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
}

.ls-processing {
    height: 4px;
    border-radius: 4px;
    background-color: #D3C9FB;
    width: 100%;
}

.ls-current-processing {
    height: 4px;
    border-radius: 4px;
    background-color: #5036DA;
    width: 0%;
}

.ls-fill-primary-btn {
    padding: 4px 8px;
    background-color: #F4F2FC;
    color: #1C00C2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    #tabProductCourseEdit.open {
        display: flex;
        flex-direction: column;
    }

    #tabProductCourseEdit .ladiui-product-course-left-fixed {
        width: 100% !important;
    }
    
    #tabProductCourseEdit .ladiui-product-course-right-fixed {
        width: 100% !important;
        /* padding-left: 0; */
        padding-top: 24px;
    }

    .ls-filter-bar {
        flex-wrap: wrap;
    }
}
.valid-input-error {
    border: 1px solid red !important;
}

.text-default {
    color: #6d6d6d;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.mini-dropdown {
    width: 100% !important;
    height: 42px !important;
    min-width: unset !important;
    background: #f1f3f4 !important;
    border-radius: 2px !important;
}

.mini-dropdown.fix {
    width: 100% !important;
}

.mini-dropdown-pd {
    padding: 2px 5px !important;
}

.head-accordion {
    background: #f1f3f4 !important;
    border-radius: 2px !important;
}

.min-max-buy {
    justify-content: space-between !important;
    display: flex !important;
    align-items: center !important;
}

.variant-option-item .variant-option-value {
    width: calc(100% - 380px);
    margin-left: 20px;
}

.btn-add-option {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    padding-left: 0px !important;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.un-hover:hover,
.un-hover:focus {
    border: unset !important;
}

/* .list-image-product .list-image .item-image {
    margin-right: 23px;
} */

.list-image-product .list-image.grid-box {
    /* display: flex;
    align-items: center;
    width: 400px;
    overflow: auto; */
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 24px;
}

.list-image-product .list-image.flex-box {
    display: flex;
}

.list-image-product .list-image.flex-box .item-image {
    margin-right: 24px !important;
}

.list-image-product .list-image .btn-add-image {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #afafaf;
    padding-top: 20px;
}

.list-image-product .image-none .ladiui-button-image {
    position: relative;
    width: 400px;
    height: 400px;
    background: #f1f3f4;
    border-radius: 2px;
}

.list-image-product .image-none .btn-add-image {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #afafaf;
    padding: 150px 80px;
}

.list-image-product .image-none .ladiui-button-image input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.item-variant-list-option .option-name {
    color: #6d6d6d;
}

.item-variant-list-option .option-value {
    color: var(--main-default);
}

.block-custom-interface {
    margin-bottom: 30px;
}

.ladiui.iframe-checkout {
    text-align: center;
    background: #d9d9d9 !important;
    padding: 20px 0px;
}

#iframe-checkout-page {
    height: calc(100vh - 340px);
}

#iframe-store-info {
    height: calc(100vh - 200px);
}

#iframe-interface {
    height: calc(100vh - 200px);
}

#iframe-general-store {
    height: calc(100vh - 200px);
}

.tox-editor-container .tox-editor-header .tox-menubar {
    display: none;
}

.product-name-table {
    display: flex !important;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.product-name-table .product_img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

.btn-product-view {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    border-radius: 4px;
    background: #f3f4f5;
    color: #0e233c;
    cursor: pointer;

    padding: 10px 16px;
}

.btn-product-view .new-ldicon-box {
    width: 16px !important;
    height: 16px !important;
}

.table-custom th:first-child,
.table-custom td:first-child {
    min-width: 40px;
}

.publish-redirect-after .ladiui.dropdown-text {
    width: 116px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0px;
}

.table-tr-th {
    color: #566577;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
}

.ticket-table {
    min-width: 1110px
}

.btn-delete-variant-image {
    /* position: absolute; */
    /* right: 0px; */
    /* z-index: 2; */

    border-radius: 3px;
    background: var(--Store-Color-store-button-primary-default, #EE1D52);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12), 0px -1px 38px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    display: flex !important;
    align-items: center;
    justify-content: center;
    grid-gap: 4px;
    gap: 4px;
    padding: 8px !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 16px auto;

    color: #ffffff;

}

.btn-delete-variant-image .new-ldicon-delete {
    background-color: #ffffff !important;
}

.btn-change-image {
    border-radius: 3px !important;
    background: var(--Course-Color, #1C00C2) !important;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12), 0px -1px 38px 0px rgba(0, 0, 0, 0.05);
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    grid-gap: 4px;
    gap: 4px;
    padding: 8px !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    color: #FFFFFF !important;
    font-family: Roboto;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal !important;
    border: none !important;
}

.btn-change-image .new-ldicon-refresh {
    background-color: #ffffff !important;
}

.setting-block-image input {
    display: none;
}

.product-highlight .group-type-highlight {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 16px;
    gap: 16px;
}

.product-highlight .block-content-highlight .ladiui.form-group {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;

    flex-direction: column;
}

.flas-sale-dayly {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 24px;
    gap: 24px;
}

.flas-sale-dayly .ladiui.form-group {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.group-show-time {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 20px;
    gap: 20px;
}

.react-time-picker__wrapper {
    border: 1px solid #CFD3D8 !important;
    border-radius: 4px;
}
/** @format */

.ladiui.icon-new {
    -webkit-mask-size: cover;
            mask-size: cover;
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.icon-20 {
    width: 20px !important;
    height: 20px !important;
}

.icon-24 {
    width: 24px !important;
    height: 24px !important;
}

.ldicon-note {
    background-color: var(--main-secondary) !important;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
}

.new-ldicon-user-asign {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-asign.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-asign.svg);
}

.ldicon-ip {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-ip.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-ip.svg);
}

.new-ldicon-checkout {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-checkout.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-checkout.svg);
}

.ldicon-assignment {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-assignment.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-assignment.svg);
}

.new-ldicon-sequence {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-sequence.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-sequence.svg);
}

.ldicon-arrow-up {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-up.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-up.svg);
}

.ldicon-arrow-down {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-down.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-down.svg);
}

.ldicon-arrow-right {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-right.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-right.svg);
}

.ldicon-arrow-left {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-left.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-left.svg);
}

.new-ldicon-arrow-rightup2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-rightup2.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-rightup2.svg);
}

.new-ldicon-arrow-rightdown2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-rightdown2.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-rightdown2.svg);
}

.new-ldicon-arrow-leftup2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-leftup2.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-leftup2.svg);
}

.new-ldicon-arrow-leftdown2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-leftdown2.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-leftdown2.svg);
}

.new-ldicon-logout {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-logout.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-logout.svg);
}

.new-ldicon-money {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-money.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-money.svg);
}

.ldicon-help {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-help.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-help.svg);
}

.new-ldicon-storefront {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-storefront.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-storefront.svg);
}

.new-ldicon-create-content {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-create-content.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-create-content.svg);
}

.new-ldicon-text {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-text.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-text.svg);
}

.ldicon-remove {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-remove.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-remove.svg);
}

.new-ldicon-coupon {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-coupon.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-coupon.svg);
}

.new-ldicon-arrow-right-fill {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-right-fill.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-right-fill.svg);
}

.new-ldicon-paint {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-paint.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-paint.svg);
}

.new-ldicon-cart {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-shopping-cart.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-shopping-cart.svg);
}

.ldicon-button {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-button.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-button.svg);
}

.ldicon-delivery-truck {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-delivery-truck.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-delivery-truck.svg);
}

.new-ldicon-bankcard {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-bankcard.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-bankcard.svg);
}

.new-ldicon-user-group {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-group.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-group.svg);
}

.new-ldicon-effect {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-effect.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-effect.svg);
}

.ldicon-payment {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-payment.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-payment.svg);
}

.new-ldicon-home {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-home.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-home.svg);
}

.new-ldicon-setting {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-setting.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-setting.svg);
}

.new-ldicon-storefront {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-storefront.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-storefront.svg);
}

.new-ldicon-notify {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-notify.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-notify.svg);
}

.new-ldicon-website-2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-website-2.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-website-2.svg);
}

.ldicon-api {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-api.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-api.svg);
}

.ldicon-circle-check2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check2.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check2.svg);
}

.ldicon-circle-check {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
}

.new-ldicon-code-2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-code-2.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-code-2.svg);
}

.new-ldicon-desktop {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-desktop.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-desktop.svg);
}

.new-ldicon-mobile {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-mobile.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-mobile.svg);
}

.new-ldicon-duotone-search {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-duotone-search.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-duotone-search.svg);
}

.ldicon-view {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-view.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-view.svg);
}

.ldicon-hide {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-hide.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-hide.svg);
}

.ldicon-sort {
    background-color: var(--icon-primary);
    display: inline-block;
    box-sizing: unset;
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-sort.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-sort.svg);
    width: 16px;
    height: 16px;
}

.icon-ldp-add {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-add.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-add.svg);
    background-color: var(--icon-on-colors);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-close {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-close.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-close.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-reload {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-reload.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-reload.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-post {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-post.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-post.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-arrow-down {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-arrow-up {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-up.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-up.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-chevron-right {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    background-color: var(--icon-primary);
    width: 12px;
    height: 12px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-chevron-left {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-left.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-left.svg);
    background-color: var(--icon-primary);
    width: 12px;
    height: 12px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-info {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-info.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-info.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.icon-move {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-folder-error {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-folder-error.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-folder-error.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.icon-caret-down-fill {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/icon-caret-down-fill.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/icon-caret-down-fill.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
    transition: all 100ms;
}

.new-ldicon-user {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user.svg);
    width: 64px;
    height: 64px;
}

.new-ldicon-box {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-box.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-box.svg);
    width: 64px;
    height: 64px;
}

.new-ldicon-copy {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-copy.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-copy.svg);
}

.new-ldicon-edit {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-edit.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-edit.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-delete {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-delete.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-delete.svg);
    background-color: var(--icon-danger) !important;
}

.new-ldicon-fill-cart {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-fill-cart.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-fill-cart.svg);
    background-color: var(--icon-primary-2);
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-circlecheck-fill {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-circlecheck-fill.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-circlecheck-fill.svg);
    background-color: #4ac000;
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-ticket {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-ticket.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-ticket.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-search {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-search.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-search.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-inbox {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-inbox.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-inbox.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-abandoned-cart {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-abandoned-cart.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-abandoned-cart.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-transport {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-transport.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-transport.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-tag {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-tag.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-tag.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-custom-field {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-custom-field.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-custom-field.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-add-circle {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-add-circle.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-add-circle.svg);
    background-color: var(--text-brand);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-add {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add.svg);
    background-color: var(--text-brand);
    width: 12px;
    height: 12px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-bill {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-bill.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-bill.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-photo {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-photo.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-photo.svg);
    background-color: var(--icon-primary);
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-file {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-file.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-file.svg);
    background-color: var(--text-brand) !important;
}

.new-ldicon-upload {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-upload.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-upload.svg);
}

.new-ldicon-link2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-link2.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-link2.svg);
}

/* icon-background */
.new-ldicon-close-fill-2 {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-close-fill-2.svg) no-repeat !important;
    background-size: contain !important;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.ladiui.icon-ldp-close {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-close-fill-2.svg) no-repeat !important;
    background-size: contain !important;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    -webkit-mask: unset !important;
            mask: unset !important;
}

.ldicon-printer {
    background: url(https://w.ladicdn.com/ladiui/icons/ldicon-printer.svg) no-repeat !important;
    background-size: contain !important;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    -webkit-mask: unset !important;
            mask: unset !important;
}

.ldicon-circle-close {
    background: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-close.svg) no-repeat !important;
    background-size: contain !important;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    -webkit-mask: unset !important;
            mask: unset !important;
}

.theme-background {
    background: url(https://w.ladicdn.com/s600x600/6109/theme-20231021082316-9lflp.png);
    background-size: cover;
    display: block;
    width: 84px;
    height: 53px;
    cursor: pointer;
    -webkit-mask: unset !important;
            mask: unset !important;
}

.new-ldicon-minus2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-minus2.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-minus2.svg);
}

.new-ldicon-double-arrow-left {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-double-arrow-left.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-double-arrow-left.svg);
}

.new-ldicon-arrow-left {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-left.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-left.svg);
}

.new-ldicon-arrow-right {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-right.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-right.svg);
}

.new-ldicon-double-arrow-right {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-double-arrow-right.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-double-arrow-right.svg);
}

.new-ldicon-lock-duotone {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-lock-duotone.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-lock-duotone.svg);
}

.new-ldicon-open-link {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-open-link.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-open-link.svg);
}

.new-ldicon-footer-sticky {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-footer-sticky.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-footer-sticky.svg);
}

.new-ldicon-refresh {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-refresh.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-refresh.svg);
}

.new-ldicon-phone {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-phone.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-phone.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-userid {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-userid.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-userid.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-bankcard {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-bankcard.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-bankcard.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-user-asign {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-asign.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-asign.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-list {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-list.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-list.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-home {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-home.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-home.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-circle-add {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-add.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-add.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-list-module {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-list-module.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-list-module.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-slide-left {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-slide-left.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-slide-left.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-slide-right {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-slide-left.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-slide-left.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
    transform: rotate(180deg);
}

.icon-ldp-more {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-more.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-more.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-save {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-save.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-save.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-email-video {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-email-video.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-email-video.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-upload-2 {
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-upload.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-upload.svg);
    -webkit-mask-size: cover;
            mask-size: cover;
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-course {
    box-sizing: unset;
    display: inline-block;
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-course.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-course.svg);
}



.page-content .page-checkout {
    padding: 24px;
}

.btn-url-page-checkout {
    max-width: 384px;
    display: block;
    white-space: pre-wrap;
}

.page-checkout-head .ldicon-save {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.ladisales-location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
}

.ladisales-location .country-district {
    width: 50%;
}
.ladisales-location .state-ward {
    width: 50%;
}

.content-customer-create .customer-email-phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
}
.content-customer-create .customer-location {
    padding-top: 20px;
    border-top: 1px solid var(--border-02);
}
.content-customer-create .customer-email-phone .item {
    width: 50%;
}

.customer-page .action-record-table {
    left: 30px;
}

.page-checkout .checkout-header {
    padding: 0px 0px 24px;
}

.page-checkout .block-filter {
    padding: 16px 0px 20px !important;
}

.page-checkout .block-search .block-filter-button {
    width: 410px !important;
}

.page-checkout .block-search .ladiui.search-group {
    width: calc(100% - 430px) !important;
}

.page-checkout .action-record-table {
    left: 50px !important;
}

.checkout-content {
    background: var(--background-02);
    min-height: calc(100vh - 92px);
}

.checkout-content .content-max-width {
    padding: 24px;
}

.checkout-url::after {
    content: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-fill-cart.svg);
    width: 16px;
    height: 16px;
}

.checkout-content .checkout-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
}

.checkout-content .checkout-details .product-list-payment,
.checkout-content .checkout-details .product-list-payment .product-list,
.checkout-content .checkout-details .product-list-payment .payments {
    width: 775px;
}

.checkout-content .checkout-details .customer-tracking,
.checkout-content .checkout-details .customer-tracking .customer,
.checkout-content .checkout-details .customer-tracking .tracking {
    width: 375px;
}

.page-checkout-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;
    border-bottom: 1px solid #E7E9EB;

    position: -webkit-sticky;

    position: sticky;
    top: 0px;
    background: #ffffff;
    z-index: 20;
    min-width: 1200px;
}

.page-checkout-head .page-checkout-back-name {
    width: 33.33%;
}

.page-checkout-head .page-checkout-name {
    max-width: 400px;
}

.page-checkout-head .page-checkout-name-input {
    width: 400px;
}

.page-checkout-head .page-checkout-name-input input {
    padding: 5px 12px !important;
}

.page-checkout-head .page-type-view-type {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 16px;
    gap: 16px;
    width: 33.33%;
}

.page-checkout-head .page-type-view-type .page-type {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    height: 32px;
}

.page-checkout-head .page-type-view-type .page-type .btn-page-view {
    background-color: var(--secondary-color);
}

.page-checkout-head .page-type-view-type .icon-desktop-mobile {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;

    border-radius: 4px;
    /* background: #f3f4f5; */
    padding: 2px;
    height: 32px;
    width: 60px;
}

.page-checkout-head .page-type-view-type .icon-desktop-mobile .icon-item {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-checkout-head .page-type-view-type .icon-desktop-mobile .icon-item.active .ladiui.icon-new {
    border-radius: 3px;
    /* background: var(--layer-layer-01, #fff); */
    /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08); */
    background-color: var(--main-primary);

}

.page-checkout-head .head-actions .page-checkout-link {
    color: #1C00C2;
    cursor: pointer;
}

.page-checkout-head .head-actions {
    width: 33.33%;
    justify-content: flex-end !important;
}

.page-checkout-head .head-actions .page-checkout-link .new-ldicon-link2 {
    background-color: #1C00C2;
}

.page-checkout-body {
    background: var(--background-02);
}

.page-checkout-content {
    display: block;
    position: relative;
    padding: 24px;
    /* display: flex;
    align-items: flex-start; */
}

.page-checkout-content .content-popup {
    background: #ffffff;
    position: absolute;
    z-index: 10;
    top: 24px;
    left: 24px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
}

.page-checkout-content .content-popup .content-header-mini {
    border-radius: 8px;

    background: var(--Background-background-01, #FFF);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
    padding: 12px;
    cursor: pointer;
}

.page-checkout-content .content-popup .content-header-mini .mini-title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-checkout-content .content-popup .content-header {
    background: var(--Full-Color-Grey-50, #F3F4F5);
    padding: 16px;
    color: var(--Text-text-primary, #0E233C);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    position: -webkit-sticky;
    position: sticky;
    top: 56px;
    z-index: 3;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;


    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-checkout-content .content-popup .content-header .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    text-transform: uppercase;
}

/* .page-checkout-content .content-popup .content-header .new-ldicon-close-fill-2 {
    position: absolute;
    top: 16px;
    right: 16px;
} */

.page-checkout-content .content-left {
    width: 420px;

}

.page-checkout-content .content-right {
    /* width: calc(100% - 420px); */
    width: 100%;
    height: calc(100vh - 100px);
    position: -webkit-sticky;
    position: sticky;
    top: 75px;
    padding: 0px 24px;
}

.page-checkout-content .content-right .content-right-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 1080px;
}

.page-checkout-body .menu-list {
    display: flex;
    flex-direction: column;
}

/* .page-checkout-body .menu-list .icon-new {
    width: 20px !important;
    height: 20px !important;
} */

.page-checkout-body .menu-list .icon-new.ldicon-chevron-right {
    width: 16px !important;
    height: 16px !important;
}

.page-checkout-body .menu-list .menu-item {
    padding: 16px;
    border-bottom: 1px solid var(--border-02-100p);
    cursor: pointer;
    height: 78px;
    /* border-left: 3px solid transparent; */
}

.page-checkout-body .menu-list .menu-item:hover {
    /* border-left: 3px solid #1c00c2; */
    background: #f4f2fc;
}

.page-checkout-body .menu-list .menu-item .menu-title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-checkout-body .menu-list .menu-item:hover .menu-title {
    color: var(--text-brand);
}

.page-checkout-config-item {
    /* height: calc(100vh - 76px); */
    /* overflow: overlay; */
}

.page-checkout-config-item .title-header {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-checkout-config-item .header {
    padding: 20px 24px !important;
    border-right: 1px solid #e7e9eb;
    border-bottom: 1px solid #e7e9eb;
    background: #ffffff;
    z-index: 3;
    position: -webkit-sticky;
    position: sticky;
    top: 104px;
}

.page-checkout-config-item .header .icon-back {
    border-radius: 8px;
    border: 1px solid #e7e9eb;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.page-checkout-config-item .header .icon-back:hover {
    background: #f4f2fc;
}

.page-checkout-config-item .body {
    padding: 20px 24px;
}

.config-body.not-active {
    opacity: 0.4;
}

.config-body .config-header {
    /* padding: 20px 24px; */
    padding: 20px 24px 16px 24px;
    text-transform: uppercase;
}

.config-body .config-require {
    text-align: center;
    width: 120px;
}

.config-body .config-list .config-item {
    border-top: 0.5px solid var(--border-02);
    padding: 16px 24px;
    cursor: pointer;
}

.config-body .config-list .config-item:hover {
    background: var(--full-color-primary-50, #f4f2fc);
}

.custom-field-tyle-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
    gap: 16px;
    padding-bottom: 24px;
}

.custom-field-tyle-list .custom-field-tyle-item {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 16px;
    cursor: pointer;

    border-radius: 4px;
    border: 1px solid #e7e9eb;
}

.custom-field-tyle-list .custom-field-tyle-item.active {
    border: 1px solid #5036da;
}

.gateway-list {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    padding: 20px 24px;
}

.gateway-list .gateway-item {
    border-radius: 4px;
    border: 1px solid #e7e9eb;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.gateway-list .gateway-item .gateway-item-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
    gap: 16px;
    padding: 16px;
    width: 100%;
}

.gateway-list .gateway-item .gateway-item-head .gateway-open-method {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.gateway-list .gateway-item .gateway-item-head .new-ldicon-arrow-up {
    transition: all 200ms;
}

.gateway-list .gateway-item .gateway-item-head:hover,
.gateway-list .gateway-item .gateway-item-head.active {
    background: #f4f2fc;
}

.gateway-list .gateway-item .gateway-item-head:hover .gateway-name,
.gateway-list .gateway-item .gateway-item-head.active .gateway-name {
    color: var(--text-brand);
}

.gateway-list .gateway-item .gateway-item-head:hover .new-ldicon-arrow-up,
.gateway-list .gateway-item .gateway-item-head.active .new-ldicon-arrow-up {
    background: var(--text-brand);
    /* rotate: 180deg; */
}

.gateway-list .gateway-item .gateway-item-head.active .new-ldicon-arrow-up {
    rotate: 180deg;
}

.method-list {
    padding: 4px 0px;
}

.method-list .method-item {
    padding: 16px 16px 16px 52px;
}

.widget-menu .widget-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px 24px;
    border-bottom: 1px solid #e7e9eb;
    cursor: pointer;
}

.widget-menu .widget-menu-item:hover {
    background: #f4f2fc;
}

.widget-menu .widget-menu-item .widget-menu-label {
    color: #566577;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.widget-menu .icon-new {
    width: 20px !important;
    height: 20px !important;
}

.widget-menu .new-ldicon-footer-sticky {
    rotate: 90deg;
}

.widget-config-item {
    padding: 20px 24px;
    /* height: calc(100vh - 150px);
    overflow: auto; */
}

.preview-store-logo {
    width: 48px;
    height: 48px;

    border-radius: 50%;
}

.preview-store-logo img {
    width: 48px;
    height: 48px;

    border-radius: 50%;
}

.preview-store-logo .store-logo-overlay {
    align-items: center;
    text-align: center;
    width: 48px;
    height: 48px;
    top: 0;
    z-index: 2;
    position: absolute;
    display: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
}

.preview-store-logo:hover .store-logo-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-store-logo:hover .store-logo-overlay .ladiui.dropdown-menu {
    right: unset !important;
}

.text-setting {
    margin-top: 24px;
    padding-top: 24px;

    border-top: 1px solid #e7e9eb;
}

.carts-item-list {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;

    margin-top: 20px;
}

.carts-item-list .carts-item-item {
    border-radius: var(--spacing-0px, 0px);
    border: 1px solid #e7e9eb;
    border-radius: 4px;
    /* background:  #f4f2fc; */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.carts-item-list .carts-item-item .carts-item-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
    gap: 16px;
    padding: 16px;

    border-radius: 4px;
    /* border: 1px solid #e7e9eb; */
    background: #f3f4f5;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.carts-item-list .carts-item-item .carts-item-head .gateway-name {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.carts-item-list .carts-item-item .carts-item-head .gateway-name i {
    width: 20px !important;
    height: 20px !important;
}

.carts-item-list .carts-item-item .carts-item-head:hover,
.carts-item-list .carts-item-item .carts-item-head.action {
    background: #f4f2fc;
}

.carts-item-list .carts-item-item .carts-item-head:hover .gateway-name,
.carts-item-list .carts-item-item .carts-item-head.action .gateway-name {
    color: var(--text-brand);
}

.carts-item-list .carts-item-item .carts-item-head .gateway-name .name {
    word-wrap: anywhere;
}

.btn-add-section {
    margin-top: 20px;
    padding: 14px 16px;
    border-radius: 4px;
    border: 1px dashed #5036da;

    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;

    cursor: pointer;
}

.btn-config-item {
    margin-top: 24px;
    padding-top: 24px;

    border-top: 1px solid #e7e9eb;
}

.btn-tang-giam {
    border-radius: 4px;
    border: 1px solid #e7e9eb;
    height: 36px;
}

.btn-tang-giam input {
    height: 34px !important;
    margin: 0px !important;
    border: unset !important;
}

.btn-tang-giam .btn-icon {
    background: #f3f4f5;
    width: 34px;
    height: 34px;
    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.btn-tang-giam .btn-icon:hover {
    background: #f3f4f590;
}

.action-add-content {
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
    padding: 8px;

    position: relative;
    z-index: 3;
}

.action-add-content label {
    padding: 8px;
}

.add-content-new {
    display: flex;
    flex-direction: column;
}

.add-content-new .add-content-item {
    /* width: 100px; */
    /* height: 100px; */
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}

.add-content-new .add-content-item .icon-antion {
    border-radius: 4px;
    border: 1px solid #cfd3d8;

    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
}

.add-content-new .add-content-item .icon-antion i {
    width: 24px !important;
    height: 24px !important;
}

.add-content-new .add-content-item:hover {
    background: #f3f4f5;
}

.popover-action-page-checkout {
    top: unset !important;
    right: -360px !important;
    left: unset !important;
    bottom: 0px;
    width: 350px;
}

.popover-action-page-checkout.add-channel {
    right: -280px !important;
}

.container-image {
    margin-top: 8px;
    padding: 20px 16px;
}

.container-image .version-upload-image {
    width: 100% !important;
    min-height: 100px !important;
    height: unset !important;
}

.container-image .version-upload-image img {
    max-height: 500px;
}

.container-image .group-upload-image {
    width: 100% !important;
    height: 100% !important;
}

.action-delete {
    border-top: 1px solid #e7e9eb;
    padding: 20px 16px 16px 16px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 16px;
    gap: 16px;
    background: #ffffff;
}

.product-list-body {
    padding: 20px 0px;
}

.product-list-body .product-empty .new-ldicon-home {
    width: 64px !important;
    height: 64px !important;
}

.product-list-add {
    border-radius: 4px;
    padding: 14px 16px;
    border: 1px dashed #5036da;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;
    margin: 12px 24px;
    cursor: pointer;
}

/* .product-list-add  */

.product-list {}

.product-list .product-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
    gap: 16px;
    padding: 16px 24px;
    cursor: pointer;
}

.product-list .product-item .product-name {
    width: calc(100% - 70px);
}

.product-list .product-item .product-price {
    width: 100px;
    white-space: nowrap;
    text-align: end;
}

.product-list .product-item .product-delete {
    width: 16px;
}

.product-list .product-item:hover {
    background: #f4f2fc;
}

.product-list .product-item .product-info {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
}

.product-list .product-item .product-image {
    width: 52px;
    height: 52px;
    border-radius: 4px;
}

.type-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
    gap: 16px;
    padding: 20px 24px;
}

.type-header .type-item {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #e7e9eb;
    width: 100%;
    cursor: pointer;
}

.type-header .type-item.active {
    border: 1px solid #5036da;
}

.color-change {
    width: 56px;
    height: 24px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #e7e9eb;

    cursor: pointer;
}

.change-color-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid #cfd3d8;
    border-radius: 8px;
}

.page-footer .change-color-block .popover-picker {
    top: 45px !important;
}

.change-color-block .popover-picker {
    top: unset !important;
    z-index: 2;

}

.change-color-block .ladiui.form-control.color-input {
    border: unset !important;
    width: 100px !important;
    height: 20px !important;
    border-radius: 0px 0px 8px 8px !important;
    text-align: center;
}

.change-color-block .ladiui.form-control.color-input:focus {
    border: unset !important;
}

.config-language {
    padding: 20px 24px 12px 24px;
}

.config-more {
    padding: 12px 24px;
}

.page-checkout-home-body {
    padding: 0px 24px 24px 24px;
}

.page-checkout-search {
    padding: 24px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 12px;
    gap: 12px;
}

.page-checkout-search .ladiui.dropdown {
    width: 274px !important;
}

.page-checkout-search .ladiui.search-group {
    width: calc(100% - 260px);
}

.page-checkout-search .ladiui.search-group.store {
    width: 100%;
}

.page-checkout-table {
    position: relative;
}

.page-checkout-table .action-record-table {
    left: 32px !important;
}

.checkout-config-selected-box {
    padding: 20px 24px 16px;
}

.checkout-config-selected-box .checkout-config-selected-box-item {
    border-radius: 4px;
    border: 1px solid #e7e9eb;
    padding: 12px;
    width: 100%;
    cursor: pointer;

    display: flex;
    align-items: center;
}

.checkout-config-selected-box .checkout-config-selected-box-item.active {
    border: 1px solid #5036da;
}

.checkout-config-share-select {
    padding: 16px 24px 12px;
}

.background-config {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    grid-gap: 12px;
    gap: 12px;
}

.background-config .background-config-item {
    cursor: pointer;
}

.background-config .background-config-item .background {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #e7e9eb;
    /* width: 108px; */
    /* height: 67px; */
}

.background-config .background-config-item.active .background {
    border: 1px solid #1c00c2;
}

.background-config .background-config-item.active .text-14 {
    color: var(--text-brand);
}

.gradient-directions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gradient-directions .gradient-direction-item {
    border: 1px solid #e7e9eb;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.gradient-directions .gradient-direction-item.active {
    background: #f3f4f5;
}

.gradient-directions .gradient-direction-item .icon-new {
    width: 20px !important;
    height: 20px !important;
}

.channel-list .section-channel {
    padding: 20px;
}

.container-product-info {
    padding: 20px 20px 16px;
    background: #ffffff;
}

.container-product-info .block-display-type .block-content .block-item.left {
    padding: 16px;
}

.container-product-info .block-display-type .block-content .block-item.left .list-pr-exam .pr-item .top {
    width: 40px;
    height: 40px;
}

.container-product-info .block-display-type .block-content .block-item.left .list-pr-exam .pr-item .bottom {
    width: 40px;
}

.container-product-info .block-display-type .block-content .block-item.right {
    padding: 16px;
}

.page-checkout-dns {
    height: calc(100vh - 100px);
}

.btn-add-payment {
    margin: 0px 24px;
    padding: 12px 0px 20px;
    border-radius: 4px;
    /* border: 1px dashed #5036da; */
    display: flex;
    align-items: center;
    justify-content: start;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

.icon-desktop-mobile-content {
    border-left: 1px solid #e7e9eb;
    padding-left: 16px;
}

.redirect-url {
    border-radius: 4px;
    border: 1px solid #cfd3d8;
    padding: 12px;
    width: 100%;
}

.redirect-btn {
    border: 1px solid #1c00c2;
}

.redirect-or {
    margin-top: 20px;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}

.redirect-or-boder {
    width: 100%;
    /* height: 1px; */
    border-top: 1px solid #e7e9eb;
}

.path-input {
    width: 100%;
    position: relative;
}

.path-input .icon-new {
    position: absolute;
    top: 12px;
    right: 10px;
}

.product-btn-add {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;

    background: #ffffff;
    padding: 12px 0px;
}

.checkout-edit-url {
    border-radius: var(--border-radius-04, 4px);
    border: 1px solid var(--Border-Border-border-01, #CFD3D8);
    background: var(--Layer-layer-01, #FFF);

    padding: 12px 36px 12px 12px;
    height: 40px;
    width: 100%;
    position: relative;
    margin-top: 8px;
}

.checkout-edit-url .new-ldicon-copy {
    position: absolute;
    right: 12px;
}

.coppy-btn {
    border-radius: 4px;
    background: #F3F4F5;

    width: 42px;
    height: 42px;

    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.coppy-btn.disabled {
    cursor: none;
}

.btn-select-domain-store {
    width: calc(100% - 50px);
}

.style-switch {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 8px;
    gap: 8px;

    margin-bottom: 20px;
}

.style-switch .ladiui.switch {
    margin: 0px;
}

.action-table {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 24px;
    gap: 24px;
}

.action-table .open-page {
    width: 24px;
    height: 24px;
}
.timeline {
    /* padding: 0px calc(18% - 100px); */
}

.timeline .top .btn-submit-comment {
    height: 32px !important;
}

.timeline .noavatar {
    background: #f3f4f5;
    color: #202020;
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    /* padding-top: 25%; */
    font-size: 14px;
}

.timeline .avatar {
    width: 40px;
    height: 40px;
    border-radius: 8px;
}

.timeline .ladiui-input-group {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    display: inline-block;
}

.timeline .ladiui-input-group input {
    width: 100% !important;
    border-right: 0px;
    border-radius: 4px 0px 0px 4px;
    border: 0px;
}

.timeline .ladiui-input-group .wrapper-input {
    display: inline-block;
    width: 100%;
}

/* .timeline .ladiui-input-group .ladiui-input-group-prepend {
    padding: 5px;
    display: inline-block;
    width: 100%;
} */

.timeline .ladiui-input-group .ladiui-input-group-prepend span {
    float: right;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend span .action {
    margin-right: 8px;
    width: 50px;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend span .action:last-child {
    margin-right: 0px;
}

.timeline .container {
    max-width: 350px;
    max-height: 630px;
    overflow: hidden;
    margin: 30px auto 0;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    font-family: 'Open Sans', sans-serif;
}

.timeline .navbar {
    background: #b97cfc;
    color: #fff;
    padding: 1em 0.5em;
}

.timeline .navbar a {
    color: #fff;
    text-decoration: none;
    font-size: 1.3em;
    float: left;
}

.timeline .navbar span {
    font-size: 1.1em;
    font-weight: 300;
    display: block;
    text-align: center;
}

.timeline .profile-pic {
    width: 30px;
    height: 30px;
    display: inline-block;
    float: right;
    position: relative;
}

.timeline .profile-pic img {
    width: 100%;
    border-radius: 50%;
}

.timeline .notification {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 2px;
    right: 2px;
    background: #f93b69;
}

.timeline .header {
    background: url(https://unsplash.it/1080/720?image=1044);
    background-size: cover;
    color: #fff;
    position: relative;
}

.timeline .color-overlay {
    padding: 3em 2em;
    box-sizing: border-box;
    background: rgba(123, 94, 155, 0.5);
}

.timeline .actionbutton {
    position: absolute;
    background: #f93b69;
    width: 50px;
    height: 50px;
    font-size: 3em;
    font-weight: 300;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -25px;
    right: 20px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.timeline .day-number {
    font-size: 4em;
    display: inline-block;
    margin-right: 15px;
}

.timeline .date-right {
    display: inline-block;
}

.timeline .day-name {
    font-size: 1.6em;
}

.timeline .month {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.6em;
    letter-spacing: 2px;
    margin-top: 2px;
}

.timeline .content {
    /* max-height: 400px; */
    overflow-y: unset;
    padding: 0px 0px 24px !important;
    min-height: unset !important;
}

.timeline .content.show {
    max-height: unset;
}

.timeline .content>ul {
    /* padding: 1em 0 0 0; */
    margin: 0;
    list-style: none;
    position: relative;
}

.timeline .content>ul li {
    padding: 16px 12px;
}

.timeline .content>ul::before {
    content: ' ';
    height: 90%;
    width: 1px;
    background-color: #d3c9fb;
    position: absolute;
    top: 0;
    left: 32px;
    z-index: 1;
}

.timeline .content>ul>li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.timeline .content>ul>li .item-avt-left {
    display: flex;
    align-items: flex-start;
    z-index: 2;
    justify-content: start;
    width: 100%;
}

.timeline .content>ul>li .item-time-right {
    display: flex;
    /* width: 150px; */
    align-items: center;
    justify-content: space-between;
}

.timeline .content>ul>li .item-time-right .ladiui-btn-dropdown {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid var(--border-02);
    background: #ffffff;
}

.timeline .content>ul>li .item-time-right .ladiui.icon-ldp-dot {
    width: 16px;
    height: 16px;
}

.timeline .content>ul>li .item-time-right .ladiui.dropdown-menu {
    right: 0px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 0px rgba(186, 186, 186, 0.3);
    width: 200px;
}

.timeline .content>ul>li .item-avt-left .ladiui-input-group {
    padding: 12px;
}

.timeline li>div {
    display: inline-block;
    /* margin: 1em 0; */
    vertical-align: middle;
    margin-right: 5px;
    /* z-index: 2; */
    position: relative;
    /* border: 3px solid #ffffff; */
}

.timeline .bullet {
    width: 1em;
    height: 1em;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    margin-right: 1em;
    position: relative;
}

.timeline .bullet.pink {
    border: 2px solid #f93b69;
}

.timeline .bullet.green {
    border: 2px solid #b0e8e2;
}

.timeline .bullet.orange {
    border: 2px solid #eb8b6e;
}

.timeline .time {
    /* width: 15%; */
    font-size: 12px;
    padding-top: 0.25em;
    color: var(--main-secondary);
}

.timeline .desc {
    width: 100%;
}

.timeline h3 {
    margin: 0;

    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.timeline h4 {
    margin-top: 4px;
    color: var(--text-secondary);
    font-size: 14px;
}

/* .timeline .avatar::before {
    content: ' ';
    height: 72px;
    width: 1px;
    background-color: #d3c9fb;
    position: absolute;
    top: -16;
    left: 20px;
    z-index: 1;
} */

.timeline .avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.timeline .credits,
.timeline .video {
    position: absolute;
    bottom: 10px;
    color: #808080;
    font-size: 100%;
    text-decoration: underline;
}

.timeline .credits {
    left: 10px;
}

.timeline .video {
    right: 10px;
}

.timeline .showmore {
    text-align: center;
}

.timeline .ladiui.button[disabled] {
    color: #6c7293;
    border-color: #ebedf2;
    background-color: #f4f5f8;
}

.timeline .top div {
    display: inline-block;
}

.timeline .top .input-text>input::placeholder {
    font-size: 13px;
    /* font-style: italic; */
}

.timeline .top .avatar {
    /* margin: 1em 0.5em 1em -2px; */
    /* margin: 0px 5px 5px -2px; */
    vertical-align: middle;
    display: inherit;
    width: 40px;
    height: 40px;
}

/* .timeline .top {
    padding: 0 0 0 1.5em;
} */

.timeline .top .input-text {
    width: 70%;
    /* margin-right: 15px; */
    margin-left: 7px;
}

.timeline .top input {
    vertical-align: middle;
}

.timeline .top .user-avatar {
    border-radius: 8px;
    width: 40px;
    height: 40px;
}

.timeline .input-history .input-custom {
    border: unset !important;
    box-shadow: unset !important;
    padding: 10px 0px !important;
}

.timeline .input-history .input-custom:focus {
    border: unset !important;
}

.timeline .input-history {
    width: 100% !important;
    position: relative;
    /* height: 60px; */
    /* border: 1px solid #f1f3f4; */
}

.timeline .input-history .comment {
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background-color: var(--main-primary);
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    margin-top: 0px !important;
    text-align: center;
}

.timeline .input-history .comment span {
    display: block;
    margin-top: 2px;
}

.timeline .expand .item {
    display: none;
    height: 100%;
    padding: 10px 0px;
}

.timeline .expand .item.show {
    display: block !important;
}

.timeline .top .comment {
    float: right;
    margin-top: 1em;
}

.timeline .expand {
    cursor: pointer;
}

.timeline .expand .title i {
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
    transition: all 100ms;
}

.timeline .expand .title i.show {
    rotate: 90deg;
}

.timeline .expand .item .key {
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.timeline .expand .item .value {
    color: var(--text-secondary);
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
}

.timeline .ladiui-input-group .ladiui-input-group-prepend button {
    height: 30px !important;
    padding: 2px 5px !important;
}

.timeline .row-actions {
    vertical-align: bottom;
}

.timeline .row-actions ul {
    right: 0px;
}

.timeline .list-comment-history ul li {
    padding: 0px !important;
}

.timeline .top {
    display: flex !important;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    border-radius: 8px;
    padding: 16px 12px;
    border: 1px solid var(--border-02);
    background: #ffffff;
}
.shipping-edits {
    background: var(--background-02);
}
.page-content .page-shipping {
    padding: 0px 24px;
}

.block-content-item .item-header {
    padding-bottom: 20px;
    border-bottom: 0.5px solid var(--border-02);
}

.version-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 48px;
}

.btn-add-version {
    width: 600px !important;
    padding: 10px 16px !important;
    border-radius: 4px !important;
    border: 1px dashed var(--border-border-brand-border-brand, #5036da) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
}

.btn-add-version .icon-ldp-add {
    background-color: #5036da;
}

.btn-add-version span {
    color: #5036da;
}

.btn-add-version:hover {
    background-color: #5036da;
}

.btn-add-version:hover .icon-ldp-add {
    background-color: #ffffff;
}

.btn-add-version:hover span {
    color: #ffffff;
}

#modal-product-digital-create .popover-image-product {
    top: unset !important;
    bottom: 20px;
}

.group-upload-image {
    /* padding: 48px 24px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--full-color-grey-50, #f3f4f5);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    border-radius: 8px;
    min-height: 100px;
}

.list-version {
    display: flex;
    flex-direction: column;
}

.list-version .version-item {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    padding: 12px 0px;
}

.list-version .version-item .head {
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
}

.list-version .version-item .version-move {
    width: 32px;
}

.list-version .version-item .version-name {
    width: calc(100% - 394px);
}

.list-version .version-item .version-name.not-id {
    width: calc(100% - 328px);
}

.list-version .version-item .version-price {
    width: 100px;
}

.list-version .version-item .version-download-num {
    width: 100px;
}

.list-version .version-item .version-id {
    width: 50px;
    cursor: context-menu;
    padding: 2px 4px;
}

.list-version .version-item .version-action {
    width: 32px;
}

.version-file-content {
    grid-gap: 16px;
    gap: 16px;
    padding: 12px 16px;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    box-shadow: 0px 0px 0px 3px #fff inset;
}

.version-file-content .version-action-file-icon {
    background: var(--layer-layer-brand-layer-brand, #f4f2fc);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
}

.version-file-content .version-file-url {
    width: calc(100% - 192px);
    cursor: pointer;
}

.version-file-content .version-file-url .version-url {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.version-file-content .version-action-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: 32px;
    height: 32px;

    border-radius: 8px;
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: var(--layer-layer-01, #fff);
}

.version-file-content .popover-version-file {
    top: 32px !important;
    right: 0px;
    left: unset !important;

    background-color: #fff;
    padding: 20px 16px 12px 16px;
    border-radius: 8px;
    width: 400px;
    z-index: 99999;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

/* .product-degital .version-upload-image .group-upload-image {
    width: 100%;
    height: 300px;
}

.product-degital .version-upload-image {
    width: 100%;
    height: 300px;
} */

/* .product-degital .version-upload-image img {
    width: 100%;
} */

.version-upload-image {
    position: relative;
    width: 400px;
    height: 400px;
}

.version-upload-image .builder-loading {
    position: absolute !important;
}

.version-upload-image .group-upload-image {
    position: absolute;
    top: 0px;
    width: 400px;
    height: 400px;
    background: #f3f4f5e6;
    /* padding-top: 120px; */
    visibility: hidden;
}

.version-upload-image:hover .group-upload-image,
.version-upload-image.active .group-upload-image {
    visibility: visible;
}
:root {
    --main-secondary: #9fa7b1;
    --main-default: #0e233c;
    --main-primary: #1c00c2;
    --hover-secondary: #cfd3d8;
}

.ladiui.modal-header {
    justify-content: flex-start !important;
    padding: 20px 24px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom: 1px solid var(--border-02);
}

.ladiui.title-text-form-lever1 {
    font-size: 20px !important;
    color: var(--text-primary) !important;
}

.ladiui.modal-header .sub-title {
    font-size: 14px;
    line-height: 20px;
    color: var(--text-secondary);
}

.ladiui.modal-close .icon-ldp-close {
    width: 20px;
    height: 20px;
}

.ladiui.modal-close:hover {
    opacity: 0.8 !important;
}

.ladiui.modal-close:hover .ladiui.icon-ldp-close {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-close-fill-2.svg) no-repeat !important;
    background-size: contain !important;
}

.ladiui.modal-footer {
    padding: 20px 24px !important;
    border-top: 1px solid var(--border-02);
}

.ladiui.btn-sm {
    height: 32px !important;
    padding: 6px 16px !important;
}

.ladiui.btn-md {
    height: 42px !important;
    padding: 10px 16px !important;
}

.ladiui.btn {
    border-radius: 4px !important;

    border: 1px solid var(--border-01);
}

.ladiui.btn.disable {
    opacity: 0.6;
    cursor: no-drop !important;
}

.ladiui.form-control {
    padding: 10px 12px !important;
    border-radius: 4px !important;
    border: 1px solid var(--border-01);
}

.ladiui.form-control:focus {
    border: 1px solid var(--border-brand);
}

.ladiui.error-input {
    color: var(--text-danger);
    margin-top: 4px;
}

.ladiui.tab-link {
    padding: 12px 0px !important;
}

.ladiui.dropdown-menu {
    padding: 10px 0px !important;
    border-radius: 4px;
    z-index: 99;
    max-height: 500px;
    overflow: auto;
}

.ladiui.label-12 {
    color: var(--text-primary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.ladiui.label-12-secondary {
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}

.ladiui.label-14 {
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.ladiui.label-16 {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.ladiui.label-18 {
    color: var(--text-primary);
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.ladiui.label-20 {
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

.ladiui.text-14 {
    color: var(--text-secondary);
    font-size: 14px;
}

.ladiui.text-14-b500 {
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.ladiui.text-14-brand {
    color: var(--text-brand);
    font-size: 14px;
    line-height: 20px;
}

.ladiui.text-18-secondary {
    color: var(--text-secondary);
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.ladiui.text-primary {
    color: var(--text-primary) !important;
}

.ladiui.text-danger {
    color: var(--text-danger) !important;
}

.ladiui.text-brand {
    color: var(--text-brand) !important;
}

.ladiui.text-upper {
    text-transform: uppercase;
}

.ladiui.fw-500 {
    font-weight: 500;
}

.ladiui.text-center {
    text-align: center;
}

.ladiui.text-right {
    text-align: right;
}

.ladiui.heading-label-2 {
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
}

.ladiui.flex-center {
    display: flex;
    align-items: center;
}

.ladiui.flex-center-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.ladiui.label-02 {
    color: var(--text-primary);
    /* Heading/label-02 */
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
}

.ladiui.pos-rel {
    position: relative;
}

.ladiui.search-section .search-section-detail {
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(32, 32, 32, 0.15);
    width: 100%;
    position: absolute;
    max-height: 0px;
    overflow: auto;
    z-index: 15;
}

.ladiui.search-section .search-section-detail.show {
    max-height: 500px;
}

.ladiui.search-section .search-section-detail .create-btn {
    padding: 16px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

.ladiui.search-section .search-section-detail .create-btn i {
    position: unset !important;
}

.ladiui.search-section .search-section-detail .search-section-list {
    padding: 12px 0px;
}

.ladiui.search-section .search-section-detail .search-section-list .search-section-item {
    padding: 12px 16px;
    border-top: 0.5px solid var(--border-02);
    cursor: pointer;
}

.ladiui.search-section .search-section-detail .search-section-list .search-section-item:hover {
    background: var(--layer-brand);
    color: var(--text-brand);
}

.ladiui.value-list {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.ladiui.value-list .value-item {
    display: flex;
    align-items: center;
}

.ladiui.flex-center-end {
    display: flex;
    align-items: center;
    justify-content: end;
}

.ladiui.flex-center-start {
    display: flex;
    align-items: center;
    justify-content: start;
}

.ladiui.btn-md.dropdown-toggle::after {
    margin-top: 15px;
}

.ladiui.dropdown-toggle::after {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down.svg) no-repeat center;
}

.ladiui.boder-none {
    border: none !important;
}

.ladiui.search-field {
    height: 40px !important;
    padding-left: 32px !important;
}

.block-search .DateRangePickerInput {
    height: 40px !important;
}

.block-search .DateRangePickerInput input {
    height: 38px;
}

.ladiui.popover-right {
    left: unset !important;
    right: 0px !important;
}

.ladiui.dropdown-text {
    margin-right: 8px !important;
}

.ladiui.dropdown-item i,
.ladiui.dropdown-item span {
    pointer-events: none;
}

.ladiui.container-place {
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    border: 0.5px solid var(--border-02);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.ladiui.container-place .footer {
    padding-top: 16px;
    border-top: 0.5px solid var(--border-02);
}

.ladiui.tag {
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 400;
}

.ladiui.shipping-pending,
.ladiui.payment-pending {
    color: var(--full-color-warning-700);
    background: var(--layer-layer-warning-layer-warning-hover);
}

.ladiui.shipping-readytopick,
.ladiui.shipping-returning,
.ladiui.shipping-returned,
.ladiui.shipping-deliveredtopostman,
.ladiui.shipping-delivering {
    color: var(--support-support-info, #3c72f9);
    background: var(--layer-layer-info-layer-info-hover, #d8e7fe);
}

.ladiui.shipping-delivered,
.ladiui.payment-success {
    background: var(--layer-layer-success-layer-success-hover, #e8fbc9);
    color: var(--full-color-success-700, #248a00);
}

.ladiui.shipping-canceled,
.ladiui.payment-canceled {
    color: #ffffff;
    background: var(--button-danger);
}

.ladiui.restock-all {
    color: #facc64;
    background: #fef2cb;
}

.ladiui.order-canceled {
    color: #f58973 !important;
    background: #fde0d0 !important;
}

.laidui.background-xam {
    background: var(--background-background-02, #f3f4f5);
}

.ladiui.variant-image-48 {
    width: 48px;
    height: 48px;
    border-radius: 4px;
}

.ladiui.text-heading-02 {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.ladiui.flex-start-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.ladiui.flex-column-end {
    display: flex;
    align-items: end;
    flex-direction: column;
}

.ladiui.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ladiui.border-t-50p {
    border-top: 0.5px solid var(--border-02);
}

.ladiui.search-group i {
    z-index: 1;
    left: 5px;
    right: unset !important;
}

.ladiui.search-group i.ls-clear-search-input {
    z-index: 1;
    right: 10px !important;
    top: 12px !important;
    left: unset !important;
}

.ladiui.table-vertical th:first-child,
.ladiui.table-vertical td:first-child {
    padding-left: 0px;
    /* min-width: 120px; */
}

.ladiui.action-table {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.ladiui.action-table .btn-group .dropdown-toggle {
    min-width: unset !important;
    margin-right: 0px !important;
}

.ladiui.email-status-1::before {
    content: '';
    display: inline-block;
    background: #4ac000;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #248a0033;
    background-clip: padding-box;
}

.ladiui.email-status-0::before {
    content: '';
    display: inline-block;
    background: #ef9300;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #ef930033;
    background-clip: padding-box;
}

.ladiui.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1220px;
    margin: 0px auto;
    padding: 16px 24px;
}

.ladiui.header-o {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 16;
    border-bottom: 0.5px solid var(--border-01-50p);
}

.ladiui.order-breadcrumbs {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;

    color: var(--text-secondary);
    font-size: 14px;
    line-height: 24px;
}

.ladiui.order-breadcrumbs .back-btn {
    cursor: pointer;
}

.ladiui.order-breadcrumbs .back-btn:hover {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
}

.ladiui.content-max-width {
    max-width: 1220px;
    margin: auto;
}

.ladiui.group-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
}

.ladiui.group-body .ladiui.body-right {
    width: 710px;
}

.ladiui.group-body .ladiui.body-left {
    width: 375px;
}

.ladiui.flex-wap-wap {
    flex-wrap: wrap;
}

.ladiui.page-container {
    min-height: unset !important;
    height: 24px;
    grid-gap: 20px;
    gap: 20px;
}

.ladiui.page-container a {
    /* padding: 0px !important; */
    margin: 0px !important;
    border-radius: 4px;
    color: #566577 !important;
    line-height: 20px;
    /* padding: 0px 8px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.ladiui.page-container a.active,
.ladiui.page-container a:hover {
    color: #0E233C;
    background: #F3F4F5;
    color: var(--text-text-brand, var(--text-text-brand, #1c00c2)) !important;
}

.ladiui.page-container .ldicon-forward,
.ladiui.page-container .ldicon-chevron-left,
.ladiui.page-container .ldicon-chevron-right,
.ladiui.page-container .ldicon-backward {
    width: 16px;
    height: 16px;
}

.ladiui.dropdown-item {
    padding: 6px 16px;
    white-space: break-spaces;
    text-transform: none;
}

img {
    object-fit: cover;
}

.ladiui.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: 14;
    height: 100%;
    width: 100%;
}

.ladiui.payment-status-pending::before,
.ladiui.shipping-status-pending::before {
    content: '';
    display: inline-block;
    background: #ef9300;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #ef930033;
    background-clip: padding-box;
}

.ladiui.payment-status-pending.draft-order::before {
    background: #bcbcbc;
    border: 3px solid #bcbcbc33;
}

.ladiui.payment-status-success::before,
.ladiui.shipping-status-delivered::before {
    content: '';
    display: inline-block;
    background: #4ac000;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #248a0033;
    background-clip: padding-box;
}

.ladiui.shipping-status-readytopick::before,
.ladiui.shipping-status-returning::before,
.ladiui.shipping-status-returned::before,
.ladiui.shipping-status-deliveredtopostman::before,
.ladiui.shippingstatus--delivering::before {
    content: '';
    display: inline-block;
    background: #3c72f9;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #d8e7fe;
    background-clip: padding-box;

    /* color: var(--support-support-info, #3c72f9);
    background: var(--layer-layer-info-layer-info-hover, #d8e7fe); */
}

.ladiui.shipping-status-canceled::before,
.ladiui.payment-status-canceled::before {
    content: '';
    display: inline-block;
    background: var(--button-danger);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #fde0d0;
    background-clip: padding-box;

    /* color: #ffffff;
    background: var(--button-danger); */
}

.form-check-input {
    width: 14px !important;
    height: 14px !important;
}

.form-check-input:checked::before {
    width: 8px !important;
    height: 8px !important;
    top: 2px !important;
    right: 2px !important;
}

.ladiui.dropdown-up {
    top: unset !important;
    bottom: 20px !important;
}

.ladiui.error-empty {
    border: 1px solid var(--border-dange);
}

.ladiui.page-body {
    background: var(--background-02);
}

.ladiui.page-content {
    max-width: 1220px;
    margin: 0px auto;
    padding: 24px;
    overflow: auto;
    /* background: #ffffff; */
    /* min-height: 500px; */
}

.ladiui.modal-footer.justify-space-between {
    justify-content: space-between !important;
}

.ladiui.selected-box-tem {
    padding: 20px 16px;
    border-radius: 4px;
    border: 1px solid #e7e9eb;

    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
    cursor: pointer;
    width: 100%;
}

.ladiui.selected-box-tem .box-icon {
    background: #f3f4f5;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.ladiui.selected-box-tem .box-title {
    width: calc(100% - 68px);
    line-height: 20px;
}

.ladiui.selected-box-tem .box-icon i {
    width: 24px !important;
    height: 24px !important;
}

.ladiui.selected-box-tem:hover {
    /* background: #f4f2fc; */
    border: 1px solid #5036da;
}

.ladiui.selected-box-tem.active {
    border: 1px solid #5036da;
}

.ladiui.selected-box-tem.active .box-icon {
    background: #f4f2fc;
}

.ladiui.selected-box-tem.active .box-icon i {
    background: #1c00c2 !important;
}

.ladiui.help-box {
    position: relative;
}

.ladiui.help-box .ladiui.help-data {
    display: none;
    width: 224px;
    height: 118px;

    position: absolute;
    z-index: 2;
    /* left: 20px; */
    bottom: 30px;
    right: -100px;
}

.ladiui.help-box:hover .ladiui.help-data {
    display: block;
}

.ladiui.checked-radio-v2 {
    border: 1px solid var(--main-mute);
    padding: 2px;
    border-radius: 50%;
}

.ladiui.checked-radio-v2.selected {
    border: 1px solid var(--main-primary);
}

.ladiui.checked-radio-v2 .background {
    width: 8px !important;
    height: 8px !important;

    background-color: #ffffff;
    border-radius: 50%;
    transition: 0.5s;
}

.ladiui.checked-radio-v2.selected .background {
    background-color: var(--main-primary);
}

.ladiui.text-area {
    padding: 10px 12px !important;
    border-radius: 4px !important;
    border: 1px solid var(--border-01);
    display: block;
    width: 100%;
}

.form-control.pr-32 {
    padding-right: 32px !important;
}

.btn-select-option {
    padding-right: 30px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 8px;
    gap: 8px;

    height: unset;
    min-height: 42px;
}

.btn-select-option .ldicon-close {
    width: 12px;
    height: 12px;
}

.ladiui.alert-text:first-letter {
    text-transform: uppercase;
}

.ladiui.menu-item .new-ldicon-inbox {
    width: 24px;
    height: 24px;
}


.iframe-open #sidebar-left .ladiui.aside .ladiui.nav,
.iframe-open .content-main-full .page-order .menu-left {
    display: none;
}

.iframe-open .ladiui.main-custom {
    width: 100%;
    margin: auto;
}

.iframe-open .page-max {
    width: 100%;
    overflow: hidden;
    height: unset;
}

.iframe-open .page-order .page-right,
.iframe-open .setting-page .right-content {
    width: 100%;
    /* max-width: unset; */
}

.ladiui.btn-secondary,
.ladiui.btn-danger,
.ladiui.btn-primary {
    border: unset;
}

.ladiui.dropdown .ladiui.btn {
    padding-right: 32px !important;
}

.iframe-open.ladiui.wrapper {
    min-width: unset;
}

.ladiui.modal-close {
    padding: 0px;
}

.ladiui.select-dropdown {
    color: #0e233c;
}

.ladiui.btn-outline-light:hover {
    border-color: var(--hover-secondary);
}

.page-no-access-content {
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.page-no-access-content .page-no-access-content-icon {
    width: 80px;
    height: 80px;
}

.page-no-access-content .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.page-no-access-content .sub-title {
    color: #566577;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.page-onboading {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.page-onboading .page-onboading-image {
    max-width: 1220px;
    width: 100%;
}

.page-onboading .page-onboading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    max-width: 689px;
    text-align: center;
}

.page-onboading .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.page-onboading .sub-title {
    color: #566577;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.onboading-item .onboading-image {
    width: 412px;
}

.onboading-item .onboading-item-content {
    margin-top: 24px;
    text-align: center;
}

.onboading-item .onboading-item-content .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.onboading-item .onboading-item-content .content {
    color: var(--Text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
}

.ladiui.pagination-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background-color: #E7E9EB;
}

.ladiui.pagination-dot.active {
    background-color: #1C00C2;
}

.onboading-page-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 16px;
    gap: 16px;
    margin-top: 20px;
}

.onboading-item-action {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 12px;
    gap: 12px;
}

.onboading-item-action button {
    width: 50%;
    justify-content: center;
}

.store-create-name .title {
    color: #0E233C;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.store-create-name #store-using-default {
    width: 18px;
    height: 18px;
}

.store-create-name .sub-input {
    color: #0E233C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.store-create-name .btn-start {
    justify-content: center;
    color: var(--Icon-icon-on-colors, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

input.ladiui.error {
    border-color: #E01A1A;
}

.ladiui.input-sub-error {
    color: #E01A1A;
    margin-top: 4px;

    font-size: 12px;
}

.create-account-success-content .image-loading {
    width: 80px;
    height: 80px;
    margin-top: 60px;
}

.create-account-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-account-loading .title {
    color: #0E233C;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 32px;
}

.create-account-loading .sub-title {
    color: #566577;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

/* https://w.ladicdn.com/ladiui/icons/new-ldicon-background-successfully.svg */
.create-account-success .header {
    margin-top: 20px;
    text-align: center;
}

.create-account-success .header .bg-hoa-hoet {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-background-successfully.svg) no-repeat;
    padding: 10px;
}

.check-success {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #BEF29E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.check-success .ldicon-circle-check {
    width: 32px;
    height: 32px;
    background-color: #35A500;
}

.create-account-success .title {
    color: var(--Gray-900, #101828);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.create-account-success .sub-title {
    color: var(--Text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.create-account-success .list-actions {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

.create-account-success .list-actions .action-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 8px;
    gap: 8px;
}

.create-account-success .list-actions .action-item .antion-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 8px;
    gap: 8px;
}

.create-account-success .list-actions .action-item .action {
    color: var(--Link-link-primary, #2B57D6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    cursor: pointer;
}

#ladi-popup-error-message .ladiui.modal-content {
    padding: 0px;
}

#ladi-popup-error-message .ladiui.modal-header {
    border-bottom: unset;
}

#ladi-popup-error-message .ladiui.modal-body {
    padding: 0px 24px !important;
}

#ladi-popup-error-message .ladiui.modal-footer {
    border-top: unset;
}

.tooltip:hover .tooltiptext {
    visibility: visible !important;
    opacity: 1 !important;
}

.tooltip .tooltiptext.top-left {
    visibility: hidden;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 900000090;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    line-height: 20px;
    opacity: 0;
    transition: opacity 1s;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.tooltip .tooltiptext.top-left::before {
    content: attr(tooltiptext);
    position: absolute;
    top: -7px;
    right: 0px;
    background: #0E233C;
    border-radius: 4px;
    color: #fff;
    padding: 8px 16px;
    min-width: 20px;
    max-width: 200px;
    margin: auto;
    width: -webkit-max-content;
    width: max-content;
    text-align: center;
    transform: translateX(0) translateY(-100%);
    word-break: break-all;
}

.tooltip .tooltiptext.top-left::after {
    content: "";
    position: absolute;
    top: -7px;
    left: 6px;
    border-width: 6px;
    border-style: solid;
    border-color: #0E233C transparent transparent transparent;
}

.ladiui.switch input {
    display: -webkit-box;
}

.tox.tox-tinymce.tox-fullscreen {
    top: 60px !important;
}

#confirm-variant-delete {
    z-index: 1080;
}
#modal-colume-condition .ladiui.modal-content {
    animation: none;
}
.content-column-condition {
    display: flex;
}

.content-column-condition .column-selected {
    width: 320px;
    padding-right: 24px;
    border-right: 1px solid var(--border-02);
}

.content-column-condition .column-sort {
    padding-left: 24px;
    width: 480px;
}
.content-column-condition .column-selected .title,
.content-column-condition .column-sort .title {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.content-column-condition .column-selected .sub-title,
.content-column-condition .column-sort .sub-title {
    color: var(--text-secondary);
    font-size: 14px;
    line-height: 20px;
}
.content-column-condition .column-selected .search-column {
    width: 100%;
    height: 40px;
    border-radius: var(--Border, 4px);
    border: 1px solid var(--border-border-border-01, #cfd3d8);
    background: var(--layer-layer-01, #fff);
    padding: 10px 10px 10px 36px;
}

.content-column-condition .column-selected .search-column::placeholder {
    color: var(--text-placeholder);
    font-size: 14px;
    line-height: 20px;
}

.content-column-condition .column-selected .search {
    position: relative;
}

.content-column-condition .column-selected .search .new-ldicon-search {
    position: absolute;
    top: 10px;
    left: 10px;
}
.content-column-condition .column-selected .columns {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
.content-column-condition .column-selected .columns li {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;

    cursor: pointer;
}
.content-column-condition .column-selected .columns .ladiui.checkbox {
    width: 14px;
    height: 14px;
}

.content-column-condition .column-selected .columns .form-check-input-checkbox:checked::before {
    /* top: 3.63px; */
    width: 3px;
    height: 7px;
}

.column-sort .column-list-sort {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
}

.column-sort .column-list-sort .column-item-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    background: var(--background-background-02, #f3f4f5);
}

.column-sort .column-list-sort .column-item-sort .column-title {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

/** @format */

body {
    /* color: #313942; */
    color: var(--text-primary);
    font-size: 14px;
    -webkit-hyphens: auto;
            hyphens: auto;
}

.ladiui.wrapper {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    /* min-width: 1366px; */
    min-width: 1280px;
    height: 0;
}

.ladiui.ladiui.my-account-avata img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.ladiui.topbar {
    height: 64px;
    border-bottom: 1px solid var(--main-line);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-white);
    position: fixed;
    width: 100%;
    z-index: 90;
}

.ladiui.main-custom {
    position: relative;
    margin-left: 60px;
    width: calc(100% - 60px);
    /* height: 100vh; */
    /* overflow: auto; */
}

.ladiui.topbar-menu {
    position: fixed;
    padding: 20px 0px 20px 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--main-secondary);
}

.ladiui.topbar-menu-right {
    position: fixed;
    right: 0;
}

/* .ladiui.btn-group {
  padding: 14px 0px 14px 0px;
} */

.ladiui.app-tools img {
    pointer-events: none;
}

.ladiui.tool-notificiations {
    padding: 19px 12px 19px 24px;
}

.ladiui.noti.dropdown-menu {
    /* left: 30px; */
    position: absolute;
    width: 400px;
    right: 122px;
    margin-top: 15px;
}

.ladiui.noti-content {
    display: flex;
    padding: 16px;
}

.ladiui.noti-content.active {
    background-color: rgba(24, 82, 250, 0.05) !important;
}

.ladiui.noti-content:hover {
    background-color: var(--main-line);
}

.ladiui.noti-header {
    padding: 6px 16px 18px;
    display: flex;
    justify-content: space-between;
}

.ladiui.noti-header-right {
    color: var(--main-primary);
}

.ladiui.noti-header-right i {
    margin-right: 4px;
}

.ladiui.noti-i {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    pointer-events: none;
}

.ladiui.noti-text {
    margin-left: 56px;
    cursor: pointer;
    pointer-events: none;
}

.ladiui.noti-sub {
    margin-top: 4px;
    color: var(--main-secondary);
}

.ladiui.noti-time {
    margin-top: 8px;
    color: var(--main-secondary);
}

/* .ladiui.tool-help {
    padding: 20px 12px 20px 12px;
} */

.ladiui.tool-help .dropdown-menu {
    left: 0;
    position: absolute;
    margin-top: 15px !important;
    /* right: 0; */
}

.ladiui.tool-help .dropdown-item {
    display: flex;
    align-items: center;
}

.ladiui.tool-language {
    padding: 20px 12px 20px 12px;
}

.ladiui.language-img {
    cursor: pointer;
    pointer-events: none;
    color: white;
    font-size: 0;
}

.ladiui.tool-language img {
    border-radius: 20px;
}

.ladiui.tool-language .dropdown-menu {
    right: 0px;
    min-width: 150px;
    position: absolute;
    margin-top: 15px;
    /* right: 0; */
}

.ladiui.my-account {
    cursor: pointer;
    /* width: 74px !important; */
    min-width: auto;
}

.ladiui.my-account.dropdown-toggle::after {
    content: none;
}

.ladiui.dropdown-toggle.nav-bar {
    min-width: 200px;
}

.ladiui.my-account-avata {
    /* padding: 12px 0px 12px 12px; */
    pointer-events: none;
}

.ladiui.my-account-info {
    padding-left: 16px;
    pointer-events: none;
}

.ladiui.my-account-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    pointer-events: none;
}

.ladiui.my-account-type {
    padding: 2px 0px 0px 0.5px;
}

.ladiui.account.dropdown-menu {
    width: 332px;
    /* left: 220px; */
    position: absolute;
    right: 24px;
}

.ladiui.account .dropdown-item {
    padding: 12px 21px;
}

.ladiui.account {
    position: relative;
}

.ladiui.account-header {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 24px;
}

.ladiui.account-header-text {
    /* pointer-events: none; */
    /* display: grid; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ladiui.account-content {
    padding: 14px 24px 0;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.account-content-footer {
    padding: 0px 24px;
    margin-bottom: 12px;
    border-top: 1px solid var(--main-line);
}

.ladiui.account-avatar {
    margin-right: 16px;
    display: flex;
    position: relative;
}

.ladiui.account-avatar a {
    padding: 0.5px;
    border-radius: 20px;
    background-color: var(--main-white);
    position: absolute;
    top: 42px;
    left: 42px;
}

.ladiui.account-body-content {
    pointer-events: none;
}

.ladiui.account-avatar img {
    pointer-events: none;
}

.ladiui.avt {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.ladiui.avartar {
    pointer-events: none;
}

.ladiui.account-body-item {
    display: flex;
    align-items: center;
    padding: 12px 24px;
}

.ladiui.my-account-type i {
    margin-right: 0;
    /* position: relative; */
}

.ladiui.dropdown-item.account-body-item i {
    margin-right: 0;
}

.ladiui.account-tick {
    background-color: inherit;
    position: absolute;
    right: 24px;
}

.ladiui.account-body-item:first-child {
    margin-top: 12px;
}

.ladiui.account-body-item:last-child {
    margin-bottom: 12px;
}

.ladiui.account-body-item:hover {
    background-color: var(--main-line);
}

.ladiui.body-avatar {
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
    pointer-events: none;
}

.ladiui.body-avatar img {
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
}

.ladiui.account-status {
    display: flex;
    justify-items: center;
    margin-top: 4px;
}

.ladiui.account-body-item.active .body-avatar {
    border: 2px solid var(--main-primary);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-tick {
    background-color: var(--main-primary);
}

.ladiui.account-body-item.active .body-avatar img {
    border: 2px solid var(--main-white);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-name {
    color: var(--main-primary);
}

/* .ladiui.account-duration {
    margin-left: 8px;
} */

.ladiui.icon-drd {
    padding: 0px 20px 0px 8px;
}

.ladiui.content-navigation .dropdown {
    display: flex;
    flex-direction: column-reverse;
}

.ladiui.new-page.dropdown-menu {
    width: 400px;
    padding: 0;
    margin-bottom: 44px;
}

.ladiui.new-page.dropdown-item {
    display: flex;
    align-items: center;
    padding: 16px;
}

.ladiui.content-sub {
    min-width: 530px;
}

.ladiui.new-page-content {
    margin-left: 8px;
}

/*Hover*/
.aside .ladiui.nav {
    position: fixed;
    z-index: 60;
    top: 0;
    left: 0;
    width: 70px;
    height: 100vh;
    background-color: var(--main-white);
    border-left: 1px solid #e7e9eb;
    transition: width 0.3s;
}

/* .ladiui.logo-item.logo {
    padding: 16px 20px;
    position: relative;
    opacity: 1;
} */

/* .ladiui.logo-item.logo-hover {
    position: absolute;
    opacity: 0;
    pointer-events: none;
} */

/* .aside .ladiui.nav:hover .logo-item.logo-hover {
    position: relative;
    opacity: 1;
    transition: opacity 0.5s;
}

.aside .ladiui.nav:hover .logo-item.logo {
    position: absolute;
} */

/* .aside .ladiui.nav:hover {
    display: block;
    width: 215px;
} */

.ladiui.menu-text {
    position: absolute;
    pointer-events: none;
    left: 50px;
    opacity: 0;
    width: 0;
    overflow: hidden;
    transition: opacity 0.2s, width 0.3s;
}

@keyframes ladiLogo {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* .aside .ladiui.nav:hover .menu-text {
    opacity: 1;
    width: 150px;
}

.aside .ladiui.nav:hover .menu-item {
    width: 215px;
} */

/* .ladiui.menu-item {
    padding: 16px 0px 16px 23px;
    width: 70px;
    transition: width 0.3s;
    border-left: 2px solid var(--secondary-color);
} */

.ladiui.menu-item a i {
    background-color: var(--main-secondary);
}

.ladiui.menu-item:hover .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item:hover i {
    background-color: var(--main-primary);
}

.ladiui.logo-item.logo-hover {
    padding: 16px 59px 16px 21px;
}

.ladiui.menu-item.active {
    background-color: var(--main-white);
    /* border-left: 2px solid var(--main-primary); */
}

.ladiui.menu-item.active i {
    background-color: var(--main-primary);
}

.ladiui.menu-item .icon-prev {
    transform: rotate(90deg);
}

.ladiui.menu-item.active .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item .dropdown-toggle {
    min-width: 50px;
    width: 50px;
}

.ladiui.menu-item .dropdown-toggle:focus {
    border: none;
}

.ladiui.menu-item .dropdown-toggle::after {
    content: none;
}

.ladiui.menu-item .dropdown-menu {
    padding: 0;
    box-shadow: none;
    background-color: inherit;
}

/* .aside .ladiui.nav:hover .dropdown-toggle {
    min-width: 200px;
    width: 200px;
    transition: width 0.3s;
}

.aside .ladiui.nav:hover .ladiui.menu-child {
    position: relative;
    opacity: 1;
    width: 100%;
} */

.ladiui.menu-child {
    position: absolute;
    opacity: 0;
    width: 0;
    transition: opacity 0.2s;
}

.ladiui.menu-child-item {
    padding: 12px 0 12px 34px;
}

.ladiui.menu-child-item:first-child {
    padding: 24px 0 12px 34px;
}

.ladiui.menu-child-item.active a {
    color: var(--main-primary);
}

.ladiui.menu-child-item:last-child {
    padding-bottom: 0px;
}

.ladiui.menu-child li a:hover {
    color: var(--main-primary);
}

.ladiui.menu-child li a.active {
    color: var(--main-primary);
}

.ladiui.menu-child li a {
    color: var(--main-secondary);
}

.ladiui.menu-text {
    white-space: nowrap;
    padding-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.head-noti {
    margin-bottom: 4px;
}

.ladiui.tag-form {
    display: inline-table;
}

.ladiui.dropdown-menu.custom-header {
    min-width: 200px;
}

.facet-container {
    width: 330px;
}

.right {
    float: right;
}

.left {
    float: left;
}

.facet-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    background: #eee;
    padding: 5px;
    width: 143px;
    min-height: 1.5em;
    font-size: 0.85em;
}

.facet-list li {
    margin: 5px;
    padding: 5px;
    font-size: 1.2em;
    width: 120px;
}

.facet-list li.placeholder {
    height: 1.2em;
}

.facet {
    border: 1px solid #bbb;
    background-color: #fafafa;
    cursor: move;
}

.facet.ui-sortable-helper {
    opacity: 0.5;
}

.placeholder {
    border: 1px solid orange;
    background-color: #fffffd;
}

/* width */

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--main-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--main-mute);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--main-secondary);
}

.ladiui-aside {
    width: 200px;
    height: 100%;
    position: relative;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

/*-------------MINI NAVBAR----------------*/

body.mini-navbar .ladiui-aside {
    width: 90px !important;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

body.mini-navbar .ladiui-main {
    width: calc(100% - 90px) !important;
}

body.mini-navbar .ladiui.nav>.menu>li>a>i {
    font-size: 18px;
    margin: 0px auto;
    position: initial;
    border-radius: 50%;
    line-height: 29px;
    width: 40px;
    height: 40px;
}

body.mini-navbar .ladiui.nav>.menu>li>a {
    text-align: center;
    padding: 10px 6px;
    line-height: 20px;
    font-size: 14px;
    display: grid;
    width: 100%;
}

body.mini-navbar .ladiui.setting-bottom a {
    padding: 14px !important;
    display: grid !important;
    text-align: center;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu {
    position: absolute;
    left: 98px;
    top: 0px;
    border-radius: 4px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
    display: none;
    background: #fff;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu.show {
    display: block;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu>li {
    list-style: none;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu>li>a {
    white-space: nowrap;
    padding: 10px 20px;
}

body.mini-navbar .ladiui.nav>.menu>li.open>.ladiui.submenu>li>a::before {
    left: 7px !important;
    display: none;
}

body.mini-navbar .ladiui-nav-toggle {
    width: 91px !important;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

/*-----------END MINI NAVBAR---------------------*/

.ladiui-logo {
    text-align: left;
    height: 50px;
    line-height: 46px;
    width: 200px;
    float: left;
}

.ladiui-logo img {
    width: 60%;
    vertical-align: middle;
    display: inline-block;
}

.ladiui-main {
    width: 100%;
    float: right;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

.ladiui-topbar {
    height: 50px;
    background-color: #1853fa;
    border-bottom: 1px solid #eaedf0;
    background: linear-gradient(90deg, rgba(12, 97, 242, 1), rgba(0, 65, 179, 1));
    display: flex;
}

.mini-navbar .ladiui-nav-toggle i {
    margin-left: 0px;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

.mini-navbar .ladiui-logo {
    margin-left: 18px;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

.ladiui-nav-toggle {
    width: 48px;
    float: left;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.ladiui-nav-toggle i {
    font-size: 18px;
    margin-top: 8px;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    padding: 7px;
}

.ladiui-topbar .topbar-left {
    width: 40%;
    float: left;
}

.ladiui-topbar .topbar-right {
    width: 60%;
    float: right;
    text-align: right;
    margin-right: 15px;
}

.ladiui.topbar-searchbox {
    position: relative;
    display: inline-block;
    height: 50px;
    line-height: 49px;
    margin-left: 30px;
}

.ladiui.topbar-searchbox>i {
    position: absolute;
    top: 17px;
    left: 12px;
    color: #8697a8;
}

.ladiui.topbar-search {
    display: inline-block;
    width: 290px;
    height: 36px;
    padding: 0px;
    text-indent: 40px;
    color: #8697a8;
    font-size: 14px;
    background-color: #f8fafb;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.ladiui.topbar-search::before {
    content: '';
    display: inline-block;
    width: 60px;
    height: 60px;
    background: red;
}

.topbar-profile {
    font-size: 15px;
    height: 50px;
    line-height: 45px;
    float: right;
    margin-left: 15px;
}

.topbar-profile a {
    text-decoration: none;
    color: #44566c;
    vertical-align: middle;
    display: block;
}

.topbar-profile li.ladiui-dropdown ul>li>a {
    transition: background-color 0.15s ease-out;
}

.topbar-profile li.ladiui-dropdown ul>li>a:hover {
    background-color: #f6f7f9 !important;
}

.topbar-profile .ladiui.avatar {
    background-color: #1853fa;
    color: white;
    width: 35px;
    height: 35px;
    display: inline-flex;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: -7px;
    margin-right: 0px;
    background-size: contain !important;
}

.topbar-profile .ladiui-navbar>li>ul {
    position: absolute;
    right: 0px;
}

.ladiui-topbar .ladiui-navbar>li>ul {
    min-width: 290px;
}

.ladiui-topbar .ladiui-navbar>li>ul>li>a {
    padding: 2px 20px;
}

.topbar-profile .ladiui-navbar>li>ul::before {
    content: '';
}

.topbar-profile .ladiui-navbar>li>ul>li:last-child>a.notification-item {
    border-bottom: 0px;
}

.notifications {
    z-index: 5;
}

.notifications>li>ul {
    padding: 0px !important;
}

.notifications .notification-item:hover {
    background: #ebedf2 !important;
}

.notifications .notification-item {
    display: -webkit-inline-box;
    align-items: center;
    border-bottom: 1px solid #f7f8fa;
    transition: background-color 0.3s ease;
    height: auto;
    line-height: 28px;
}

.notifications .has-message {
    position: relative;
}

.notifications .has-message::after {
    position: absolute;
    width: 5px;
    height: 5px;
    background: red;
    content: '';
    border-radius: 50%;
    top: 1px;
    right: 0px;
}

.notifications .notification-item .notification-time {
    font-size: 12px;
    float: left;
    height: 28px;
    line-height: 28px;
    width: 100%;
    text-align: left;
}

.notifications .notification-title {
    font-size: 14px;
}

.notifications .notification-item .notification-icon {
    display: flex;
    flex: 0 0 2.5rem;
    align-items: center;
}

.notifications .notification-item .notification-details {
    padding-left: 20px;
    -webkit-box-orient: vertical;
}

.notifications .notification-item .notification-icon i {
    font-size: 30px;
    color: #5d78ff;
}

.notifications .notification-item .notification-details .notification-item-title {
    transition: color 0.3s ease;
    font-size: 1rem;
    font-weight: 400;
    color: #6c7293;
}

.notifications .notification-item .notification-details .notification-item-time {
    font-size: 1rem;
    font-weight: 300;
    color: #a7abc3;
}

.topbar-profile .ladiui-navbar.-horizontal>li {
    line-height: 55px;
    text-align: left;
}

.topbar-profile .ladiui-navbar.-horizontal>li:first-child {
    padding-left: 12px;
}

.app-tools li a {
    font-size: 15px;
}

.app-tools li i,
.topbar-profile .ladiui-navbar.-horizontal>li>ul>li>a>i {
    font-size: 14px;
    margin-right: 6px;
    vertical-align: middle;
}

.topbar-profile .ladiui-navbar.-horizontal>li>a {
    height: 50px;
    line-height: 53px;
    color: #fff;
    font-size: 15px;
}

.topbar-profile .ladiui-navbar li:hover>a:first-child,
.topbar-profile .ladiui-navbar li.-active>a:first-child {
    background: transparent;
}

.ladiui-store-list li a i {
    font-size: 30px;
}

.topbar-tools .ladiui.list {
    line-height: 50px;
    height: 50px;
}

.topbar-tools .ladiui.list>li>a {
    text-decoration: none;
    color: #44566c;
    vertical-align: middle;
    display: block;
    padding: 0px 10px;
}

.topbar-right>div {
    display: -webkit-inline-box;
}

.app-packages span {
    display: inline-block;
    margin: 0px 20px;
    color: #fff;
}

.app-packages {
    padding-right: 24px;
    border-right: 1px solid #335de7;
    line-height: 49px;
}

.app-packages .upgrade {
    display: inline-block;
    margin-top: 7px;
}

.ladiui-content {
    padding: 10px 30px 30px 20px;
}

.ladiui-table-actions {
    /* margin-bottom: 24px; */
    margin-bottom: 12px;
    position: relative;
    z-index: 1;
    min-height: 40px;
    display: flex;
    z-index: 50;
    justify-content: space-between;
}

.ladiui-table-actions.reports {
    margin-bottom: 12px !important;
}

.ladiui.search-actions {
    float: left;
    line-height: 39px;
}

.ladiui.actions {
    float: right;
}

.ladiui.actions>button,
.ladiui.actions>div {
    margin: 0px 6px;
}

.ladiui.actions>button,
.ladiui.actions>div:last-child {
    margin-right: 0px;
}

.ladiui.search-keyword {
    width: 290px;
    position: relative;
}

.ladiui.search-keyword .ladiui.input {
    text-indent: 5px;
    padding-right: 35px;
}

.ladiui.search-keyword i {
    position: absolute;
    top: 5px;
    color: #8697a8;
    right: 0px;
    cursor: pointer;
    padding: 9px;
}

/* .ladiui.nav>.menu>li {
  list-style: none;
  position: relative;
} */
/* 
.ladiui.menu {
  margin: 0px;
  padding: 0px;
} */
/* 
.ladiui.nav>.menu>li>a {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  font-size: 15px;
  color: #44566c;
  font-style: normal;
  font-stretch: normal;
  line-height: 31px;
  letter-spacing: normal;
  border-radius: 0px 30px 30px 0px;
  transition: color 0.6s ease-out;
}

.ladiui.nav>.menu>li>a>i {
  margin-right: 7px;
  font-size: 17px;
  border-radius: 50%;
  padding: 6px;
}

.ladiui.nav>.menu>li>a:hover {
  color: #1853fa;
}

.ladiui.nav>.menu>li>a:hover,
.ladiui.nav>.menu>li>a:focus,
.ladiui.nav>.menu>li>a:active {
  background-color: #f8fafb;
} */

.ladiui.submenu {
    background-color: #f8fafb;
    display: none;
    padding: 0px;
}

/* 
.ladiui.nav>.menu>li.open>a {
  background: #e8f0fe;
  color: #1853fa;
}

.ladiui.nav>.menu>li.open>a>i {
  border-color: #1853fa;
}

.ladiui.nav>.menu>li.open>.ladiui.submenu {
  display: block;
  padding: 0px;
  transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
} */

.ladiui.submenu>li {
    list-style: none;
}

.ladiui.submenu>li>a {
    color: #44566c;
    padding: 10px 0px 10px 45px;
    display: block;
    position: relative;
}

.ladiui.submenu>li>a:hover {
    color: #1853fa;
}

/* 
.ladiui.nav>.menu>li>a>.ladiui.pill {
  background: #ff3d57;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  padding: 3px 5px;
  position: absolute;
  right: 18px;
} */

.ladiui.pagination-block {
    width: 100%;
    margin-top: 20px;
}

.ladiui-search-box {
    background: #fff;
    padding: 15px;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    z-index: 0;
    margin-bottom: 24px;
    display: flex;
}

.ladiui.showing {
    min-width: 300px;
    width: auto;
    margin-left: 10px;

    color: var(--text-subdued, #636363);
    font-size: 14px;
    line-height: 20px;
}

.ladiui.showing-total {
    width: 300px;
    color: #8697a8;
    font-size: 14px;
    float: right;
    line-height: 40px;
    text-align: right;
}

.ladiui.showing span {
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    color: var(--main-default);
}

/* 
.ladiui.pagination>ul>li {
  display: inline-block;
  margin: 2px;
}

.ladiui.pagination>ul>li>a {
  text-decoration: none;
  width: 30px;
  line-height: 27px;
  display: block;
  padding-top: 2px;
  text-align: center;
  color: #44566c;
  border-radius: 3px;
}

.ladiui.pagination>ul>li.disabled>a {
  cursor: not-allowed;
  color: #c7c6c6 !important;
  background-color: #fff !important;
}

.ladiui.pagination>ul>li:first-child>a {
  border-radius: 5px 0px 0px 5px;
}

.ladiui.pagination>ul>li:last-child>a {
  border-radius: 0px 5px 5px 0px;
} */

*/
/* .ladiui.pagination>ul>li.active>a,
.ladiui.pagination>ul>li>a.active,
.ladiui.pagination>ul>li>a:active,
.ladiui.pagination>ul>li>a:hover {
  color: #fff;
  background-color: #1853fa;
} */

/* .ladiui.pagination>ul>li>a>i {
  font-size: 12px;
} */

.ladiui.setting-bottom {
    position: absolute;
    bottom: 48px;
    width: 100%;
    background: #fafafa;
}

.ladiui.setting-bottom a {
    padding: 15px 23px;
    color: #44566c;
    display: block;
    font-size: 12px;
}

.ladiui.data-limit {
    float: left;
}

.ladiui.data-limit>span {
    display: block;
    float: left;
    margin-right: 10px;
}

.ladiui.per-page {
    width: 85px;
    float: right;
}

.ladiui.form-filter>div:first-child {
    margin-left: 0px;
}

.ladiui.form-filter>div {
    display: inline-block;
    margin: 0px 10px;
}

.ladiui.select.per-page {
    min-width: 75px;
}

.ladiui-modal-body .ladiui-tabs {
    border-bottom: 1px solid rgba(6, 21, 40, 0.05);
}

.ladiui-tabs li.active a {
    padding-bottom: 7px;
}

.group-prices .price-item {
    width: 100%;
}

.product-image-preview {
    width: 100%;
    height: 306px;
    background: #fafafa;
    border: 1px solid #ebedf2;
}

.slider-products .item {
    margin-top: 15px;
    width: 70px;
    height: 70px;
    background: #fafafa;
    text-align: center;
    line-height: 70px;
    float: left;
}

.slider-products .owl-nav {
    position: absolute;
    width: 100%;
    top: 20%;
}

.slider-products .owl-nav .owl-prev {
    position: absolute;
    left: 0px;
}

.slider-products .owl-nav .owl-next {
    position: absolute;
    right: 0px;
}

.slider-products .owl-nav .owl-prev span,
.slider-products .owl-nav .owl-next span {
    font-size: 30px;
}

.slider-products .owl-nav [class*='owl-']:hover {
    background: transparent;
}

.slider-products .owl-nav [class*='owl-']:hover span {
    color: #1853fa;
}

.tox .tox-statusbar {
    display: none !important;
}

.margin-top-15 {
    display: inline-block;
    margin-top: 15px;
}

.btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.fixed {
    position: fixed;
}

.ladiui-product-left {
    width: 400px;
    float: left;
}

.ladiui-product-right {
    width: calc(100% - 424px);
    float: right;
    margin-left: 24px;
}

.ladiui-product-course-left {
    width: calc(350px);
    float: left;
}

.ladiui-product-course-right {
    width: calc(100% - 350px);
    float: right;
    padding-left: 24px;
}

.ladiui-modal-footer>.ladiui.button {
    margin: 0px 5px;
}

.variant-list {
    overflow-y: scroll;
    border-top: 1px solid #dfe3e8;
    max-height: 263px;
}

.aspect-ratio {
    position: relative;
    float: left;
    border: 1px solid rgba(195, 207, 216, 0.3);
    text-align: center;
    background: #fafafa;
    width: 400px !important;
    height: 316px !important;
    float: inherit !important;
    margin: 0px auto;
}

.variant-list .aspect-ratio {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.aspect-ratio::before {
    z-index: 1;
    content: '\ea38';
    position: absolute;
    top: -3px;
    right: 0;
    bottom: 0;
    font-family: 'LadiIcons';
    left: 0;
    font-size: 26px;
    color: #dedede;
}

.ladiui-widget-body ul li a:hover,
.ladiui-widget-body ul li a.active {
    color: #1853fa;
}

.ladiui-col-3 {
    width: 30%;
}

.ladiui-col-7 {
    width: 65%;
}

#tab-variants .ladiui-form-group>.ladiui-col {
    width: 31%;
    float: left;
    margin-right: 19px;
    margin-bottom: 20px;
}

.variant-options {
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: none;
}

.variant-options .ladiui-widget-body {
    padding: 15px 0px;
    overflow: hidden;
    border-bottom: 1px solid #f2f3f4;
}

.ladiui.group-prices {
    clear: both;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 24px;
}

.form-tags .select2-selection__rendered {
    width: 497px !important;
}

.variant-option-list {
    margin-top: 10px;
}

/* .variant-option-item {
    height: 55px;
} */

.variant-option-item>div {
    display: inline-block;
    float: left;
}

.variant-option-item>div:last-child {
    float: right;
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
}

.ladiui-form-group.variant-option-head {
    margin-top: 24px;
    margin-bottom: 16px;
}

.variant-option-item .variant-option-name {
    width: 250px;
    position: relative;
}

.variant-option-item .variant-option-name .btn-save-option-name {
    position: absolute;
    right: 10px;
    color: #1c00c2;
    cursor: pointer;
    top: 10px;
}

.new-variant-option {
    color: inherit;
    display: block;
    margin-top: 15px;
}

.variant-option-item .variant-option-value .select2-search__field {
    width: 310px !important;
}

.variant-option-item .remove-variant-option {
    width: 20px;
}

.variant-option-item .remove-variant-option a {
    color: inherit;
}

.variant-option-head>span:first-child {
    width: 120px;
}

.variant-option-head>span:last-child {
    width: 350px;
    margin-left: 17px;
}

.variant-option-head>span {
    font-weight: 500;
    display: inline-block;
}

.order-stack-total {
    border-bottom: 0px !important;
}

.order-stack-total td {
    padding: 10px 4px;
}

.order-stack-total td strong {
    font-weight: 700;
    font-size: 18px;
}

.order-stack-total td:last-child {
    padding-right: 91px;
    width: 240px;
    text-align: right;
}

.order-customer {
    overflow: hidden;
    background: #fff;
    /* padding: 15px; */
    /* border-radius: 5px; */
    /* margin-bottom: 20px; */
    /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1); */
    overflow: inherit;
    /* min-width: 300px; */
    /* border-bottom: 1px solid #f1f3f4; */
    /* padding: 24px 0px; */
}

.order-customer:last-child {
    border-bottom: 0px !important;
}

.customer-info {
    position: relative;
    padding-bottom: 20px;
}

.customer-info>span>i {
    border: 1px solid #eaedf0;
    border-radius: 50%;
    padding: 6px;
    position: relative;
    top: 1px;
    font-size: 12px;
}

.customer-info strong {
    font-weight: 500;
}

.created-at {
    display: block;
    position: absolute;
    top: -38px;
    right: 0px;
    color: inherit;
}

.payment-box:first-child {
    margin-left: 0px;
}

.payment-box {
    width: 30%;
    float: left;
    margin: 0px 15px;
}

.payment-box ul {
    padding: 0px;
    margin-top: 10px;
}

.payment-box ul li {
    line-height: 30px;
    list-style: none;
}

.payment-box .ladiui.select {
    background: #f8fafb;
}

.order-heading h3 {
    font-size: 15px;
    font-weight: 500;
    /* margin-bottom: 10px; */
}

.payment-box .order-heading {
    margin-top: 15px;
}

.ladiui-order-left {
    width: 70%;
    float: left;
    /* position: relative; */
}

.order-head-right .placed-on {
    margin-right: 30px;
}

.order-head-right .placed-on span {}

.order-head-right .placed-on span:first-child {
    font-weight: 500;
}

.order-head-right .placed-on span:last-child {}

/* .ladiui-order-right {
  width: 28%;
  float: right;
} */

.ladi-card.order-products .ladiui.table {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

@media (max-width: 1000px) {
    .ladiui-topbar .topbar-right {
        width: 900px;
    }

    /* .ladiui.actions {
        width: 100vh;
    } */
}

/*****************24042019***************/

/* .ladiui-table-actions .action-item {
    display: block;
    float: left;
    margin-right: 20px;
} */

/*--------------------- LOGIN -------------------*/

.ladiui.auth .auth-left {
    float: left;
    width: 50%;
}

.ladiui.auth .auth-right {
    width: 50%;
    float: right;
}

.ladiui.auth .auth-left iframe {
    width: 100%;
    border: 0px;
    height: 100vh;
}

.ladiui.auth {
    background: url(https://builder.ladipage.vn/images/geometry2.png) repeat;
    color: #44566c;
}

.ladiui.auth h1.form-title {
    margin: 0px 0px 20px 0px;
    padding: 0px;
    font-size: 21px;
    text-align: center;
    font-weight: 500;
    color: #44566c;
}

.ladiui.auth .ladiui-login {
    padding: 30px;
    float: none;
    width: 424px;
    background: #fff;
    margin: 30px auto;
    border-radius: 5px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    display: block;
}

.ladiui.auth .ladiui-logo {
    width: 290px;
    padding: 40px;
    margin: 0px auto;
    border: 0px;
    float: none;
}

.ladiui.auth .ladiui-logo img {
    width: 100%;
}

.ladiui.auth .ladiui-login-form .ladiui-form-group {
    margin-bottom: 15px;
    position: relative;
}

.ladiui.auth .ladiui-login-form label {
    margin: 8px 0px;
    display: block;
}

.ladiui.auth .ladiui-login-form .button {
    width: 100%;
    padding: 15px;
    font-size: 15px;
}

.ladiui.auth .social-login {
    text-align: center;
}

.ladiui.auth .social-login a.login-facebook,
.ladiui.auth .social-login a.login-google {
    display: inline-block;
    width: 150px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 0px;
    padding: 14px 10px;
    margin: 2px;
    line-height: 11px;
}

.ladiui.auth .social-login a.login-facebook {
    background: #4267b2;
    color: #fff;
}

.ladiui.auth .social-login>a.login-google:last-child {
    background: #d93025;
    color: #fff;
}

.ladiui.auth .forgot-password {
    float: right;
    text-align: right;
}

.ladiui.auth .forgot-password a {
    color: #6c7293;
    font-size: 14px;
}

.ladiui.auth .password-box {
    margin-bottom: 20px;
    overflow: hidden;
}

.ladiui.auth span.login-with {
    display: block;
    width: 100%;
    position: relative;
    border-top: 1px solid #ecedee;
    margin-top: 25px;
}

.ladiui.auth span.login-with span {
    margin: 0px auto;
    top: -12px;
    background: #fff;
    padding: 0px 5px;
    position: relative;
}

.ladiui.auth .question-form {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.ladiui.auth .question-form a {
    color: inherit;
    font-weight: 600;
}

.ladiui.auth .ladipage-terms {
    text-align: center;
}

.ladiui.auth .ladipage-terms ul {
    margin: 0px;
    padding: 0px;
}

.ladiui.auth .ladipage-terms ul li {
    display: inline-block;
}

.ladiui.auth .ladipage-terms ul li a {
    color: inherit;
    margin: 0px 20px;
    font-size: 14px;
}

.ladiui.auth .ladipage-terms ul li a:hover {
    text-decoration: underline;
}

.ladiui.table .ladiui-navbar.-horizontal li>ul {
    right: 0px;
}

.ladiui.table thead tr th {
    /* position: relative; */
}

.ladiui.table thead tr th.sorting {
    cursor: pointer;
}

.ladiui.table thead tr th.sorting.up::after {
    content: '\ea47';
    font-family: 'LadiIcons';
    font-size: 10px;
    /* top: 20px; */
    /* top: 0px; */
    position: absolute;
    z-index: 0;
    color: #8697a8;
    padding-left: 6px;
}

.ladiui.table thead tr th.sorting::after,
.ladiui.table thead tr th.sorting.down::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 8px;
    /* top: 21px; */
    top: unset;
    position: absolute;
    z-index: 0;
    color: #8697a8;
    padding-left: 6px;
}

.ladiui-topbar.fixed {
    position: fixed;
    width: 100%;
    left: 0px;
    z-index: 10;
}

.ladiui.fixed-sidebar .ladiui-aside {
    z-index: 11;
}

.ladiui-topbar .topbar-left {
    position: relative;
    left: 0px;
}

.ladiui-content {
    padding-top: 48px;
    display: grid;
    position: relative;
}

.ladiui-order-right .order-customer {
    position: relative;
}

.order-head-right {
    position: absolute;
    top: 70px;
    right: 33px;
}

.order-head-right a {
    color: inherit;
}

.order-customer-edit {
    display: block;
    /* position: absolute;
  top: 10px;
  right: 15px; */
    color: var(--main-primary);
}

.row-actions>li>a {
    line-height: inherit;
}

.pagination-block .per-page ul {
    bottom: 50px;
    top: inherit;
}

.ladiui-search-custom::after {
    content: '\ea41';
    font-family: 'LadiIcons';
    font-size: 18px;
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.ladipage-account {
    font-size: 14px;
}

.ladipage-account .ladipage-account-avatar {
    margin-top: 20px;
    width: 60px;
    float: left;
    margin-right: 35px;
}

.ladipage-account .ladipage-account-avatar img {
    width: 74px;
    height: 74px;
    border-radius: 50%;
}

.ladipage-account {
    padding: 0px 20px !important;
}

.ladipage-account-info>span {
    display: block;
    line-height: 24px;
}

.ladipage-account-name {
    font-weight: 500;
}

.ladipage-account-email {
    font-size: 12px;
}

.ladipage-account-info {
    padding: 20px 0px;
}

.ladipage-account-signout {
    text-align: right;
}

.icon-form-account {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.highcharts-point {
    rx: unset !important;
    ry: unset !important;
}

.text-tooltip {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.point-tolltip {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    /* background: #ff5849; */
}

.highcharts-credits {
    display: none !important;
}

.product-note {
    white-space: pre-line;
}

.ladiui.modal .search-field {
    height: 32px;
}

/*-----------------------------------------Ladipage Home---------------------------------------*/
.ladiui.content {
    height: 90vh;
    position: relative;
    top: 64px;
    padding: 0px 40px 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.content-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.content-sub {
    max-width: 530px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.sub-weight {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.content-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
}

.ladiui.content-navigation .dropdown-toggle::after {
    content: none;
}

.ladiui.end {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px 24px 0px 24px;
}

.ladiui.tutorial a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.tutorial {
    border-bottom: 1px solid var(--main-primary);
}

/*---------------------------------------------------------------------------------------Landingpage list-------------------------------------------------------------------------------*/
.ladiui.content-main {
    position: absolute;
    top: 64px;
    border-left: 1px solid var(--main-line);
    width: calc(100% - 236px);
    min-width: 1012px;
    height: 91.5vh;
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 24px 40px 24px;
}

.ladiui.content-main1 {
    position: absolute;
    top: 64px;
    width: 100%;
    min-width: 1012px;
    /* height: 82vh; */
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 20px 40px 24px;
}

.ladiui.content-main-full {
    /* padding: 0px 0px 5px 0px; */
    margin: auto;
    width: 100%;
}

.ladiui.main-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

.ladiui.content-list-landingpage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
}

.ladiui.content-main-title {
    justify-content: space-between;
}

.ladiui.content-tag {
    max-width: 236px;
    width: calc(100% - 236px);
    height: 100%;
    padding: 24px 24px 0px 23px;
    position: fixed;
    top: 64px;
    left: 74px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
}

.ladiui.content-tag-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.content-tag-setting {
    position: absolute;
    right: 24px;
}

.ladiui.btn-primary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px 0px;
}

.ladiui.btn-custom-img {
    padding: 9px 8px 9px 0px;
    pointer-events: none;
}

.ladiui.btn-custom-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
    pointer-events: none;
    white-space: nowrap;
}

.ladiui.table {
    /* margin-top: 12px; */
    /* min-width: 1012px; */
    width: 100%;
}

/* .ladiui.btn-group.custom-btn-list-landingpage {
    margin-left: 24px;
} */

.ladiui.content-main-tool {
    margin-top: 28px;
}

.ladiui.custom-btn-list-landingpage {
    min-width: 154px;
}

.ladiui.custom-btn-list-landingpage button {
    width: 100%;
}

.ladiui-btn-tag.landingpage-list {
    margin: 4px 4px 4px 0px;
}

.ladiui-btn-tag.landingpage-list:hover {
    background-color: var(--main-primary);
    color: var(--main-white);
    cursor: pointer;
}

.ladiui-btn-tag.landingpage-list:active {
    background-color: var(--main-primary);
    color: var(--main-white);
}

.ladiui.content-main-tool {
    height: 36px;
}

/*--------------------------------------------------------------------------------Ladipage banner-ads-list------------------------------------------------------------------------------*/
.ladiui.dropdown-toggle.custom-pre-page {
    min-width: 54px !important;
    height: 32px;
    padding: 6px 8px;
}

.ladiui.dropdown-toggle.custom-pre-page::after {
    margin: 10px;
}

.ladiui.dropdown-text.custom-pre-page {
    margin-right: 0px !important;
    padding: 0px !important;
}

/* .ladiui.dropdown-toggle.custom-pre-page:after {
  margin: 12px 12px 12px 0px;
} */

.ladiui.dropdown-menu.custom-pre-page {
    min-width: 54px;
    bottom: 0;
}

.ladiui.btn-group.custom-pre-page {
    padding: 0;
}

.ladiui.from-filter {
    min-height: 32px;
}

.ladiui.search-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.ladiui.col-one.custom-th-banner {
    min-width: 344px;
}

.ladiui.setting-content {
    width: 236px;
    padding: 24px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.ladiui.content-tag-text {
    color: var(--main-default);
}

.ladiui.ladiui.content-tag-text.active {
    color: var(--main-primary);
}

.ladiui.setting-name {
    margin-bottom: 18px;
}

.ladiui.setting-item {
    margin: 32px 0;
}

.ladiui.switch-content.form-vertical {
    margin-bottom: 10px;
}

.ladiui.form-vertical {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.ladiui.form-vertical:last-child {
    margin-bottom: 0;
}

.ladiui.input-name {
    width: 100px;
    margin-right: 24px;
}

.ladiui.setting-input-name {
    width: 150px;
    margin-right: 24px;
}

.ladiui.col-tow.custom-th-banner {
    min-width: 344px;
}

.ladiui.col-three.custom-th-banner {
    min-width: 269px;
}

/* -------------------------------------------------Setting---------------------------------------- */
.ladiui.setting.content-main table {
    margin-top: 18px;
}

.ladiui.setting-content a:hover {
    color: var(pr);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.content-parent {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content-left {
    position: fixed;
    top: 65px;
}

.ladiui.shared.setting-content-right {
    width: 636px;
    padding-bottom: 18px;
}

.ladiui.setting-content-right:last-child {
    border-bottom: none;
}

.ladiui.setting-content-right .btn-group {
    margin: 0 20px 0 0;
}

.ladiui.setting-content-right .btn-group:first-child {
    margin-left: 0px;
}

.ladiui.content-parent .table {
    margin-top: 0;
}

.ladiui.content-parent:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.ladiui.switch-btn.form-vertical {
    justify-content: end;
    margin-top: 34px;
}

.ladiui.switch-btn.form-vertical button {
    margin-right: 24px;
}

.ladiui.switch-btn.form-vertical button:last-child {
    margin-right: 0;
}

.ladiui.col-six.custom-th-banner {
    min-width: 18px;
}

.ladiui.icon.icon-ldp-list {
    margin-right: 16px;
}

/*--------------------------------------------------------------------------------banner grid----------------------------------------------------------------------------------------*/

.ladiui.card-custom {
    width: 310px;
    height: 250px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    border-radius: 2px;
}

.ladiui.card-img-top-custom {
    width: 310px;
    height: 250px;
    position: absolute;
}

.ladiui.card-body-custom {
    background-color: var(--main-white);
    position: relative;
    height: 60px;
    top: 190px;
}

.ladiui.card-title-custom {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin: 8px 0px 4px 17.47px;
}

.ladiui.text-time {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
    margin-left: 8.73px;
}

.ladiui.card-custom-date {
    margin: 0px 0px 0px 17.47px;
}

.ladiui.banner-ads-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 24px;
    column-gap: 24px;
    grid-row-gap: 24px;
    row-gap: 24px;
    margin-top: 24px;
}

.ladiui.content-main-tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start !important;
}

.ladiui.content-main-tool-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
}

.ladiui.table-show {
    display: none;
}

.ladiui.grid-show {
    display: none;
}

.ladiui.icon-list-view.tab-link,
.ladiui.icon-grid-view.tab-link {
    border-bottom: none;
}

/* ---------------------------------------------------------------------------------------intro---------------------------------------------------------------------------------- */
.ladiui.intro-content {
    padding-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ladiui.intro-head {
    margin-bottom: 19px;
}

.ladiui.intro-header {
    text-align: center;
    margin-bottom: 65px;
}

.ladiui.intro-body {
    display: flex;
    flex-direction: row;
    margin-bottom: 48px;
}

.ladiui.intro-body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 243px;
    text-align: center;
    margin: 0 121px;
}

.ladiui.intro-body-content i {
    margin-bottom: 53px;
}

.ladiui.intro-body-content span {
    margin-bottom: 8px;
}

.ladiui.intro-footer {
    width: 528px;
    margin-bottom: 24px;
    text-align: center;
}

/*----------------------------------------------------------------------------------------custom table dynamic--------------------------------------------------------------------------------------------------------------*/
.ladiui.col-one.custom-dynamic {
    min-width: 324px;
}

.ladiui.col-tow.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-three.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-threes.custom-dynamic {
    min-width: 193px;
}

.ladiui.col-for.custom-dynamic {
    min-width: 132px;
}

.ladiui.col-fors.custom-dynamic {
    min-width: 112px;
}

.ladiui.col-five.custom-dynamic {
    min-width: 112px;
}

/*Custom form dynamic*/
.ladiui.modal-content.custom-dynamic {
    min-width: 824px;
    padding: 24px;
}

.ladiui.modal-header.dynamic {
    padding: 0px 0px 4px 0px;
}

.ladiui.item.dynamic {
    margin-top: 32px;
}

.ladiui.btn-group-dynamic {
    padding: 0px;
}

.ladiui.btn-group.dynamic {
    padding: 0px;
}

.ladiui.item-form.dynamic {
    align-items: center;
}

.ladiui.switch.sw-lg.dynamic {
    margin: 0px;
}

.ladiui.item-form {
    margin: 0px;
}

.ladiui.item-form {
    padding: 0px;
}

.ladiui.search-group .dropdown-menu {
    min-width: 270px;
}

/*----------------------------------------------------------------------------------------Custom modal home----------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-ldp-width {
    width: 600px;
}

.ladiui.custom-ldp {
    padding: 24px 24px 24px 24px;
}

.ladiui.modal-header.custom-ldp {
    padding: 0px 0px 12px 0px;
}

.ladiui.form-group-custom {
    margin-top: 28px;
}

.ladiui.modal-footer.custom-ldp {
    padding: 48px 0px 0px 0px;
}

.ladiui.form-check-inputs-custom {
    margin-top: 32px;
}

.ladiui.radio.custom-ldp-radio {
    margin-top: 8px;
}

.ladiui.dropdown-toggle.custom-main-tool {
    margin-left: 24px;
}

.ladiui.search-width {
    min-width: 100px !important;
}

/*----------------------------------------------------------------------------------------custom modal popup--------------------------------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-popup-width {
    width: 718px;
}

.ladiui.label-embel-code {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-text-color);
    margin-top: 20px;
}

.ladiui.input-embel-code {
    margin-top: 16px;
}

/*----------------------------------------------------------------------------------------custom dropdown people--------------------------------------------------------------------------------------------------------------*/

.ladiui.dropdown-toggle.custom-people {
    min-width: 168px;
}

.ladiui.dropdown-menu.custom-people {
    position: absolute;
    left: 24px;
}

.ladiui.dropdown-menu.custom-people {
    padding: 0px;
}

/* --------------------------------------------------------------------------------------------Domain---------------------------------------------------------------------------------------------- */
.ladiui.domain .modal-dialog {
    width: 600px;
}

.ladiui.modal .form-group:first-child {
    margin-top: 0;
}

/* .ladiui.modal .btn-group {
  padding: 0;
  width: 552px;
} */

.ladiui.modal .btn-group .dropdown-toggle {
    min-width: 160px;
    margin-right: 8px;
}

/* .ladiui.modal .btn-group .dropdown-menu {
  width: 552px;
} */

.ladiui.domain .span-text {
    margin-right: 40px;
}

.ladiui.domain .table-vertical td {
    padding: 0;
}

.ladiui.modal .domain-action.btn-group {
    width: 120px;
    margin-right: 16px;
}

.ladiui.modal .domain-action.btn-group .dropdown-toggle {
    min-width: 120px;
    width: 120px;
}

.ladiui.modal .domain-action.btn-group .dropdown-menu {
    min-width: 120px;
    width: 120px;
}

/* -------------------------------------------------------------------------------------------------------------Member------------------------------------------------------------------------------------------------------ */
.ladiui.member .search-field {
    width: 295px;
}

.ladiui.member .custom-btn-list-landingpage {
    width: 188px;
}

.ladiui.member .table {
    margin-top: 44px;
}

.ladiui.member .ladiui.content-main-tool {
    margin-top: 18px;
}

.ladiui.member .col-one {
    min-width: 150px;
}

.ladiui.member .col-three {
    min-width: 150px;
}

.ladiui.member table .btn-group {
    padding: 11.5px 0;
}

.ladiui .modal .btn-group.decent {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-toggle {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-menu {
    min-width: 222px;
    width: 222px;
}

.ladiui.auto-height.form-control {
    word-break: normal;
    word-wrap: break-word;
}

.ladiui.share-recource {
    display: grid;
    grid-template-columns: auto auto auto;
}

.ladiui.share-recource .span-text {
    margin: 0;
    padding: 8px 0 16px;
}

.ladiui.list-recource {
    display: none;
}

.ladiui.form-group .select-search .btn {
    max-width: none;
    text-overflow: unset;
}

.ladiui.form-group .select-search .dropdown-text {
    overflow: unset;
}

.ladiui.status.form-group .span-text {
    margin: 0 62px;
}

.ladiui.status.form-group .span-text:first-child {
    margin-left: 0;
}

.ladiui.status.form-group .span-text:last-child {
    margin-right: 0;
}

.ladiui.member .list-team .col-one {
    min-width: 220px;
}

.ladiui.member .list-team .col-five {
    min-width: 28px;
}

/*-----------------------------------------------------------------------------------------------------custom modal dropdown----------------------------------------------------------------------------------------*/
.ladiui.custom-ldp-width1 {
    min-width: 450px;
}

.ladiui.text.on-access-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.ladiui.modal-footer.custom-ldp1 {
    padding: 24px 0px 0px 0px;
    justify-content: left;
}

.ladiui.form-check-inputs-custom1 {
    margin-top: 20px;
}

.ladiui.radio.custom-ldp-radio1 {
    margin-top: 16px;
    margin-bottom: 8px;
}

.ladiui.member .member-img-left {
    margin-right: 16px;
}

.ladiui.member .member-img-left img {
    height: 32px;
    width: 32px;
}

.ladiui.member .member-control {
    margin: 8px 0;
}

.ladiui.member .member-control:first-child {
    margin-top: 0;
}

.ladiui.member .member-control:last-child {
    margin-bottom: 0;
}

.ladiui.modal-header.custom-data-backup {
    padding: 0px 0px 4px 0px;
}

.ladiui.modal-footer.custom-ldp-decentralization {
    padding: 54px 0px 0px 0px;
}

.ladiui.help-footer {
    margin-right: 8px;
}

.ladiui.modal-footer-link a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.list-user {
    margin-top: 32px;
}

.ladiui.container-list-user {
    margin: 8px 0px 16px 0px;
}

.ladiui.text-sub-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-title-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.container-list-user-left {
    display: flex;
    flex-direction: column;
}

.ladiui.container-list-user,
.ladiui.container-list-user-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/*----------------------------------------------------------------------------------------custom button popup----------------------------------------------------------------------------------------*/
.ladiui.btn-outline-primary.custom-popup {
    font-weight: 500;
    margin-right: 24px;
}

.ladiui.modal-header.custom-popupX {
    padding: 0px 0px 4px 0px;
}

.ladiui.text-sub-notification {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-sub-bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.modal-footer.custom-ldp-popupX {
    padding: 48px 0px 0px 0px;
}

/* --------------------------------------------------------------------------------------Access------------------------------------------------------------------------------ */
.ladiui.access.content-main-tool .search-group {
    width: 270px;
}

.ladiui.modal.key-access .modal-dialog {
    width: 963px;
}

.ladiui.modal.key-access .search-group {
    width: 270px;
}

.ladiui.modal.key-access .col-first {
    width: 40px;
}

.ladiui.modal.key-access .col-one {
    min-width: 80px;
    width: 80px;
}

.ladiui.modal.key-access .col-tow {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-three {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-four {
    min-width: 116px;
    width: 116px;
    text-align: center;
}

.ladiui.modal.key-access .col-five {
    min-width: 136px;
    width: 130px;
    text-align: center;
}

.ladiui.modal.key-access .col-six {
    min-width: 16px;
    width: 85px;
    text-align: center;
}

.ladiui.modal.key-access .col-seven {
    min-width: 107px;
    width: 107px;
}

.ladiui.modal.key-access .btn-group {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access .btn-group .dropdown-toggle {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access table .btn-group .dropdown-menu {
    min-width: 175px;
    width: 175px;
}

.ladiui.modal.key-access .btn-group .dropdown-menu {
    min-width: 65px;
    width: 65px;
}

.ladiui.create-key .dropdown-toggle {
    min-width: 100px;
}

.ladiui.create-key .dropdown-toggle::after {
    content: none;
}

.ladiui.create-key .dropdown-menu {
    min-width: 145px;
    width: 145px;
}

.ladiui.confirm-2 .modal-footer {
    margin-top: 20px;
}

.ladiui.funnel-text {
    margin-left: 50px;
}

.ladiui.funnel-text label {
    margin-left: 246px;
}

.ladiui.funnel-text label:first-child {
    margin-left: 0;
}

.ladiui.funnel-wrapper {
    display: flex;
}

.ladiui.group-input {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary-color);
    width: 712px;
    border-radius: 2px;
}

.ladiui.group-input .form-control {
    border: 1px solid var(--main-white);
    padding-left: 8px;
}

.ladiui.space-between {
    width: 2px;
    height: 20px;
    background-color: var(--secondary-color);
}

.ladiui.wrapper-group .ladiui-btn-tag {
    width: 34px;
    height: 34px;
    padding: 6px;
    text-align: center;
    margin-right: 8px;
}

.ladiui.sortable-line {
    margin: 8px 0;
}

body {
    counter-reset: section;
}

.ladiui.sortable-line .ladiui-btn-tag::before {
    counter-increment: section;
    content: counter(section);
}

.ladiui.sortable-line:first-child {
    margin-top: 0;
}

.ladiui.sortable-line:last-child {
    margin-bottom: 0;
}

.ladiui.first.form-control {
    width: 552px;
}

.ladiui.group-input .icon-ldp-drag {
    cursor: grab;
    pointer-events: all;
    margin-right: 10px;
}

.ladiui.sortable-line .icon-ldp-delete {
    cursor: pointer;
    pointer-events: all;
    margin-left: 10px;
}

.ladiui.funnel .proviso {
    padding: 16px 0 0 0;
}

.ladiui.funnel td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ladiui.funnel .col-first {
    width: 16px;
}

.ladiui.funnel .col-one {
    width: 470px;
}

/*----------------------------------------------------------------------------------------dynamic-report----------------------------------------------------------------------------------------*/
.ladiui.dynamic-report-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.ladiui.dynamic-report-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.dynamic-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary);
}

.ladiui.dynamic-span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-left: 8px;
}

.ladiui.dropdown-select-dynamic {
    margin-top: 10px;
}

.ladiui.btn-group.pull-right {
    margin-left: 24px;
}

.ladiui.dynamic-report-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 50px;
}

.ladiui.report-total-item {
    border: 1px solid var(--main-primary);
    border-radius: 10px;
    min-width: 188px;
    height: 106px;
}

.ladiui.report-total-item-lable {
    padding: 24px 0px 0px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.report-total-item-number {
    padding: 4px 0px 0px 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report-bg {
    border: 1px solid var(--main-line);
    margin-top: 50px;
    border-radius: 5px;
}

.ladiui.table.boder {
    margin-top: 0px;
}

.ladiui.table-vertical.header.item th {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.item td {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.end td {
    padding: 16px 16px 25px 16px;
}

.ladiui.search-action.custom {
    margin: 0px 16px 16px 16px;
}

.ladiui.report-convert {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 24px;
    margin-top: 24px;
}

.ladiui.report-convert-left {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-right {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin: 16px 0px 16px 0px;
}

.ladiui.report-convert-right {
    text-align: center;
}

.ladiui.report-convert-right img {
    margin-top: 32px;
}

/* ----------------------------------------------------------------------------------conversion-tool--------------------------------------------------------------------------------------- */
.ladiui.conversion-tab {
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.conversion-content-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin-bottom: 16px;
}

/*custom modal dynamic*/
.ladiui.modal .btn-group.custom-form-dynamic {
    width: 180px;
}

.ladiui.modal .btn-group .dropdown-toggle.custom-form-dynamic,
.ladiui.modal .btn-group .dropdown-menu.custom-form-dynamic {
    width: 180px;
}

.ladiui.item-form.sub1 {
    margin-left: 24px;
}

.ladiui.switch.custom-form-dynamic {
    margin: 0px;
}

.ladiui.item-form.custom-form-dynamic {
    margin-left: 42px;
}

.ladiui.title-text-form-lever2.custom-form-dynamic {
    margin-bottom: 16px;
}

.ladiui.item.flex-row.custom-form-dynamic {
    align-items: flex-start;
}

.ladiui.dropdown-menu.custom-dropdown-dynamic {
    width: 200px !important;
}

.ladiui.btn-group.col-2,
.ladiui.dropdown-toggle.col-2,
.ladiui.dropdown-menu.col-2 {
    width: 320px !important;
    margin-right: 16px;
}

.ladiui.itemss {
    justify-content: flex-start;
}

.ladiui.items-1 {
    width: 310px;
}

.ladiui.items-2 {
    position: relative;
    width: 310px;
    margin-left: 16px;
}

.ladiui.items-3 {
    width: 90px;
    margin-left: 16px;
}

.ladiui.items-4 {
    position: relative;
    width: 704px;
}

.ladiui.flex-row.custom-create-dynamic {
    margin-top: 4px;
}

.ladiui.modal-footer.custom-ldp-popupX.mod {
    padding-top: 36px;
}

.ladiui.infomation-link {
    margin-bottom: 19px;
    margin-left: 4px;
}

.ladiui.infomation-links {
    margin: 0px 0px 12px 4px;
}

.ladiui.dropdown-toggle.custom-main-tool.dynamic,
.ladiui.dropdown-menu.custom-dynamic {
    width: 320px !important;
    margin-left: 16px;
}

.ladiui.btn-group.custom-dynamic {
    width: 336px !important;
}

.ladiui.bg-form-custom.custom-dynamic {
    margin-top: 28px;
}

.ladiui.subtitle-2.custom-dynamic {
    margin-top: 8px;
    font-weight: 400;
    margin-left: 326px;
}

.ladiui.icon-add-input {
    position: absolute;
    top: 8px;
    right: 8px;
}

.ladiui.icon-add-input1 {
    position: absolute;
    top: 5px;
    right: 8px;
}

.ladiui.flex-row.custom-dynamic-s {
    text-align: center;
}

.ladiui.infomation-linkss {
    margin-left: 8px;
}

.ladiui.icon-search.custom {
    display: block;
    position: relative;
    left: 0px;
    margin: 18px 8px 2px 0px;
}

.ladiui.search-tag-fixed {
    align-items: baseline;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-mute);
}

.ladiui.inpud-search-tag-fixed {
    position: relative;
    bottom: 3px;
    width: 165px;
}

.ladiui.delete-right {
    margin-left: 16px;
}

.ladiui.dropdown-group-form {
    max-width: 744px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ladiui.dropdown-toggle.select-dropdown.col-3 {
    width: 280px !important;
}

.ladiui.btn-group.ctm {
    width: 196px !important;
}

.ladiui.btn-group.ctms {
    width: 280px !important;
    margin-left: 16px;
}

.ladiui.dropdown-menu.ctms {
    width: 280px !important;
}

.ladiui.btn-group.ctm.col2 {
    margin-left: 16px;
}

.ladiui.conversion.content-main {
    display: none;
}

.ladiui.conversion.main .tab-link {
    border-bottom: none;
    margin-left: 12px;
}

/* ---------------------------------------------------------------------------------------Tracking global --------------------------------------------------------------------------------------- */
.ladiui.nav-tracking {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link.active {
    border-bottom: 2px solid var(--main-primary);
}

.ladiui.tracking-area .ladiui.tab-content .tab-pane {
    margin-top: 0;
}

.ladiui.tracking-list .col-four {
    min-width: 200px;
    width: 200px;
}

.ladiui.tracking-list .col-first {
    min-width: 40px;
    width: 40px;
}

.ladiui.account-tracking img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

/* ------------------------------------------------------------------------------------------conversion-link-------------------------------------------------------------------------------------------- */
.ladiui.grid-link {
    width: 795px;
    height: 384px;
    display: grid;
    grid-template-columns: 263px 263px 263px;
    grid-template-rows: 136px 136px 136px 136px 136px 136px 136px 136px 136px 136px;
    overflow-y: auto;
}

.ladiui.link-box {
    cursor: pointer;
    width: 250px;
    height: 120px;
    border: 1px solid var(--main-line);
    border-radius: 2px;
}

.ladiui.link-box:hover {
    border: 1px solid var(--main-primary);
}

.ladiui.link-header {
    pointer-events: none;
    margin-bottom: 24px;
    padding: 16px 16px 0 16px;
}

.ladiui.link-header img {
    margin-right: 16px;
}

.ladiui.link-body {
    pointer-events: none;
    padding: 0px 16px 16px 16px;
    color: var(--main-secondary);
}

.ladiui.modal-header img {
    margin-right: 16px;
}

.ladiui.header-gmail {
    width: 36px;
    height: 24px;
}

.ladiui.header-ladisale {
    width: 32px;
    height: 32px;
}

.ladiui.flex.input .ladiui.form-group {
    width: 100%;
    margin: 24px 12px 0;
}

.ladiui.flex.input .ladiui.form-group:first-child {
    margin-left: 0;
}

.ladiui.flex.input .ladiui.form-group:last-child {
    margin-right: 0;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-toggle {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-menu {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .smtp.flex .btn-group {
    min-width: 100px;
    width: 100px;
}

.ladiui.smtp-host {
    width: 264px;
}

.ladiui.smtp-port {
    width: 140px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-toggle {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-menu {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .esms.flex .btn-group {
    min-width: 264px;
    width: 264px;
}

.ladiui.form-group .btn.convert {
    margin-top: 27px;
}

.ladiui.ggform-url .form-group .form-control {
    width: 450px;
}

.ladiui.form-field {
    margin-top: 16px;
    width: 100%;
}

.ladiui.form-field .icon {
    margin: 0 16px;
}

.ladiui.form-field .icon:last-child {
    margin-right: 0;
}

.ladiui.form-field .form-control {
    width: 252px;
}

.ladiui.form-field .btn-group {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-toggle {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-menu {
    min-width: 220px;
    width: 220px;
}

.ladiui.form-field .size-checkbox {
    margin: 0 12px 0 16px;
}

.ladiui.type-account {
    align-items: center;
    text-align: center;
}

.ladiui.type-account img {
    margin: 0 8px 0 16px;
    height: 24px;
    width: 24px;
}

.ladiui.type-account .custom-img {
    height: 16px;
    width: auto;
}

/* ----------------------------------------------------------------------------------Conversion-configuration--------------------------------------------------------------------------------------------- */
.ladiui.config-box .about {
    text-align: center;
    width: 190px;
    margin-top: 8px;
}

.ladiui.config .search-group {
    min-width: 270px;
}

.ladiui.config .step {
    margin: 16px 0 32px;
}

.ladiui.config .step:last-child {
    margin: 40px 0 0;
}

.ladiui.grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 350px auto auto;
    padding: 16px 0;
}

.ladiui.linking-form-group {
    padding-right: 20px;
    margin-right: -20px;
    max-height: 250px;
    overflow-y: auto;
}

.ladiui.setting-config .grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 300px auto auto;
    padding: 16px 0;
}

/* .ladiui.linking-form-group::-webkit-scrollbar {
  width: 3px;
} */

.ladiui.linking-form-group .grid-linking-form {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.grid-linking-form .type-account {
    text-align: start;
}

.ladiui.grid-linking-form .type-account .account-tracking img {
    margin-left: 0;
    width: 16px;
    height: 16px;
}

.ladiui.grid-linking-form .select-button {
    text-align: end;
}

.ladiui.grid-linking-form .select-button .edit-icon {
    margin-right: 10px;
}

/*------------------------------------------------------------------------------------------template----------------------------------------------------------------------------------*/
.ladiui.content-main-tool.custom-template {
    margin-top: 0px;
}

.ladiui.content-main-tab {
    padding-bottom: 16px;
    margin-top: 24px;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.content-main-tab-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ladiui.content-main-tab-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.ladiui.content-main-item-hover {
    display: none;
    position: absolute;
    top: 20px;
    left: -72px;
    background-color: var(--main-white);
    z-index: 999;
}

.ladiui.content-main-grid {
    min-width: 824px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ladiui.content-main-item.col4 {
    margin: 24px 24px 24px 24px;
}

.ladiui.content-main-item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 8px;
}

.ladiui.content-main-item-subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    margin-bottom: 8px;
    width: 250px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-main-item-hover {
    display: block;
}

.ladiui.content-main-tab-item.tow:hover .ladiui.content-main-item-hover.tow {
    display: block;
}

.ladiui.content-main-tab-item.three:hover .ladiui.content-main-item-hover.three {
    display: block;
}

.ladiui.content-main-tab-item.for:hover .ladiui.content-main-item-hover.for {
    display: block;
}

.ladiui.content-main-item-hover.tow {
    width: 265px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-tab-text {
    color: var(--main-primary);
}

.ladiui.custom-width-tab {
    margin-left: 32px;
}

.ladiui.custom-width-tab-item {
    margin-left: 4px;
}

.ladiui.content-tab-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    pointer-events: none;
    color: var(--main-default);
}

.ladiui.content-main-tab-item.tab-link {
    padding-bottom: 0px;
}

.ladiui.content-main-tab-item.tab-link.active {
    border: 0px;
}

.ladiui.content-tab-icon {
    pointer-events: none;
}

.ladiui.tab-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
    margin: 24px 0px 4px 0px;
}

.ladiui.tab-item-text-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.custom-btn {
    padding: 6px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 2px;
    color: var(--main-black);
    background-color: var(--main-white);
    border: 1px solid var(--main-line);
    cursor: pointer;
}

.ladiui.custom-btn:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.custom-btn:active {
    color: var(--main-white);
    background-color: var(--main-primary);
}

.ladiui.custom-btn.active {
    color: var(--main-white);
    background-color: var(--main-primary);
    cursor: pointer;
}

.ladiui.group-item-main-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ladiui.custom-left-group-button {
    margin-left: 8px;
}

.ladiui.template-grid {
    min-width: calc(100% / 3 - 20px);
    min-height: 290px;
    border-radius: 2px;
    margin-top: 32px;
    position: relative;
    border: 1px solid var(--main-line);
}

.ladiui.opacity-img {
    width: 100%;
    height: 100%;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    opacity: 0.09;
}

@media (max-width: 1371px) {
    .ladiui.template-grid {
        width: calc(100% / 2 - 20px);
        min-height: 290px;
        border-radius: 2px;
        margin-top: 32px;
        position: relative;
    }

    .ladiui.opacity-img {
        min-width: calc(100% / 2 - 20px);
        min-height: 250px;
    }
}

.ladiui.template-grid-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-top: 16px;
}

.ladiui.template-grid-type {
    position: absolute;
    top: 8px;
    left: 8px;
}

.ladiui.template-grid-type-hover {
    position: absolute;
    top: 8px;
    left: 8px;
    display: none;
}

.ladiui.template-grid-button {
    width: 100px;
    height: 36px;
    background-color: var(--main-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
}

.ladiui.group-button-template-grid {
    position: absolute;
    top: 107px;
    left: calc((100% - 216px) / 2);
    display: none;
}

.ladiui.template-grid:hover .ladiui.group-button-template-grid {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type-hover {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type {
    display: none;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    background-color: var(--main-white);
}

.ladiui.template-grid-button {
    cursor: pointer;
}

.ladiui.template-grid-button:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.content-main-item-subtext:hover {
    color: var(--main-primary);
}

.ladiui.template-btn-show-more {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.show-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
}

.ladiui.template-btn-show-more {
    margin-bottom: 6px;
}

.ladiui.banner-ads-grid.showcase {
    margin-top: 0px;
}

.ladiui.modal-dialog.showcase {
    width: 824px;
    text-align: center;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.ladiui.modal-footer.showcase {
    justify-content: center;
}

.ladiui.show-case-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
}

.ladiui.show-case-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 31px 0px 48px 0px;
}

.col-50 {
    width: 48%;
    float: left;
    margin: 0px 15px;
}

.ladiui-row>.col-50:first-child {
    margin-left: 0px;
}

.ladiui-row>.col-50:last-child {
    margin-right: 0px;
    float: right;
}

.ladiui-search-dropdown {
    border-radius: 4px;
    position: relative;
    min-width: 200px;
    height: 40px;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ladiui-search-dropdown>input.ladiui-search-input {
    padding: 0px 15px;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    background: #fff;
    cursor: pointer;
    color: #313942;
    top: 0;
    left: 0px;
    width: 100%;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.ladiui-search-dropdown>.text {
    display: inline-block;
    transition: none;
    cursor: text;
    position: relative;
    left: 3px;
    z-index: 3;
    line-height: 40px;
    pointer-events: none;
    font-weight: 400;
    color: #313942;
    width: 100%;
    padding: 0px 15px;
}

.ladiui-search-dropdown>.text.open {
    color: #b3b3b3 !important;
    font-weight: 300;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu {
    border-color: #96c8da;
    /* box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15); */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    position: absolute;
    top: 42px;
    left: 0px;
    border-radius: 4px;
    display: none;
    background: #fff;
    height: -webkit-max-content;
    height: max-content;
    max-height: 300px;
    overflow-y: scroll;
    width: 100%;
    z-index: 10;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu>.item {
    position: relative;
    cursor: pointer;
    white-space: normal;
    word-wrap: normal;
    display: block;
    height: auto;
    padding: 10px 15px;
    text-align: left;
    border-top: none;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    box-shadow: none;
    background: #fff;
    line-height: 24px;
    border-bottom: 1px solid #f8fafb;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu>.item:hover {
    background: #f8fafb;
}

.ladiui-search-dropdown>span {
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: -3px;
    cursor: pointer;
}

.ladiui-search-dropdown>span {
    position: relative;
}

.ladiui-search-dropdown>span::after {
    display: block;
    z-index: 2;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 4px;
    cursor: pointer;
}

.ladiui-search-dropdown::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    display: block;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.hide-mt .ladiui.dropdown-toggle:after {
    /* display: none !important; */
    /* background: #ffffff !important; */
    background: unset !important;
}

.ba-c .ladiui.dropdown-toggle {
    min-width: unset !important;
    border: none !important;
}

.ladiui.icon-ldp-fb-group {
    width: 14px !important;
    height: 14px !important;
}

.page-checkout-type::first-letter {
    text-transform: uppercase;
}

.action-table-order-tag {
    left: 30px;
    top: 10px;
    position: absolute;
    z-index: 10;
}

.action-table-order-tag .ladiui.dropdown-toggle {
    min-width: 110px;
}

.action-table-order-tag .ladiui.dropdown-toggle::after {
    top: -4px !important;
}

.ladiui.caption {
    white-space: nowrap;
}

.tox-notifications-container {
    height: 0px !important;
    overflow: hidden !important;
}

.ladiui.col-action {
    width: 32px;
}

.tox-promotion {
    display: none;
}

input.ladiui.readonly {
    background: #eeeeee;
    color: black !important;
}

/* css page course v2 */
.ls-setting-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 64px;
    gap: 64px;
    width: 100%;
    /* min-width: 900px; */
}

.ls-setting-row-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ls-setting-row-right {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* gap: 24px; */
}

.ls-setting-row-title {
    color: var(--text-text-primary, var(--text-text-primary, #0e233c));
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.ls-setting-row-description {
    color: var(--main-secondary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ls-preview-store-logo {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    object-fit: cover;
    padding: 4px;
    border: 1px dotted #CFD3D8;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F3F4F5;
}

.ls-img-chooser {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.ls-img-chooser-overlay {
    display: none;
}

.ls-preview-store-logo:hover .ls-img-chooser-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
  
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ls-img-chooser-overlay i {
    background-color: #fff !important;
    width: 20px !important;
    height: 20px !important;
}

.ls-image-setting-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.ls-text-11 {
    font-size: 11px !important;
}

.ls-text-12 {
    font-size: 12px !important;
}

.ls-text-14 {
    font-size: 14px !important;
}

.ls-text-16 {
    font-size: 16px !important;
}

.ls-text-text-primary {
    color: #0E233C !important;
}

.ls-text-text-placeholder {
    color: #9FA7B1 !important;
}

.ls-size-12 {
    width: 12px !important;
    height: 12px !important;
    -webkit-mask-size: 12px !important;
            mask-size: 12px !important;
}

.ls-size-16 {
    width: 16px !important;
    height: 16px !important;
    -webkit-mask-size: 16px !important;
            mask-size: 16px !important;
}

.ls-size-24 {
    width: 24px !important;
    height: 24px !important;
    -webkit-mask-size: 24px !important;
            mask-size: 24px !important;
}

.ls-size-40 {
    width: 40px !important;
    height: 40px !important;
    -webkit-mask-size: 40px !important;
            mask-size: 40px !important;
}

.ls-font-normal {
    font-weight: 400;
}

.ls-font-medium {
    font-weight: 500;
}

.ls-uppercase {
    text-transform: uppercase !important;
}

.ls-flex-wrap {
    flex-wrap: wrap !important;
}

.ls-flex {
    display: flex;
}

.ls-flex-col {
    flex-direction: column;
}

.ls-justify-start {
    justify-content: flex-start;
}

.ls-justify-center {
   justify-content: center;
}

.ls-justify-end {
    justify-content: flex-end;
}

.ls-justify-between {
    justify-content: space-between !important;
}

.ls-items-start {
    align-items: flex-start;
}

.ls-items-center {
    align-items: center;
}

.ls-items-end {
    align-items: flex-end;
}

.ls-gap-4 {
    grid-gap: 4px;
    gap: 4px;
}

.ls-gap-8 {
    grid-gap: 8px;
    gap: 8px;
}

.ls-gap-12 {
    grid-gap: 12px;
    gap: 12px;
}

.ls-gap-16 {
    grid-gap: 16px;
    gap: 16px;
}

.ls-gap-20 {
    grid-gap: 20px !important;
    gap: 20px !important;
}

.ls-gap-24 {
    grid-gap: 24px;
    gap: 24px;
}

.ls-rounded-4 {
    border-radius: 4px;
}

.ls-rounded-8 {
    border-radius: 8px;
}

.ls-divide-1 {
    border-bottom: 1px solid #E7E9EB;
    width: 100%;
}

.ls-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.ls-p-20 {
    padding: 20px !important;
}

.ls-p-8 {
    padding: 8px !important;
}


.ls-p-16 {
    padding: 16px !important;
}

.ls-p-0 {
    padding: 0px !important;
}

.ls-pt-4 {
    padding-top: 4px !important;
}

.ls-pt-0 {
    padding-top: 0 !important;
}

.ls-shadow-none {
    box-shadow: none !important;
}


.ls-input-read-only {
    border: 1px solid #CFD3D8;
    padding: 12px;
    width: 100%;
    border-radius: 4px;
    color: #9FA7B1;
    font-size: 14px;
}

.ls-btn-outline-primary {
    padding: 10px 16px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border-radius: 4px !important;
    border: 1px solid var(--button-primary);
    background: #ffffff;
    cursor: pointer;

    color: var(--text-brand);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.ls-btn-danger {
    padding: 10px 16px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border-radius: 4px !important;
    border: 1px solid #E01A1A;
    background: #E01A1A;
    cursor: pointer;

    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.ls-btn-danger {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    background: #ffffff;
    cursor: pointer;

    color: #E01A1A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.ls-btn-outline-primary i {
    background-color: var(--text-brand);
}

.ls-btn-outline-primary:hover {
    border: 1px solid var(--button-primary-hover);
    background: var(--layer-brand);
}

.ls-btn-outline-danger {
    padding: 10px 16px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border-radius: 4px !important;
    border: 1px solid var(--text-danger, #C01322);
    background: #ffffff;
    cursor: pointer;

    color: var(--text-danger, #C01322);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.ls-btn-outline-danger i {
    background-color: var(--text-danger, #C01322);
}

.ls-btn-outline-danger:hover {
    background:  #fdf2f3;
}

.ls-text-secondary {
    color: #566577;
}

.ls-text-white {
    color: #fff;
}

.ls-text-primary {
    color: #1C00C2;
}

.ls-text-danger {
    color: #E01A1A !important;
}

.ls-text-black {
    color: #000;
}

.ls-px-24 {
    padding-left: 24px;
    padding-right: 24px;
}

.ls-dropdown-actions i{
    color: #1C00C2;
}

.ls-dropdown-actions .dropdown-item{
    color: #1C00C2;
}

.ls-min-w-fit {
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}

.ls-min-w-16 {
    min-width: 16px !important;
}

.ls-min-w-160 {
    min-width: 160px !important;
}

.ls-max-w-160 {
    max-width: 160px !important;
}

.ls-min-size-12 {
    min-width: 12px;
    min-height: 12px;
}

.ls-w-full {
    width: 100% !important;
}

.ls-min-w-20 {
    min-width: 20px !important;
}

.ls-max-w-20 {
    max-width: 20px !important;
}

.ls-h-full {
    height: 100% !important;
}

.ls-max-h-min-content {
    max-height: -webkit-min-content !important;
    max-height: min-content !important;
}

/* margin css */
.ls-m-0 {
    margin-right: 0px !important;
}

.ls-mr-12 {
    margin-right: 12px;
}

.ls-my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.ls-my-24 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.ls-my-12 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.ls-mt-12 {
    margin-top: 12px !important;
}

.ls-mt-20 {
    margin-top: 20px !important;
}

.ls-mt-16 {
    margin-top: 16px !important;
}

.ls-mt-24 {
    margin-top: 24px !important;
}

.ls-mt-4 {
    margin-top: 4px !important;
}

.ls-mb-0 {
    margin-bottom: 0px !important;
}

.ls-mb-8 {
    margin-bottom: 8px !important;
}

.ls-mb-20 {
    margin-bottom: 20px !important;
}

.ls-mb-24 {
    margin-bottom: 24px !important;
}

/* background css */
.ls-bg-primary {
    background-color: #0E233C;
}

.ls-bg-white {
    background-color: #fff !important;
}

.ls-bg-layer-info {
    background-color: #F1F7FF !important;
}

.ls-bg-layer-success {
    background-color: #F3FCE5 !important;
}

.ls-bg-layer-warning-hover {
    background-color: #FEF2CB !important;
}

.ls-bg-background-02 {
    background-color: #F3F4F5 !important;
}

.ls-bg-icon-secondary {
    background-color: #86919D !important;
}

.ls-bg-brand {
    background-color: #F4F2FC !important;
}

.ls-bg-layer-info-hover {
    background-color: #D8E7FE !important;
}

.ls-bg-main-primary {
    background-color: var(--main-primary) !important;
}

/* table css */
.ls-table-separate {
    border-collapse: separate;
}

.ls-table-separate td {
    padding: 0 !important;
    padding-bottom: 16px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.ls-table-separate td:first-child {
    padding-left: 0 !important;
}

.ls-table-separate td:last-child {
    padding-right: 0 !important;
}

.ls-table-separate tr:last-child td {
    padding-bottom: 0 !important;
}

.ls-no-image-block {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F3F4F5;
}

.ls-row-item {
    width: 100%;
}

.ls-grid {
    display: grid;
    grid-template-columns: var(--column, 1fr);
}

.ls-absolute {
    position: absolute;
}

.ls-relative {
    position: relative;
}

.ls-right-10 {
    right: 10px !important;
}

.ls-top-12 {
    top: 12px !important;
}

/* css upload 1 image */

.ls-upload-single-image .image-none .ladiui-button-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 48px 24px; */
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    background: var(--full-color-grey-50, #f3f4f5);
    box-shadow: inset 0 0 0 3px #fff;
}

.ls-upload-img-container .ls-item-image {
    /* width: 134px; */
    /* height: 134px; */
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 0px 4px #fff inset;
    overflow: hidden;
}

.ls-upload-img-container .ls-item-image:hover .ls-widget-actions{
    display: flex;
    
}

.ls-widget-actions {
    display: none;
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
}

.ls-widget-actions:hover {
    display: flex;
}

.ls-icon-action {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
}

.ls-icon-action i {
    background-color: #fff !important;
}

.ls-upload-single-image .ladiui-button-upload-image {
    width: 134px;
    height: 134px;
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 0px 4px #fff inset;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
}

@media screen and (max-width: 768px) {
    .ls-setting-row {
        flex-direction: column;
        grid-gap: 24px;
        gap: 24px;
    }

    .ls-setting-row-left {
        width: 100%;
    }

    .ls-setting-row-right {
        width: 100%;
    }

    #block-content-product-course {
        display: flex;
        flex-direction: column;

    }

    #block-content-product-course .ls-course-left-col {
        width: 100%;
    }

    #block-content-product-course .ls-course-right-col {
        width: 100%;
    }

    .ls-edit-chapter-name {
        max-width: 150px;
    }
}

.ls-link-hover:hover {
    color: var(--main-primary);
}

.lesson-name:hover {
    cursor: pointer;
}

.lesson-name:hover i.ls-link-hover {
    background-color: var(--main-primary) !important;
}

.lesson-name:hover span.ls-link-hover {
    color: var(--main-primary) !important;
}

.ls-wrapper-more-actions {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: #F3F4F5 !important;
    cursor: pointer;
}

.ls-wrapper-more-actions i {
    background-color: #566577 !important;
    width: 14px !important;
    height: 14px !important;
    -webkit-mask-size: 14px !important;
            mask-size: 14px !important;
}

.ls-events-none {
    pointer-events: none !important;
}

.ls-cursor-not-allowed {
    cursor: not-allowed !important;
}

.ls-text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: var(--line, 1);
    -webkit-box-orient: vertical;
    word-break: break-word;
}

@media screen and (max-width: 1100px) and (min-width: 600px) {
    .ls-wrapper-video {
        flex-direction: column;
        grid-gap: 16px;
        gap: 16px;
    }
}



/** @format */

body {
    line-height: 1.2em;
    font-size: 14px;
}

a {
    color: var(--main-primary);
}

.variant-active {
    color: #fff;
    background-color: #1853fa;
    border-color: #1853fa;
}

.variant-option-item {
    /* padding-top: 12px; */
    clear: both;
    width: 100%;
}

.ladiui-form-group.variant-option-list .list-item {
    margin-bottom: 12px;
}

.variant-option-item .ladiui-tags {
    border: 1px solid #ebedf2;
    border-radius: 4px;
}

.variant-option-item .ladiui-tags>input {
    border: none;
}

.variant-text {
    color: #1853fa;
    padding-left: 20px;
}

#gallery-create,
#gallery-edit {
    width: 400px;
    height: 430px;
}

.ladi-gallery-container:hover .ladi-gallery-control-item {
    opacity: 0.3;
}

.ladi-gallery-container:hover .close-image {
    opacity: 1;
}

.close-image {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.search-panel li {
    display: inline-block;
    padding: 0px 20px 0px 20px;
    width: 30%;
}

.discount-option {
    position: relative;
    display: initial;
}

.shipping-option {
    position: relative;
    display: initial;
}

.discount-box {
    width: 300px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    z-index: 7;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
}

.discount-option .discount-reason {
    margin: 15px 0px;
}

.discount-option .discount-reason>label {
    display: inherit;
    text-align: left;
}

.discount-button>button {
    margin-left: 15px;
}

.discount-button-active {
    color: #6c7293 !important;
    border-color: #ebedf2 !important;
    background-color: #f4f5f8 !important;
}

.shipping-box {
    width: 450px;
    left: 440px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    z-index: 7;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
}

.shipping-item {
    width: 48.5%;
    margin-right: 10px;
    display: inline-block;
}

.shipping-group {
    margin-bottom: 15px;
}

.shipping-group .shipping-item:last-child {
    margin-right: 0px;
}

.shipping-button>button {
    margin-left: 15px;
}

/* .order-address-dropdown {
    cursor: pointer;
    padding: 5px !important;
} */

/* .order-address-dropdown:hover {
    background-color: #f8fafb;
} */

@media (min-width: 1366px) {
    .order-page {
        width: 85%;
        margin: 0px auto;
    }

    .setting-page {
        width: 85%;
        margin: 0px auto;
    }

    .shipping-page {
        width: 85%;
        margin: 0px auto;
    }
}

.customer-info>span {
    display: flex;
    align-items: center;
    line-height: inherit;
    margin-bottom: 8px;
    font-size: 14px;
}

.customer-info>span img {
    margin-right: 8px;
}

.order-custom-field .more-info span i {
    margin-right: 8px !important;
}

.discount-date {
    width: 40%;
}

.ladiui.form-group .discount-item {
    margin-bottom: 16px;
    display: flex;
}

.ladiui.form-group .discount-item:last-child {
    margin-bottom: 0px;
}

.order-head-right {
    position: absolute;
    top: 28px;
    right: 0px;
}

.order-page {
    position: relative;
}

.ladiui.table .order-table-item {
    color: var(--main-primary);
    font-weight: 400;
}

.ladiui.table .ladiui.checkall {
    width: 30px;
}

.builder-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    background-color: #fafafa;
    z-index: 900000080;
    background-size: 128px;
}

.builder-loading-blur {
    background-color: rgba(12, 97, 242, 0.05);
}

.setting-page {
    position: relative;
}

.setting-content {
    background-color: #fff;
    padding: 15px;
}

.setting-content>ul {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.setting-content>ul::after {
    content: '';
    width: 400px;
}

.setting-item {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    line-height: 1.4;
    font-size: 15px;
}

/* .setting-item:hover {
    background-color: #f9fafb;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
} */

.setting-item .title {
    font-weight: 500;
    margin: 0;
    color: #1853fa;
}

.setting-item .description {
    color: #637381;
}

.setting-item .icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    text-align: center;
    background-color: #dfe3e8;
    border-radius: 3px;
}

.setting-item .icon .ladi-icon {
    margin-top: 12px;
}

.setting-item .text {
    width: 315px;
}

.account-content {
    margin-top: 5px;
    font-size: 14px;
}

/* .account-table .ladiui.table>tbody>tr>td:first-child {
    padding: 10px !important;
} */

.account-table .avatar {
    border-radius: 50%;
}

.account-body .ladiui-table-actions {
    min-height: 62px;
    border-radius: 5px;
    padding: 10px;
}

.account-body .ladiui-table-actions .ladiui.form-group {
    display: flex;
    align-items: center;
}

.account-body .ladiui-table-actions .ladiui.form-group .ladiui-label {
    margin-right: 15px;
}

.account-body .ladiui-table-actions .ladiui.form-group .data-permission {
    width: 200px;
}

.scope-group {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.scope-group .scope-parent {
    margin-right: 50px;
    margin-bottom: 5px;
}

.scope-group .scope-parent>label {
    font-weight: 400;
}

.scope-group .scope-children {
    margin-left: 20px;
}

.scope-group .scope-children>label {
    margin-right: 15px;
}

.upgrade-heading {
    font-weight: 500;
    margin: 20px 0px;
}

.upgrade-content {
    width: 650px;
    margin: 0px auto;
    background-color: #fff;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.upgrade-package>span {
    display: inline-block;
    margin-right: 30px;
}

.upgrade-package label {
    font-weight: 500;
    font-size: 14px;
}

.upgrade-package-time li {
    display: inline-block;
    background: #fafafa;
    padding: 10px 15px;
}

.upgrade-package-time li.active {
    background-color: #1853fa;
    color: #fff;
}

.upgrade-checkout {
    margin-top: 50px;
}

.upgrade-total {
    display: block;
    float: right;
    margin-top: -22px;
    font-weight: 500;
}

.product-variants small {
    font-size: 15px;
    /* font-style: italic; */
}

.ladiui-product-right .ladiui.textarea {
    min-height: 100px;
}

.condition-group>label {
    position: relative;
    top: -10px;
    background: #fff;
    display: inline-block;
    padding-right: 10px;
}

.condition-group {
    border-top: 1px solid #ebedf2;
    clear: both;
}

#modal-discount .ladiui.form-group {
    display: inline-table;
}

.ls-dashboard-chart {
    margin: 20px 0px;
}

.ls-dashboard-chart>div {
    height: 250px !important;
}

.ls-dashboard-chart .highcharts-container {
    height: 250px !important;
}

.search-variant .variant {
    display: inline-block;
}

.search-variant .image {
    width: 20%;
}

.search-variant .name {
    width: 60%;
}

.shipping-not-delivery .title .icon-delivery {
    font-size: 20px;
    margin-right: 10px;
}

.shipping-not-delivery .title h3 {
    text-transform: uppercase;
    display: inline-block;
}

.shipping-not-delivery .title button {
    float: right;
}

#modal-shipping-form .ladiui-row {
    display: flex;
}

#modal-shipping-form .left {
    flex: 0.6 1;
    margin-right: 50px;
}

#modal-shipping-form .right {
    flex: 0.4 1;
    position: relative;
}

#modal-shipping-form .shipping-footer {
    display: flex;
    justify-content: space-between;
}

#modal-shipping-form .shipping-footer .button {
    margin-left: 10px;
}

#modal-shipping-form .ladiui-row .coupon {
    /* flex: 0.4; */
    width: 239px;
}

#modal-shipping-form .ladiui-row .size {
    /* flex: 1; */
    width: 100px;
    margin-top: 0px !important;
}

#modal-shipping-form .order-info {
    overflow: hidden;
    background: #fff;
    padding: 20px 0px 0px;
    border-top: 1px solid var(--border-02);
}

.order-info .heading h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 24px;
}

#modal-setting-shipping #tab-service .item {
    margin-bottom: 15px;
    border-radius: 10px;
}

#modal-setting-shipping #tab-service .item:last-child {
    margin-bottom: 0px;
}

#modal-setting-shipping #tab-service .item .shipping-partner-logo {
    height: 25px;
}

#modal-setting-shipping #tab-service .item .description {
    margin-top: 15px;
    padding-bottom: 15px;
    line-height: 22px;
    font-size: 15px;
}

#modal-setting-shipping #tab-service .item .partner-account {
    margin-top: 15px;
}

#modal-setting-shipping #tab-service .item .partner-footer {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebedf2;
    justify-content: flex-end;
}

#modal-setting-shipping #tab-service .item .partner-footer .right button {
    margin-left: 10px;
}

#modal-setting-shipping #tab-service .item .partner-footer .left button {
    margin-left: 10px;
}

#modal-shipping-form #tab-service .select-hub {
    display: inline-block;
    width: 210px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.shipping-delivered .title {
    display: flex;
    justify-content: flex-start;
}

.shipping-delivered .title i {
    margin-right: 15px;
    color: #16bf0e;
    font-size: 20px;
}

.shipping-detail {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    padding: 15px;
    border-radius: 4px;
    overflow: inherit;
    min-width: 325px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    background-color: #fff;
}

.shipping-detail .title {
    display: flex;
}

.shipping-detail .title h3 {
    font-weight: 500;
    margin-right: 30px;
}

.shipping-detail .badge-status {
    background-color: #feaf9a;
    border-radius: 20px;
    padding: 6px 10px 0px 10px;
}

.shipping-detail .badge-status label {
    font-size: 13px;
}

.shipping-detail .shipping-action {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ebedf2;
    margin-top: 15px;
}

.shipping-detail .shipping-action button {
    margin-top: 15px;
}

.ladiui.button.disabled {
    color: #6c7293;
    border-color: #ebedf2;
    background-color: #f4f5f8;
}

.ladiui-shipping-left {
    width: 70%;
    float: left;
    position: relative;
}

.ladiui-shipping-right {
    width: 28%;
    float: right;
}

.shipping-page {
    position: relative;
}

.shipping-head-right {
    position: absolute;
    top: 28px;
    right: 0px;
}

.shipping-head-right .placed-on {
    margin-right: 30px;
}

.shipping-head-right .placed-on span:first-child {
    font-weight: 500;
}

.ladiui-shipping-right .order-customer {
    position: relative;
}

.shipping-info {
    /* border-bottom: 1px solid #f1f3f4;
    padding-bottom: 20px; */

    /* overflow: hidden;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 0px 11px 0 rgba(0, 0, 0, .1); */
}

.shipping-info .shipping-info-heading h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.shipping-info .item {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
}

.shipping-info .item.status {
    border-top: 1px solid #ebedf2;
    padding-top: 15px;
}

.shipping-info .item.status .ladiui.button {
    padding: 2px 15px;
}

.shipping-stack-total.hide-border {
    border: none !important;
}

.ladiui-shipping-left {
    background: #fff;
}

.ladiui-shipping-left .shipping-item-box {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px 20px 15px;
    border-bottom: 1px solid #efefef;
    border-top: 1px solid #efefef;
}

.ladiui-shipping-left .shipping-item-box h3 {
    font-weight: 500;
    font-size: 17px;
}

.ladiui-shipping-left .shipping-item-box h3 i {
    margin-right: 20px;
}

.ladiui-shipping-left .actions {
    padding: 20px 15px 0px 15px;
    justify-content: space-between;
}

#modal-image-variant .image-variant .item {
    padding: 3px;
    border: 1px solid rgba(195, 207, 216, 0.3);
    cursor: pointer;
    display: inline-block;
    margin: 5px;
}

.selected-image-variant {
    border: 1px solid #0b61f2 !important;
    border-radius: 3px;
}

#modal-image-variant .image-variant .item img {
    display: block;
    margin: auto;
}

.topbar-profile .ladiui.avatar {
    background-color: #1853fa;
    color: white;
    width: 35px;
    height: 35px;
    display: inline-flex;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: -7px;
    margin-right: 0px;
    background-size: contain !important;
}

#modal-new-product .info {
    display: flex;
}

#modal-new-product .info>div {
    margin-right: 15px;
}

#modal-new-product .info>div:first-child {
    flex: 0.5 1;
}

#modal-new-product .info>div:nth-child(2) {
    flex: 0.3 1;
}

#modal-new-product .info>div:nth-child(3) {
    flex: 0.2 1;
}

#modal-new-product .info>div:last-child {
    margin-right: 0px;
}

.custom-input-autocomplete {
    cursor: pointer;
}

.async-auto-complete.menu {
    margin-left: -13px;
    margin-top: 10px;
    max-height: 175px !important;
    min-height: 100px !important;
    /* min-width: 552px!important; */
    width: 100%;
    z-index: 999999;
}

.async-auto-complete.menu .item {
    overflow: hidden;
    padding: 8px 16px;
    text-overflow: ellipsis;
    white-space: nowrap;

    position: relative;
    display: table;
    width: 100%;
    cursor: pointer;
}

.async-auto-complete.menu .item .image {
    text-align: left;
    width: 55px;
    display: table-cell;
}

.async-auto-complete.menu .item .name {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}

.async-auto-complete.menu .item .price {
    text-align: right;
    display: table-cell;
    vertical-align: middle;
    white-space: nowrap;
}

#autocomplete-customer .item .name span {
    font-weight: 500;
}

.condition-group {
    border-top: 1px solid #ebedf2;
    clear: both;
    margin: 10px 0px;
}

.ladiui-tabs li.active a {
    padding-bottom: 8px;
}

.ladiui-modal-content .ladiui-tabs {
    margin: 0px -15px;
}

.ladiui-tab-item .ladiui.table {
    box-shadow: none;
}

.ladiui-tab-content .ladiui.input,
.ladiui-tab-content .ladiui.select {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.variant-option-head>span {
    font-size: 14px;
}

.discount-from-date,
.discount-to-date {
    width: 200px;
}

.discount-from-date {
    margin-right: 20px;
}

.discount-check-endate {
    width: 196px;
    vertical-align: middle;
    display: block;
    overflow: hidden;
    line-height: 93px;
}

.discount-from-date,
.discount-check-endate {
    float: left;
}

.discount-to-date {
    float: left;
    margin-right: 20px;
}

.discount-to-date label,
.discount-from-date label {
    margin: 5px 0px;
    display: block;
}

.ladiui-input-group-prepend button {
    height: 40px;
}

.discount-button-active {
    background: var(--main-primary) !important;
    color: #fff !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.ladiui-tag-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
}

.ladiui-tag-list .ladiui.dropdown-toggle {
    min-width: unset !important;
    border: none !important;
}

.ladiui-tag-list .ladiui.dropdown-toggle:after {
    /* display: none !important; */
    /* background: #ffffff !important; */
    background: unset !important;
}

.ladiui-tag-list .dropdown-text {
    color: var(--main-primary) !important;
}

.ladiui-tags-label {
    display: flex;
    justify-content: space-between;
}

.ladiui-tags-label .all-tag {
    color: rgb(26, 115, 232);
    cursor: pointer;
    font-weight: 400;
}

.modal-all-tag .ladiui.form-group {
    min-height: 70px;
}

.modal-all-tag .ladiui-tags {
    border: 0px;
}

.modal-all-tag .tag {
    cursor: pointer;
}

.disabled-tag {
    background: #1853fa59 !important;
}

.ladiui-tags {
    max-width: 100%;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    min-height: 40px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    line-height: 20px;
    grid-gap: 8px;
    gap: 8px;
    padding: 10px 12px;
}

.ladiui-tags .tag {
    position: relative;
    display: flex;
    align-items: center;
    float: left;
    color: var(--main-default);
    background: #f1f3f4;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 0px 8px;
    /* margin-right: 8px; */

    /* padding: 8px 18px 8px 12px; */
    /* margin: 4px; */
    transition: all 0.3s ease-out;
}

.ladiui-tags .tag .close {
    /* position: absolute;
    top: 0px;
    right: 0;
    width: 14px;
    height: 100%; */
    /* background: var(--main-primary); */
    line-height: 20px;
    cursor: pointer;
    border-radius: 0 2px 2px 0;
    transition: background 0.3s;
}

.table-address .default {
    background-color: #f8fafb;
}

.ladiui-order-left .ladi-card.order-products {
    /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, .1); */
    border-radius: 4px;
    background: #fff;
}

#autocomplete-variant .async-auto-complete.menu {
    top: 50px !important;
    max-height: 300px !important;
}

#autocomplete-variant .async-auto-complete.menu .item .image img {
    vertical-align: middle;
    border-radius: 3px;
}

.ladiui-order-left .ladiui.input,
.ladiui-order-left .ladiui.select {
    box-shadow: none;
    -webkit-box-shadow: none;
}

/* #autocomplete-variant .ladiui.input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    height: 50px;
    border-bottom: 0px;
} */

.ladiui-order-left .ladiui-search-custom::after {
    line-height: 50px;
}

.ladiui-order-left .ladi-card.order-products .ladiui.table {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    -webkit-box-shadow: none;
    margin-bottom: 30px;
}

.order-stack-total:first-child td {
    padding-top: 30px !important;
}

.discount-box .ladiui-input-group .ladiui-input-group-prepend button {
    border-radius: 4px;
    border-left: 0px;
}

button.discount-percent {
    border-radius: 0px !important;
}

button.discount-fixed {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.discount-box .ladiui-input-group .ladiui-input-group-prepend {
    margin: 0px !important;
}

.discount-box .ladiui-input-group .ladiui.input {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

/* .ladiui.table tbody tr td {
    padding: 10px 15px;
    line-height: 24px;
} */

.price-option {
    position: relative;
}

.price-box {
    width: 400px;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    z-index: 7;
    background: #fff;
    border-radius: 4px;
    border: 1px solid var(--border-02);
    /* padding: 20px; */
}

.price-option .discount-reason>label {
    display: inherit;
    text-align: left;
}

.price-option .discount-reason {
    margin: 15px 0px;
}

.discount-option .discount-code {
    margin: 15px 0px;
}

.shipping-group>span {
    display: inline-block;
    margin-right: 30px;
}

.discount-fixed {
    border-left: 0px;
}

.order-customer .async-auto-complete .item .info span {
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
}

.discount-box::before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 10px solid #ba222200;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fafafa;
    top: -10px;
    position: absolute;
}

.price-option {
    display: initial;
}

.ladiui.input,
.ladiui.select {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 6px 0 rgba(169, 194, 209, 0.1);
}

.ladiui-modal-content .ladiui-select-options {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 1px 2px 0 rgba(169, 194, 209, 0.1);
}

.ladiui-modal-content .order-customer,
.ladiui-modal-content .order-info {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.order-customer #autocomplete-customer .async-auto-complete {
    top: 45px !important;
    min-width: 325px !important;
    left: -15px !important;
}

.ladiui-search-custom.has-default .async-auto-complete>div:first-child {
    padding: 8px 10px;
}

#autocomplete-product-tag .item .name {
    line-height: 40px;
}

.async-auto-complete .item .tag-item:hover {
    background: #fafafa;
}

.shipping-rate-name {
    font-weight: 500;
    font-size: 14px;
}

.shipping-rate-table.ladiui.table tbody tr {
    border-bottom: 0px;
}

.shipping-rate-table.ladiui.table tbody tr.last-row {
    border-bottom: 1px solid #efefef;
}

.shipping-rate-table.ladiui.table tbody tr.last-row:last-child {
    border-bottom: 0px;
}

#modal-shipping-rate-detail .item-district {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group {
    margin-right: 25px;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group:first-child {
    flex: 0.3 1;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group:nth-child(2) {
    flex: 0.45 1;
}

#modal-shipping-rate-detail .item-district .ladiui.form-group:nth-child(3) {
    flex: 0.25 1;
}

.account-table .ladiui.table {
    box-shadow: none;
}

#modal-customer .condition-group>label {
    font-size: 16px;
}

.ladiui-label {
    color: var(--text-secondary);
    padding-bottom: 4px;
    font-size: 14px;
    display: block;
}

.ladiui-label>i {
    margin-left: 5px;
    font-size: 10px;
    font-weight: 600;
    color: #71a3e6;
}

strong {
    font-weight: 500;
}

#modal-shipping-form .suggestion {
    line-height: normal;
    line-height: initial;
    font-style: italic;
    /* background-color: var(--secondary-color); */
    /* padding: 10px 5px 10px 8px; */
    border-radius: 4px;
    /* font-size: 15px; */
}

#modal-shipping-form .suggestion::before {
    content: '\ea55';
    font-family: 'LadiIcons';
    margin-right: 10px;
    color: cornflowerblue;
    font-style: initial;
}

.welcome .init-store {
    margin-top: 100px;
    font-size: 40px;
    margin-bottom: 30px;
}

.welcome {
    width: 760px;
    margin: 0 auto;
    line-height: 20px;
    height: 100vh;
}

.welcome .btn-create-store {
    clear: both;
    float: right;
}

#modal-discount .overview-item {
    display: block;
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 8px;
    /* font-style: italic; */
}

#modal-discount .overview-content {
    padding-left: 12px;
}

.action-item .date {
    width: 135px;
}

.order-customer .avatar {
    top: 45px;
}

.order-table .order-assign {
    padding: 5px;
    background-color: #feaf9a82;
    border-radius: 7px;
    cursor: pointer;
}

.customer-order .order-overview {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(6, 21, 40, 0.05);
    padding-bottom: 16px;
}

.customer-order .order-overview .item p {
    color: var(--main-secondary);
    font-size: 14px;
}

.customer-order .order-overview .item h3 {
    font-weight: 500;
    text-align: center;
}

.customer-order .heading {
    margin-bottom: 15px;
}

.customer-order .heading label {
    display: inline-block;
}

.customer-order .heading a {
    float: right;
}

.payment-shipping {
    /* height: 60px; */
    /* border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef; */
    /* display: table; */
    width: 100%;
    margin-bottom: 24px;
}

.payment-shipping .title-payment {
    /* width: calc(100% - 150px);
    display: table-cell;
    vertical-align: middle;
    padding-left: 15px; */
}

.payment-shipping .title-payment i {
    /* margin-right: 15px; */
    color: #16bf0e;
    font-size: 10px;
    border: 1px solid;
    border-radius: 50%;
    padding: 3px;
}

.payment-shipping button {
    /* float: right;
    margin-top: 10px;
    margin-right: 15px; */
}

.ladiui-order-left {
    clear: both;
}

.tag-color .ladiui-tags {
    border: none;
    float: left;
}

.tag-color .add-tag {
    cursor: pointer;
    display: inline-block;
    margin: 4px;
    border-radius: 2px;
    padding: 6px 8px;
}

.tag-color .add-tag i {
    color: #42526e;
    position: relative;
    top: 2px;
}

.tag-color .selected-tag {
    margin-top: 30px;
    padding: 5px;
    display: inline-block;
    border-radius: 3px;
}

.tag-color .ladiui-tags .tag .close:after {
    /* position: absolute; */
    content: '\00d7';
    /* top: 0px; */
    /* left: 3px; */
    font-weight: 900;
}

.tag-color .ladiui-tags .tag-name {
    padding: 0px 10px 0px 8px;
    position: relative;
    text-align: left;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

.tag-color .ladiui-tags .tag {
    position: relative;
    margin: 0px 4px;
    line-height: 20px;
    height: 24px;
    border-radius: 2px;
    font-size: 14px;
    transition: all 0.3s ease-out;
    padding: 1px 8px;
    white-space: nowrap;
}

.order-table .order-cancel td:nth-child(2) a {
    color: #6c72938f !important;
    text-decoration: line-through;
}

.shipping-table .shipping-cancel td:nth-child(2) a {
    color: #6c72938f !important;
    text-decoration: line-through;
}

.ladiui-order-right .btn-group-order {
    position: absolute;
    display: inline;
    top: -55px;
    right: 0;
}

.ladiui-order-right .btn-group-order button {
    margin-left: 10px;
}

#modal-setting-shipping .ladiui.table tbody tr td {
    padding: 6px 15px;
    line-height: inherit;
}

/* .ladiui.table thead tr th.sorting::after,
.ladiui.table thead tr th.sorting.down::after,
.ladiui.table thead tr th.sorting.up::after {
    top: 17px;
} */

/* input[disabled] {
    background-color: rgb(6 21 40 / 3%) !important;
} */

.item-district input[type='checkbox'] {
    display: inline-block;
    position: relative;
    top: 2px;
}

.form-tags .tag-item {
    color: var(--main-black);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.form-tags .recent-header {
    border-bottom: 1px solid #eaedf0;
    width: 100%;
    padding-left: 9px;
    padding-top: 8px;
}

.order-stack-total td {
    padding: 5px 15px !important;
}

.order-stack-total td strong {
    font-weight: 500;
    font-size: 15px;
    color: #44566c;
}

.customer-info>span:last-child {
    margin-bottom: 0px;
}

.async-auto-complete.menu .icon-c-add {
    position: relative;
    top: 2px;
    display: inline-block;
}

.ladiui.table {
    font-size: 15px;
}

.ladiui-order-left {
    width: 100%;
    float: left;
    /* position: relative; */
    padding-right: 354px;
}

.tag-color {
    width: 100%;
    float: left;
    /* margin: 4px 0px; */
    display: flex;
}

.tag-color .ladiui-tags .ladiui-tag-list .dropdown-tag button {
    padding: 0px !important;
    height: 24px !important;
}

.order-actions {
    width: 25%;
    float: right;
    text-align: right;
}

.order-actions>button {
    display: inline-block;
    margin-left: 15px;
}

.tag-color .ladiui-tags .tag .close {
    line-height: 20px;
    right: 8px;
}

.page-title {
    padding: 26px 0px 20px 0px;
    color: var(--main-default);
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 18px;
    margin-right: 27px;
}

.head-name-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: var(--main-secondary);
}

.tag-color .ladiui-tag-list .tag:first-child {
    margin-left: 0px;
}

.order-page .discount-note {
    font-style: italic;
    font-size: 14px;
}

.payment-shipping span {
    /* text-transform: uppercase; */
    font-size: 14px;
}

.order-page .order-assignee {
    background: #dfe4e8;
    border-radius: 28px;
    font-size: 12px;
    padding: 6px 10px;
    margin-right: 15px;
    min-width: 65px;
    display: inline-block;
    text-align: center;
}

.payment-shipping .btn-status {
    float: right;
    margin-top: 10px;
    margin-right: 15px;
}

/* .ladiui.table thead tr th {
    font-size: 14px;
} */

/* .ladiui-table-actions .action-item {
    margin-right: 24px;
} */

.shipping-status {
    padding: 2px 10px !important;
    border: none !important;
    font-size: 12px !important;
    border-radius: 20px !important;
    line-height: 24px;
}

.shipping-status-1 {
    background-color: #ffc58b !important;
    color: #222 !important;
}

.shipping-status-2 {
    background-color: #47c1bf !important;
    color: #fff !important;
}

.shipping-status-3 {
    background-color: #06a95f !important;
    color: #fff !important;
}

.shipping-status-4 {
    background-color: #f7c85a !important;
    color: #222 !important;
}

.shipping-status-5 {
    background-color: #6849c1 !important;
    color: #fff !important;
}

.shipping-status-6 {
    background-color: #c4cdd5 !important;
    color: #222 !important;
}

.order-shipping-package {
    border: 1px solid #f1f3f4;
}

.order-shipping-package strong:first-child {
    display: block;
    background-color: #fff;
    padding: 15px;
    /* border-bottom: 1px solid #efefef; */
    background: #f8fafb;
}

.ladiui-input-group>.ladiui.input:not(:first-child) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 35px;
}

.ladiui-money .prefix {
    border: 0px;
    padding: 1px 6px;
}

.ladiui-money {
    margin-left: 0px;
    position: absolute;
    right: 2px;
    z-index: 1;
    top: 8px;
}

.ladiui-modal-body {
    max-height: 75vh;
    overflow: scroll;
}

.ladiui-tab-content {
    max-height: none !important;
    overflow-y: unset;
    overflow-x: unset;
}

.unset-modal {
    overflow: unset !important;
}

.ladiui.table tbody tr.order-create:last-child td {
    padding-bottom: 20px !important;
}

span[disabled] {
    background-color: rgba(6, 21, 40, 0.05) !important;
}

.ladiui.table {
    font-size: 14px;
}

/* .group-prices > div {
    width: 30.5%;
} */

.group-weight .ladiui.input {
    display: inline-block;
    width: 75%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.group-weight .ladiui-select-options {
    display: inline-block;
    min-width: 25%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0px;
    border-color: #ebedf2;
}

.group-weight .ladiui-select-options>ul {
    min-width: 25%;
}

.order-page .select-assignee,
.order-page .select-checkout-config {
    /* position: absolute; */
    /* right: 345px; */
    /* width: 200px; */
    /* margin-top: 9px; */
    margin-right: 15px;
    box-shadow: none;
    webkit-box-shadow: none;
}

.order-page .select-assignee {
    z-index: 6;
}

#modal-confirm-payment-order .ladiui-modal-footer .send-email {
    display: inline-block;
    float: left;
}

#modal-confirm-payment-order .ladiui-modal-footer .button {
    display: inline-block;
}

#modal-confirm-payment-order .ladiui-modal-footer .button>.ladiui.button {
    margin: 0px 5px;
}

#modal-confirm-payment-order .ladiui-modal-body {
    font-size: 14px;
    line-height: 20px;
}

#modal-confirm-payment p {
    line-height: 24px !important;
}

#modal-export-order .item,
#modal-export-data .item {
    margin-bottom: 15px;
}

li.custom-menu-dropdown .dropdown-content {
    left: -216% !important;
    padding: 20px 3px 4px 10px;
    width: 810px;
    margin-top: 12px !important;
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07) !important; */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

li.custom-menu-dropdown .dropdown-content>li {
    display: block;
    width: 33%;
    float: left;
}

li.custom-menu-dropdown .dropdown-content>li.search {
    padding: 0px 0px 12px 12px;
    float: right;
}

li.custom-menu-dropdown .dropdown-content>li.search button {
    margin-top: 15px;
    min-width: 120px;
    margin-right: 20px;
    float: right;
}

li.custom-menu-dropdown .dropdown-content>li.title {
    width: 100%;
}

li.custom-menu-dropdown .dropdown-content>li.separator {
    width: 100%;
    border-top: 1px solid #efefef;
}

/* .order-tab {
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
    font-size: 14px;
    color: #6c7293;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
} */

.order-table {
    border-radius: 0px 0px 4px 4px !important;
}

.ladiui-select-options>ul>li:hover {
    background: #f8fafb;
}

li.custom-menu-dropdown li.title {
    width: 100%;
    margin-bottom: 15px;
}

li.custom-menu-dropdown .ladiui-select-options {
    box-shadow: none;
    -webkit-box-shadow: none;
}

li.custom-menu-dropdown {
    font-size: 14px;
}

li.custom-menu-dropdown label {
    font-size: 14px;
}

.order-tab .ladiui-tabs li a {
    color: #747577;
}

.order-tab .ladiui-tabs li.active a {
    color: #1853fa;
}

.DateRangePickerInput {
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid var(--border-01) !important;
    border-radius: 4px !important;
}

.DateInput_input__focused {
    border-color: #fff !important;
}

.DateInput_fang {
    top: 38px !important;
}

.CalendarMonth_table {
    margin-top: 10px;
}

.CalendarMonth_caption::first-letter {
    text-transform: capitalize !important;
}

.CalendarMonth_caption {
    font-size: 16px !important;
}

.CalendarDay__selected_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected {
    background: #0b61f2 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected:hover {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay {
    vertical-align: middle !important;
}

.DateInput_input::-webkit-input-placeholder,
.DateInput_input::placeholder {
    font-size: 13px !important;
}

.DateRangePickerInput_calendarIcon i {
    vertical-align: middle !important;
}

.DateInput_input {
    font-weight: inherit !important;
}

.ladiui.submenu>li>a.active {
    color: #1853fa;
}

.ladiui-topbar.fixed {
    z-index: 15;
}

.order-tab .ladiui-tabs li {
    padding: 10px 20px 10px 15px;
}

.custom-menu-dropdown .dropdown-content::before {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    left: 48%;
    width: 12px;
    height: 12px;
    background: #ffffff;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    -moz-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.ladiui-topbar-noavatar {
    background: #1c00c2;
    color: #fff;
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 50%;
    text-align: center;
    padding-top: 15%;
}

[contenteditable='true'] {
    -moz-box-sizing: border-box;
    border: 0px;
    max-height: 70px;
    max-width: 450px;
    word-wrap: break-word;
    margin: 0;
    min-height: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-left: 5px;
    vertical-align: top;
    box-sizing: border-box;
    font-size: 13px;
}

.order-timeline {
    font-weight: 500;
    /* border-bottom: 1px solid #efefef; */
    background-color: #fff;
    border-radius: 5px 5px 0px 0px;
    padding: 8px 0px;
    margin-top: 24px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px; */
    /* background: #f8fafb; */
}

.order-page .timeline {
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;
    padding-bottom: 15px;
}

.order-page .timeline .content {
    overflow-y: none;
    height: 100%;
}

#modal-store .welcome {
    height: 100%;
}

.dont-check-inventory {
    text-align: center;
    cursor: pointer;
}

[data-tooltip]:before {
    content: attr(data-tooltip);
}

[data-tooltip] {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

/* Tooltip styling */
[data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: #0E233C;
    color: #fff;
    padding: 8px;
    font-size: 13px;
    line-height: normal;
    min-width: 100px;
    text-align: center;
    border-radius: 8px;
}

/* Dynamic horizontal centering */
[data-tooltip-position='top']:before,
[data-tooltip-position='bottom']:before {
    left: 50%;
    transform: translateX(-50%);
}

/* Dynamic vertical centering */
[data-tooltip-position='right']:before,
[data-tooltip-position='left']:before {
    top: 50%;
    transform: translateY(-50%);
}

[data-tooltip-position='top']:before {
    bottom: 100%;
    margin-bottom: 6px;
}

[data-tooltip-position='right']:before {
    left: 100%;
    margin-left: 6px;
}

[data-tooltip-position='bottom']:before {
    top: 100%;
    margin-top: 6px;
}

[data-tooltip-position='left']:before {
    right: 100%;
    margin-right: 6px;
}

/* Tooltip arrow styling/placement */
[data-tooltip]:after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

/* Dynamic horizontal centering for the tooltip */
[data-tooltip-position='top']:after,
[data-tooltip-position='bottom']:after {
    left: 50%;
    margin-left: -6px;
}

/* Dynamic vertical centering for the tooltip */
[data-tooltip-position='right']:after,
[data-tooltip-position='left']:after {
    top: 50%;
    margin-top: -6px;
}

[data-tooltip-position='top']:after {
    bottom: 100%;
    border-width: 6px 6px 0;
    border-top-color: #0E233C;
}

[data-tooltip-position='right']:after {
    left: 100%;
    border-width: 6px 6px 6px 0;
    border-right-color: #6c7293;
}

[data-tooltip-position='bottom']:after {
    top: 100%;
    border-width: 0 6px 6px;
    border-bottom-color: #6c7293;
}

[data-tooltip-position='left']:after {
    right: 100%;
    border-width: 6px 0 6px 6px;
    border-left-color: #6c7293;
}

/* Show the tooltip when hovering */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 50;
}

.group-adjust-quantity {
    border-radius: 5px;
    margin: 0px auto;
}

.group-adjust-quantity input {
    display: inline-block !important;
    width: 150px !important;
    border-radius: 0px !important;
    height: 36px !important;
}

.group-adjust-quantity input:focus {
    border-color: #ebedf2 !important;
}

.group-adjust-quantity button {
    border-radius: 0px !important;
}

.group-adjust-quantity item:first-child {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.group-adjust-quantity .mode {
    background-color: #fff;
}

.group-adjust-quantity .mode.active {
    color: #6c7293 !important;
    border-color: #ebedf2 !important;
    background-color: #f4f5f8 !important;
}

.group-adjust-quantity .item:last-child {
    line-height: 20px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

#modal-update-batch .group-adjust-quantity input {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.group-adjust-quantity .remove-right {
    border-right: none !important;
}

.inventory-adjust-triagle {
    margin: 0px 10px;
    font-size: 10px;
    color: var(--main-primary);
}

.inventory-page button.primary[disabled] {
    background-color: #759ee4;
    border-color: #759ee4;
}

textarea {
    resize: none;
}

#modal-refund .ladiui.form-group {
    display: inline-block;
    position: relative;
    width: 100%;
}

#modal-refund .ladiui.form-group .ladiui-row .col-50:first-child {
    top: 20%;
    position: absolute;
}

#modal-refund button[disabled] {
    background-color: #759ee4;
    border-color: #759ee4;
}

.ladiui-user-type {
    position: relative;
    display: inline-block;
    top: 13px;
    padding: 4px 15px;
    color: #fff;
    font-weight: 700;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    background: #1853fa;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ladiui-logo {
    width: 135px;
}

.ladiui-logo img {
    width: 120px;
}

.pkg-STARTER {
    background: linear-gradient(90deg, #57a900, #bfe500);
}

.pkg-PREMIUM {
    background: linear-gradient(90deg, #f22c29, #ff7b4a);
}

.package-table-info tr {
    margin: 10px;
    display: inline-block;
    width: 100%;
}

.package-table-info tr td:first-child {
    width: 200px;
}

/* li.order-address-dropdown {
    line-height: 22px;
    border-bottom: 1px solid #f6f7f9;
    font-weight: 300;
}

ul li.order-address-dropdown:last-child {
    border-bottom: none;
} */

.order-tag {
    color: var(--main-default);
    background: #1c00c2;
    border-radius: 2px;
    font-size: 13px;
    transition: all 0.3s ease-out;
    padding: 4px 15px;
    cursor: pointer;
    white-space: break-spaces;
    max-width: 500px;
}

.custom-menu-dropdown .ladiui-modal-footer .icon {
    width: auto;
    height: auto;
}

.custom-menu-dropdown #modal-all-tag {
    height: 100%;
    min-height: 50vh;
}

.custom-menu-dropdown #modal-all-tag .ladiui-modal-content {
    width: 600px;
}

.customer-page li.custom-menu-dropdown .dropdown-content,
.product-page li.custom-menu-dropdown .dropdown-content {
    width: 500px;
    left: -112% !important;
}

.customer-page #modal-all-tag .ladiui-modal-content,
.product-page #modal-all-tag .ladiui-modal-content {
    width: 400px;
}

.ladiui-modal {
    overflow: unset;
}

.ladiui-modal.visible {
    overflow: scroll;
}

.ladiui-modal-body {
    overflow: unset;
    max-height: inherit;
}

.dropdown-tag {
    float: left;
}

.dropdown-tag .item {
    padding: 5px !important;
    border-radius: 4px;
    margin: 8px 10px;
}

.dropdown-tag .item:first-child {
    margin-top: 2px;
}

.dropdown-tag .item:hover {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.dropdown-tag .ladiui-search-dropdown-menu {
    overflow-y: unset;
}

.dropdown-tag .ladiui-search-input {
    background: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
}

.dropdown-tag.ladiui-search-dropdown::after {
    content: '' !important;
}

.dropdown-tag .ladiui.text::before {
    content: '\ea05';
    font-family: 'LadiIcons';
    font-size: 12px;
    display: block;
    position: absolute;
    left: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.form-default-color {
    margin-top: 16px;
}

.form-default-color .item {
    float: left;
    height: 30px;
    padding: 0;
    width: 64px;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    border-radius: 2px;
}

.form-default-color .item i {
    color: var(--main-primary);
}

.order-tag-item {
    padding: 5px 15px;
    display: inline-block;
    cursor: pointer;
    border-radius: 2px;
}

.ladiui.table.scope tr td {
    width: 1%;
    white-space: nowrap;
}

/* #modal-edit-account .ladiui-modal-content {
    max-width: 1200px;
} */

.onoffswitch {
    position: relative;
    width: 70px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin-top: 14px;
}

.onoffswitch.m-t-0 {
    margin-top: 0px !important;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0px solid #ffffff;
    border-radius: 20px;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 10px;
    color: white;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: 'ONLINE';
    padding-left: 10px;
    background-color: var(--main-primary);
    color: #ffffff;
    text-align: left;
}

.onoffswitch-inner:after {
    content: 'OFFLINE';
    padding-right: 10px;
    background-color: #eeeeee;
    color: #999999;
    text-align: right;
}

.onoffswitch-switch {
    display: block;
    width: 8px;
    height: 8px;
    margin: 5px;
    background: #ffffff;
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 49px;
    border: 2px solid #ffffff;
    border-radius: 16px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 3px;
    top: 1px;
}

.basic-rest-weight {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    margin-left: 5px;
}

#modal-assign .onoffswitch {
    float: right;
    top: -15px;
}

#modal-assign .onoffswitch-inner::before {
    content: 'ON';
}

#modal-assign .onoffswitch-inner:after {
    background-color: #484848;
    content: 'OFF';
}

.rule-group {
    position: relative;
    display: flex;
    flex-direction: column;
}

.rule-group .rule-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    padding: 16px;
}

.rule-group .rule-item .item {
    display: inline-block;
    min-width: 20%;
    position: relative;
}

.rule-group .select-assignee .ladiui-select-options {
    z-index: inherit;
}

.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init:after {
    z-index: 0;
    right: 0px;
}

.rule-group.other .ladiui-select-options>.ladiui-select-init:after {
    z-index: 0;
}

.rule-group .rule,
.rule-group .assign {
    display: block;
    width: 100%;
    float: left;
    /* padding: 0px 15px; */
}

/* .rule-group .assign {
    margin-bottom: 15px;
} */

.rule-group .assign .apply-to {
    display: inline-block;
    min-width: 25%;
    font-weight: 500;
}

.rule-group .assign .select-assignee {
    display: inline-block;
    width: 100%;
}

.rule-group .assign .ladiui-select-options {
    display: block;
    float: left;
    width: 150px;
    margin-right: 10px;
}

.rule-group.other .select-assignee .ladiui-select-options>.ladiui-select-init {
    text-indent: 15px;
}

.rule-group.other .ladiui-select-options {
    width: 250px;
    z-index: inherit;
}

.rule-group.other .select-assignee .ladiui-select-options {
    width: 240px;
}

.rule-group .rule .ladiui-tags {
    position: relative;
}

.rule-group .ladiui-tags {
    border: none;
    display: block;
    float: left;
    max-width: 600px !important;
}

.rule-group .rule .rule-item .ladiui-select-options,
.rule-group .rule .rule-item .custom-input-autocomplete,
.rule-group .assign .ladiui-select-options {
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    box-shadow: none;
    border-radius: 0px;
    padding-bottom: 7px;
}

.rule-group .ladiui-tag-list {
    /* margin-top: 10px; */
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.rule-group .flex-tag {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 8px !important;
}

.rule-group .ladiui-tag-list .tag {
    background: #f1f3f4;
    border-radius: 2px;
    padding: 2px 8px;
    margin: 4px 4px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.rule-group .ladiui-tag-list .tag img {
    margin-left: 9px;
}

.rule-group .rule .rule-item .input {
    height: 32px !important;
}

.rule-group .ladiui-tags .tag {
    position: relative;
    display: block;
    float: left;
    color: #191919;
    background: #fafafa;
    padding: 4px 23px 4px 16px;
    margin: 4px;
    border-radius: 20px;
    font-size: 14px;
    transition: all 0.3s ease-out;
}

.rule-group .ladiui-tags .close {
    color: #191919;
    background: #fafafa;
    right: 5px;
    top: 0px;
    height: 26px;
    border-radius: 10px;
    line-height: 13px;
}

.rule-group .icon-c-add {
    clear: both;
    position: absolute;
    top: -10px;
    right: -21px;
    cursor: pointer;
    color: #033c9d;
}

.rule-group .icon-c-add::before {
    font-size: 15px;
}

.rule-group .icon-bin {
    font-size: 13px;
    top: 0px;
    right: 5px;
    position: absolute;
    cursor: pointer;
    margin-left: 10px;
}

.rule-group .ladiui.input:focus,
.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init:hover,
.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init:focus,
.rule-group .select-assignee .ladiui-select-options:hover,
.rule-group .select-assignee .ladiui-select-options:focus {
    border-color: #ebedf2;
}

.rule-group .select-assignee .ladiui-select-options>.ladiui-select-init {
    text-indent: 0px;
}

.rule-group .rule .rule-item {
    position: relative;
    margin-bottom: 24px;
}

.rule-group .rule .rule-item:last-child {
    margin-bottom: 0;
}

.rule-group .ladiui-search-dropdown {
    /* position: absolute; */
    /* clear: both;
    top: -20px;
    right: -33px; */
    background: #fff;
    min-width: 150px;
}

.rule-group .dropdown-tag .item {
    margin: 0px !important;
}

.rule-group .dropdown-tag .item:hover {
    box-shadow: none;
}

.ladiui-table-responsive {
    overflow-x: inherit;
}

.order-table .tag-color .ladiui-tags .tag {
    padding: inherit;
    position: relative;
    display: flex;
    align-items: center;
    color: #ffffff;
    background: #1853fa;
    border-radius: 2px;
    font-size: 12px;
    transition: all 0.3s ease-out;
    padding: 4px 2px;
    margin: 1px;
    line-height: 1.2;
}

.rule-group .place-holder {
    position: relative;
    cursor: pointer;
    margin-top: 15px;
    margin-right: 20px;
    float: left;
}

.rule-group .item .title label {
    cursor: pointer;
}

.rule-group .item .title {
    position: relative;
    width: 160px;
    cursor: pointer;
    float: left;
    min-height: 40px;
    margin-top: 15px;
}

.rule-group .item .title::after {
    content: '\ea05';
    font-family: 'LadiIcons';
    font-size: 12px;
    display: block;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: -11px;
    cursor: pointer;
    z-index: 2;
}

.rule-group .search-panel {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    width: 331px;
    min-height: 250px;
    position: absolute;
    top: 40px !important;
    padding: 15px;
    z-index: 3;
    background: #fff;
    border-radius: 5px;
    overflow: auto;
}

.rule-group .search-panel .ladiui-search-custom {
    width: 300px;
    border-radius: 3px;
    border: 1px solid #ebedf27a;
}

.rule-group .search-panel .ladiui-search-custom input {
    border-bottom: 0px;
}

.rule-group .search-panel .ladiui-search-custom .menu {
    top: 111% !important;
    box-shadow: none !important;
}

.rule-group .search-panel .ladiui-search-custom::after {
    content: '\ea44';
    font-family: 'LadiIcons';
    font-size: 18px;
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.rule-group .popover {
    float: left;
}

.rule-group .search-panel.utm {
    min-height: 100px;
}

.rule-group .search-panel .add {
    margin-top: 15px;
    float: right;
}

.ladiui.search-keyword {
    width: 400px;
}

.badge {
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 2px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 20px;
    height: 18px;
    /* position: absolute; */
    /* left: 0px;
    bottom: -4px; */
    cursor: pointer;
    /* padding: 0px 8px 0px 18px; */
    padding: 0px 8px;
}

.badge-light {
    color: #fff;
    /* background-color: rgba(243, 83, 83, 1); */
    background-color: var(--active-warning-color);
}

.badge-child-light {
    color: #fff;
    /* background-color: #d94f45; */
    background-color: var(--active-warning-color);
}

/* 
.badge > i {
    position: absolute;
    left: 4px;
    top: 4px;
} */

/* .order-table .duplicate {
    background-color: rgb(248, 250, 251);
} */

.payment-shipping .order-cancel {
    color: #313942 !important;
}

.order-page .more-info i {
    float: left;
    margin-right: 5px;
    cursor: pointer;
}

.order-custom-field .more-info span {
    font-size: 14px;
    /* margin-bottom: 10px; */
    margin-right: 5px;
    margin-left: 0px;
    display: inline-block;
    /* width: 100%; */
}

.order-custom-field .more-info [data-tooltip-position='bottom']:before {
    left: 90px !important;
}

.order-custom-field .more-info [data-tooltip-position='bottom']:after {
    left: 90px !important;
}

#order-custom-field .order-page .more-info span {
    margin-right: 0px !important;
}

.order-page .more-info span h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.order-page .more-info b {
    font-weight: 500;
    margin-right: 1px;
    display: inline-block;
    float: left;
    margin-right: 5px;
}

.DateRangePicker .custom-btn-date-panel {
    padding: 0px 22px 11px 22px;
}

.DateRangePicker .custom-btn-date {
    position: relative;
    height: 100%;
    text-align: center;
    background: none;
    border: 1px solid #0b61f2;
    border-radius: 5px;
    color: #0b61f2;
    padding: 4px 9px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
}

.DateRangePicker .custom-btn-date:active {
    outline: 0;
}

.DateRangePicker .custom-btn-date.selected {
    color: #fff;
    background: var(--main-primary);
}

.more-filter {
    width: 380px;
    right: -380px;
    position: fixed;
    background: #fff;
    z-index: 16;
    height: 100vh;
    top: 0px;
    /* box-shadow: 0 31px 41px 0 rgba(32, 42, 53, .2), 0 2px 16px 0 rgba(32, 42, 54, .08);
     */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    transition: right 1s;
}

.more-filter.show-filter {
    right: 0px;
}

.more-filter.hide-filter {
    right: -380px;
}

.more-filter .heading {
    border-bottom: 1px solid #efefef;
    padding: 20px 15px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.more-filter .main-content {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.more-filter .footer {
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 380px;
    text-align: right;
    padding: 15px 15px;
    clear: both;
    border-top: 1px solid #ebedf2;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.close-more-filter {
    position: absolute;
    top: 12px;
    right: 0;
    font-size: 18px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

.more-filter .title:hover {
    background-image: linear-gradient(rgba(223, 227, 232, 0.3), rgba(223, 227, 232, 0.3));
}

.more-filter .item .title {
    position: relative;
    cursor: pointer;
    padding: 15px 0px 10px 15px;
}

.more-filter .item .title label {
    cursor: pointer;
    font-size: 14px;
}

.more-filter h3 {
    font-size: 18px;
    font-weight: 500;
}

.more-filter .main-content .item .title:focus {
    /* box-shadow: inset 0.2rem 0 0 #5478ec; */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.more-filter .main-content .item .content {
    display: block;
    max-height: 0px;
    margin: 0px 15px 5px 15px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
}

.more-filter .main-content .item .content.full.tag {
    overflow: unset;
}

.more-filter .main-content .item .content.full {
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
}

.more-filter .main-content .item .content ul li {
    font-size: 14px;
    margin: 0px 0px 5px 1px;
}

.more-filter .main-content .item .short-content {
    background-color: #dfe3e8;
    color: #212b36;
    padding: 1px 9px;
    border-radius: 34px;
    font-size: 12px;
    font-weight: 400;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 4px;
}

.more-filter .main-content .item .short-content:last-child {
    margin-right: 0px;
}

.more-filter .main-content .item.collapse {
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
}

.more-filter .main-content>div.item.collapse:last-child {
    border-bottom: none;
}

.more-filter .main-content .item .clear {
    display: block;
    cursor: pointer;
    margin-top: 15px;
    color: #006fbb;
    font-size: 14px;
}

.more-filter .main-content .item .arrow {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 14px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu {
    max-height: 3000x;
}

.no-scroll {
    overflow: hidden;
}

.mt-6 {
    margin-top: 6px;
}

button.loader::before {
    /* margin: 0px auto; */
    /* position: absolute; */
    margin-right: 5px;
    border-top: 2px solid var(--main-line);
    border-right: 2px solid var(--main-line);
    border-bottom: 2px solid var(--main-line);
    border-left: 2px solid var(--main-primary);
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    display: inline-block;
    content: '' !important;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    top: calc(50% - 10px);
    left: 6px;
    z-index: 10;
}

button.loader {
    padding-left: 30px !important;
    opacity: 0.6 !important;
}

.glossary.ladiui.table tbody .header {
    font-weight: 500;
    text-align: center;
}

.glossary.ladiui.table tbody tr {
    border: 1px solid #efefef;
}

.glossary.ladiui.table tbody tr td {
    border-right: 1px solid #efefef;
    padding: 10px;
}

.glossary.ladiui.table tbody tr td:last-child {
    border-right: none;
}

a.link {
    color: #1853fa;
}

.variant-option-item .adjust {
    height: 40px;
    line-height: 40px;
    margin-left: 15px;
}

.variant-option-item .adjust a {
    font-size: 13px;
}

#modal-adjust .swatch-text .ladiui.form-group {
    /* width: 500px;
     */
    display: flex;
    align-items: center;
}

#modal-adjust .visual-swatch .ladiui.form-group {
    width: 500px;
}

#modal-adjust .swatch-text .ladiui.form-group .ladiui.input,
#modal-adjust .visual-swatch .ladiui.form-group .ladiui.input {
    display: inline-block;
    width: 70%;
}

#modal-adjust .swatch-text .ladiui.form-group .ladi-icon,
#modal-adjust .visual-swatch .ladiui.form-group .ladi-icon {
    display: inline-block;
    margin-left: 15px;
}

.swatch-picker {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid #efefef;
    border-radius: 6px;
    margin-right: 15px;
    vertical-align: middle;
    cursor: pointer;
    background-color: #ffff;
    position: relative;
}

.swatch-picker div {
    margin: 4px;
    background-color: #dedede;
    width: 30px !important;
    height: 30px;
    border-radius: 4px;
    position: absolute;
}

.swatch-picker div.image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.swatch-picker div.image img {
    pointer-events: none;
}

.visual-swatch .ladiui-navbar.-horizontal>li {
    float: inherit;
}

.visual-swatch .ladiui-navbar.-horizontal,
.visual-swatch ul.ladiui-navbar.-vertical {
    margin-left: -12px;
}

.popover-picker {
    z-index: 1;
    top: 45px !important;
}

.variant-image {
    cursor: pointer;
}

.order-customer .total-order {
    margin-left: 5px;
    font-size: 12px;
    font-style: italic;
    color: #6c7293;
    font-weight: 500;
}

.group-date {
    clear: both;
}

.group-date .date-item {
    width: 50%;
    display: inline-block;
}

.group-date .date-item:first-child {
    margin-right: 24px;
}

.group-date .date-item:last-child {
    right: 0px;
}

#modal-setting-payment .item {
    margin-bottom: 15px;
    border-radius: 10px;
}

#modal-setting-payment .item:last-child {
    margin-bottom: 0px;
}

#modal-setting-payment .item .shipping-partner-logo {
    height: 25px;
}

#modal-setting-payment .item .shipping-partner-logo.shopee-pay {
    height: 45px !important;
}

#modal-setting-payment .item .description {
    margin-top: 15px;
    padding-bottom: 15px;
    line-height: 22px;
    font-size: 15px;
}

#modal-setting-payment .item .partner-account {
    margin-top: 15px;
}

#modal-setting-payment .item .partner-footer {
    display: flex;
    padding-bottom: 15px;
    justify-content: flex-end;
}

#modal-setting-payment .item .partner-footer .right button {
    margin-left: 10px;
}

#modal-setting-payment .item .partner-footer .left button {
    margin-left: 10px;
}

#tab-checkout .col-33 {
    width: 31.5%;
    margin-left: 0px;
}

.col-33 {
    width: 33%;
    float: left;
    margin: 0px 15px;
}

#tab-checkout .publish div,
#tab-checkout-config .publish div {
    clear: both;
}

#tab-checkout .publish div label.border-bottom,
#tab-checkout-config .publish div label.border-bottom {
    display: inline-block;
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 1px solid rgba(6, 21, 40, 0.1) !important;
    padding: 5px 12px 5px 0px;
    font-weight: 400;
    height: 35px;
    font-style: italic;
}

#tab-checkout .publish div input,
#tab-checkout-config .publish div input {
    display: inline-block;
    width: 21%;
    margin-right: 15px;
    border-radius: 0 !important;
    /* border: 0 !important;
    border-bottom: 1px solid rgba(6, 21, 40, .1) !important; */
}

#tab-checkout .publish div button,
#tab-checkout-config .publish div button {
    display: inline-block;
}

#modal-store .onoffswitch {
    float: left;
    top: -15px;
}

#modal-store .onoffswitch-inner::before {
    content: 'ON';
}

#modal-store .onoffswitch-inner:after {
    background-color: #484848;
    content: 'OFF';
}

/* .checkout-config-item {
    margin-left: 20px;
} */

.checkout-config-item.disabled {
    color: #6c72938f !important;
    text-decoration: line-through;
}

.ladiui-row.product-service {
    clear: both;
}

.ladiui-row.product-service .ladiui.form-group {
    width: 32.1%;
    display: inline-block;
    margin-right: 15px;
}

.ladiui-row.product-service .ladiui.form-group:last-child {
    margin-right: 0px;
}

#autocomplete-price-unit.ladiui-search-custom::after {
    content: '' !important;
}

.package-price-description {
    margin-top: 5px;
    display: block;
    font-size: 13px;
    font-style: italic;
}

.group-store-logo {
    width: 70%;
    display: flex;
    padding-right: 20px;
    margin-bottom: 20px;
}

.group-store-logo .image {
    width: 100%;
    display: flex;
}

.group-store-logo img {
    height: 150px;
}

.group-store-logo img {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1px;
}

.checkout-config .ladiui.table thead {
    border-bottom: none;
}

.checkout-config .ladiui.table tbody tr {
    /* border-bottom: none; */
    border-bottom: 1px solid #e5e5e5;
}

.checkout-config .ladiui.table tbody tr td {
    /* padding: 8px 16px; */
    padding: 12px 0;
}

.checkout-config .ladiui.table tbody tr:last-child td {
    padding-bottom: 16px;
}

.checkout-config .ladiui.table tbody tr:last-child {
    border-bottom: none;
}

.checkout-config .ladiui.table thead tr th {
    padding: 5px 0px 10px 15px;
}

.ladiui.table tbody tr.default {
    background-color: #f8fafb;
}

.panel-setting-checkout.preview,
.panel-setting-checkout.preview .ladiui.table {
    padding: 10px;
    border-radius: 4px;
}

.order-page.order-edit .btn-link-checkout {
    z-index: 5;
}

.order-page .link-checkout {
    margin-left: 33px;
    display: block;
    width: 500px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    left: 15px;
    position: absolute;
}

.payment-shipping .title-payment i.copy {
    margin-left: 5px;
    color: inherit;
    cursor: pointer;
    border: none;
    font-size: 14px;
}

.checkout-page .ladiui-order-right {
    top: 275px;
}

.ladiui-select-options>ul>li.header-item {
    cursor: pointer;
    display: block;
    color: #313942;
    width: 100%;
    padding: 0px 12px;
    line-height: 40px;
}

.ladiui.table .badge.badge-child-light.sold-out,
.ladiui.table .badge.badge-child-light.sold-out {
    left: 0px;
    position: relative;
    display: block;
    margin-top: -3px;
    margin-bottom: -13px;
}

.ladiui.table tr.sold-out,
.ladiui.table tr.sold-out {
    background-color: rgb(248, 250, 251) !important;
}

.ladiui.table tr.sold-out>td:first-child,
.ladiui.table tr.sold-out>td:first-child {
    position: relative;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0px !important;
}

span.buy-information {
    font-weight: 400;
    font-size: 12px;
    margin-left: 5px;
    font-style: italic;
}

.onoffswitch.ticket-seat {
    width: 92px;
}

.onoffswitch.ticket-seat .onoffswitch-inner:before {
    content: 'Đã tham gia';
}

.onoffswitch.ticket-seat .onoffswitch-inner:after {
    content: 'Chưa tham gia';
}

.onoffswitch.ticket-status .onoffswitch-inner:before {
    content: 'Đã bán';
}

.onoffswitch.ticket-status .onoffswitch-inner:after {
    content: 'Chưa bán';
}

.onoffswitch.ticket-seat .onoffswitch-switch {
    right: 73px;
}

.ladiui-select-options.select-option-checkout-config ul {
    max-height: 515px;
    overflow: scroll;
}

.ladi-card.ladiui-table-responsive.checkout-config tr th {
    font-weight: 400;
}

.scope-description {
    white-space: break-spaces;
    display: block;
    font-weight: 400;
    font-style: italic;
    font-size: 11px;
    line-height: 12px;
}

.price-before-discount {
    color: var(--text-secondary);
    text-decoration: line-through;
    display: block;
    font-size: 12px;
    line-height: 16px;
}

/* .ladiui.menu {
    overflow-y: scroll;
    max-height: calc(100vh - 100px);
}

.ladiui.menu::-webkit-scrollbar {
    width: 0px;
} */

.ladiui-toast {
    position: relative;
}

.ladiui-toast .ladi-icon.icon-e-remove {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 18px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

/* .qr-code-publish {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 9000000080;
    background: rgba(0, 0, 0, .5);
} */

/* .qr-code-publish img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: scale-down;
    max-width: 90%;
    max-height: 90%;
} */

#tab-checkout-config .publish div,
#tab-checkout .publish div {
    clear: both;
}

.qr-code-publish,
.qr-code-publish img {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.qr-code-publish {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9000000080;
    background: rgba(0, 0, 0, 0.5);
}

.qr-code-publish img {
    position: absolute;
    object-fit: scale-down;
    max-width: 90%;
    max-height: 90%;
}

/* .panel-publish a {
    width: 78%;
    border-bottom: 1px solid rgba(6, 21, 40, .1) !important;
    padding-bottom: 7px;
} */

.panel-publish .item {
    margin-right: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    /* border-bottom: 1px solid rgba(6, 21, 40, .1) !important; */
    border-radius: 0px;
    box-shadow: none;
}

.panel-publish .group {
    clear: both;
    display: flex;
    /* width: 83%; */
    align-items: center;
}

.panel-publish .group .item.ladiui-select-options>.ladiui-select-init {
    margin-left: 30px;
    text-indent: 5px;
}

.panel-publish .group .item.ladiui-select-options>.ladiui-select-init:after {
    right: inherit;
    left: 10px;
}

.qr-code-publish-close {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 9000000090;
    background: url(https://w.ladicdn.com/v2/source/ladi-icons.svg) hsla(0, 0%, 100%, 0.2) no-repeat;
    background-position: -108px;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    cursor: pointer;
    top: calc(50vh - 155px);
    right: calc(50vw - 155px);
}

#tab-checkout-config .publish div,
#tab-checkout .publish div {
    clear: both;
}

.ladiui-search-custom::after {
    content: '\ea44';
}

/* .ladiui.nav>.menu>li>a {
    font-size: 16px;
} */

.ellipsis {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.ladiui-modal-header h3 {
    display: inline-block;
}

.ladiui-modal-header .modal-hight-light-title {
    color: #1853fa;
    margin-left: 106px;
    font-weight: 500;
}

.prevent-click {
    pointer-events: none;
}

#confirm-order-not-show .ladiui-modal-content.confirm {
    top: 40%;
}

/* #tab-tracking-custom {
    max-height: 395px;
    overflow: auto;
} */

#tab-tracking-custom .tracking-custom-item {
    margin-bottom: 15px;
}

.tr-disable {
    background-color: #f8fafb;
}

/* .detail-checkout-config {
    margin-left: 20px;
    position: relative;
    top: -15px;
} */

#tab-checkout {
    margin-left: 5px;
}

.ladi-card.ladiui-table-responsive.form-group.checkout-config {
    border-radius: 4px;
    font-size: 14px;
    color: #6c7293;
    background-color: #fff;
    min-width: 250px;
}

.ladi-card.ladiui-table-responsive.form-group.checkout-config>.ladiui.table {
    box-shadow: none;
}

#autocomplete-variant .badge.sold-out {
    left: 0px;
}

#tab-product .ladiui-select-page-checkout {
    display: block;
    width: 30%;
    margin-bottom: 10px;
}

#tab-product .ladiui-select-page-checkout .ladiui-select-options {
    border-radius: 0px !important;
}

#autocomplete-product-page-checkout {
    display: block;
    float: left;
    width: 100%;
}

#autocomplete-product-page-checkout .badge {
    left: 1px;
}

.form-config-no-account {
    line-height: 20px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-weight: 100;
    margin-bottom: 15px;
}

.text-toggle-input-type {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    position: relative;
    right: 5px;
    top: -31px;
    /* border-radius: 2px; */
    float: right;
    /* font-size: 12px; */
    /* background: #f1f1f1; */
    padding: 1px 5px;
    /* color: #353535; */
}

.mapping-fields .btn-add-custom-fields {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.mapping-fields tr {
    border-bottom: none !important;
}

.mapping-fields tr td:first-child {
    padding-left: 0px !important;
}

.mapping-fields tr td:last-child {
    padding-right: 0px !important;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.button-link {
    color: #0c61f2;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.a-link {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.m-bt-10 {
    margin-bottom: 10px;
}

.label-custom-fields {
    font-weight: 400;
    font-size: 14px;
}

.form-config-account.google-sheet .ladiui-search-dropdown::after {
    content: '\ea44';
    font-size: 15px;
}

.form-config-account.google-sheet .google-sheet-sheet {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-config-account.google-sheet .google-sheet-sheet .ladiui.dropdown {
    width: 80% !important;
}

.form-config-account.google-sheet .google-sheet-sheet .ladiui-search-dropdown {
    flex: 1 1;
    margin-right: 15px;
}

/* #modal-tag-edit .ladiui.search-keyword {
    width: 195px;
    position: absolute;
    right: 27px;
    z-index: 1;
} */

/* #modal-tag-edit .ladiui.search-keyword input#keyword {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    box-shadow: none;
    border-radius: initial;
    padding-top: 15px;
} */

#modal-tag-edit .ladiui.search-keyword i {
    padding-top: 11px;
}

#modal-tag-edit table tr:hover i {
    display: inline-block;
    transition: display 5s ease-out;
}

#modal-tag-edit table tr i {
    display: none;
    margin-top: 4px;
    transform: rotate(-45deg);
}

.order_payment_transaction {
    background-color: #fffcf6;
    border: 1px solid #f6eee0;
    border-radius: 4px;
    padding: 5px;
    margin-left: 33px;
    max-width: 400px;
    font-style: italic;
}

.order_payment_transaction span {
    display: block;
    text-transform: inherit;
    line-height: 22px;
}

.order_payment_transaction .order_transaction_id {
    text-transform: uppercase !important;
}

.ladiui.form-group .ladiui-checkout-payment-body .ladiui-checkout-payment-header-show-2 {
    margin-bottom: 16px;
}

.ladiui.form-group .ladiui-label.payment-method-title {
    margin-bottom: 4px;
}

.client-order-code [data-tooltip]::before {
    width: 300px !important;
    text-align: left;
}

.pc_description .ladiui-label,
.mb_description .ladiui-label {
    display: flex;
    align-items: center;
}

.pc_description .ladiui-label>i,
.mb_description .ladiui-label>i {
    font-size: 15px;
    color: #333;
    margin-right: 8px;
}

.form-group .builder-modal-input-label {
    position: relative;
}

.form-group .builder-modal-input-label label {
    position: absolute;
    top: -8px;
    left: 10px;
    padding: 0 5px;
    background: #fff;
    z-index: 1;
    font-size: 13px;
    font-weight: 500;
}

.form-group .builder-modal-input-label input {
    font-size: 13px;
    padding: 24px 12px;
}

.form-group.input-image {
    display: inline-block;
    clear: both;
}

.form-group .builder-modal-input-label.show-link-img {
    width: 80%;
    float: left;
}

.ladiui-button-upload {
    position: relative;
    width: 82px;
    height: 82px;
    background: #f1f3f4;
    border-radius: 4px;
}

.ladiui-button-upload button {
    margin: 0px auto !important;
    cursor: pointer;
}

.ladiui-button-upload button .icon-data-upload {
    padding-right: 5px;
}

.ladiui-button-upload.link-img {
    margin-top: 0px;
    float: right;
    border-radius: 5px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

.ladiui-button-upload.link-img button {
    background-color: #fbfafa;
}

/* .block-change-language {
    max-width: 250px;
} */

.order-cancel .reference-no a {
    color: rgba(108, 114, 147, 0.56) !important;

    text-decoration: line-through;
}

.code-ticket-canceled {
    text-decoration: line-through;
}

#modal-setting-custom-field .ladiui-modal-body .pull-right {
    display: block;
    width: 100%;
    float: right;
    text-align: right;
}

.is-Show-Advanced {
    display: block;
    clear: both;
    margin-bottom: 20px;
}

.is-Show-Advanced.product {
    margin-top: 10px;
}

#modal-setting-custom-field .ladiui-modal-body .block-search .input-search {
    display: inline;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

#modal-setting-custom-field .ladiui-modal-body .block-search label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

#modal-setting-custom-field .ladiui-modal-body .block-search button {
    background-color: #0341ac;
    color: #fff;
    padding: 10px;
    cursor: pointer;
}

#modal-setting-web-hook .ladiui-modal-footer {
    text-align: left;
    font-style: italic;
    font-size: 13px;
}

#order-custom-field {
    background-color: #fff;
    /* padding: 10px; */
    position: relative;
    /* margin-bottom: 20px; */
    border-radius: 5px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

#order-custom-field .title {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.btn-custom-field-add {
    float: right;
}

#modal-setting-custom-field .block-search {
    clear: both;
}

.customer-info.customer-edit-custom-field {
    text-align: right;
    text-align: right;
    position: absolute;
    right: 33px;
    top: 10px;
}

.customer-info.customer-edit-custom-field a {
    margin: 0px 5px;
}

.customer-info.customer-edit-custom-field a:last-child {
    margin-right: 0px;
}

.ladiui.form-group .custom-field-content {
    /* max-height: 320px;
    overflow: scroll; */
    /* padding: 0px 5px; */
    overflow-x: hidden;
    width: 100%;
}

.ladiui.form-group .custom-field-content.custom {
    max-height: none;
    overflow: none;
}

.ladiui.form-group .hidden-custom-field {
    position: absolute;
    top: 10px;
    right: 10px;
}

.hidden-custom-field .icon-down-arrow,
.order-customer-edit .icon-e-remove {
    font-size: 12px;
    color: #585f67;
}

.show-error-text {
    color: #ff0000;
    font-size: 13px;
    margin-top: 8px;
    display: block;
}

.order-customer-edit-btn {
    top: 5px !important;
}

.panel-setting-checkout .config-payments table tr td {
    position: relative;
}

.panel-setting-checkout .config-payments table tr td .icon-onpin {
    transform: rotate(-45deg);
    transition: ease-in-out;
}

.panel-setting-checkout .config-payments table tr:hover .icon-onpin {
    display: inline !important;
    box-sizing: border-box;
}

#modal-checkout-config .child-content {
    padding-left: 20px;
}

#modal-checkout-config .child-content div {
    padding-bottom: 12px;
}

#modal-checkout-config .child-content div:first-child {
    padding-top: 12px !important;
}

#modal-checkout-config .child-content div:last-child {
    padding-bottom: 0;
}

#modal-checkout-config .child-content p .icon-move-2 {
    float: right;
    display: none;
}

#modal-checkout-config .child-content p:hover .icon-move-2 {
    display: block;
}

.panel-setting-checkout .config-payments .table.list-active-payment-method {
    display: block;
}

#modal-setting-web-hook .ladi-card.account-table .ladiui.table .show-name {
    max-width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#modal-form-account .connect {
    position: relative;
    line-height: 40px;
}

#modal-form-account .connect .button-connect {
    margin: 0px 15px;
}

.input-padding-left {
    padding: 7px 28px !important;
}

.input-padding-left.pl-10 {
    padding-left: 10px !important;
}

.page-content {
    max-width: 1240px;
    margin: 0px auto;
    padding: 24px;
    overflow: auto;
    min-height: calc(100vh - 100px);
    /* min-width: 1000px */
}

.page-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.align-item-baseline {
    align-items: baseline !important;
}

.overflow-hide {
    overflow: hidden !important;
}

.icon-add-border {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: solid 0.5px var(--main-primary);
    padding: 2px;
    margin-right: 5px;
}

/* .flex-center {
    display: flex !important;
    align-items: center !important;
} */

.flex-center-left {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

a {
    cursor: pointer;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-top-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ladiui.modal.show {
    display: block !important;
}

.ladiui.modal-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #3e526a;
}

.text-end {
    text-align: right !important;
}

.ladiui.modal-content .description-header {
    padding: 24px 24px 0px !important;
}

.ladiui.modal-content .description-header p {
    font-weight: 400;
    font-size: 14px;
    color: #6e7d8f;
    line-height: 20px;
}

.ladiui.modal-content .block-account-type {
    padding: 24px 24px 0px !important;
    display: flex;
    align-items: center;
    color: var(--main-default);
}

.ladiui.modal-content .block-account-type strong {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.modal-content .block-account-type span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.custom-info .order-customer .order-heading {
    padding: 10px 24px;
    background-color: #f1f3f4;
}

.custom-info .order-customer .customer-info {
    margin: 24px;
}

.info-customer .block-shipping-address {
    padding: 24px !important;
}

.order-customer .order-heading.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-custom-field.custom.bottom {
    padding: 24px !important;
    margin-bottom: 24px;
}

.custom-info .order-customer .customer-info .info span {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.custom-info .order-customer .customer-info .info span img {
    margin-right: 8px;
}

.custom-info .order-customer .customer-info .info span:last-child {
    margin-bottom: 0px !important;
}

.custom-info .order-customer .customer-info {
    /* display: flex;
    justify-content: space-between; */
}

.create-order-field-right .order-custom-field {
    padding: 10px 24px !important;
}

.custom-info .order-customer .customer-info.draft-order {
    display: block !important;
}

.custom-dropdown-staff {
    min-width: auto !important;
    width: auto !important;
    padding-right: 30px !important;
}

.dropdown-tag-custom:hover {
    box-shadow: none !important;
}

.ladiui.btn.btn-outline-light.ellipsis {
    width: auto;
    padding-right: 30px;
}

.ladiui-tag-list .tag-product-item {
    background-color: #f1f3f4;
    padding: 2px 8px;
    border-radius: 6px;
    margin-right: 5px;
}

.info-customer.info-customer-shipping {
    padding: 24px !important;
}

.info-customer-shipping .shipping-info-customer {
    padding-top: 24px !important;
}

.info-customer-shipping .shipping-info-customer .order-customer .order-heading {
    padding-bottom: 24px !important;
}

.info-customer-shipping .shipping-info-customer .order-customer .customer-info {
    padding-bottom: 24px;
}

.info-customer-shipping .shipping-info-customer .order-customer .order-heading.create-customer-shipping {
    padding-top: 24px !important;
}

.info-customer-shipping .shipping-info-customer .shipping-address .customer-info {
    padding-bottom: 0px !important;
}

.ladiui.status-premium {
    width: unset !important;
}

.ladiui.menu-item .ladiui.dropdown-toggle {
    position: inherit !important;
}

#modal-billing-address .action-item .ladiui.btn-group .ladiui.dropdown-toggle:after {
    top: -3px !important;
}

.ladiui.block-item-district {
    max-height: 350px;
    overflow: auto;
    border: 1px solid #f1f3f4;
    padding: 16px 0px 16px 16px;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.ladiui-product-right.event-custom .group-date .date-item {
    width: 50% !important;
}

.package-content-info ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
    border-bottom: 1px solid #E7E9EB;
}

.package-content-info ul li:first-child {
    padding-top: 0px;
}

.package-content-info ul li.package-item span:first-child {
    color: #9FA7B1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ladiui.btn.btn-primary[disabled] {
    opacity: 0.65 !important;
    background-color: var(--main-primary) !important;
}

.ladiui.btn.btn-outline-primary[disabled] {
    opacity: 0.65 !important;
    border: 1px solid var(--main-primary) !important;
}

.ladiui.btn.btn-outline-light[disabled] {
    opacity: 0.65 !important;
    border: 1px solid var(--main-primary) !important;
    color: var(--main-primary);
}

.block-adjustBatch .list .item {
    padding: 10px;
    border-bottom: 1px solid #f1f3f4;
}

.block-adjustBatch .list .item:last-child {
    border-bottom: unset;
}

.block-help-ladisales-top li a i {
    width: 14px !important;
}

.ladiui.block-avatar.custom {
    margin-right: 12px;
}

.ladiui.show-avata-name {
    border: 2px solid var(--main-primary);
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.ladiui.show-text-avata {
    align-items: center;
    color: var(--main-white);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    justify-content: center;
    background-color: #0b61f2;
    border-radius: 50%;
}

.ladiui.role-text {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 16px;
}

.ladiui.show-avata-name.custom {
    border: none !important;
}

.block-content-product-tag .list .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px 12px 0px;
}

.block-content-product-tag .list .list-item:first-child {
    padding-top: 0px;
}

.block-content-product-tag .list {
    max-height: 350px;
    overflow-y: auto;
}

.ladiui.account-body {
    max-height: 200px;
    overflow: auto;
}

#tab-terms-of-use .form-group .ladiui-label {
    color: var(--main-secondary);
}

#tab-privacy-policy .form-group .ladiui-label {
    color: var(--main-secondary);
}

#tab-refund-policy .form-group .ladiui-label {
    color: var(--main-secondary);
}

.action-record-table {
    position: absolute;
    top: 10px;
    z-index: 10;
    left: 64px;
}

.action-record-table .ladiui.dropdown-toggle::after {
    top: -4px;
}

.table-custom thead th {
    padding: 12px 18px !important;
}

.table-custom tbody td {
    padding: 12px 18px !important;
}

.ladiui.form-check-input-checkbox.vertical-middle {
    vertical-align: middle;
}

.ladiui-tag-list .tag .close-tag-icon {
    display: flex;
    cursor: pointer;
}

.ladiui.table-vertical.main {
    border-bottom: 1px solid var(--secondary-color);
}

.order-product-payment .ladiui.table-vertical.main:last-child {
    border-bottom: none;
}

.ladiui.table-custom .table-vertical.header th {
    white-space: nowrap;
}

.ladiui.table-vertical.main:last-child td {
    padding-bottom: 24px !important;
}

.table-custom thead tr th:first-child {
    padding-left: 0px !important;
}

.table-custom tbody tr td:first-child {
    padding-left: 0px !important;
}

.icon-close-no-bg-16 {
    background-color: var(--status-mute-color);
    width: 8px;
    height: 8px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg) no-repeat 50% 50%;
}

.ladiui-btn-dropdown.dropdown-toggle .ladiui.icon.icon-ldp-dot {
    vertical-align: middle;
}

.ladi-icon.icon-bin {
    /* background-color: var(--status-mute-color); */
    background-color: #d94f45;
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg) no-repeat 50% 50%;
}

.ladi-icon.icon-bin.red-color {
    background-color: #d94f45 !important;
}

.ladi-icon.icon-qrcode {
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-qrcode.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-qrcode.svg) no-repeat 50% 50%;
}

.ladi-icon.icon-copy {
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-coppy.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-coppy.svg) no-repeat 50% 50%;
}

.icon-calendar-date-2 {
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-calendar.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-calendar.svg) no-repeat 50% 50%;
}

.icon-tag-footer-modal {
    background-color: #1c00c2;
    width: 16px;
    height: 16px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-tag.svg) no-repeat 50% 50%;
}

.list-image-product .list-image .item-image:hover .ladi-icon.icon-bin {
    background-color: var(--main-white);
}

.ladiui.table-vertical.custom-tag td {
    padding-top: 0px !important;
}

.tag-color .ladiui-tags {
    padding: 0px !important;
    min-height: auto !important;
}

.email-address-store-custom {
    overflow-wrap: anywhere;
}

#block-dropdown-tag-custom .item:first-child {
    padding-top: 10px;
}

.url-checkout .ladiui.btn-group .dropdown button span {
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0 !important;
}

.checkout-url-custom .more-info .item-tracking .show-content {
    width: 100%;
    max-width: 200px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.block-content-product-tag .list .list-item .image {
    position: relative;
}

.block-content-product-tag .list .list-item .image .badge-child-light {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: -8px;
    right: initial;
    left: 63px;
}

.ladiui.my-account-avata .show-avt-name {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    border-radius: 50%;
    background-color: #0b61f2;
}

.ladiui.account-avatar.block-info .show-avt-name {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    border-radius: 50%;
    background-color: #0b61f2;
}

.block-shipping-address.checkout .order-customer {
    padding-bottom: 0px !important;
}

.ladiui.account-header-text .account-email.subtitle-2 {
    overflow-wrap: anywhere !important;
}

.block-product-content-left .list-image-product .image-select {
    position: relative;
}

.block-product-content-left .list-image-product .image-select .block-delete-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 8px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 8px;
}

.block-product-content-left .list-image-product .image-select:hover .block-delete-img {
    display: block;
}



.block-reup-img .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}


.block-product-content-left .list-image-product .image-select:hover .ladi-icon.icon-bin {
    background-color: var(--main-white);
}

.ladiui.modal-body {
    max-height: calc(100vh - 270px);
    overflow: auto;
    margin-bottom: unset !important;
    padding: 20px 24px !important;
}

#modal-event .input-upload-file {
    display: block;
    margin-bottom: 24px;
    clear: both;
}

/* -----------------Icon CTA---------------------- */
.cta-add {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-calendar {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-calendar.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-calendar.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-cart {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-shopping-cart.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-shopping-cart.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-add-member {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add-member.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add-member.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-download {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-import.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-import.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.cta-discount-coupon {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.ldicon-subcategory {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-subcategori.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-subcategori.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.ldicon-discount-coupon {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-discount-coupon.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-buying-time {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-buying-time.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-buying-time.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.cta-tag {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-tag.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-tag.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
}

.icon-export {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-export.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-export.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: block;
}

#modal-create-version-digital .block-upload-file {
    padding: 16px;
    border: 1px solid #eee;
    border-radius: 2px;
    margin-bottom: 24px;
}

#modal-create-version-digital .block-upload-file .ladiui-button-upload {
    display: flex;
    cursor: pointer;
    margin-left: 24px;
}

#modal-create-version-digital .block-upload-file .ladiui-button-upload button {
    padding: 4px;
    margin: 4px !important;
    display: flex;
    width: 80px;
    justify-content: center;
    background: transparent;
    border: 1px solid var(--main-primary);
    color: var(--main-primary);
    cursor: pointer;
}

#modal-create-version-digital .block-upload-file .line-bar {
    border-bottom: 1px solid #eee;
    margin: 24px 0;
    position: relative;
}

#modal-create-version-digital .block-upload-file .line-bar span {
    position: absolute;
    top: -9px;
    left: 0;
    padding-right: 16px;
    background-color: #fff;
}

#modal-create-version-digital .block-selected-files {
    border: 1px solid #eee;
}

#modal-create-version-digital .block-selected-files .title {
    padding: 12px 16px;
    background-color: #eee;
}

#modal-create-version-digital .block-selected-files .list-files {
    padding: 16px;
}

#modal-create-version-digital .block-selected-files .list-files ul .file-item {
    margin-top: 16px;
}

#modal-create-version-digital .block-selected-files .list-files ul .file-item:first-child {
    margin-top: 0px;
}

#modal-create-version-digital .request-format-file {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
}

input[type='file'] {
    cursor: pointer;
}

#modal-create-version-digital .input-upload-file .limit-text-upload {
    padding-right: 100px;
    display: block;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}

#modal-create-version-digital .input-upload-file .limit-text-link {
    display: block;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}

.block-seo-social .input-upload-file .ladiui-button-upload {
    background-color: transparent !important;
}

.ghn-form .block-required-note .item:last-child {
    margin-bottom: 0px;
}

.ldicon-chevron-left {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-left.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-left.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-forward {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-forward.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-forward.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-backward {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-backward.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-backward.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-link {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-delete {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-delete.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-delete.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-save {
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-save.svg);
            mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-save.svg);
    background-color: var(--main-default);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.ldicon-image {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-image.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-image.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-menu {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-menu.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-menu.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-user {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-user.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-user.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.ldicon-circle-check {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}



.ldicon-add-circle {
    margin-right: unset;
    pointer-events: none;
    background-color: #1c00c2;
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg) no-repeat 50% 50%;
}


#modal-tag-edit .ladiui .modal-content {
    animation: none !important;
}

.ladiui .dropdown-input-search-custom {
    position: -webkit-sticky;
    position: sticky;
    padding: 16px 16px 8px 16px;
    top: -10px;
    z-index: 999999;
    margin: 0;
    background-color: #fff;
    border-bottom: 1px solid #eee;
}

.ladiui .dropdown-input-search-custom input {
    padding-left: 40px !important;
    /* background-color: #eee; */
}

.ldicon-search {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-search.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-search.svg);
    background-color: var(--main-default);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ladiui .dropdown-input-search-custom i {
    position: absolute;
    bottom: 18px;
    left: 30px;
}

.icon-page-checkout-ladisales {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-brand-ladisales.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-brand-ladisales.svg);
    background-color: var(--main-default);
    width: 28px;
    height: 28px;
    display: inline-block;
}

.icon-other-page-checkout {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-integration.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-integration.svg);
    background-color: var(--main-default);
    width: 28px;
    height: 28px;
    display: inline-block;
}

.ladiui .block-new-page-checkout li .title-description h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui .block-new-page-checkout li .title-description span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
}

#modal-info-ticket-seat .ladiui.form-group .ladiui-label {
    width: 30%;
    float: left;
}

#modal-info-ticket-seat .ladiui.form-group .status-checkin-ticket {
    padding: 2px 8px;
    border-radius: 2px;
    color: #fff;
}

.ladiui.modal {
    overflow-x: auto !important;
}

.sdk-script-content {
    height: 70px;
    background-color: var(--main-line);
    margin-bottom: 20px;
    width: 100%;
    border-radius: 5px;
    padding: 13px;
    font-size: 13px;
    color: var(--main-default);
}

#modal-discount .block-preview-discount {
    padding: 32px;
    border: 1px solid #eee;
    border-radius: 2px;
}

#modal-discount .block-preview-discount h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    color: var(--main-black);
}

#modal-discount .block-preview-discount .block-overview .block-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
}

#modal-discount .block-preview-discount .block-overview .block-item label {
    padding-bottom: 0px !important;
    max-width: 130px;
    width: 100%;
    line-height: 24px;
}

#modal-discount .block-preview-discount .line-bar {
    border-bottom: 1px solid #eee;
    margin: 12px 0;
}

#modal-discount .promotion-conditions-apply {
    display: inline-flex;
    margin-top: 24px;
    width: 100%;
    justify-content: space-between;
}

#modal-discount .promotion-conditions-apply .item-condition {
    width: 100%;
    max-width: 250px;
}

.page-checkin-content {
    background-color: #e5e5e5;
}

.page-checkin-content .block-header-checkin {
    background-color: #fff;
    padding: 10px;
}

.page-checkin-content .block-header-checkin .content-header {
    width: 980px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    padding: 0px 24px;
}

.page-checkin-content .block-header-checkin .qr-code {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.page-checkin-content .block-header-checkin .qr-code img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.page-checkin-content .block-guest-list .content {
    width: 980px;
    margin: 0px auto;
}

.page-checkin-content .block-guest-list .content h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin: 30px 0px;
    line-height: 28px;
    padding: 0px 24px;
    color: var(--main-black);
}

.page-checkin-content .block-guest-list .content .list-guest {
    background-color: #fff;
    padding: 24px;
}

.page-checkin-content .block-guest-list .content .list-guest .title {
    color: var(--main-secondary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: block;
    margin-bottom: 24px;
}

.page-checkin-content .block-guest-list .content .list-guest .list {
    display: block;
    width: 100%;
}

.page-checkin-content .block-guest-list .content .list-guest .list .list-guest-item {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.page-checkin-content .block-guest-list .content .list-guest .list .list-guest-item:first-child {
    padding-top: 0px !important;
}

.list-guest .list .list-guest-item .block-icon-check {
    display: flex;
    margin-right: 24px;
    align-items: center;
    justify-content: center;
}

.list-guest .list .list-guest-item .block-icon-check .icon-circle-check {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
    background-color: var(--main-secondary);
    width: 35px;
    height: 35px;
    display: inline-block;
}

.list-guest .list .list-guest-item .block-icon-check .icon-circle-check.checked-in {
    background-color: #41b983;
}

.list-guest .list .list-guest-item .info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.list-guest .list .list-guest-item .info .info-item {
    display: block;
    width: 100%;
    margin-bottom: 8px;
}

.block-guest-list .powered-by .ladi-icon.icon-ladisales {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-brand-ladisales.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-brand-ladisales.svg);
    background-color: var(--main-default);
    width: 20px;
    height: 20px;
    display: inline-block;
}

.block-guest-list .powered-by span {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.page-checkin-info {
    max-width: 375px;
    padding: 16px;
    width: 100%;
    margin: 0px auto;
    background-color: #e5e5e5;
}

.list-guest .list .list-guest-item .info .block-icon-check {
    display: none;
}

.list-guest .list .list-guest-item .info .block-group-btn-checkin {
    display: block;
}

.list-guest .list .list-guest-item .block-group-btn-checkin {
    display: none;
}

@media screen and (max-width: 768px) {
    .page-checkin-content .block-guest-list .content {
        width: auto;
    }

    .page-checkin-content .block-header-checkin .content-header {
        width: auto;
    }

    .page-checkin-info {
        max-width: 100%;
    }

    .list-guest .list .list-guest-item .block-icon-check {
        display: none;
    }

    .list-guest .list .list-guest-item .info .block-icon-check {
        display: block;
        margin-right: 0px;
    }

    .list-guest .list .list-guest-item .info .block-group-btn-checkin {
        display: none;
    }

    .list-guest .list .list-guest-item {
        flex-direction: column;
    }

    .list-guest .list .list-guest-item .block-group-btn-checkin {
        display: block;
        width: 100%;
        margin-top: 16px;
    }

    .list-guest .list .list-guest-item .block-group-btn-checkin .btn-checkin button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .list-guest .list .list-guest-item .block-group-btn-checkin .group-btn-checkin {
        justify-content: space-between;
    }

    .page-checkin-content .block-guest-list .content .list-guest .pagination-checkin {
        flex-direction: column-reverse;
    }

    .page-checkin-content .block-guest-list .content .list-guest .pagination-checkin .ladiui.pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }

    .page-checkin-content .block-guest-list .content .list-guest .ladiui.search-action {
        align-items: unset;
    }
}

.page-checkin-info .block-actions-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-checkin-info .block-actions-top button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
}

.page-checkin-info .block-info-checkin {
    margin-top: 16px;
    background-color: #fff;
    padding: 16px;
    border-radius: 2px;
}

.page-checkin-info .block-info-checkin .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--main-black);
    text-align: center;
}

.page-checkin-info .block-info-checkin .info {
    display: block;
    width: 100%;
}

.page-checkin-info .block-info-checkin .info .item {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    font-size: 14px;
    color: var(--main-secondary);
}

.page-checkin-info .block-info-checkin .info .item:first-child {
    margin-top: 16px;
}

.page-checkin-info .block-info-checkin .info .item strong {
    font-size: 14px;
    font-weight: 500;
    color: var(--main-black);
}

.page-checkin-info .block-info-checkin .info .item .status {
    padding: 2px 8px;
    border-radius: 2px;
}

.page-checkin-info .block-info-checkin .info .item .status.checkin {
    background-color: #e89d35;
    color: #fff;
}

.page-checkin-info .block-info-checkin .info .item .status.checked-in {
    background-color: #41b983;
    color: #fff;
}

.page-checkin-info .block-info-checkin .btn-checkin {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.page-checkin-info .block-history-checkin {
    margin-top: 24px;
    background-color: #fff;
    padding: 16px;
    border-radius: 2px;
}

.page-checkin-info .block-history-checkin .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--main-black);
    text-align: center;
}

.page-checkin-info .block-history-checkin .list-history {
    display: block;
    max-height: 200px;
    overflow: auto;
}

.page-checkin-info .block-history-checkin .list-history .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.page-checkin-info .powered-by span {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
}

.page-checkin-info .block-info-checkin .actions-checkin-cancel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}

.page-checkin-info .block-info-checkin .actions-checkin-cancel .cancel-checkin {
    color: #d94f45;
    cursor: pointer;
}

#block-custom-btn-checkout {
    display: block;
    max-width: 900px;
}

#block-custom-btn-checkout .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.block-custom-html-selector {
    display: block;
    max-width: 900px;
}

.block-custom-html-selector .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

#block-content-product-course .list-chapters {
    display: block;
    overflow-y: auto;
    max-height: 100vh;
}

#block-content-product-course .list-chapters .chapter-list-item {
    border: 1px solid var(--main-line);
    border-radius: 2px;
    margin-bottom: 24px;
}

#block-content-product-course .list-chapters .chapter-list-item .title-chapter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: var(--main-line);
}

#block-content-product-course .list-chapters .chapter-list-item .title-chapter .icon-edit-chapter {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-edit.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-edit.svg);
    background-color: var(--main-default);
    width: 16px;
    height: 16px;
    display: none;
    cursor: pointer;
}

#block-content-product-course .list-chapters .chapter-list-item .title-chapter:hover .icon-edit-chapter {
    display: inline-block;
}

#block-content-product-course .list-chapters .chapter-list-item .add-lesson .btn-add-lesson {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    font-weight: 500;
    font-size: 14px;
    color: var(--main-secondary);
    cursor: pointer;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson {
    display: block;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 24px;
    justify-content: space-between;
    border-bottom: 1px solid var(--main-line);
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item.selected {
    color: #1c00c2;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item.selected .ldicon-lesson-post {
    background-color: #1c00c2;
}

#block-content-product-course .list-chapters .chapter-list-item .list-lesson .lesson-item.selected .ldicon-lesson-video {
    background-color: #1c00c2;
}

#block-content-product-course .ladiui-product-course-right .choose-content {
    padding: 50px 0px;
    border-radius: 2px;
    border: 1px solid #eee;
    margin-top: 24px;
}

#block-content-product-course .ladiui-product-course-right .choose-content .title {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: var(--main-secondary);
}

.ladiui-product-course-right .choose-content .list-box-content .box-content {
    width: 100px;
    height: 100px;
    border: 1px solid var(--main-line);
    box-shadow: 0px 1px 4px rgba(186, 186, 186, 0.3);
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ladiui-product-course-right .choose-content .list-box-content .box-content:hover {
    border: 1px solid var(--main-primary);
}

.ladiui-product-course-right .choose-content .list-box-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

/* .ladiui-product-course-right .block-add-content .choose-content .list-box-content .box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */
.ladiui-product-course-right .choose-content .list-box-content .box-content .box-name {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--main-secondary);
}

.ladiui-sub-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: var(--main-secondary);
    margin-bottom: 8px;
    display: block;
}

#block-content-product-course .ladiui-product-course-right .block-content-video {
    display: block;
}

#block-content-product-course .ladiui-product-course-right .block-content-video .show-link-video {
    padding: 8px 12px;
    background-color: #e5e5e5;
    overflow: hidden;
}

#block-content-product-course .ladiui-product-course-right .block-content-video .show-link-video span {
    display: block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 8px;
}

#tabProductCourseEdit .ladiui-product-course-right .thumbnail {
    width: 253px;
    height: 100%;
    max-height: 180px;
    border-radius: 2px;
    position: relative;
}

#tabProductCourseEdit .ladiui-product-course-right .block-content-video .group-input {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 277px);
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail {
    width: 100%;
    height: 316px;
    border-radius: 2px;
    position: relative;
    background-color: #f1f3f4;
}

#tabProductCourseEdit .ladiui-product-course-left .ls-custom-thumnail {
    width: 100%;
    height: auto;
    aspect-ratio: 600 / 800;
    border-radius: 4px;
}

#tabProductCourseEdit .ladiui-product-course-right .image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

#tabProductCourseEdit .ladiui-product-course-left .image {
    width: 100%;
    height: 100%;
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 4px;
}

#tabProductCourseEdit .thumbnail .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay {
    margin-top: 60px;
    text-align: center;
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail:hover .block-upload-image .block-overlay {
    /* margin-top: 130px; */
    margin-top: unset;
    margin: 50% auto;
    text-align: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-stack {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-stack.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-stack.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-file {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    background-color: var(--main-secondary);
    width: 12px;
    height: 12px;
    display: inline-block;
}

.block-list-path {
    padding: 16px 0px;
    border-top: 1px solid #f1f3f4;
    margin-top: 20px;
    padding-bottom: 0;
}

.block-list-path .path-item {
    margin-bottom: 12px;
}

.block-list-path .path-item .path-name {
    margin: 0;
    max-width: 100%;
    line-height: 22px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ldicon-lesson-post {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-lesson-video {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-video.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-video.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#modal-create-category .block-upload-image-category {
    display: block;
    /* width: 100%; */
    width: 220px;
    height: 180px;
    background-color: var(--main-line);
    border-radius: 2px;
    cursor: pointer;
    position: relative;
}

#modal-create-category .block-upload-image-category .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
}

#modal-create-category .block-upload-image-category .image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

#modal-create-category .block-upload-image-category:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

#modal-create-category .block-upload-image-category:hover .block-upload-image .block-overlay {
    margin-top: 45px;
    text-align: center;
}

#modal-create-category .block-upload-image-category:hover .block-upload-image .block-overlay .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#modal-create-category .ladiui.modal-content {
    animation: none !important;
    border-radius: 4px;
}

.setting-content-menu-banner {
    max-width: 900px;
    height: 100vh;
    background: #fff;
}

.setting-content-menu-banner .block-category-top-menu h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.setting-content-menu-banner .block-category-top-menu .sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4d4d4d;
    padding-top: 4px;
    padding-bottom: 24px;
    display: block;
}

.config-panel .panel {
    width: 100%;
    margin-bottom: 24px;
    display: block;
}

.config-panel .panel .panel-line {
    display: flex;
    margin: 8px 0;
}

.config-panel .panel .panel-line .main-description {
    color: var(--main-black);
    width: 250px;
    padding-top: 10px;
    font-weight: 500;
}

.config-panel .panel .panel-line .content {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}

.config-panel .panel .panel-line .content .color-picker {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
}

.config-panel .panel .panel-line .main-description .sub-description {
    line-height: 16px;
    margin-top: 6px;
    color: var(--main-mute);
    font-size: 12px;
    font-weight: 400;
}

.setting-content-menu-banner .block-category-show-home {
    margin-top: 24px;
}

.block-category-show-home .btn-add-category button {
    font-weight: 500;
    font-size: 14px;
}

.block-category-show-home .block-list-category {
    display: block;
}

.block-list-category .category-list-item {
    border: 1px solid #eee;
    margin-bottom: 16px;
}

.block-list-category .category-list-item .block-add-featured-course {
    padding: 0px 24px 16px 24px;
}

.block-list-category .category-list-item .content-top-item {
    display: flex;
    padding: 16px 12px;
    align-items: center;
    justify-content: space-between;
    background-color: #eee;
}

.block-list-category .category-list-item .content-top-item .icon-name span {
    display: -webkit-box;
    width: 450px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.block-list-category .category-list-item .content-top-item .ladi-icon.icon-bin {
    background-color: #d94f45;
}

.block-list-category .category-list-item .content-top-item .action-right {
    display: flex;
    align-items: center;
    grid-column-gap: 24px;
    column-gap: 24px;
    justify-content: space-between;
}

.setting-content-menu-banner .block-custom-interface {
    margin-top: 32px;
}

.setting-content-menu-banner .block-custom-interface .title h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.setting-content-menu-banner .block-custom-interface .title .sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-top: 4px;
    color: #6d6d6d;
}

#modal-interactive-chanel .block-list-chanels .title {
    font-weight: 500;
    font-size: 14px;
}

.setting-content-domain-course .content {
    max-width: 900px;
}

.setting-content-domain-course .content .block-seo-social {
    max-width: 900px !important;
}

.setting-content-domain-course .content .block-seo-social h3 {
    color: var(--Text-text-primary, #0E233C);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.setting-content-domain-course .ladiui.form-group .link-default {
    padding: 8px 12px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
}

/* .setting-content-domain-course .preview-store-logo #store-logo {
    position: relative;
    width: 100%;
    height: 64px;
    max-width: 106px;
    overflow: hidden;
}
.setting-content-domain-course .preview-store-logo #store-logo .store-logo {
    width: 100%;
    height: 100%;
    border-radius: 8px;
} */

.page-course-welcome .content {
    padding: 100px 40px;
    display: flex;
    align-items: center;
    max-width: 1000px;
}

.page-course-welcome .content .left-content {
    width: 50%;
}

.page-course-welcome .content .left-content .title {
    font-weight: 400;
    font-size: 28px;
    line-height: 26px;
    color: var(--main-black);
}

.page-course-welcome .content .left-content .block-list-functions {
    display: block;
    margin-top: 24px;
    margin-bottom: 34px;
}

.page-course-welcome .content .left-content .block-list-functions li {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.page-course-welcome .content .left-content .block-list-functions li:last-child {
    margin-bottom: 0;
}

.page-course-welcome .content .left-content .block-list-functions li span {
    font-weight: 400;
    font-size: 14px;
    color: var(--main-black);
    line-height: 16px;
}

.page-course-welcome .content .left-content .block-list-functions li .ldicon-circle-check {
    background-color: #4ac000;
}

.page-course-welcome .content .right-content {
    width: 50%;
}

.page-course-welcome .content .right-content .block-image img {
    width: 100%;
    object-fit: cover;
}

.block-suggestion-category h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.block-suggestion-category .sub-title {
    font-weight: 400;
    font-size: 12px;
    color: #4d4d4d;
    display: block;
    padding-top: 4px;
    padding-bottom: 24px;
}

.block-suggestion-category .list-categories {
    display: block;
}

.block-suggestion-category .list-categories .category-item {
    display: flex;
    padding: 16px 12px;
    align-items: center;
    justify-content: space-between;
    background-color: #eee;
    margin-bottom: 16px;
}

.list-categories .category-item .category-img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

.block-suggestion-category .list-categories .category-item .category-name {
    display: flex;
    align-items: center;
}

.setting-content-domain-course .preview-store-logo #store-logo {
    width: -webkit-max-content;
    width: max-content;
}

.setting-content-domain-course .preview-store-logo #store-logo .store-logo {
    width: 64px;
    height: 64px;
    border-radius: 2px;
}

.setting-content-domain-course .preview-store-logo #store-logo:hover #store-logo-overlay {
    width: 64px;
    height: 64px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setting-content-domain-course .preview-store-logo #store-logo #store-logo-overlay .icon {
    margin-left: 0px;
}

.block-content-product-course .ladiui-product-course-left .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    margin-bottom: 24px;
}

.block-content-product-course .ladiui-product-course-right .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
}

.dropdown-custom-lds .ladiui.dropdown-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
}

#dropdown-domain-custom {
    width: calc(100% - 48px) !important;
}

#dropdown-active-chanel-custom {
    width: calc(100% - 48px) !important;
}

#dropdown-confirm-payment {
    width: calc(100% - 48px) !important;
}

.ladiui.table-vertical.main {
    min-height: 57px;
    border-bottom: 1px solid var(--secondary-color);
}

.ladiui.table-vertical.category-item {
    position: relative;
    display: flex;
    align-items: center;
}

.ladiui.drag-vertical.category {
    align-items: center;
    display: flex;
    width: 100%;
    height: 56px;
}

.ladiui.drag-vertical .ladiui.col-first {
    font-weight: 500;
    width: 24px;
}

.ladiui.drag-vertical .ladiui.col-name {
    font-weight: 500;
    width: calc(100% - 32px - 135px - 135px - 24px);
}

.ladiui.drag-vertical .ladiui.col-five {
    font-weight: 500;
    width: 135px;
}

.ladiui.table-vertical.category-item {
    position: relative;
    display: flex;
    align-items: center;
}

.ladiui.table-vertical.category-item .category-name {
    margin-top: 4px;
    width: calc(100% - 32px - 135px - 135px - 24px);
}

.ladiui.table-vertical.category-item .category-name a {
    overflow: hidden;
    padding-right: 12px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    color: #0E233C;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
}

.ladiui.table-vertical.category-item .td-draghandle {
    width: 24px;
    min-width: 24px;
    display: flex;
}

.ladiui.category-count {
    width: 135px;
}

.category-item .ladiui.end-table-option {
    /* position: absolute; */
    /* right: 24px; */
}

.ladiui.end-table-option {
    width: 32px;
}

.ladiui.table-vertical.category-item:hover .td-draghandle .icon-draggable {
    display: block !important;
}

.ladiui.table-vertical.category-item:hover .td-draghandle .ldicon-subcategory {
    display: none !important;
}

.block-seo-social .panel .preview-store-logo #store-logo .store-logo {
    width: 64px;
    height: 64px;
    border-radius: 2px;
}

.block-seo-social .panel .preview-store-logo #store-logo:hover #store-logo-overlay {
    width: 64px;
    height: 64px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-seo-social .panel .preview-store-logo #store-logo #store-logo-overlay .icon {
    margin-left: 0px;
}

.block-seo-social .panel .preview-store-logo #store-logo {
    width: -webkit-max-content;
    width: max-content;
}

#modal-add-video .list-videos {
    display: block;
}

#modal-add-video .list-videos .list-video-item {
    margin-bottom: 16px;
}

#modal-add-video .list-videos .list-video-item label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

#modal-add-video .list-videos .list-video-item:last-child {
    margin-bottom: 0px;
}

#modal-add-video .list-videos .list-video-item .video-info {
    display: flex;
    align-items: center;
}

#modal-add-video .list-videos .list-video-item .video-info img {
    width: 80px;
    height: auto;
    object-fit: cover;
    border-radius: 2px;
}

#modal-add-video .list-videos .list-video-item .video-info span {
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

#modal-add-video .list-videos .list-video-item .date-time {
    display: flex;
    align-items: center;
}

#modal-add-video .list-videos .list-video-item .date-time span {
    display: block;
    white-space: nowrap;
}

#modal-add-video .list-videos .list-video-item .date-time .time-video {
    margin-right: 24px;
}

#modal-add-video .ladiui.modal-footer-custom {
    padding: 0px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#modal-add-video .ladiui.modal-footer-custom .btn-upload-video {
    display: flex;
    cursor: pointer;
    align-items: center;
}

#modal-add-video .ladiui.modal-footer-custom span {
    font-weight: 500;
    color: var(--main-primary);
}

#modal-add-video .ladiui.modal-footer-custom .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-status-success {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #4ac000;
}


.ldicon-status-normal {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #f18227;
}

.ldicon-status-error {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #e01a1a;
}

#dropdown-customer-field {
    width: calc(100% - 48px) !important;
}

#dropdown-choose-customer {
    width: calc(100% - 48px) !important;
}

.limit-text-vertical {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

#modal-add-category-child .block-list-cates ul {
    padding: 16px;
    border-radius: 2px;
    border: 1px solid #e5e5e5;
    max-height: 350px;
    overflow: scroll;
}

#modal-add-category-child .block-list-cates ul li {
    margin-bottom: 16px;
    cursor: pointer;
}

#modal-add-category-child .block-list-cates ul li:last-child {
    margin-bottom: 0;
}

#modal-preview-video-bunny .block-preview-video .block-progress-video {
    display: block;
    width: 100%;
    background-color: #4d4d4d;
    padding: 64px;
}

#modal-preview-video-bunny .block-preview-video .block-progress-video .content {
    padding: 24px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    border-radius: 2px;
    text-align: center;
}

#modal-preview-video-bunny .block-preview-video .block-progress-video .content h3 {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 16px;
}

#modal-preview-video-bunny .block-preview-video .block-progress-video .content p {
    font-weight: 400;
    font-size: 11px;
}

.rst__moveHandle,
.rst__loadingHandle {
    background-image: url('https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg') !important;
    /* background: transparent url("https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg") !important; */
    background-repeat: no-repeat;
    border: none !important;
    box-shadow: none !important;
    background-color: #f4f4f4 !important;
}

.rst__rowContents {
    border: none !important;
    border-left: none;
    box-shadow: none !important;
    background-color: #f4f4f4 !important;
}

.rst__row {
    border: 1px solid #e5e5e5;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
    background-color: #e5e5e5 !important;
}

.rst__lineChildren::after {
    background-color: #e5e5e5 !important;
}

.rst__collapseButton,
.rst__expandButton {
    box-shadow: 0 0 0 1px #e5e5e5 !important;
}

.ldicon-star-secondary {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/ldicon-star.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/ldicon-star.svg);
    background-color: #e5e5e5;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.ldicon-star {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-star.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-star.svg);
    background-color: #ef9300;
    width: 16px;
    height: 16px;
    display: inline-block;
}

#modal-add-product-review .panel .content .block-add-image-background {
    width: calc(33% - 9px);
}

#modal-add-product-review .panel .content .block-list-banner-images .banner-image-item {
    width: calc(33% - 9px);
}

.product-time-picker {
    width: 100%;
}

.product-time-picker .react-ios-time-picker-main .react-ios-time-picker-input {
    height: 36px;
    width: 100%;
    border: 1px solid #f1f3f4;
}

.fullTime__wrapper .inputWrapper__dropdown.fullTime {
    z-index: 999;
}

.block-no-data {
    display: block;
    margin-top: 135px;
    text-align: center;
}

.block-no-data p {
    margin-top: 24px;
    line-height: 24px;
}

/* .block-no-data .ldicon.icon-no-data {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-star.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-colored-star.svg);
    width: 16px;
    height: 16px;
    display: inline-block;
} */
.discount-item #autocomplete-variant .async-auto-complete.menu .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: wrap !important;
}

.discount-item #autocomplete-variant .async-auto-complete.menu .item .image-name {
    display: flex;
    align-items: center;
    width: inherit;
}

.discount-item #autocomplete-variant .async-auto-complete.menu .item .image-name .name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 8px;
    text-overflow: ellipsis;
}

.dnd-sortable-tree_simple_tree-item {
    padding: 16px !important;
}

#modal-introduce-viet-qr .block-content-viet-qr {
    display: flex;
    align-items: center;
}

#modal-introduce-viet-qr .block-content-viet-qr .content-left .content {
    width: 300px;
    padding-right: 16px;
}

#modal-introduce-viet-qr .block-content-viet-qr .content-left .text-new {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #4ac000;
}

#modal-introduce-viet-qr .block-content-viet-qr .content-left h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 8px;
    color: #202020;
}

#modal-introduce-viet-qr .block-content-viet-qr .content-left p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #4d4d4d;
    margin-top: 8px;
    margin-bottom: 32px;
}

#modal-introduce-viet-qr .block-content-viet-qr .content-right .block-banner img {
    width: 100%;
    object-fit: cover;
}

.modal-footer-custom .footer-next-page {
    display: flex;
    align-items: center;
}

.modal-footer-custom .footer-next-page button {
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
}

/* css modal choose icon menu */
.list-icon {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 8px;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 24px;
}

.icon-item {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.icon-item:hover {
    background-color: #e2e2e2;
}

.choose-icon-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.icon-item-selected {
    width: 38px;
    height: 38px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #e2e2e2;
}

.menu-item-content {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.icon-empty {
    width: 24px;
    height: 24px;
}

.page-setting-menu .setting-content-menu-banner {
    /* display: none; */
    height: auto;
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}

#modal-choose-icon .modal-body {
    padding: 0;
    margin-bottom: 0;
    min-height: 40vh;
}

#modal-choose-icon .search-icon {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    background-color: #fff;
    padding: 24px;
}

#modal-choose-icon .list-icon {
    padding: 24px;
    padding-top: 0px;
    overflow-x: auto;
}

.ls-delete-text {
    color: red;
    cursor: pointer;
}

#preview-text {
    font-size: 16px;
}

.header-page-custom {
    padding-left: 0;
    padding-right: 0;
}

.ls-text-primary {
    color: var(--main-primary) !important;
    font-weight: 400;
}

.ls-hover-text-primary:hover {
    color: var(--main-primary) !important;
    cursor: pointer;
}

#modal-create-category>.form-group {
    margin-top: 20px !important;
}

.ladiui.modal-content {
    border-radius: 4px;
}

.dropdown-select-custom {
    width: 100%;
}

.dropdown-select-custom .dropdown-input-search-custom {
    margin-top: 0 !important;
    top: 0px;
    padding: 16px;
    width: 100%;
}

.dropdown-select-custom .dropdown-input-search-custom i {
    bottom: 28px;
}

.dropdown-select-custom .ladiui.dropdown-item {
    padding: 12px 16px;
}

.dropdown-select-custom .ladiui.dropdown-menu {
    padding: 0 !important;
    padding-bottom: 8px !important;
}

.ladiui.modal-footer .btn:not(:last-child) {
    margin-right: 16px;
}

.ls-ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: var(--line, 1);
    -webkit-box-orient: vertical;
    word-break: break-all;
}
@media (max-width: 768px) {
    .ladiui.fixed-sidebar .ladiui-main {
        width: 100%;
    }

    .ladiui-topbar .topbar-right {
        position: absolute;
        right: 0px;
        width: 90px;
    }

    .ladiui-aside {
        display: none;
    }

    body.mini-navbar .ladiui-aside {
        display: block !important;
    }

    .ladiui-user-type {
        padding: 2px 8px;
        font-size: 9px;
        top: 16px;
    }

    body.mini-navbar .ladiui-user-type {
        display: none;
    }

    .dashboard {
        display: block;
    }

    .dashboard .card {
        width: 100%;
        display: block;
        margin: 0px;
    }

    .ladiui-topbar.fixed {
        width: 100%;
        display: block;
    }

    .ladiui-topbar .topbar-left {
        width: 100%;
        display: block;
        height: 50px;
    }

    .ladiui-content {
        padding: 45px 10px 0px 10px;
        display: block;
    }

    .topbar-profile .ladiui-navbar.-horizontal > li:first-child {
        display: none;
        top: 1px;
    }

    .ladiui-order-left {
        padding: 0px;
    }

    .ladiui-order-right {
        position: relative;
        width: 100%;
        top: inherit;
        right: inherit;
    }

    .ladiui-table-responsive {
        overflow-x: scroll;
        position: relative;
    }

    .ladiui-table-responsive table {
        width: 1280px !important;
    }

    /* .ladiui.pagination {
        width: 100%;
        text-align: center;
    } */

    .ladiui.pagination-block {
        text-align: center;
    }

    /* .ladiui.pagination>ul>li>a {
        width: 25px;
    } */

    .order-tab {
        clear: both;
    }

    .ladiui.data-limit {
        float: none;
    }

    .order-tab .ladiui-tabs li {
        padding: 10px;
    }
}

/** @format */

.setting-page {
    width: 100%;
    display: flex;
}

.setting-page .menu-left {}

.setting-page-general .setting-content {
    max-width: 150px;
}

.setting-content {
    width: 100%;
    max-width: 236px;
    padding: 0px;
    /* overflow: auto; */
    overflow: hidden;

    /* max-height: calc(100vh - 64px); */
}

.setting-content>ul {
    display: block;
}

.setting-content>ul .setting-item {
    display: flex;
    justify-content: flex-start;
    padding: 16px 0px 16px 16px;
    cursor: pointer;
    border-bottom: 1px solid #f1f3f4;
    margin: 0px;
}

.setting-content>ul .setting-item .title {
    font-weight: 500;
    font-size: 14px;
    color: var(--main-default);
}

.setting-page .right-content {
    width: calc(100vw - 294px);
    max-width: 1220px;
    margin: 0px auto;
    background-color: #fff;
    padding: 24px;
    padding-bottom: 64px;
}

.setting-page .right-content .ladiui-tabs {
    border-bottom: 1px solid #f1f3f4;
}

.setting-page .right-content .ladiui-tab-content {
    padding-left: 0px;
    padding-right: 0px;
}

.setting-page .right-content .ladiui-tabs.top-menu li:first-child {
    padding-left: 0px !important;
}

.setting-page .right-content .ladiui-tab-content.form-setting {
    max-width: 770px;
}

.ladiui.table thead {
    border-bottom: none;
}

/* .ladiui.table tbody tr {
    border-bottom: none;
} */

.page-setting .ladiui-table-actions {
    margin-top: 24px;
}

.page-setting table {
    box-shadow: none !important;
}

#page-setting-domain .ladiui-table-actions .create-domain {
    padding-right: 20px;
}

.page-setting .block-title-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

.page-setting .block-title-action label {
    display: flex;
}

.page-setting .block-title-action .title {
    color: #0E233C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}

.page-setting .block-title-action .btn-action {
    /* padding-right: 20px; */
    display: flex;
}

/* .page-setting .block-title-action .btn-action button {
    font-weight: 500;
} */

.page-setting .block-title-action .btn-action .back-default {
    display: flex;
    align-items: center;
}

.page-setting .block-title-action .btn-action .back-default svg {
    margin-right: 9px;
}

#page-setting-payment .list-payment {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
    grid-gap: 24px;
    gap: 24px;
}

#page-setting-payment .list-payment .item {
    padding: 24px;
    border: 1px solid #f1f3f4;
    flex-basis: calc(50% - 12px);

}

#page-setting-payment .list-payment .item .description {
    white-space: break-spaces;

    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

}

#page-setting-payment .list-payment .item img {
    object-fit: cover;
    max-height: 24px;
}

#page-setting-payment .list-payment .item .sub-name {
    font-weight: 500;
    font-size: 14px;
    color: #3e526a;
    line-height: 21px;
    display: block;
    margin-top: 8px;
    margin-bottom: 4px;
}

#page-setting-payment .list-payment .item .action {
    display: flex;
    justify-content: space-between;
}

#panel-setting-checkout {
    margin-top: 0px !important;
}

#panel-setting-checkout .flex-basis {
    flex-basis: 48%;
}

#panel-setting-checkout .checkout-config table {
    /* border: 1px solid #f1f3f4; */
    margin-top: 8px;
    min-width: auto !important;
}

#panel-setting-checkout .checkout-config table .menu-item-payment-method {
    background-color: #f4f4f4;
    padding: 8px 12px;
}

#panel-setting-checkout .checkout-config table tbody tr {
    border-bottom: none !important;
}

#panel-setting-checkout .checkout-config table .menu-item-payment-method .dropdown-payment-method {
    background-color: transparent !important;
}

#panel-setting-checkout .checkout-config .title-block {
    margin-top: 24px;
    display: flex;
    align-items: center;
}

#panel-setting-checkout input.checkbox,
.config-store-checkout input.checkbox {
    border: 1px solid #727272;
    /* background-color: #fff; */
}

#panel-setting-checkout input.checkbox:checked,
.config-store-checkout input.checkbox:checked {
    background-color: var(--main-primary);
}

/* #panel-setting-checkout .checkout-config .ladiui.table tbody tr td {
    padding: 8px 16px !important;
} */

#panel-setting-checkout .checkout-config .ladiui.table thead tr th {
    /* padding: 16px 0px 10px 15px !important; */
    padding: 16px 0px !important;
    font-weight: 500;
}

.panel-setting-checkout .config-payments .table.list-active-payment-method {
    padding-top: 8px !important;
}

/* .onOffSetting {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 16px;
    margin-left: 16px;
}

.onOffSetting input {
    opacity: 0;
    width: 0;
    height: 0;
} */

/* .onoffswitch-setting {
    position: absolute;
    cursor: pointer;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.onoffswitch-setting:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
} */

/* .onOffSetting input:checked+.onoffswitch-setting {
    background-color: var(--main-primary);
}

.onOffSetting input:focus+.onoffswitch-setting {
    box-shadow: 0 0 1px var(--main-primary);
}

.onOffSetting input:checked+.onoffswitch-setting:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
} */

.page-setting .block-title-action.credit {
    justify-content: flex-start;
    align-items: center;

    color: #0E233C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    display: block;
}

.page-setting .block-title-action.credit .title {
    margin-right: 16px;
}

.page-setting .block-title-action.credit .sub-title {
    color: var(--Text-text-secondary, var(--Text-text-secondary, #566577));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
}

.ladiui.search-keyword .ladiui.input {
    text-indent: 5px;
    padding-right: 35px;
}

#page-setting-domain .ladiui-table-actions .ladiui.search-keyword {
    width: 100%;
}

.ladiui.input {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    height: 40px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #F1F3F4; */
    border-radius: 2px;
    transition: border-color 0.15s ease-out;
}

.setting-page .right-content .ladiui-tabs>li:first-child {
    padding-left: 0px;
}

.page-setting .content-editor .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
}

.page-setting .content-editor .title-header h4 {
    font-weight: 500;
    font-size: 14px;
    color: #6d6d6d;
}

.page-setting .content-editor .title-header span {
    font-weight: 400;
    font-size: 14px;
    color: var(--main-primary);
}

.page-setting .list-payment .item .action .btn-action {
    display: flex;
}

#modal-setting-template-print table {
    min-width: auto !important;
}

#modal-setting-template-email table {
    min-width: auto !important;
}

.ladiui.form-filter {
    display: flex;
    align-items: center;
}

.page-setting .max-width-content {
    max-width: 900px;
}

#modal-edit-web-hook table {
    min-width: auto !important;
}

.ladiui.checkbox {
    /* background-color: #fff; */
    border: 1px solid #CFD3D8;
}

.ladiui.checkbox:checked {
    accent-color: var(--main-primary) !important;
}

.ladiui.checkbox {
    width: 16px;
    height: 16px;
}

/* .ladiui.checkbox:checked::before {
    left: 3px !important;
    top: -2px !important;
} */

.ladiui.modal-footer {
    justify-content: flex-end !important;
}

.page-setting #modal-checkout-config .ladiui.modal-content {
    animation: none;
}

.setting-page-general .setting-content .ladiui.setting-item.active .title {
    color: var(--main-primary);
}

/* #page-setting-shipping #tab-service {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto auto auto;
    margin-top: 30px;
} */

#page-setting-shipping .action.btn-add-new {
    height: 42px;
}

#page-setting-shipping #tab-service .item {
    padding: 16px;
    border: 1px solid #f1f3f4;
}

#page-setting-shipping .ladiui-tab-content .list-shipping {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: calc(33% - 12px) calc(33% - 12px) calc(33% - 12px);
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item .action {
    display: flex;
    justify-content: space-between;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item .action .btn-action {
    display: flex;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item .action .sub-name {
    font-weight: 500;
    font-size: 14px;
    color: #3e526a;
    margin-top: 8px;
    margin-bottom: 16px;
    display: block;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item img {
    max-height: 24px;
    display: block;
}

#page-setting-shipping .list-shipping-content .block-list-shipping table {
    width: 100%;
}

#page-setting-shipping .list-shipping-content .block-list-shipping table thead th {
    padding: 16px 16px 8px 16px;
    font-size: 14px;
    color: #6d6d6d;
}

#page-setting-shipping .list-shipping-content .block-list-shipping table tbody td {
    padding: 12px 12px 12px 16px;
}

#page-setting-shipping .list-shipping-content .block-list-shipping {
    margin-bottom: 24px;
    border-radius: 2px;
    border: 1px solid #f1f3f4;
}

#page-setting-shipping .list-shipping-content .ladiui-navbar.-horizontal li>ul {
    text-align: center;
    left: -80px;
}

.rule-item .title-rule {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
}

.list-shipping-content .title-heading-shipping {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.text-toggle-input-type {
    margin: 4px;
}

.ladiui-search-custom {
    width: 100%;
}

.list-pick-color-tag-customer {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 8px;
}

/* .modal-add-customer .custom-modal-add-customer {
    margin-top: 11px !important;
} */

/* #page-setting-shipping .block-title-action {
    padding-top: 0px !important;
    margin-top: 24px;
    height: 32px;
} */

.ladiui.nav {
    /* overflow-y: initial !important;
    overflow-y: initial !important; */
    overflow: initial !important;
}

#data-tooltip-guide label {
    display: flex;
    align-items: center;
}

#data-tooltip-guide label .tooltip-icon {
    display: flex;
    align-items: center;
    margin-left: 7px;
}

/* .ladiui-tags .tag .close::after {
    position: absolute;
    content: '×';
    top: 6px;
    left: 3px;
    font-weight: 900;
} */

.ladiui.modal-footer.custom-footer {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}

.ladiui.modal-footer.custom-footer .help-integrate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: var(--main-default);
}

/* .block-filter-condition {
    padding-top: 24px !important;
} */

.list-content-shipping-rate {
    padding: 0px 16px;
}

.list-content-shipping-rate table {
    margin-top: 0px !important;
}

.list-content-shipping-rate table tbody tr {
    border-bottom: 1px solid #f1f3f4 !important;
}

.list-content-shipping-rate table thead tr {
    border-bottom: 1px solid #f1f3f4 !important;
}

.list-content-shipping-rate table thead tr th {
    padding: 12px 0px !important;
}

.list-content-shipping-rate table tbody tr td {
    padding: 12px 0px !important;
}

.list-content-shipping-rate table tbody tr:last-child {
    border-bottom: none !important;
}

.setting-block-image {
    position: relative;
}

.setting-block-image .block-upload-image {
    position: absolute;
    margin-top: 0px;
    left: 0px;
    top: 10px;
}

.setting-block-image .block-upload-image button {
    display: flex;
    flex-direction: column;
    height: auto;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    padding: 6px 0;
    border: none;
}

.setting-block-image .block-upload-image label {
    display: flex;
    flex-direction: column;
    height: 66px;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    padding: 6px 0;
    border: none;
}

.setting-block-image .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    /* cursor: pointer; */
}

.setting-block-image:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

.ladiui-button-upload input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.table-shipping-detail thead th {
    padding-left: 15px !important;
}

.table-shipping-detail tbody td {
    padding-left: 15px !important;
}

#block-custom-interface .label-header h3 {
    font-weight: 500;
    font-size: 14px;
    color: var(--main-default);
}

#block-custom-interface .label-header p {
    font-weight: 400;
    font-size: 12px;
    margin-top: 3px;
    line-height: 16px;
    color: #6d6d6d;
}

#block-custom-interface .block-custom-checkout {
    display: block;
    width: 100%;
}

#block-custom-interface .block-custom-checkout .add-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#block-custom-interface .block-custom-checkout .add-block button {}

#block-custom-interface .block-custom-checkout .add-block .ladiui.btn-group {
    width: 80%;
}

#block-custom-interface .block-content .block-item {
    display: block;
    padding: 21px 15px 15px 15px;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    position: relative;
}

#block-custom-interface .block-content .block-item .legend-header {
    position: absolute;
    top: -9px;
    background-color: #fff;
    width: -webkit-max-content;
    width: max-content;
}

#block-custom-interface .block-content .block-item .legend-header .tooltip-icon {
    margin: 0px 7px;
}

#block-custom-interface .block-content .block-item .choose-content {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    margin-top: 16px;
    background-color: #fff;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

#block-custom-interface .block-content .block-item .choose-content ul {
    width: -webkit-max-content;
    width: max-content;
}

#block-custom-interface .block-content .block-item .choose-content ul>li {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 12px 12px 12px 0px;
    cursor: pointer;
}

#block-custom-interface .block-content .block-item .choose-content ul>li .image {
    padding: 0px 18px;
}

#block-custom-interface .block-content .block-item .choose-content ul>li:hover {
    background-color: #f1f3f4;
}

#block-custom-interface .block-content .block-item .action-footer {
    margin-top: 17px;
}

#block-custom-interface .block-content .block-item .list-item {
    display: block;
}

#block-custom-interface .block-content .block-item .list-item .item {
    margin-bottom: 12px;
}

#block-custom-interface .block-content .block-item .list-item .item .content {
    padding: 11px 12px;
    background-color: #f1f3f4;
    border-radius: 2px;
}

.ladiui.nav-item {
    margin: 0 16px !important;
}

.ladiui.nav-item:first-child {
    margin-left: 0px !important;
}

.block-filter-header .filter-condtion-area button {
    display: flex;
    align-items: center;
}

#modal-export-data .item {
    display: flex;
    align-items: center;
}

.ladiui-link {
    color: var(--main-primary) !important;
}

.ladiui-link:hover {
    text-decoration: underline;
}

.timeline .content>ul>li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timeline .content>ul>li .item-avt-left {
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: start;
}

.timeline .content>ul>li .item-time-right {
    display: flex;
    /* width: 150px; */
    align-items: center;
    justify-content: space-between;
}

.timeline .content>ul>li .item-time-right .ladiui.dropdown-menu {
    right: 0px;
}

.timeline .content>ul>li .item-avt-left .ladiui-input-group {
    width: 400px;
    padding: 12px;
}

.ladiui-order-left .w-name-product .item-variant-name-info .item-variant-list-option span {
    font-size: 12px;
    line-height: 16px;
}

.ladiui-order-left .item-variant {
    font-size: 14px;
}

.order-custom-edit .ladiui-tags {
    height: auto !important;
}

.payment-shipping .url-checkout {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.payment-shipping .url-checkout .title-payment .link .link-checkout {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 20px;
}

.payment-shipping .url-checkout .title-payment .link {
    padding: 6px 12px;
    border: 1px solid #f1f3f4;
}

.block-create-order-staff .ladiui.dropdown button {
    min-width: 160px !important;
}

.discount-box .ladiui-input-group {
    flex-wrap: nowrap;
}

.block-custom-field.order .custom-field-content .date-item label {
    font-size: 14px;
    /* font-weight: 400; */
    line-height: 20px;
}

.block-filter-condition .result-filter-condition .ladiui.btn-tag {
    display: flex;
    align-items: center;
}

.ladiui-product-right .product-variants span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6d6d6d;
}

.ladiui-order-right .info-customer .custom-info.fix .order-customer {
    border-bottom: none !important;
}

.ladiui-order-right .info-customer .custom-info.fix .order-customer .customer-info {
    margin-bottom: 0px !important;
    padding-bottom: 24px;
    border-bottom: 1px solid #f1f3f4;
}

.tiket-seat-order .action-item.pull-right .search-keyword {
    margin-bottom: 24px;
}

.tiket-seat-order .action-item.pull-right .search-keyword input {
    border: 1px solid #ebedf2;
}

/* .ladiui.dropdown-toggle:after {
    top: 0px !important;
} */

.block-filter-header .ladiui.dropdown-menu.open {
    width: 100%;
}

#modal-export-order .item {
    display: flex;
    align-items: center;
}

.modal-fix-overflow {
    overflow: inherit !important;
}

.input-upload-file .ladiui-button-upload {
    position: absolute !important;
    top: 4px !important;
    right: 4px !important;
    margin-top: 0px !important;
    width: auto !important;
    height: auto !important;
}

.input-upload-file .ladiui-button-upload button {
    padding: 4px;
    margin: 4px !important;
    background: transparent;
    border: 1px solid var(--main-primary);
    color: var(--main-primary);
    cursor: pointer;
}

#modal-customer .timeline .input-history {
    height: 43px !important;
}

#modal-customer .timeline .input-history .comment {
    bottom: 5px !important;
}

.aside .ladiui.nav ul>li .ladiui.menu-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ladiui-order-left .item-variant .image-product-variant {
    width: 82px !important;
    height: 82px !important;
    border-radius: 5px;
    overflow: hidden;
}

.ladiui.btn.rounded {
    border-radius: 20px;
    cursor: pointer;
}

.block-list-location .block-item .list-item .item-text {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 20px;
    max-width: 350px;
}

.block-list-location .block-item .list-item .item {
    justify-content: space-between;
}

.ladiui.dropdown-menu.custom-form-dynamic {
    max-height: 300px;
    overflow-y: scroll;
    padding: 0px;
}

.address-default-btn {
    background-color: #41b983;
    padding: 2px 7px;
    color: #fff;
    border-radius: 2px;
    margin-left: 8px;
}

.btn-payment-custom {
    color: #1c00c2 !important;
}

.actions-dropdown-domain li a {
    white-space: nowrap;
}

#modal-web-hook-transactions .search-keyword input {
    height: 40px !important;
}

.icon-ldp-status-warning.status-custom-error {
    background-color: #d94f45;
}

#custom_javascript_head,
#custom_javascript_body {
    border: 1px solid #f1f3f4;
    width: 100% !important;
}

#modal-page-checkout .ladiui .modal-content {
    animation: none !important;
}

.block-update-status .item-update-status {
    border-top: 1px solid #f1f3f4;
    align-items: center;
}

.shiping-info-edit.custom {
    border: 1px solid #f1f3f4;
    margin-bottom: 24px;
}

.shiping-info-edit.custom .shipping-info {
    border-bottom: 0px !important;
}

.shiping-info-edit.custom .shipping-info .shipping-info-heading {
    padding: 10px 24px;
    background-color: #f1f3f4;
}

.shiping-info-edit.custom .shipping-info .shipping-info-heading h3 {
    margin-bottom: 0px !important;
}

.shiping-info-edit.custom .shipping-info .shipping-info-content {
    padding: 0px 24px;
}

.shipping-info-customer.shipping-edit {
    border: 1px solid #f1f3f4;
}

.shipping-info-customer.shipping-edit .order-customer .order-heading {
    padding: 10px 24px !important;
    background-color: #f1f3f4;
}

.shipping-info-customer.shipping-edit .order-customer .customer-info {
    padding: 24px 24px;
}

.shipping-info-customer.shipping-edit .shipping-address .order-customer .order-heading {
    background-color: transparent;
    padding: 24px 24px 0px 24px !important;
}

.shipping-info-customer.shipping-edit .shipping-address .order-customer {
    border-bottom: 0px !important;
}

#modal-image-variant .ladiui-button-upload {
    width: auto !important;
    height: auto !important;
}

#modal-shipping-form .ladiui.modal-body .right .order-customer .customer-info {
    padding-bottom: 24px !important;
}

.block-tracking-info .item-tracking {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.block-tracking-info .item-tracking:last-child {
    margin-bottom: 0px !important;
}

.block-tracking-info .item-tracking span {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.block-tracking-info .item-tracking .show-content {
    width: 200px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.block-tracking-info .item-tracking span i {
    margin-right: 8px;
}

.order-unsuccess .block-shipping-address .order-customer {
    padding-bottom: 24px;
}

.block-customer-info-right .order-custom-field.set-max-width {
    max-width: 330px !important;
    /* overflow: hidden; */
}

.create-order-customer-right .order-customer.not-customer-selected.block-dropdown-customer .title-block-customer {
    padding: 10px 24px !important;
    background-color: #f1f3f4;
}

.create-order-customer-right .order-customer.not-customer-selected.block-dropdown-customer .block-custom-content {
    padding: 16px 16px 0px 16px;
}

.create-order-customer-right .panel-customer .order-customer .order-heading {
    padding: 10px 24px;
    background-color: #f1f3f4;
}

.create-order-customer-right .panel-customer .order-customer .customer-info {
    margin: 0px 24px;
    padding: 24px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.create-order-customer-right .order-customer {
    border-bottom: none !important;
}

.create-order-customer-right .panel-shipping-address {
    margin: 24px;
}

.create-order-customer-right .panel-shipping-address .customer-info.custom-shipping-address {
    padding-bottom: 24px;
    border-bottom: 1px solid #f1f3f4;
}

.block-total-all-order {
    padding: 10px 32px;
    border-radius: 2px;
    margin-top: 20px;
    background-color: #f1f3f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-print-order-my-order {
    padding: 7px;
    border: 1px solid var(--main-primary);
    background-color: transparent;
    color: var(--main-primary);
    display: flex;
    align-items: center;
}

.block-content-shippings {
    display: block;
    overflow: hidden;
    margin-top: 20px;
}

.info-customer .order-unfinish .order-customer .order-heading {
    padding: 10px 20px !important;
    background-color: #f1f3f4;
}

.info-customer .order-unfinish .order-customer .customer-info {
    margin: 0px 24px;
    padding: 24px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.info-customer .order-unfinish .order-customer {
    border-bottom: 0px !important;
}

.DateRangePicker_picker {
    z-index: 1111 !important;
}

.incomplete-order {
    display: block;
    width: 100%;
    overflow: hidden;
    margin-top: 24px;
}

.block-list-actions-orders>li a {
    white-space: nowrap !important;
}

.dropdown-payment-method.arrow-down::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    /* top: 21px; */
    top: 20px;
    right: 16px;
    position: absolute;
    z-index: 0;
    color: #8697a8;
    /* padding-left: 6px; */
}

.dropdown-payment-method.arrow-up::after {
    content: '\ea47';
    font-family: 'LadiIcons';
    font-size: 12px;
    /* top: 20px; */
    top: 20px;
    right: 16px;
    position: absolute;
    z-index: 0;
    color: #8697a8;
    /* padding-left: 6px; */
}

.input-general-custom {
    margin-top: 24px !important;
    margin-bottom: 0px !important;
}

#modal-variant .ladiui.dropdown-toggle:after {
    top: auto !important;
}

/* .block-product-content-left .block-custom-field h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 24px;
} */

.block-product-content-left .block-custom-field .custom-field-content label {
    font-size: 14px;
}

.list-filter-condition.show .ladiui.form-group {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.list-filter-condition.show .ladiui.form-group .ladiui.tags {
    padding: 1px 8px !important;
}

.list-filter-condition.show .ladiui.form-group .ladiui.tags .async-auto-complete.menu {
    min-width: 100% !important;
}

.ladiui.table-vertical.order-cancel td:nth-child(2) a {
    color: rgba(108, 114, 147, 0.56) !important;
    text-decoration: line-through;
}

/* #tab-general-edit .ladiui-product-right .ladiui.dropdown-toggle:after {
    top: auto !important;
} */

#add-product .ladiui.modal-body .list-product .product-name {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    padding-right: 10px;
}

.ladiui.dropdown-toggle.dropdown-discount-type:after {
    top: auto !important;
}

.tool-help-fix-top .list-tool {
    /* left: -85px !important; */
    min-width: 200px !important;
}

.tool-help-fix-top .btn-help {
    background: transparent !important;
    cursor: pointer;
}

.tiket-seat-order-edit #modal-ticket-seat {
    overflow: inherit !important;
}

.tiket-seat-order-edit #modal-ticket-seat .ladiui.modal-dialog {
    margin-top: 0px !important;
}

#modal-ticket-seat .ladiui.modal-body {
    overflow-x: hidden !important;
    overflow-y: auto;
}

#modal-ticket-seat .ladiui.modal-body .ladi-card table {
    width: 100%;
    display: block !important;
    overflow: auto !important;
}

.url-checkout .ladiui.btn-group {
    width: 30%;
    max-width: 30%;
    padding-right: 12px;
}

.url-checkout .ladiui.btn-group .select-checkout-config button {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
}

.url-checkout .title-payment {
    width: 70%;
    /* margin-left: 12px; */
}

.block-billing-infomation .customer-info .item-info {
    display: flex;
    align-items: center;
    line-height: 20px;
}

.block-billing-infomation .customer-info .item-info>span {
    color: var(--main-default);
}

.block-billing-infomation .customer-info .item-info>label {
    color: #6d6d6d;
    white-space: nowrap;
}

.block-customer-field .customer-info .item-info {
    display: flex;
    line-height: 20px;
}

.block-customer-field .customer-info .item-info>span {
    color: var(--main-default);
}

.block-customer-field .customer-info .item-info>label {
    color: #6d6d6d;
    white-space: nowrap;
}

/* .ladiui.btn-tag.custom {
    margin-right: 8px;
} */

#modal-shipping-form .ladiui.modal-body .left .ladiui.form-group.custom-form-group {
    margin-top: 24px !important;
}

/* 
#modal-edit-custom-field .custom-field-content .ladiui.dropdown-menu.custom-form-dynamic {
    top: -184px !important;
} */

/* 
#modal-customer .custom-field-content .ladiui.dropdown-menu.custom-form-dynamic {
    top: -184px !important;
} */

#modal-adjust .ladiui-button-upload {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-top: 20px;
    width: 0px !important;
    height: 0px !important;
}

.ladi-card.fix-width {
    max-width: 900px !important;
}

/* .block-info-top>label {
    font-size: 16px;
    font-weight: 500;
}
.block-info-top .content-info {
    font-size: 14px;
    font-weight: 400;
    color: #6d6d6d;
} */

.ladiui.form-group .publish-times .publish-path {
    width: 100%;
}

.ladiui.form-group .publish-times .publish-redirect-after {
    padding-left: 24px;
    /* width: 30%; */
}

.btn-add-auto-assign {
    border: none !important;
}

.btn-add-auto-assign:hover {
    box-shadow: none !important;
}

.btn-add-auto-assign::after {
    background: #fff !important;
}

.btn-add-auto-assign span {
    color: var(--main-primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.add-auto-assign {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.toggle-dropdown-product-edit h2 {
    position: relative;
    background-color: #f1f3f4;
}

.toggle-dropdown-product-edit .ladiui.arrow-down::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    top: 6px;
    right: 10px;
    position: absolute;
    z-index: 0;
    color: #6d6d6d;
    padding-left: 6px;
}

.toggle-dropdown-product-edit .ladiui.arrow-up::after {
    content: '\ea47';
    font-family: 'LadiIcons';
    font-size: 12px;
    right: 10px;
    top: 6px;
    position: absolute;
    z-index: 0;
    color: #6d6d6d;
    padding-left: 6px;
}

.ladiui.list-customer-form-edit li {
    padding: 12px 16px;
}

.ladiui.list-customer-form-edit li .text-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.list-customer-form-edit li .email {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6d6d6d;
}

.ladiui.list-customer-form-edit li span {
    display: block;
}

.ladiui.list-customer-form-edit li:hover {
    background-color: #f1f3f4;
}

.ladiui.list-customer-form-edit {
    max-height: 300px;
    overflow: auto;
    overflow-y: scroll;
}

.table-account th {
    white-space: nowrap;
}

.utm-info .async-auto-complete.menu {
    display: none !important;
}

.toggle-dropdown-product-edit .title-block {
    padding: 6px 16px;
    background-color: #f1f3f4;
}

.toggle-dropdown-product-edit .title-block {
    font-size: 14px;
    font-weight: 500;
}

.list-image-product .list-image .item-image {
    width: -webkit-max-content;
    width: max-content;
}

.list-image-product .list-image .item-image .block-icon-star {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    border-radius: 2px;
    display: none;
    left: 3px;
}

.list-image-product .list-image .item-image:hover .block-icon-star {
    display: block;
}

.list-image-product .list-image .item-image .block-delete-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 3px;
}

.list-image-product .list-image .item-image:hover .block-delete-img {
    display: block;
}

#modal-shipping-form .modal-fix-overflow .ladiui.modal-dialog {
    margin-top: 0px !important;
}

/* .ladiui .block-filter-condition .ladiui.tags .tag:first-child {
    margin-left: 10px!important;
} */
.table.table-order tfoot tr {
    height: 40px;
    border-radius: 2px;
    line-height: 40px;
    background-color: #f1f3f4;
}

.table.table-order tfoot tr>td>strong {
    font-weight: 500;
    font-size: 14px;
    color: var(--main-default);
    padding-left: 32px;
}

/* #panel-setting-checkout .ladi-card .table.list-active-payment-method {
    height: 502px;
    max-height: 502px;
    overflow: scroll;
} */

#panel-setting-checkout .ladi-card .table.list-active-payment-method tbody {
    width: 100%;
    display: inline-table;
}

.ladiui-product-right.service .ladiui-label.custom::first-letter {
    text-transform: capitalize;
}

.ladiui.custom-btn-list-landingpage .ladiui.dropdown button {
    line-height: 20px;
}

#tab-package-info .package-content-info {
    /* border: 1px solid var(--main-line); */
    width: 394px;
    padding: 24px;
    margin-top: 40px;

    border-radius: 8px;
    border: 1px solid #E7E9EB;
    background: #ffffff;
}

#tab-package-info .pkg-btn-upgrade {
    display: flex;
    justify-content: center;
    padding-top: 24px;
}

#tab-package-info .pkg-btn-upgrade button {
    width: 100%;
    justify-content: center;
}

.payment-method-note {
    margin-left: 24px;
    color: var(--main-secondary);
    width: -webkit-fill-available !important;
}

#panel-setting-checkout .ladiui.nav-tabs .ladiui.nav-item {
    margin: 0px !important;
    width: 33%;
    text-align: center;
}

#panel-setting-checkout .checkout-config .block-table-customer {
    padding: 0px 16px;
    border: 1px solid #f1f3f4;
    margin-top: 4px;
}

#panel-setting-checkout .block-config-checkout .block-list-config-store-checkout {
    display: flex;
    flex-wrap: wrap;
}

#panel-setting-checkout .block-config-checkout .block-list-config-store-checkout .config-store-checkout {
    width: 50%;
}

.checkout-config-item input[disabled].checkbox:checked {
    opacity: 0.6 !important;
    background-color: #1c00c2 !important;
}

#modal-add-customfield-checkout-config .list-custom-fields ul {
    padding: 16px;
    border-radius: 2px;
    border: 1px solid #e5e5e5;
    max-height: 350px;
    overflow: scroll;
}

#modal-add-customfield-checkout-config .list-custom-fields ul li {
    margin-bottom: 16px;
    cursor: pointer;
}

#modal-add-customfield-checkout-config .list-custom-fields ul li:last-child {
    margin-bottom: 0;
}

.label-finan-create {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    grid-gap: 8px;
    gap: 8px;
}

.setting-page .right-content .content {
    margin-top: 24px;
}

.webhook-transaction .ladiui.block-search .action-item.action-search {
    width: calc(100% - 270px);
}
.page-discount {
    background-color: #fff;
    padding: 24px;
}

.page-discount .block-title-action .block-left {
    display: flex;
    align-items: center;
}

.page-discount .block-title-action .block-left .title {
    margin-right: 26px;
}

.ladiui.table {
    box-shadow: none;
}

.page-discount .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#modal-discount .condition-group {
    border-top: none;
    margin-top: 0px;
}

#modal-discount .condition-group>label {
    position: inherit;
    top: 0px;
    padding-bottom: 16px;
}

/* .page-discount .ladiui.content-main-tool {
    padding: 14px 0px;
} */

.page-discount .ladiui.content-main-tool .btn-group {
    padding: 0px;
}

#modal-discount .ladiui.form-group {
    display: block;
    margin-bottom: 24px;
}

#modal-discount .ladiui.form-group .ladiui.checkbox {
    margin-right: 8px;
}
#modal-discount .ladiui.form-group .coupon-create-types {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}
#modal-discount .ladiui.form-group .coupon-create-types .item {
    display: flex;
    align-items: center;
}
#modal-discount .line-title-condition {
    display: block;
    width: 100%;
    overflow: hidden;
}
#modal-discount .line-title-condition h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    margin: 24px 0px;
}
.page-integrate {
    background-color: #fff;
    padding: 24px;
}

.page-integrate .ladiui-tab-content {
    padding: 0px;
}

.page-integrate .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#modal-form-account .list-integrate {
    display: grid;
    /* grid-template-columns: auto auto auto; */
    grid-template-columns: 240px 240px 240px;
    grid-column-gap: 16px;
    column-gap: 16px;
    grid-row-gap: 16px;
    row-gap: 16px;
}

#modal-form-account .list-integrate .item-integrate {
    padding: 16px;
    cursor: pointer;
    border: 1px solid #f1f3f4;
}

#modal-form-account .list-integrate .item-integrate .img-title {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

#modal-form-account .list-integrate .item-integrate .description {
    font-size: 14px;
    color: #6d6d6d;
}

#modal-form-account .list-integrate .item-integrate .description p {
    line-height: 20px;
}

#modal-form-account .list-integrate .item-integrate .img-title .icon-form-account {
    margin-right: 16px;
}

.page-report-content {
    background-color: #fff;
    padding: 24px;
}

#page-customer {
    padding: 24px;
    background-color: #fff;
}

#page-customer .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#page-customer .block-title-action .block-left {
    display: flex;
    align-items: center;
}

#page-customer .block-title-action .block-left .title {
    margin-right: 26px;
}

#page-checkout .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#page-checkout .block-title-action .block-left {
    display: flex;
    align-items: center;
}

#page-checkout .block-title-action .block-left .title {
    margin-right: 26px;
}

#page-tags {
    padding: 24px;
}

#page-tags .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#page-tags .block-title-action .block-left {
    display: flex;
    align-items: center;
}

#page-tags .block-title-action .block-left .title {
    margin-right: 26px;
}
.tab-config-sdk .ladiui .sdk-page {
    display: block;
    height: 100vh;
    width: 100%;
    float: left;
}
.tab-config-sdk .ladiui .sdk-page .setting-sdk {
    width: calc(100% - 430px);
    height: 100vh;
    /* float: left; */
    padding-right: 24px;
    /* overflow-y: scroll; */
    /* overflow-x: auto; */
    background: #fff;
}
.tab-config-sdk .ladiui .sdk-page .preview-sdk {
    overflow: hidden;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    /* width: 506px; */
    width: 430px;
    float: right;
    background-image: url(data:image/gif;base64,R0lGODlhEAAQAJEAAAAAAP////X19f///yH5BAEAAAMALAAAAAAQABAAAAIflG+hq4jM3IFLJhqswNly/XkcBpIiVaInlLJr9FZWAQA7);
    height: calc(100vh - 70px);
    border-top-right-radius: 5px;
}
.ladisales-widget-frame {
    display: block;
    border: none;
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: hidden;
    background: none transparent;
    padding: 10px;
    transform: translateY(0);
    transition: none 0s ease 0s;
    visibility: visible;
}
.preview-sdk .screen {
    height: calc(100% - 92px);
    overflow: auto;
}
.preview-sdk .screen #ladisales {
    height: 100%;
}
.preview-sdk .select-screen {
    background: #fff;
    display: flex;
    padding: 10px 0 8px;
    align-items: center;
}
.preview-sdk .select-screen .title {
    margin-right: 24px;
    white-space: nowrap;
}
.sdk-page .setting-sdk .panel {
    width: 100%;
    margin-bottom: 24px;
    display: block;
}
.sdk-page .setting-sdk .panel .panel-line {
    display: flex;
    margin: 8px 0px;
}
.sdk-page .setting-sdk .panel .panel-line .main-description {
    color: var(--main-black);
    width: 20%;
    padding-top: 10px;
    font-weight: 500;
}
.sdk-page .setting-sdk .panel .panel-line .content {
    width: 100%;
    text-align: left;
    padding-left: 10px;
}
.sdk-page .setting-sdk .panel .panel-line .content .color-picker {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
}
.color-item {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin: 4px 6px 0;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}
.color-item.btn-add-background {
    border: 1px solid #afafaf;
    font-size: 20px;
}
.color-item.checked {
    pointer-events: none !important;
    border: 1px solid var(--main-line);
}
.sdk-page .setting-sdk .panel .panel-line .content .widget-position {
    display: flex;
}
.select-text-color.white {
    background: var(--main-white);
    border: 1px solid var(--main-secondary);
}
.select-text-color.black {
    background: var(--main-black);
}
.sdk-page .setting-sdk .panel .col-50 {
    margin-left: 0px !important;
}
.sdk-page .setting-sdk .panel .panel-line .main-description .sub-description {
    line-height: 16px;
    margin-top: 6px;
    color: var(--main-mute);
    font-size: 12px;
    font-weight: 400;
}
#store-logo {
    position: relative;
}
#store-logo img .store-logo {
    background-color: var(--main-line);
    border: 1px solid var(--main-line);
    object-fit: cover;
    width: 56px;
    height: 56px;
    border-radius: 50%;
}
#store-logo-overlay {
    align-items: center;
    text-align: center;
    width: 56px;
    height: 56px;
    top: 0;
    z-index: 2;
    position: absolute;
    display: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
}
.preview-store-logo #store-logo:hover > #store-logo-overlay {
    display: flex;
}
#store-logo-overlay .icon {
    background-color: var(--main-white);
    margin-left: 16px;
}
.ladiui.icon-ldp-pen-edit {
    box-sizing: unset;
    display: inline-block;
    width: 24px;
    height: 24px;
    pointer-events: none;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-edit.svg) no-repeat 50% 50%;
}
.ladiui.danger-text {
    color: var(--danger-color);
}
.sdk-page .setting-sdk .panel .panel-line .content .preview-store-logo {
    text-align: left;
    padding: 12px 9px 10px 0;
    height: 80px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
}
.sdk-page .setting-sdk .panel .panel-line .content .preview-store-logo .button.upload label {
    cursor: pointer;
}
.panel-line .content .preview-store-logo .store-logo {
    border: 1px solid var(--main-line);
    object-fit: cover;
    width: 56px;
    height: 56px;
    border-radius: 50%;
}
.ladiui.button.upload {
    margin-left: 11px;
    display: inline-block;
    box-shadow: none;
    margin-top: 18px;
    color: var(--main-primary);
}
.setting-sdk .panel .panel-line .content .cart-bg-image {
    display: flex;
    margin: 10px 0;
    line-height: 20px;
    background: #fff;
    border: 1px solid var(--main-line);
    border-radius: 3px;
}
.cart-bg-image.back-ground {
    display: inline-flex;
    width: 316px;
    height: 126px;
    background: var(--main-secondary);
    justify-content: center;
    position: relative;
    padding: unset !important;
    cursor: pointer;
}

.panel .content .block-add-image-background {
    width: calc(25% - 9px);
    text-align: center;
    height: 90px;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #6d6d6d;
    align-items: center;
    cursor: pointer;
}
.panel .content .block-list-banner-images {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
}
.panel .content .block-list-banner-images .banner-image-item {
    width: calc(25% - 9px);
    height: 90px;
    cursor: pointer;
    position: relative;
}
.panel .content .block-list-banner-images .banner-image-item .block-action-item {
    align-items: center;
    position: absolute;
    top: 4px;
    right: 4px;
    display: none;
}
.panel .content .block-list-banner-images .banner-image-item .block-action-item .block-icon-link {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
    margin-right: 4px;
}
.panel .content .block-list-banner-images .banner-image-item .block-action-item .block-icon-delete {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
}
.panel .content .block-list-banner-images .banner-image-item .block-input-add-link {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #fff;
    width: 100%;
}
.panel .content .block-list-banner-images .banner-image-item .block-input-add-link .ldicon-save {
    position: absolute;
    right: 8px;
    cursor: pointer;
    bottom: 6px;
}
.panel .content .block-list-banner-images .banner-image-item .block-input-add-link .ladiui.form-control {
    height: 24px !important;
    width: 100% !important;
    margin: 0 !important;
    padding-right: 30px;
    font-size: 12px;
}
.panel .content .block-list-banner-images .banner-image-item:hover .block-action-item {
    display: flex;
}
.panel .content .block-list-banner-images .banner-image-item:hover .ldicon-link {
    background-color: #fff;
}
.panel .content .block-list-banner-images .banner-image-item:hover .ldicon-delete {
    background-color: #fff;
}
.panel .content .block-list-banner-images .banner-image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content .cart-bg-image #upload-background-overlay {
    position: absolute;
    top: 50px;
    left: 95px;
    display: none;
}
.content .cart-bg-image.back-ground:hover > #upload-background-overlay {
    display: flex;
}
.content .cart-bg-image.back-ground a {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    border-radius: 50%;
    margin-left: 20px;
    background-color: var(--main-primary);
}
.content .cart-bg-image.back-ground .white-background .icon {
    background-color: var(--danger-color);
}
.content .cart-bg-image.back-ground .white-background {
    background-color: var(--main-white);
}
.content .cart-bg-image.back-ground img {
    max-width: 380px;
    width: 380px;
    object-fit: cover;
}
#upload-background-overlay .ladiui.ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}
.ladiui.icon-checked {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-check.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-check.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}
.select-text-color.white .ladiui.icon-checked {
    background-color: var(--main-secondary) !important;
}

#home-page {
    padding: 24px;
    background-color: #fff;
}
#home-page-info .content {
    width: 100%;
    display: flex;
}
#home-page-info .content .right-content {
    width: 100%;
    background-color: #fff;
    padding-left: 24px;
    padding-right: 24px;
    border-left: 1px solid #f1f3f4;
}

#home-page-info .content .ladiui.setting-item.active .title {
    color: var(--main-primary);
}
#home-page-info .content .right-content .panel-store-info .block-title-action {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    align-items: center;
    margin-bottom: 24px;
}
#home-page-info .content .right-content .panel-store-info .block-title-action .title {
    font-size: 18px;
    font-weight: 500;
    color: var(--main-default);
}
.panel-store-info .main-content {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}
.panel-store-info .main-content .left-content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
}
#store-banner {
    position: relative;
}

.panel-store-info .main-content .left-content .preview-store-banner #store-banner .store-banner {
    width: 160px;
    height: 90px;
    border-radius: 2px;
    object-fit: cover;
}

.panel-store-info .main-content .left-content .preview-store-banner #store-banner:hover #store-logo-overlay {
    width: 160px;
    height: 90px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#home-page-info .content .right-content .panel-store-info .right-content {
    border: none;
    padding: 16px 32px;
    background-color: #d9d9d9;
    margin-left: 24px;
}
#dropdown-active-chanel-store {
    max-width: 400px;
}

.block-setting-general-store .block-domain {
    display: block;
    margin-top: 24px;
    width: 100%;
}
.block-setting-general-store .block-domain .title {
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4d;
}
.block-homepage-interface .main-content .block-interfaces {
    position: relative;
}
.block-homepage-interface .main-content .block-interfaces .choose-content {
    display: block;
    position: absolute;
    z-index: 9999;
    left: 0;
    background-color: #fff;
    box-shadow: 0 -1px 38px rgba(0, 0, 0, 0.05), 0 4px 7px rgba(0, 0, 0, 0.12);
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul > li {
    display: flex;
    align-items: center;
    padding: 12px 12px 12px 0;
    cursor: pointer;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul > li:hover {
    background-color: #f1f3f4;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul > li .image {
    padding: 0px 18px;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul > li .image img {
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul > li .content h3 {
    font-weight: 500;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul > li .content {
    display: block !important;
}

.block-homepage-interface .block-list-locations .location-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.block-homepage-interface .block-list-locations .location-item:last-child {
    margin-bottom: 0;
}
.block-homepage-interface .block-list-locations .location-item .content {
    padding: 11px 12px;
    background-color: #f1f3f4;
    border-radius: 2px;
}
.block-homepage-interface .block-list-locations .location-item .content .action-item .btn-edit {
    margin-right: 14px;
}
.block-homepage-interface .block-list-locations .location-item .content .item-text span {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 20px;
    max-width: 350px;
}

#homepage-add-banner .block-content-image {
    background-color: #e5e5e5;
    height: 230px;
    text-align: center;
}
#homepage-add-banner .block-content-image .block-upload {
    color: #6d6d6d;
    cursor: pointer;
    padding-top: 65px;
    width: -webkit-max-content;
    width: max-content;
    margin: 0px auto;
}
#homepage-add-banner .block-show-image {
    width: 100%;
    position: relative;
}
#homepage-add-banner .block-show-image .image img {
    width: 100%;
    max-height: 230px;
    object-fit: cover;
}
#homepage-add-banner .block-show-image .block-action-item {
    align-items: center;
    position: absolute;
    top: 4px;
    right: 4px;
    display: none;
}
#homepage-add-banner .block-show-image .block-input-add-link {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
}
#homepage-add-banner .block-show-image .block-input-add-link input {
    height: 24px !important;
    width: 100% !important;
    margin: 0 !important;
    padding-right: 30px;
    font-size: 12px;
}
#homepage-add-banner .block-show-image .block-input-add-link .ldicon-save {
    position: absolute;
    right: 8px;
    cursor: pointer;
    bottom: 6px;
}
#homepage-add-banner .block-show-image:hover .block-action-item {
    display: flex;
}
#homepage-add-banner .block-show-image:hover .block-action-item .item {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
    cursor: pointer;
}
#homepage-add-banner .block-show-image:hover .ldicon-link,
#homepage-add-banner .block-show-image:hover .ldicon-delete {
    background-color: #fff;
}
.table-store-add-discount tr th {
    padding-right: 0px !important;
}
.table-store-add-discount tr td {
    padding-right: 0px !important;
}

.block-display-type {
    display: block;
    margin-top: 24px;
}
.block-display-type .block-content {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
    flex-wrap: nowrap;
}
.block-display-type .block-content .block-item {
    width: calc(50% - 6px);
    border-radius: 2px;
    border: 1px solid #d1d1d1;
    cursor: pointer;
}
.block-display-type .block-content .block-item.left {
    padding: 48px 0;
}
.block-display-type .block-content .block-item .list-pr-exam {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;
}
.block-display-type .block-content .block-item.left .list-pr-exam .pr-item .top {
    width: 60px;
    height: 60px;
    background-color: #e5e5e5;
    border-radius: 2px;
}
.block-display-type .block-content .block-item.left .list-pr-exam .pr-item .bottom {
    width: 60px;
    height: 8px;
    background-color: #e5e5e5;
    border-radius: 2px;
    margin-top: 4px;
}
.block-display-type .block-content .block-item.right .list-pr-exam .pr-item .top {
    width: 50px;
    height: 50px;
    background-color: #e5e5e5;
    border-radius: 2px;
}
.block-display-type .block-content .block-item.right .list-pr-exam .pr-item .bottom {
    width: 50px;
    height: 8px;
    background-color: #e5e5e5;
    border-radius: 2px;
    margin-top: 4px;
}
.block-display-type .block-content .block-item.right .list-pr-exam {
    flex-wrap: wrap;
}
.block-display-type .block-content .block-item.right {
    padding: 20px 50px;
}
.block-display-type .block-content .block-item.active {
    border: 1px solid #1c00c2;
}
.block-display-type .block-content .block-item.active .list-pr-exam .pr-item .top {
    background-color: #d3c9fb;
}
.block-display-type .block-content .block-item.active .list-pr-exam .pr-item .bottom {
    background-color: #d3c9fb;
}
#homepage-add-product-modal .ladiui.modal-content {
    animation: none !important;
}
#homepage-discount-modal .ladiui.modal-content {
    animation: none !important;
}

.ladi-element {
    position: relative;
    z-index: 1;
}

.ladi-gallery {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ladi-gallery .ladi-gallery-view {
    position: absolute;
    overflow: hidden;
}

.ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
    transition: left 400ms ease-in-out;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item {
        transition: transform 400ms ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        perspective: 1000px;
    }

    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.next,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.selected.right {
        left: 0;
        transform: translate3d(100%, 0, 0);
    }

    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.prev,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.selected.left {
        left: 0;
        transform: translate3d(-100%, 0, 0);
    }

    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.next.left,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.prev.right,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.selected {
        left: 0;
        transform: translate3d(0, 0, 0);
    }
}

.ladi-gallery .ladi-gallery-view>.selected,
.ladi-gallery .ladi-gallery-view>.next,
.ladi-gallery .ladi-gallery-view>.prev {
    display: block;
}

.ladi-gallery .ladi-gallery-view>.selected {
    left: 0;
}

.ladi-gallery .ladi-gallery-view>.next,
.ladi-gallery .ladi-gallery-view>.prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.ladi-gallery .ladi-gallery-view>.next {
    left: 100%;
}

.ladi-gallery .ladi-gallery-view>.prev {
    left: -100%;
}

.ladi-gallery .ladi-gallery-view>.next.left,
.ladi-gallery .ladi-gallery-view>.prev.right {
    left: 0;
}

.ladi-gallery .ladi-gallery-view>.selected.left {
    left: -100%;
}

.ladi-gallery .ladi-gallery-view>.selected.right {
    left: 100%;
}

.ladi-gallery .ladi-gallery-control {
    position: absolute;
    overflow: hidden;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-view {
    width: 100%;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control {
    top: 0;
    width: 100%;
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-view {
    top: 0;
    width: 100%;
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control {
    width: 100%;
    bottom: 0;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-view {
    height: 100%;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control {
    height: 100%;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-view {
    height: 100%;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control {
    height: 100%;
    right: 0;
}

.ladi-gallery .ladi-gallery-view .ladi-gallery-view-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery .ladi-gallery-view .ladi-gallery-view-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    right: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    cursor: pointer;
    z-index: 90000050;
    transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    right: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    cursor: pointer;
    z-index: 90000050;
    transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    cursor: pointer;
    z-index: 90000050;
    transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    right: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    cursor: pointer;
    z-index: 90000050;
    transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box {
    position: relative;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-flex;
    left: 0;
    transition: left 150ms ease-in-out;
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-flex;
    left: 0;
    transition: left 150ms ease-in-out;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-grid;
    top: 0;
    transition: top 150ms ease-in-out;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-grid;
    top: 0;
    transition: top 150ms ease-in-out;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    position: relative;
    cursor: pointer;
    opacity: 0.75;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item:hover {
    opacity: 1;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item.selected {
    opacity: 1;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item:last-child {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    ;
}
.ladi-gallery>.ladi-gallery-control {
    height: 80px;
}
.ladi-gallery>.ladi-gallery-control>.ladi-gallery-control-box>.ladi-gallery-control-item {
    width: 80px;
    height: 80px;
    margin-right: 5px;
}
.ladi-gallery>.ladi-gallery-view {
    height: calc(100% - 85px);
}

a.product-image-tools {
    display: block;
    position: absolute;
    top: 6px;
    left: 5px;
    opacity: 0.9;
    color: #fff;
    font-size: 11px;
    text-align: center;
    background: #1853fa;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

a.product-image-tools i {
    font-size: 10px;
    line-height: 20px;
}
.react-datepicker {
    /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2); */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    font-family: inherit;
    border: 0px;
}

.react-datepicker__time-container {
    border-left: 1px solid #eaedf0;
}

.react-datepicker__header {
    background-color: #fff;
    padding-top: 12px;
    border-bottom: 1px solid #ebedf2;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--selected:hover {
    background-color: var(--main-primary);
    color: #fff !important;
    border-radius: 4px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle:before {
    border-bottom-color: #fff;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
    color: #000;
    width: 30px;
    margin: 3px;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
    cursor: default;
    color: #ccc !important;
}

.react-datepicker__navigation {
    top: 13px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

.ladi-date-picker-icon {
    float: right;
    margin-right: 12px;
    margin-top: -30px;
    position: relative;
    z-index: 0;
    cursor: pointer;
}

.react-datepicker__day {
    width: 32px;
    height: 32px;
    line-height: 32px;
}

.react-datepicker__current-month {
    font-weight: 500;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    line-height: 30px;
    padding: inherit;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--main-primary);
    color: #fff !important;
    font-weight: initial;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-weight: 500;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: inherit;
}

.react-datepicker-popper {
    z-index: 6;
}

.custom-btn-date-panel {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
}

/** @format */

/* .DateRangePickerInput {
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid var(--secondary-color) !important;
    border-radius: 2px !important;
    height: 36px;
} */

.DateInput_input__focused {
    border-color: #fff !important;
}

.DateInput_fang {
    top: 38px !important;
}

.CalendarMonth_table {
    margin-top: 10px;
}

.CalendarMonth_caption::first-letter {
    text-transform: capitalize !important;
}

.CalendarMonth_caption {
    font-size: 16px !important;
    font-weight: 500;
}

.CalendarDay__selected_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected {
    background: var(--main-primary) !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected:hover {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay {
    vertical-align: middle !important;
}

.DateInput_input::-webkit-input-placeholder,
.DateInput_input::placeholder {
    font-size: 13px !important;
}
.DateRangePickerInput_calendarIcon {
    padding: 7px !important;
}
.DateRangePickerInput_calendarIcon i {
    vertical-align: middle !important;
}

.DateInput_input {
    font-weight: inherit !important;
}

.DateRangePicker .custom-btn-date-panel {
    padding: 0px 22px 11px 22px;
}

.DateRangePicker .custom-btn-date {
    position: relative;
    height: 100%;
    text-align: center;
    background: none;
    border: 1px solid var(--main-primary);
    border-radius: 2px;
    color: var(--main-primary);
    padding: 4px 4px;
    margin-right: 4px;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 13px;
}

.DateRangePicker .custom-btn-date:active {
    outline: 0;
}

.DateRangePicker .custom-btn-date.selected {
    color: #fff;
    background: var(--main-primary);
}

.DayPickerNavigation_button__horizontalDefault,
.DayPicker_transitionContainer,
.DayPicker__withBorder {
    border-radius: 2px;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0;
}

.DateInput_input__small {
    font-size: 14px;
}

.DateRangePickerInput_clearDates__small {
    padding: 9px 23px;
    top: 47%;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: transparent;
}

