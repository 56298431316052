.ladi-element {
    position: relative;
    z-index: 1;
}

.ladi-gallery {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ladi-gallery .ladi-gallery-view {
    position: absolute;
    overflow: hidden;
}

.ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
    transition: left 400ms ease-in-out;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item {
        transition: transform 400ms ease-in-out;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        perspective: 1000px;
    }

    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.next,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.selected.right {
        left: 0;
        transform: translate3d(100%, 0, 0);
    }

    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.prev,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.selected.left {
        left: 0;
        transform: translate3d(-100%, 0, 0);
    }

    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.next.left,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.prev.right,
    .ladi-gallery .ladi-gallery-view>.ladi-gallery-view-item.selected {
        left: 0;
        transform: translate3d(0, 0, 0);
    }
}

.ladi-gallery .ladi-gallery-view>.selected,
.ladi-gallery .ladi-gallery-view>.next,
.ladi-gallery .ladi-gallery-view>.prev {
    display: block;
}

.ladi-gallery .ladi-gallery-view>.selected {
    left: 0;
}

.ladi-gallery .ladi-gallery-view>.next,
.ladi-gallery .ladi-gallery-view>.prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.ladi-gallery .ladi-gallery-view>.next {
    left: 100%;
}

.ladi-gallery .ladi-gallery-view>.prev {
    left: -100%;
}

.ladi-gallery .ladi-gallery-view>.next.left,
.ladi-gallery .ladi-gallery-view>.prev.right {
    left: 0;
}

.ladi-gallery .ladi-gallery-view>.selected.left {
    left: -100%;
}

.ladi-gallery .ladi-gallery-view>.selected.right {
    left: 100%;
}

.ladi-gallery .ladi-gallery-control {
    position: absolute;
    overflow: hidden;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-view {
    width: 100%;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control {
    top: 0;
    width: 100%;
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-view {
    top: 0;
    width: 100%;
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control {
    width: 100%;
    bottom: 0;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-view {
    height: 100%;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control {
    height: 100%;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-view {
    height: 100%;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control {
    height: 100%;
    right: 0;
}

.ladi-gallery .ladi-gallery-view .ladi-gallery-view-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery .ladi-gallery-view .ladi-gallery-view-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    right: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    cursor: pointer;
    z-index: 90000050;
    transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    right: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    cursor: pointer;
    z-index: 90000050;
    transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    cursor: pointer;
    z-index: 90000050;
    transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    right: 5px;
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    cursor: pointer;
    z-index: 90000050;
    transform: scale(0.6);
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control .ladi-gallery-control-arrow-left {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    background-position-x: -48px;
    transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control .ladi-gallery-control-arrow-right {
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 5px;
    left: calc(50% - 24px);
    background: url("https://w.ladicdn.com/source/arrows.svg") no-repeat;
    transform: rotate(90deg) scale(0.6);
    cursor: pointer;
    z-index: 90000050;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box {
    position: relative;
}

.ladi-gallery.ladi-gallery-top .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-flex;
    left: 0;
    transition: left 150ms ease-in-out;
}

.ladi-gallery.ladi-gallery-bottom .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-flex;
    left: 0;
    transition: left 150ms ease-in-out;
}

.ladi-gallery.ladi-gallery-left .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-grid;
    top: 0;
    transition: top 150ms ease-in-out;
}

.ladi-gallery.ladi-gallery-right .ladi-gallery-control .ladi-gallery-control-box {
    display: inline-grid;
    top: 0;
    transition: top 150ms ease-in-out;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    position: relative;
    cursor: pointer;
    opacity: 0.75;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item:hover {
    opacity: 1;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item.selected {
    opacity: 1;
}

.ladi-gallery .ladi-gallery-control .ladi-gallery-control-box .ladi-gallery-control-item:last-child {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    ;
}
.ladi-gallery>.ladi-gallery-control {
    height: 80px;
}
.ladi-gallery>.ladi-gallery-control>.ladi-gallery-control-box>.ladi-gallery-control-item {
    width: 80px;
    height: 80px;
    margin-right: 5px;
}
.ladi-gallery>.ladi-gallery-view {
    height: calc(100% - 85px);
}

a.product-image-tools {
    display: block;
    position: absolute;
    top: 6px;
    left: 5px;
    opacity: 0.9;
    color: #fff;
    font-size: 11px;
    text-align: center;
    background: #1853fa;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

a.product-image-tools i {
    font-size: 10px;
    line-height: 20px;
}