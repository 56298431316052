/** @format */

.setting-page {
    width: 100%;
    display: flex;
}

.setting-page .menu-left {}

.setting-page-general .setting-content {
    max-width: 150px;
}

.setting-content {
    width: 100%;
    max-width: 236px;
    padding: 0px;
    /* overflow: auto; */
    overflow: hidden;

    /* max-height: calc(100vh - 64px); */
}

.setting-content>ul {
    display: block;
}

.setting-content>ul .setting-item {
    display: flex;
    justify-content: flex-start;
    padding: 16px 0px 16px 16px;
    cursor: pointer;
    border-bottom: 1px solid #f1f3f4;
    margin: 0px;
}

.setting-content>ul .setting-item .title {
    font-weight: 500;
    font-size: 14px;
    color: var(--main-default);
}

.setting-page .right-content {
    width: calc(100vw - 294px);
    max-width: 1220px;
    margin: 0px auto;
    background-color: #fff;
    padding: 24px;
    padding-bottom: 64px;
}

.setting-page .right-content .ladiui-tabs {
    border-bottom: 1px solid #f1f3f4;
}

.setting-page .right-content .ladiui-tab-content {
    padding-left: 0px;
    padding-right: 0px;
}

.setting-page .right-content .ladiui-tabs.top-menu li:first-child {
    padding-left: 0px !important;
}

.setting-page .right-content .ladiui-tab-content.form-setting {
    max-width: 770px;
}

.ladiui.table thead {
    border-bottom: none;
}

/* .ladiui.table tbody tr {
    border-bottom: none;
} */

.page-setting .ladiui-table-actions {
    margin-top: 24px;
}

.page-setting table {
    box-shadow: none !important;
}

#page-setting-domain .ladiui-table-actions .create-domain {
    padding-right: 20px;
}

.page-setting .block-title-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 999;
}

.page-setting .block-title-action label {
    display: flex;
}

.page-setting .block-title-action .title {
    color: #0E233C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}

.page-setting .block-title-action .btn-action {
    /* padding-right: 20px; */
    display: flex;
}

/* .page-setting .block-title-action .btn-action button {
    font-weight: 500;
} */

.page-setting .block-title-action .btn-action .back-default {
    display: flex;
    align-items: center;
}

.page-setting .block-title-action .btn-action .back-default svg {
    margin-right: 9px;
}

#page-setting-payment .list-payment {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
    gap: 24px;
}

#page-setting-payment .list-payment .item {
    padding: 24px;
    border: 1px solid #f1f3f4;
    flex-basis: calc(50% - 12px);

}

#page-setting-payment .list-payment .item .description {
    white-space: break-spaces;

    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

}

#page-setting-payment .list-payment .item img {
    object-fit: cover;
    max-height: 24px;
}

#page-setting-payment .list-payment .item .sub-name {
    font-weight: 500;
    font-size: 14px;
    color: #3e526a;
    line-height: 21px;
    display: block;
    margin-top: 8px;
    margin-bottom: 4px;
}

#page-setting-payment .list-payment .item .action {
    display: flex;
    justify-content: space-between;
}

#panel-setting-checkout {
    margin-top: 0px !important;
}

#panel-setting-checkout .flex-basis {
    flex-basis: 48%;
}

#panel-setting-checkout .checkout-config table {
    /* border: 1px solid #f1f3f4; */
    margin-top: 8px;
    min-width: auto !important;
}

#panel-setting-checkout .checkout-config table .menu-item-payment-method {
    background-color: #f4f4f4;
    padding: 8px 12px;
}

#panel-setting-checkout .checkout-config table tbody tr {
    border-bottom: none !important;
}

#panel-setting-checkout .checkout-config table .menu-item-payment-method .dropdown-payment-method {
    background-color: transparent !important;
}

#panel-setting-checkout .checkout-config .title-block {
    margin-top: 24px;
    display: flex;
    align-items: center;
}

#panel-setting-checkout input.checkbox,
.config-store-checkout input.checkbox {
    border: 1px solid #727272;
    /* background-color: #fff; */
}

#panel-setting-checkout input.checkbox:checked,
.config-store-checkout input.checkbox:checked {
    background-color: var(--main-primary);
}

/* #panel-setting-checkout .checkout-config .ladiui.table tbody tr td {
    padding: 8px 16px !important;
} */

#panel-setting-checkout .checkout-config .ladiui.table thead tr th {
    /* padding: 16px 0px 10px 15px !important; */
    padding: 16px 0px !important;
    font-weight: 500;
}

.panel-setting-checkout .config-payments .table.list-active-payment-method {
    padding-top: 8px !important;
}

/* .onOffSetting {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 16px;
    margin-left: 16px;
}

.onOffSetting input {
    opacity: 0;
    width: 0;
    height: 0;
} */

/* .onoffswitch-setting {
    position: absolute;
    cursor: pointer;
    border-radius: 34px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.onoffswitch-setting:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
} */

/* .onOffSetting input:checked+.onoffswitch-setting {
    background-color: var(--main-primary);
}

.onOffSetting input:focus+.onoffswitch-setting {
    box-shadow: 0 0 1px var(--main-primary);
}

.onOffSetting input:checked+.onoffswitch-setting:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
} */

.page-setting .block-title-action.credit {
    justify-content: flex-start;
    align-items: center;

    color: #0E233C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    display: block;
}

.page-setting .block-title-action.credit .title {
    margin-right: 16px;
}

.page-setting .block-title-action.credit .sub-title {
    color: var(--Text-text-secondary, var(--Text-text-secondary, #566577));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
}

.ladiui.search-keyword .ladiui.input {
    text-indent: 5px;
    padding-right: 35px;
}

#page-setting-domain .ladiui-table-actions .ladiui.search-keyword {
    width: 100%;
}

.ladiui.input {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    height: 40px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #F1F3F4; */
    border-radius: 2px;
    -webkit-transition: border-color 0.15s ease-out;
    transition: border-color 0.15s ease-out;
}

.setting-page .right-content .ladiui-tabs>li:first-child {
    padding-left: 0px;
}

.page-setting .content-editor .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
}

.page-setting .content-editor .title-header h4 {
    font-weight: 500;
    font-size: 14px;
    color: #6d6d6d;
}

.page-setting .content-editor .title-header span {
    font-weight: 400;
    font-size: 14px;
    color: var(--main-primary);
}

.page-setting .list-payment .item .action .btn-action {
    display: flex;
}

#modal-setting-template-print table {
    min-width: auto !important;
}

#modal-setting-template-email table {
    min-width: auto !important;
}

.ladiui.form-filter {
    display: flex;
    align-items: center;
}

.page-setting .max-width-content {
    max-width: 900px;
}

#modal-edit-web-hook table {
    min-width: auto !important;
}

.ladiui.checkbox {
    /* background-color: #fff; */
    border: 1px solid #CFD3D8;
}

.ladiui.checkbox:checked {
    accent-color: var(--main-primary) !important;
}

.ladiui.checkbox {
    width: 16px;
    height: 16px;
}

/* .ladiui.checkbox:checked::before {
    left: 3px !important;
    top: -2px !important;
} */

.ladiui.modal-footer {
    justify-content: flex-end !important;
}

.page-setting #modal-checkout-config .ladiui.modal-content {
    animation: none;
}

.setting-page-general .setting-content .ladiui.setting-item.active .title {
    color: var(--main-primary);
}

/* #page-setting-shipping #tab-service {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto auto auto;
    margin-top: 30px;
} */

#page-setting-shipping .action.btn-add-new {
    height: 42px;
}

#page-setting-shipping #tab-service .item {
    padding: 16px;
    border: 1px solid #f1f3f4;
}

#page-setting-shipping .ladiui-tab-content .list-shipping {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: calc(33% - 12px) calc(33% - 12px) calc(33% - 12px);
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item .action {
    display: flex;
    justify-content: space-between;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item .action .btn-action {
    display: flex;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item .action .sub-name {
    font-weight: 500;
    font-size: 14px;
    color: #3e526a;
    margin-top: 8px;
    margin-bottom: 16px;
    display: block;
}

#page-setting-shipping .ladiui-tab-content .list-shipping .item img {
    max-height: 24px;
    display: block;
}

#page-setting-shipping .list-shipping-content .block-list-shipping table {
    width: 100%;
}

#page-setting-shipping .list-shipping-content .block-list-shipping table thead th {
    padding: 16px 16px 8px 16px;
    font-size: 14px;
    color: #6d6d6d;
}

#page-setting-shipping .list-shipping-content .block-list-shipping table tbody td {
    padding: 12px 12px 12px 16px;
}

#page-setting-shipping .list-shipping-content .block-list-shipping {
    margin-bottom: 24px;
    border-radius: 2px;
    border: 1px solid #f1f3f4;
}

#page-setting-shipping .list-shipping-content .ladiui-navbar.-horizontal li>ul {
    text-align: center;
    left: -80px;
}

.rule-item .title-rule {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
}

.list-shipping-content .title-heading-shipping {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.text-toggle-input-type {
    margin: 4px;
}

.ladiui-search-custom {
    width: 100%;
}

.list-pick-color-tag-customer {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 8px;
}

/* .modal-add-customer .custom-modal-add-customer {
    margin-top: 11px !important;
} */

/* #page-setting-shipping .block-title-action {
    padding-top: 0px !important;
    margin-top: 24px;
    height: 32px;
} */

.ladiui.nav {
    /* overflow-y: initial !important;
    overflow-y: initial !important; */
    overflow: initial !important;
}

#data-tooltip-guide label {
    display: flex;
    align-items: center;
}

#data-tooltip-guide label .tooltip-icon {
    display: flex;
    align-items: center;
    margin-left: 7px;
}

/* .ladiui-tags .tag .close::after {
    position: absolute;
    content: '×';
    top: 6px;
    left: 3px;
    font-weight: 900;
} */

.ladiui.modal-footer.custom-footer {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}

.ladiui.modal-footer.custom-footer .help-integrate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: var(--main-default);
}

/* .block-filter-condition {
    padding-top: 24px !important;
} */

.list-content-shipping-rate {
    padding: 0px 16px;
}

.list-content-shipping-rate table {
    margin-top: 0px !important;
}

.list-content-shipping-rate table tbody tr {
    border-bottom: 1px solid #f1f3f4 !important;
}

.list-content-shipping-rate table thead tr {
    border-bottom: 1px solid #f1f3f4 !important;
}

.list-content-shipping-rate table thead tr th {
    padding: 12px 0px !important;
}

.list-content-shipping-rate table tbody tr td {
    padding: 12px 0px !important;
}

.list-content-shipping-rate table tbody tr:last-child {
    border-bottom: none !important;
}

.setting-block-image {
    position: relative;
}

.setting-block-image .block-upload-image {
    position: absolute;
    margin-top: 0px;
    left: 0px;
    top: 10px;
}

.setting-block-image .block-upload-image button {
    display: flex;
    flex-direction: column;
    height: auto;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    padding: 6px 0;
    border: none;
}

.setting-block-image .block-upload-image label {
    display: flex;
    flex-direction: column;
    height: 66px;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    padding: 6px 0;
    border: none;
}

.setting-block-image .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    /* cursor: pointer; */
}

.setting-block-image:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

.ladiui-button-upload input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.table-shipping-detail thead th {
    padding-left: 15px !important;
}

.table-shipping-detail tbody td {
    padding-left: 15px !important;
}

#block-custom-interface .label-header h3 {
    font-weight: 500;
    font-size: 14px;
    color: var(--main-default);
}

#block-custom-interface .label-header p {
    font-weight: 400;
    font-size: 12px;
    margin-top: 3px;
    line-height: 16px;
    color: #6d6d6d;
}

#block-custom-interface .block-custom-checkout {
    display: block;
    width: 100%;
}

#block-custom-interface .block-custom-checkout .add-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#block-custom-interface .block-custom-checkout .add-block button {}

#block-custom-interface .block-custom-checkout .add-block .ladiui.btn-group {
    width: 80%;
}

#block-custom-interface .block-content .block-item {
    display: block;
    padding: 21px 15px 15px 15px;
    border: 1px solid #f1f3f4;
    border-radius: 2px;
    position: relative;
}

#block-custom-interface .block-content .block-item .legend-header {
    position: absolute;
    top: -9px;
    background-color: #fff;
    width: max-content;
}

#block-custom-interface .block-content .block-item .legend-header .tooltip-icon {
    margin: 0px 7px;
}

#block-custom-interface .block-content .block-item .choose-content {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    margin-top: 16px;
    background-color: #fff;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

#block-custom-interface .block-content .block-item .choose-content ul {
    width: max-content;
}

#block-custom-interface .block-content .block-item .choose-content ul>li {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 12px 12px 12px 0px;
    cursor: pointer;
}

#block-custom-interface .block-content .block-item .choose-content ul>li .image {
    padding: 0px 18px;
}

#block-custom-interface .block-content .block-item .choose-content ul>li:hover {
    background-color: #f1f3f4;
}

#block-custom-interface .block-content .block-item .action-footer {
    margin-top: 17px;
}

#block-custom-interface .block-content .block-item .list-item {
    display: block;
}

#block-custom-interface .block-content .block-item .list-item .item {
    margin-bottom: 12px;
}

#block-custom-interface .block-content .block-item .list-item .item .content {
    padding: 11px 12px;
    background-color: #f1f3f4;
    border-radius: 2px;
}

.ladiui.nav-item {
    margin: 0 16px !important;
}

.ladiui.nav-item:first-child {
    margin-left: 0px !important;
}

.block-filter-header .filter-condtion-area button {
    display: flex;
    align-items: center;
}

#modal-export-data .item {
    display: flex;
    align-items: center;
}

.ladiui-link {
    color: var(--main-primary) !important;
}

.ladiui-link:hover {
    text-decoration: underline;
}

.timeline .content>ul>li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timeline .content>ul>li .item-avt-left {
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: start;
}

.timeline .content>ul>li .item-time-right {
    display: flex;
    /* width: 150px; */
    align-items: center;
    justify-content: space-between;
}

.timeline .content>ul>li .item-time-right .ladiui.dropdown-menu {
    right: 0px;
}

.timeline .content>ul>li .item-avt-left .ladiui-input-group {
    width: 400px;
    padding: 12px;
}

.ladiui-order-left .w-name-product .item-variant-name-info .item-variant-list-option span {
    font-size: 12px;
    line-height: 16px;
}

.ladiui-order-left .item-variant {
    font-size: 14px;
}

.order-custom-edit .ladiui-tags {
    height: auto !important;
}

.payment-shipping .url-checkout {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.payment-shipping .url-checkout .title-payment .link .link-checkout {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 20px;
}

.payment-shipping .url-checkout .title-payment .link {
    padding: 6px 12px;
    border: 1px solid #f1f3f4;
}

.block-create-order-staff .ladiui.dropdown button {
    min-width: 160px !important;
}

.discount-box .ladiui-input-group {
    flex-wrap: nowrap;
}

.block-custom-field.order .custom-field-content .date-item label {
    font-size: 14px;
    /* font-weight: 400; */
    line-height: 20px;
}

.block-filter-condition .result-filter-condition .ladiui.btn-tag {
    display: flex;
    align-items: center;
}

.ladiui-product-right .product-variants span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6d6d6d;
}

.ladiui-order-right .info-customer .custom-info.fix .order-customer {
    border-bottom: none !important;
}

.ladiui-order-right .info-customer .custom-info.fix .order-customer .customer-info {
    margin-bottom: 0px !important;
    padding-bottom: 24px;
    border-bottom: 1px solid #f1f3f4;
}

.tiket-seat-order .action-item.pull-right .search-keyword {
    margin-bottom: 24px;
}

.tiket-seat-order .action-item.pull-right .search-keyword input {
    border: 1px solid #ebedf2;
}

/* .ladiui.dropdown-toggle:after {
    top: 0px !important;
} */

.block-filter-header .ladiui.dropdown-menu.open {
    width: 100%;
}

#modal-export-order .item {
    display: flex;
    align-items: center;
}

.modal-fix-overflow {
    overflow: inherit !important;
}

.input-upload-file .ladiui-button-upload {
    position: absolute !important;
    top: 4px !important;
    right: 4px !important;
    margin-top: 0px !important;
    width: auto !important;
    height: auto !important;
}

.input-upload-file .ladiui-button-upload button {
    padding: 4px;
    margin: 4px !important;
    background: transparent;
    border: 1px solid var(--main-primary);
    color: var(--main-primary);
    cursor: pointer;
}

#modal-customer .timeline .input-history {
    height: 43px !important;
}

#modal-customer .timeline .input-history .comment {
    bottom: 5px !important;
}

.aside .ladiui.nav ul>li .ladiui.menu-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ladiui-order-left .item-variant .image-product-variant {
    width: 82px !important;
    height: 82px !important;
    border-radius: 5px;
    overflow: hidden;
}

.ladiui.btn.rounded {
    border-radius: 20px;
    cursor: pointer;
}

.block-list-location .block-item .list-item .item-text {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 20px;
    max-width: 350px;
}

.block-list-location .block-item .list-item .item {
    justify-content: space-between;
}

.ladiui.dropdown-menu.custom-form-dynamic {
    max-height: 300px;
    overflow-y: scroll;
    padding: 0px;
}

.address-default-btn {
    background-color: #41b983;
    padding: 2px 7px;
    color: #fff;
    border-radius: 2px;
    margin-left: 8px;
}

.btn-payment-custom {
    color: #1c00c2 !important;
}

.actions-dropdown-domain li a {
    white-space: nowrap;
}

#modal-web-hook-transactions .search-keyword input {
    height: 40px !important;
}

.icon-ldp-status-warning.status-custom-error {
    background-color: #d94f45;
}

#custom_javascript_head,
#custom_javascript_body {
    border: 1px solid #f1f3f4;
    width: 100% !important;
}

#modal-page-checkout .ladiui .modal-content {
    animation: none !important;
}

.block-update-status .item-update-status {
    border-top: 1px solid #f1f3f4;
    align-items: center;
}

.shiping-info-edit.custom {
    border: 1px solid #f1f3f4;
    margin-bottom: 24px;
}

.shiping-info-edit.custom .shipping-info {
    border-bottom: 0px !important;
}

.shiping-info-edit.custom .shipping-info .shipping-info-heading {
    padding: 10px 24px;
    background-color: #f1f3f4;
}

.shiping-info-edit.custom .shipping-info .shipping-info-heading h3 {
    margin-bottom: 0px !important;
}

.shiping-info-edit.custom .shipping-info .shipping-info-content {
    padding: 0px 24px;
}

.shipping-info-customer.shipping-edit {
    border: 1px solid #f1f3f4;
}

.shipping-info-customer.shipping-edit .order-customer .order-heading {
    padding: 10px 24px !important;
    background-color: #f1f3f4;
}

.shipping-info-customer.shipping-edit .order-customer .customer-info {
    padding: 24px 24px;
}

.shipping-info-customer.shipping-edit .shipping-address .order-customer .order-heading {
    background-color: transparent;
    padding: 24px 24px 0px 24px !important;
}

.shipping-info-customer.shipping-edit .shipping-address .order-customer {
    border-bottom: 0px !important;
}

#modal-image-variant .ladiui-button-upload {
    width: auto !important;
    height: auto !important;
}

#modal-shipping-form .ladiui.modal-body .right .order-customer .customer-info {
    padding-bottom: 24px !important;
}

.block-tracking-info .item-tracking {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.block-tracking-info .item-tracking:last-child {
    margin-bottom: 0px !important;
}

.block-tracking-info .item-tracking span {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.block-tracking-info .item-tracking .show-content {
    width: 200px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.block-tracking-info .item-tracking span i {
    margin-right: 8px;
}

.order-unsuccess .block-shipping-address .order-customer {
    padding-bottom: 24px;
}

.block-customer-info-right .order-custom-field.set-max-width {
    max-width: 330px !important;
    /* overflow: hidden; */
}

.create-order-customer-right .order-customer.not-customer-selected.block-dropdown-customer .title-block-customer {
    padding: 10px 24px !important;
    background-color: #f1f3f4;
}

.create-order-customer-right .order-customer.not-customer-selected.block-dropdown-customer .block-custom-content {
    padding: 16px 16px 0px 16px;
}

.create-order-customer-right .panel-customer .order-customer .order-heading {
    padding: 10px 24px;
    background-color: #f1f3f4;
}

.create-order-customer-right .panel-customer .order-customer .customer-info {
    margin: 0px 24px;
    padding: 24px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.create-order-customer-right .order-customer {
    border-bottom: none !important;
}

.create-order-customer-right .panel-shipping-address {
    margin: 24px;
}

.create-order-customer-right .panel-shipping-address .customer-info.custom-shipping-address {
    padding-bottom: 24px;
    border-bottom: 1px solid #f1f3f4;
}

.block-total-all-order {
    padding: 10px 32px;
    border-radius: 2px;
    margin-top: 20px;
    background-color: #f1f3f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-print-order-my-order {
    padding: 7px;
    border: 1px solid var(--main-primary);
    background-color: transparent;
    color: var(--main-primary);
    display: flex;
    align-items: center;
}

.block-content-shippings {
    display: block;
    overflow: hidden;
    margin-top: 20px;
}

.info-customer .order-unfinish .order-customer .order-heading {
    padding: 10px 20px !important;
    background-color: #f1f3f4;
}

.info-customer .order-unfinish .order-customer .customer-info {
    margin: 0px 24px;
    padding: 24px 0px;
    border-bottom: 1px solid #f1f3f4;
}

.info-customer .order-unfinish .order-customer {
    border-bottom: 0px !important;
}

.DateRangePicker_picker {
    z-index: 1111 !important;
}

.incomplete-order {
    display: block;
    width: 100%;
    overflow: hidden;
    margin-top: 24px;
}

.block-list-actions-orders>li a {
    white-space: nowrap !important;
}

.dropdown-payment-method.arrow-down::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    /* top: 21px; */
    top: 20px;
    right: 16px;
    position: absolute;
    z-index: 0;
    color: #8697a8;
    /* padding-left: 6px; */
}

.dropdown-payment-method.arrow-up::after {
    content: '\ea47';
    font-family: 'LadiIcons';
    font-size: 12px;
    /* top: 20px; */
    top: 20px;
    right: 16px;
    position: absolute;
    z-index: 0;
    color: #8697a8;
    /* padding-left: 6px; */
}

.input-general-custom {
    margin-top: 24px !important;
    margin-bottom: 0px !important;
}

#modal-variant .ladiui.dropdown-toggle:after {
    top: auto !important;
}

/* .block-product-content-left .block-custom-field h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 24px;
} */

.block-product-content-left .block-custom-field .custom-field-content label {
    font-size: 14px;
}

.list-filter-condition.show .ladiui.form-group {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.list-filter-condition.show .ladiui.form-group .ladiui.tags {
    padding: 1px 8px !important;
}

.list-filter-condition.show .ladiui.form-group .ladiui.tags .async-auto-complete.menu {
    min-width: 100% !important;
}

.ladiui.table-vertical.order-cancel td:nth-child(2) a {
    color: rgba(108, 114, 147, 0.56) !important;
    text-decoration: line-through;
}

/* #tab-general-edit .ladiui-product-right .ladiui.dropdown-toggle:after {
    top: auto !important;
} */

#add-product .ladiui.modal-body .list-product .product-name {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 100%;
    padding-right: 10px;
}

.ladiui.dropdown-toggle.dropdown-discount-type:after {
    top: auto !important;
}

.tool-help-fix-top .list-tool {
    /* left: -85px !important; */
    min-width: 200px !important;
}

.tool-help-fix-top .btn-help {
    background: transparent !important;
    cursor: pointer;
}

.tiket-seat-order-edit #modal-ticket-seat {
    overflow: inherit !important;
}

.tiket-seat-order-edit #modal-ticket-seat .ladiui.modal-dialog {
    margin-top: 0px !important;
}

#modal-ticket-seat .ladiui.modal-body {
    overflow-x: hidden !important;
    overflow-y: auto;
}

#modal-ticket-seat .ladiui.modal-body .ladi-card table {
    width: 100%;
    display: block !important;
    overflow: auto !important;
}

.url-checkout .ladiui.btn-group {
    width: 30%;
    max-width: 30%;
    padding-right: 12px;
}

.url-checkout .ladiui.btn-group .select-checkout-config button {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
}

.url-checkout .title-payment {
    width: 70%;
    /* margin-left: 12px; */
}

.block-billing-infomation .customer-info .item-info {
    display: flex;
    align-items: center;
    line-height: 20px;
}

.block-billing-infomation .customer-info .item-info>span {
    color: var(--main-default);
}

.block-billing-infomation .customer-info .item-info>label {
    color: #6d6d6d;
    white-space: nowrap;
}

.block-customer-field .customer-info .item-info {
    display: flex;
    line-height: 20px;
}

.block-customer-field .customer-info .item-info>span {
    color: var(--main-default);
}

.block-customer-field .customer-info .item-info>label {
    color: #6d6d6d;
    white-space: nowrap;
}

/* .ladiui.btn-tag.custom {
    margin-right: 8px;
} */

#modal-shipping-form .ladiui.modal-body .left .ladiui.form-group.custom-form-group {
    margin-top: 24px !important;
}

/* 
#modal-edit-custom-field .custom-field-content .ladiui.dropdown-menu.custom-form-dynamic {
    top: -184px !important;
} */

/* 
#modal-customer .custom-field-content .ladiui.dropdown-menu.custom-form-dynamic {
    top: -184px !important;
} */

#modal-adjust .ladiui-button-upload {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-top: 20px;
    width: 0px !important;
    height: 0px !important;
}

.ladi-card.fix-width {
    max-width: 900px !important;
}

/* .block-info-top>label {
    font-size: 16px;
    font-weight: 500;
}
.block-info-top .content-info {
    font-size: 14px;
    font-weight: 400;
    color: #6d6d6d;
} */

.ladiui.form-group .publish-times .publish-path {
    width: 100%;
}

.ladiui.form-group .publish-times .publish-redirect-after {
    padding-left: 24px;
    /* width: 30%; */
}

.btn-add-auto-assign {
    border: none !important;
}

.btn-add-auto-assign:hover {
    box-shadow: none !important;
}

.btn-add-auto-assign::after {
    background: #fff !important;
}

.btn-add-auto-assign span {
    color: var(--main-primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.add-auto-assign {
    width: fit-content;
}

.toggle-dropdown-product-edit h2 {
    position: relative;
    background-color: #f1f3f4;
}

.toggle-dropdown-product-edit .ladiui.arrow-down::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    top: 6px;
    right: 10px;
    position: absolute;
    z-index: 0;
    color: #6d6d6d;
    padding-left: 6px;
}

.toggle-dropdown-product-edit .ladiui.arrow-up::after {
    content: '\ea47';
    font-family: 'LadiIcons';
    font-size: 12px;
    right: 10px;
    top: 6px;
    position: absolute;
    z-index: 0;
    color: #6d6d6d;
    padding-left: 6px;
}

.ladiui.list-customer-form-edit li {
    padding: 12px 16px;
}

.ladiui.list-customer-form-edit li .text-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.list-customer-form-edit li .email {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6d6d6d;
}

.ladiui.list-customer-form-edit li span {
    display: block;
}

.ladiui.list-customer-form-edit li:hover {
    background-color: #f1f3f4;
}

.ladiui.list-customer-form-edit {
    max-height: 300px;
    overflow: auto;
    overflow-y: scroll;
}

.table-account th {
    white-space: nowrap;
}

.utm-info .async-auto-complete.menu {
    display: none !important;
}

.toggle-dropdown-product-edit .title-block {
    padding: 6px 16px;
    background-color: #f1f3f4;
}

.toggle-dropdown-product-edit .title-block {
    font-size: 14px;
    font-weight: 500;
}

.list-image-product .list-image .item-image {
    width: max-content;
}

.list-image-product .list-image .item-image .block-icon-star {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    border-radius: 2px;
    display: none;
    left: 3px;
}

.list-image-product .list-image .item-image:hover .block-icon-star {
    display: block;
}

.list-image-product .list-image .item-image .block-delete-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 3px;
}

.list-image-product .list-image .item-image:hover .block-delete-img {
    display: block;
}

#modal-shipping-form .modal-fix-overflow .ladiui.modal-dialog {
    margin-top: 0px !important;
}

/* .ladiui .block-filter-condition .ladiui.tags .tag:first-child {
    margin-left: 10px!important;
} */
.table.table-order tfoot tr {
    height: 40px;
    border-radius: 2px;
    line-height: 40px;
    background-color: #f1f3f4;
}

.table.table-order tfoot tr>td>strong {
    font-weight: 500;
    font-size: 14px;
    color: var(--main-default);
    padding-left: 32px;
}

/* #panel-setting-checkout .ladi-card .table.list-active-payment-method {
    height: 502px;
    max-height: 502px;
    overflow: scroll;
} */

#panel-setting-checkout .ladi-card .table.list-active-payment-method tbody {
    width: 100%;
    display: inline-table;
}

.ladiui-product-right.service .ladiui-label.custom::first-letter {
    text-transform: capitalize;
}

.ladiui.custom-btn-list-landingpage .ladiui.dropdown button {
    line-height: 20px;
}

#tab-package-info .package-content-info {
    /* border: 1px solid var(--main-line); */
    width: 394px;
    padding: 24px;
    margin-top: 40px;

    border-radius: 8px;
    border: 1px solid #E7E9EB;
    background: #ffffff;
}

#tab-package-info .pkg-btn-upgrade {
    display: flex;
    justify-content: center;
    padding-top: 24px;
}

#tab-package-info .pkg-btn-upgrade button {
    width: 100%;
    justify-content: center;
}

.payment-method-note {
    margin-left: 24px;
    color: var(--main-secondary);
    width: -webkit-fill-available !important;
}

#panel-setting-checkout .ladiui.nav-tabs .ladiui.nav-item {
    margin: 0px !important;
    width: 33%;
    text-align: center;
}

#panel-setting-checkout .checkout-config .block-table-customer {
    padding: 0px 16px;
    border: 1px solid #f1f3f4;
    margin-top: 4px;
}

#panel-setting-checkout .block-config-checkout .block-list-config-store-checkout {
    display: flex;
    flex-wrap: wrap;
}

#panel-setting-checkout .block-config-checkout .block-list-config-store-checkout .config-store-checkout {
    width: 50%;
}

.checkout-config-item input[disabled].checkbox:checked {
    opacity: 0.6 !important;
    background-color: #1c00c2 !important;
}

#modal-add-customfield-checkout-config .list-custom-fields ul {
    padding: 16px;
    border-radius: 2px;
    border: 1px solid #e5e5e5;
    max-height: 350px;
    overflow: scroll;
}

#modal-add-customfield-checkout-config .list-custom-fields ul li {
    margin-bottom: 16px;
    cursor: pointer;
}

#modal-add-customfield-checkout-config .list-custom-fields ul li:last-child {
    margin-bottom: 0;
}

.label-finan-create {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    gap: 8px;
}

.setting-page .right-content .content {
    margin-top: 24px;
}

.webhook-transaction .ladiui.block-search .action-item.action-search {
    width: calc(100% - 270px);
}