@media (max-width: 768px) {
    .ladiui.fixed-sidebar .ladiui-main {
        width: 100%;
    }

    .ladiui-topbar .topbar-right {
        position: absolute;
        right: 0px;
        width: 90px;
    }

    .ladiui-aside {
        display: none;
    }

    body.mini-navbar .ladiui-aside {
        display: block !important;
    }

    .ladiui-user-type {
        padding: 2px 8px;
        font-size: 9px;
        top: 16px;
    }

    body.mini-navbar .ladiui-user-type {
        display: none;
    }

    .dashboard {
        display: block;
    }

    .dashboard .card {
        width: 100%;
        display: block;
        margin: 0px;
    }

    .ladiui-topbar.fixed {
        width: 100%;
        display: block;
    }

    .ladiui-topbar .topbar-left {
        width: 100%;
        display: block;
        height: 50px;
    }

    .ladiui-content {
        padding: 45px 10px 0px 10px;
        display: block;
    }

    .topbar-profile .ladiui-navbar.-horizontal > li:first-child {
        display: none;
        top: 1px;
    }

    .ladiui-order-left {
        padding: 0px;
    }

    .ladiui-order-right {
        position: relative;
        width: 100%;
        top: inherit;
        right: inherit;
    }

    .ladiui-table-responsive {
        overflow-x: scroll;
        position: relative;
    }

    .ladiui-table-responsive table {
        width: 1280px !important;
    }

    /* .ladiui.pagination {
        width: 100%;
        text-align: center;
    } */

    .ladiui.pagination-block {
        text-align: center;
    }

    /* .ladiui.pagination>ul>li>a {
        width: 25px;
    } */

    .order-tab {
        clear: both;
    }

    .ladiui.data-limit {
        float: none;
    }

    .order-tab .ladiui-tabs li {
        padding: 10px;
    }
}
