/** @format */

.ladiui.icon-new {
    mask-size: cover;
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.icon-20 {
    width: 20px !important;
    height: 20px !important;
}

.icon-24 {
    width: 24px !important;
    height: 24px !important;
}

.ldicon-note {
    background-color: var(--main-secondary) !important;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
}

.new-ldicon-user-asign {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-asign.svg);
}

.ldicon-ip {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-ip.svg);
}

.new-ldicon-checkout {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-checkout.svg);
}

.ldicon-assignment {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-assignment.svg);
}

.new-ldicon-sequence {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-sequence.svg);
}

.ldicon-arrow-up {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-up.svg);
}

.ldicon-arrow-down {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-down.svg);
}

.ldicon-arrow-right {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-right.svg);
}

.ldicon-arrow-left {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-arrow-left.svg);
}

.new-ldicon-arrow-rightup2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-rightup2.svg);
}

.new-ldicon-arrow-rightdown2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-rightdown2.svg);
}

.new-ldicon-arrow-leftup2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-leftup2.svg);
}

.new-ldicon-arrow-leftdown2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-leftdown2.svg);
}

.new-ldicon-logout {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-logout.svg);
}

.new-ldicon-money {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-money.svg);
}

.ldicon-help {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-help.svg);
}

.new-ldicon-storefront {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-storefront.svg);
}

.new-ldicon-create-content {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-create-content.svg);
}

.new-ldicon-text {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-text.svg);
}

.ldicon-remove {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-remove.svg);
}

.new-ldicon-coupon {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-coupon.svg);
}

.new-ldicon-arrow-right-fill {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-right-fill.svg);
}

.new-ldicon-paint {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-paint.svg);
}

.new-ldicon-cart {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-shopping-cart.svg);
}

.ldicon-button {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-button.svg);
}

.ldicon-delivery-truck {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-delivery-truck.svg);
}

.new-ldicon-bankcard {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-bankcard.svg);
}

.new-ldicon-user-group {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-group.svg);
}

.new-ldicon-effect {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-effect.svg);
}

.ldicon-payment {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-payment.svg);
}

.new-ldicon-home {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-home.svg);
}

.new-ldicon-setting {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-setting.svg);
}

.new-ldicon-storefront {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-storefront.svg);
}

.new-ldicon-notify {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-notify.svg);
}

.new-ldicon-website-2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-website-2.svg);
}

.ldicon-api {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-api.svg);
}

.ldicon-circle-check2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check2.svg);
}

.ldicon-circle-check {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-check.svg);
}

.new-ldicon-code-2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-code-2.svg);
}

.new-ldicon-desktop {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-desktop.svg);
}

.new-ldicon-mobile {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-mobile.svg);
}

.new-ldicon-duotone-search {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-duotone-search.svg);
}

.ldicon-view {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-view.svg);
}

.ldicon-hide {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-hide.svg);
}

.ldicon-sort {
    background-color: var(--icon-primary);
    display: inline-block;
    box-sizing: unset;
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-sort.svg);
    width: 16px;
    height: 16px;
}

.icon-ldp-add {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-add.svg);
    background-color: var(--icon-on-colors);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-close {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-close.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-reload {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-reload.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-post {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-post.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-arrow-down {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-arrow-up {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-up.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-chevron-right {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    background-color: var(--icon-primary);
    width: 12px;
    height: 12px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-chevron-left {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-left.svg);
    background-color: var(--icon-primary);
    width: 12px;
    height: 12px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-info {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-info.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.icon-move {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-folder-error {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-folder-error.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.icon-caret-down-fill {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/icon-caret-down-fill.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
    transition: all 100ms;
}

.new-ldicon-user {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user.svg);
    width: 64px;
    height: 64px;
}

.new-ldicon-box {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-box.svg);
    width: 64px;
    height: 64px;
}

.new-ldicon-copy {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-copy.svg);
}

.new-ldicon-edit {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-edit.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-delete {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-delete.svg);
    background-color: var(--icon-danger) !important;
}

.new-ldicon-fill-cart {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-fill-cart.svg);
    background-color: var(--icon-primary-2);
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-circlecheck-fill {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-circlecheck-fill.svg);
    background-color: #4ac000;
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-ticket {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-ticket.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-search {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-search.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-inbox {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-inbox.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-abandoned-cart {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-abandoned-cart.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-transport {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-transport.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-tag {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-tag.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-custom-field {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-custom-field.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-add-circle {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-add-circle.svg);
    background-color: var(--text-brand);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-add {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-add.svg);
    background-color: var(--text-brand);
    width: 12px;
    height: 12px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-bill {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-bill.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-photo {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-photo.svg);
    background-color: var(--icon-primary);
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-file {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-file.svg);
    background-color: var(--text-brand) !important;
}

.new-ldicon-upload {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-upload.svg);
}

.new-ldicon-link2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-link2.svg);
}

/* icon-background */
.new-ldicon-close-fill-2 {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-close-fill-2.svg) no-repeat !important;
    background-size: contain !important;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.ladiui.icon-ldp-close {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-close-fill-2.svg) no-repeat !important;
    background-size: contain !important;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    mask: unset !important;
}

.ldicon-printer {
    background: url(https://w.ladicdn.com/ladiui/icons/ldicon-printer.svg) no-repeat !important;
    background-size: contain !important;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    mask: unset !important;
}

.ldicon-circle-close {
    background: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-close.svg) no-repeat !important;
    background-size: contain !important;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    mask: unset !important;
}

.theme-background {
    background: url(https://w.ladicdn.com/s600x600/6109/theme-20231021082316-9lflp.png);
    background-size: cover;
    display: block;
    width: 84px;
    height: 53px;
    cursor: pointer;
    mask: unset !important;
}

.new-ldicon-minus2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-minus2.svg);
}

.new-ldicon-double-arrow-left {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-double-arrow-left.svg);
}

.new-ldicon-arrow-left {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-left.svg);
}

.new-ldicon-arrow-right {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-right.svg);
}

.new-ldicon-double-arrow-right {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-double-arrow-right.svg);
}

.new-ldicon-lock-duotone {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-lock-duotone.svg);
}

.new-ldicon-open-link {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-open-link.svg);
}

.new-ldicon-footer-sticky {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-footer-sticky.svg);
}

.new-ldicon-refresh {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-refresh.svg);
}

.new-ldicon-phone {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-phone.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-userid {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-userid.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-bankcard {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-bankcard.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-user-asign {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-user-asign.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-list {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-list.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-home {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-home.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-circle-add {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-circle-add.svg);
    background-color: var(--icon-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-list-module {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-list-module.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-slide-left {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-slide-left.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-slide-right {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-slide-left.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
    transform: rotate(180deg);
}

.icon-ldp-more {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-more.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-save {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-save.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-email-video {
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-email-video.svg);
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.new-ldicon-upload-2 {
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-upload.svg);
    mask-size: cover;
    background-color: #566577;
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

.ldicon-course {
    box-sizing: unset;
    display: inline-block;
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-course.svg);
}


