@import './pageCheckout/style.css';
@import './customer/customer.css';
@import './checkout/checkout.css';
@import './history/history.css';
@import './shipping/shipping.css';
@import './products/digital.css';

:root {
    --main-secondary: #9fa7b1;
    --main-default: #0e233c;
    --main-primary: #1c00c2;
    --hover-secondary: #cfd3d8;
}

.ladiui.modal-header {
    justify-content: flex-start !important;
    padding: 20px 24px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom: 1px solid var(--border-02);
}

.ladiui.title-text-form-lever1 {
    font-size: 20px !important;
    color: var(--text-primary) !important;
}

.ladiui.modal-header .sub-title {
    font-size: 14px;
    line-height: 20px;
    color: var(--text-secondary);
}

.ladiui.modal-close .icon-ldp-close {
    width: 20px;
    height: 20px;
}

.ladiui.modal-close:hover {
    opacity: 0.8 !important;
}

.ladiui.modal-close:hover .ladiui.icon-ldp-close {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-close-fill-2.svg) no-repeat !important;
    background-size: contain !important;
}

.ladiui.modal-footer {
    padding: 20px 24px !important;
    border-top: 1px solid var(--border-02);
}

.ladiui.btn-sm {
    height: 32px !important;
    padding: 6px 16px !important;
}

.ladiui.btn-md {
    height: 42px !important;
    padding: 10px 16px !important;
}

.ladiui.btn {
    border-radius: 4px !important;

    border: 1px solid var(--border-01);
}

.ladiui.btn.disable {
    opacity: 0.6;
    cursor: no-drop !important;
}

.ladiui.form-control {
    padding: 10px 12px !important;
    border-radius: 4px !important;
    border: 1px solid var(--border-01);
}

.ladiui.form-control:focus {
    border: 1px solid var(--border-brand);
}

.ladiui.error-input {
    color: var(--text-danger);
    margin-top: 4px;
}

.ladiui.tab-link {
    padding: 12px 0px !important;
}

.ladiui.dropdown-menu {
    padding: 10px 0px !important;
    border-radius: 4px;
    z-index: 99;
    max-height: 500px;
    overflow: auto;
}

.ladiui.label-12 {
    color: var(--text-primary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.ladiui.label-12-secondary {
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}

.ladiui.label-14 {
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.ladiui.label-16 {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.ladiui.label-18 {
    color: var(--text-primary);
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.ladiui.label-20 {
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

.ladiui.text-14 {
    color: var(--text-secondary);
    font-size: 14px;
}

.ladiui.text-14-b500 {
    color: var(--text-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.ladiui.text-14-brand {
    color: var(--text-brand);
    font-size: 14px;
    line-height: 20px;
}

.ladiui.text-18-secondary {
    color: var(--text-secondary);
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.ladiui.text-primary {
    color: var(--text-primary) !important;
}

.ladiui.text-danger {
    color: var(--text-danger) !important;
}

.ladiui.text-brand {
    color: var(--text-brand) !important;
}

.ladiui.text-upper {
    text-transform: uppercase;
}

.ladiui.fw-500 {
    font-weight: 500;
}

.ladiui.text-center {
    text-align: center;
}

.ladiui.text-right {
    text-align: right;
}

.ladiui.heading-label-2 {
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
}

.ladiui.flex-center {
    display: flex;
    align-items: center;
}

.ladiui.flex-center-between {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.ladiui.label-02 {
    color: var(--text-primary);
    /* Heading/label-02 */
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
}

.ladiui.pos-rel {
    position: relative;
}

.ladiui.search-section .search-section-detail {
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(32, 32, 32, 0.15);
    width: 100%;
    position: absolute;
    max-height: 0px;
    overflow: auto;
    z-index: 15;
}

.ladiui.search-section .search-section-detail.show {
    max-height: 500px;
}

.ladiui.search-section .search-section-detail .create-btn {
    padding: 16px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

.ladiui.search-section .search-section-detail .create-btn i {
    position: unset !important;
}

.ladiui.search-section .search-section-detail .search-section-list {
    padding: 12px 0px;
}

.ladiui.search-section .search-section-detail .search-section-list .search-section-item {
    padding: 12px 16px;
    border-top: 0.5px solid var(--border-02);
    cursor: pointer;
}

.ladiui.search-section .search-section-detail .search-section-list .search-section-item:hover {
    background: var(--layer-brand);
    color: var(--text-brand);
}

.ladiui.value-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.ladiui.value-list .value-item {
    display: flex;
    align-items: center;
}

.ladiui.flex-center-end {
    display: flex;
    align-items: center;
    justify-content: end;
}

.ladiui.flex-center-start {
    display: flex;
    align-items: center;
    justify-content: start;
}

.ladiui.btn-md.dropdown-toggle::after {
    margin-top: 15px;
}

.ladiui.dropdown-toggle::after {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down.svg) no-repeat center;
}

.ladiui.boder-none {
    border: none !important;
}

.ladiui.search-field {
    height: 40px !important;
    padding-left: 32px !important;
}

.block-search .DateRangePickerInput {
    height: 40px !important;
}

.block-search .DateRangePickerInput input {
    height: 38px;
}

.ladiui.popover-right {
    left: unset !important;
    right: 0px !important;
}

.ladiui.dropdown-text {
    margin-right: 8px !important;
}

.ladiui.dropdown-item i,
.ladiui.dropdown-item span {
    pointer-events: none;
}

.ladiui.container-place {
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    border: 0.5px solid var(--border-02);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.ladiui.container-place .footer {
    padding-top: 16px;
    border-top: 0.5px solid var(--border-02);
}

.ladiui.tag {
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    width: fit-content;
    font-weight: 400;
}

.ladiui.shipping-pending,
.ladiui.payment-pending {
    color: var(--full-color-warning-700);
    background: var(--layer-layer-warning-layer-warning-hover);
}

.ladiui.shipping-readytopick,
.ladiui.shipping-returning,
.ladiui.shipping-returned,
.ladiui.shipping-deliveredtopostman,
.ladiui.shipping-delivering {
    color: var(--support-support-info, #3c72f9);
    background: var(--layer-layer-info-layer-info-hover, #d8e7fe);
}

.ladiui.shipping-delivered,
.ladiui.payment-success {
    background: var(--layer-layer-success-layer-success-hover, #e8fbc9);
    color: var(--full-color-success-700, #248a00);
}

.ladiui.shipping-canceled,
.ladiui.payment-canceled {
    color: #ffffff;
    background: var(--button-danger);
}

.ladiui.restock-all {
    color: #facc64;
    background: #fef2cb;
}

.ladiui.order-canceled {
    color: #f58973 !important;
    background: #fde0d0 !important;
}

.laidui.background-xam {
    background: var(--background-background-02, #f3f4f5);
}

.ladiui.variant-image-48 {
    width: 48px;
    height: 48px;
    border-radius: 4px;
}

.ladiui.text-heading-02 {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.ladiui.flex-start-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.ladiui.flex-column-end {
    display: flex;
    align-items: end;
    flex-direction: column;
}

.ladiui.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ladiui.border-t-50p {
    border-top: 0.5px solid var(--border-02);
}

.ladiui.search-group i {
    z-index: 1;
    left: 5px;
    right: unset !important;
}

.ladiui.search-group i.ls-clear-search-input {
    z-index: 1;
    right: 10px !important;
    top: 12px !important;
    left: unset !important;
}

.ladiui.table-vertical th:first-child,
.ladiui.table-vertical td:first-child {
    padding-left: 0px;
    /* min-width: 120px; */
}

.ladiui.action-table {
    width: fit-content;
}

.ladiui.action-table .btn-group .dropdown-toggle {
    min-width: unset !important;
    margin-right: 0px !important;
}

.ladiui.email-status-1::before {
    content: '';
    display: inline-block;
    background: #4ac000;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #248a0033;
    background-clip: padding-box;
}

.ladiui.email-status-0::before {
    content: '';
    display: inline-block;
    background: #ef9300;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #ef930033;
    background-clip: padding-box;
}

.ladiui.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1220px;
    margin: 0px auto;
    padding: 16px 24px;
}

.ladiui.header-o {
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 16;
    border-bottom: 0.5px solid var(--border-01-50p);
}

.ladiui.order-breadcrumbs {
    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--text-secondary);
    font-size: 14px;
    line-height: 24px;
}

.ladiui.order-breadcrumbs .back-btn {
    cursor: pointer;
}

.ladiui.order-breadcrumbs .back-btn:hover {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
}

.ladiui.content-max-width {
    max-width: 1220px;
    margin: auto;
}

.ladiui.group-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.ladiui.group-body .ladiui.body-right {
    width: 710px;
}

.ladiui.group-body .ladiui.body-left {
    width: 375px;
}

.ladiui.flex-wap-wap {
    flex-wrap: wrap;
}

.ladiui.page-container {
    min-height: unset !important;
    height: 24px;
    gap: 20px;
}

.ladiui.page-container a {
    /* padding: 0px !important; */
    margin: 0px !important;
    border-radius: 4px;
    color: #566577 !important;
    line-height: 20px;
    /* padding: 0px 8px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.ladiui.page-container a.active,
.ladiui.page-container a:hover {
    color: #0E233C;
    background: #F3F4F5;
    color: var(--text-text-brand, var(--text-text-brand, #1c00c2)) !important;
}

.ladiui.page-container .ldicon-forward,
.ladiui.page-container .ldicon-chevron-left,
.ladiui.page-container .ldicon-chevron-right,
.ladiui.page-container .ldicon-backward {
    width: 16px;
    height: 16px;
}

.ladiui.dropdown-item {
    padding: 6px 16px;
    white-space: break-spaces;
    text-transform: none;
}

img {
    object-fit: cover;
}

.ladiui.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: 14;
    height: 100%;
    width: 100%;
}

.ladiui.payment-status-pending::before,
.ladiui.shipping-status-pending::before {
    content: '';
    display: inline-block;
    background: #ef9300;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #ef930033;
    background-clip: padding-box;
}

.ladiui.payment-status-pending.draft-order::before {
    background: #bcbcbc;
    border: 3px solid #bcbcbc33;
}

.ladiui.payment-status-success::before,
.ladiui.shipping-status-delivered::before {
    content: '';
    display: inline-block;
    background: #4ac000;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #248a0033;
    background-clip: padding-box;
}

.ladiui.shipping-status-readytopick::before,
.ladiui.shipping-status-returning::before,
.ladiui.shipping-status-returned::before,
.ladiui.shipping-status-deliveredtopostman::before,
.ladiui.shippingstatus--delivering::before {
    content: '';
    display: inline-block;
    background: #3c72f9;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #d8e7fe;
    background-clip: padding-box;

    /* color: var(--support-support-info, #3c72f9);
    background: var(--layer-layer-info-layer-info-hover, #d8e7fe); */
}

.ladiui.shipping-status-canceled::before,
.ladiui.payment-status-canceled::before {
    content: '';
    display: inline-block;
    background: var(--button-danger);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #fde0d0;
    background-clip: padding-box;

    /* color: #ffffff;
    background: var(--button-danger); */
}

.form-check-input {
    width: 14px !important;
    height: 14px !important;
}

.form-check-input:checked::before {
    width: 8px !important;
    height: 8px !important;
    top: 2px !important;
    right: 2px !important;
}

.ladiui.dropdown-up {
    top: unset !important;
    bottom: 20px !important;
}

.ladiui.error-empty {
    border: 1px solid var(--border-dange);
}

.ladiui.page-body {
    background: var(--background-02);
}

.ladiui.page-content {
    max-width: 1220px;
    margin: 0px auto;
    padding: 24px;
    overflow: auto;
    /* background: #ffffff; */
    /* min-height: 500px; */
}

.ladiui.modal-footer.justify-space-between {
    justify-content: space-between !important;
}

.ladiui.selected-box-tem {
    padding: 20px 16px;
    border-radius: 4px;
    border: 1px solid #e7e9eb;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    cursor: pointer;
    width: 100%;
}

.ladiui.selected-box-tem .box-icon {
    background: #f3f4f5;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.ladiui.selected-box-tem .box-title {
    width: calc(100% - 68px);
    line-height: 20px;
}

.ladiui.selected-box-tem .box-icon i {
    width: 24px !important;
    height: 24px !important;
}

.ladiui.selected-box-tem:hover {
    /* background: #f4f2fc; */
    border: 1px solid #5036da;
}

.ladiui.selected-box-tem.active {
    border: 1px solid #5036da;
}

.ladiui.selected-box-tem.active .box-icon {
    background: #f4f2fc;
}

.ladiui.selected-box-tem.active .box-icon i {
    background: #1c00c2 !important;
}

.ladiui.help-box {
    position: relative;
}

.ladiui.help-box .ladiui.help-data {
    display: none;
    width: 224px;
    height: 118px;

    position: absolute;
    z-index: 2;
    /* left: 20px; */
    bottom: 30px;
    right: -100px;
}

.ladiui.help-box:hover .ladiui.help-data {
    display: block;
}

.ladiui.checked-radio-v2 {
    border: 1px solid var(--main-mute);
    padding: 2px;
    border-radius: 50%;
}

.ladiui.checked-radio-v2.selected {
    border: 1px solid var(--main-primary);
}

.ladiui.checked-radio-v2 .background {
    width: 8px !important;
    height: 8px !important;

    background-color: #ffffff;
    border-radius: 50%;
    transition: 0.5s;
}

.ladiui.checked-radio-v2.selected .background {
    background-color: var(--main-primary);
}

.ladiui.text-area {
    padding: 10px 12px !important;
    border-radius: 4px !important;
    border: 1px solid var(--border-01);
    display: block;
    width: 100%;
}

.form-control.pr-32 {
    padding-right: 32px !important;
}

.btn-select-option {
    padding-right: 30px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    height: unset;
    min-height: 42px;
}

.btn-select-option .ldicon-close {
    width: 12px;
    height: 12px;
}

.ladiui.alert-text:first-letter {
    text-transform: uppercase;
}

.ladiui.menu-item .new-ldicon-inbox {
    width: 24px;
    height: 24px;
}


.iframe-open #sidebar-left .ladiui.aside .ladiui.nav,
.iframe-open .content-main-full .page-order .menu-left {
    display: none;
}

.iframe-open .ladiui.main-custom {
    width: 100%;
    margin: auto;
}

.iframe-open .page-max {
    width: 100%;
    overflow: hidden;
    height: unset;
}

.iframe-open .page-order .page-right,
.iframe-open .setting-page .right-content {
    width: 100%;
    /* max-width: unset; */
}

.ladiui.btn-secondary,
.ladiui.btn-danger,
.ladiui.btn-primary {
    border: unset;
}

.ladiui.dropdown .ladiui.btn {
    padding-right: 32px !important;
}

.iframe-open.ladiui.wrapper {
    min-width: unset;
}

.ladiui.modal-close {
    padding: 0px;
}

.ladiui.select-dropdown {
    color: #0e233c;
}

.ladiui.btn-outline-light:hover {
    border-color: var(--hover-secondary);
}

.page-no-access-content {
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.page-no-access-content .page-no-access-content-icon {
    width: 80px;
    height: 80px;
}

.page-no-access-content .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.page-no-access-content .sub-title {
    color: #566577;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.page-onboading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.page-onboading .page-onboading-image {
    max-width: 1220px;
    width: 100%;
}

.page-onboading .page-onboading-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 689px;
    text-align: center;
}

.page-onboading .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.page-onboading .sub-title {
    color: #566577;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.onboading-item .onboading-image {
    width: 412px;
}

.onboading-item .onboading-item-content {
    margin-top: 24px;
    text-align: center;
}

.onboading-item .onboading-item-content .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.onboading-item .onboading-item-content .content {
    color: var(--Text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
}

.ladiui.pagination-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background-color: #E7E9EB;
}

.ladiui.pagination-dot.active {
    background-color: #1C00C2;
}

.onboading-page-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
}

.onboading-item-action {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.onboading-item-action button {
    width: 50%;
    justify-content: center;
}

.store-create-name .title {
    color: #0E233C;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.store-create-name #store-using-default {
    width: 18px;
    height: 18px;
}

.store-create-name .sub-input {
    color: #0E233C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.store-create-name .btn-start {
    justify-content: center;
    color: var(--Icon-icon-on-colors, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

input.ladiui.error {
    border-color: #E01A1A;
}

.ladiui.input-sub-error {
    color: #E01A1A;
    margin-top: 4px;

    font-size: 12px;
}

.create-account-success-content .image-loading {
    width: 80px;
    height: 80px;
    margin-top: 60px;
}

.create-account-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create-account-loading .title {
    color: #0E233C;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 32px;
}

.create-account-loading .sub-title {
    color: #566577;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

/* https://w.ladicdn.com/ladiui/icons/new-ldicon-background-successfully.svg */
.create-account-success .header {
    margin-top: 20px;
    text-align: center;
}

.create-account-success .header .bg-hoa-hoet {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-background-successfully.svg) no-repeat;
    padding: 10px;
}

.check-success {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #BEF29E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.check-success .ldicon-circle-check {
    width: 32px;
    height: 32px;
    background-color: #35A500;
}

.create-account-success .title {
    color: var(--Gray-900, #101828);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.create-account-success .sub-title {
    color: var(--Text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.create-account-success .list-actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.create-account-success .list-actions .action-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.create-account-success .list-actions .action-item .antion-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.create-account-success .list-actions .action-item .action {
    color: var(--Link-link-primary, #2B57D6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    cursor: pointer;
}

#ladi-popup-error-message .ladiui.modal-content {
    padding: 0px;
}

#ladi-popup-error-message .ladiui.modal-header {
    border-bottom: unset;
}

#ladi-popup-error-message .ladiui.modal-body {
    padding: 0px 24px !important;
}

#ladi-popup-error-message .ladiui.modal-footer {
    border-top: unset;
}

.tooltip:hover .tooltiptext {
    visibility: visible !important;
    opacity: 1 !important;
}

.tooltip .tooltiptext.top-left {
    visibility: hidden;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 900000090;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    line-height: 20px;
    opacity: 0;
    transition: opacity 1s;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.tooltip .tooltiptext.top-left::before {
    content: attr(tooltiptext);
    position: absolute;
    top: -7px;
    right: 0px;
    background: #0E233C;
    border-radius: 4px;
    color: #fff;
    padding: 8px 16px;
    min-width: 20px;
    max-width: 200px;
    margin: auto;
    width: max-content;
    text-align: center;
    transform: translateX(0) translateY(-100%);
    word-break: break-all;
}

.tooltip .tooltiptext.top-left::after {
    content: "";
    position: absolute;
    top: -7px;
    left: 6px;
    border-width: 6px;
    border-style: solid;
    border-color: #0E233C transparent transparent transparent;
}

.ladiui.switch input {
    display: -webkit-box;
}

.tox.tox-tinymce.tox-fullscreen {
    top: 60px !important;
}

#confirm-variant-delete {
    z-index: 1080;
}