@import './service.css';
@import url('./course.css');

.valid-input-error {
    border: 1px solid red !important;
}

.text-default {
    color: #6d6d6d;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.mini-dropdown {
    width: 100% !important;
    height: 42px !important;
    min-width: unset !important;
    background: #f1f3f4 !important;
    border-radius: 2px !important;
}

.mini-dropdown.fix {
    width: 100% !important;
}

.mini-dropdown-pd {
    padding: 2px 5px !important;
}

.head-accordion {
    background: #f1f3f4 !important;
    border-radius: 2px !important;
}

.min-max-buy {
    justify-content: space-between !important;
    display: flex !important;
    align-items: center !important;
}

.variant-option-item .variant-option-value {
    width: calc(100% - 380px);
    margin-left: 20px;
}

.btn-add-option {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    padding-left: 0px !important;
    cursor: pointer;
    width: fit-content;
}

.un-hover:hover,
.un-hover:focus {
    border: unset !important;
}

/* .list-image-product .list-image .item-image {
    margin-right: 23px;
} */

.list-image-product .list-image.grid-box {
    /* display: flex;
    align-items: center;
    width: 400px;
    overflow: auto; */
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 24px;
}

.list-image-product .list-image.flex-box {
    display: flex;
}

.list-image-product .list-image.flex-box .item-image {
    margin-right: 24px !important;
}

.list-image-product .list-image .btn-add-image {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #afafaf;
    padding-top: 20px;
}

.list-image-product .image-none .ladiui-button-image {
    position: relative;
    width: 400px;
    height: 400px;
    background: #f1f3f4;
    border-radius: 2px;
}

.list-image-product .image-none .btn-add-image {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #afafaf;
    padding: 150px 80px;
}

.list-image-product .image-none .ladiui-button-image input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.item-variant-list-option .option-name {
    color: #6d6d6d;
}

.item-variant-list-option .option-value {
    color: var(--main-default);
}

.block-custom-interface {
    margin-bottom: 30px;
}

.ladiui.iframe-checkout {
    text-align: center;
    background: #d9d9d9 !important;
    padding: 20px 0px;
}

#iframe-checkout-page {
    height: calc(100vh - 340px);
}

#iframe-store-info {
    height: calc(100vh - 200px);
}

#iframe-interface {
    height: calc(100vh - 200px);
}

#iframe-general-store {
    height: calc(100vh - 200px);
}

.tox-editor-container .tox-editor-header .tox-menubar {
    display: none;
}

.product-name-table {
    display: flex !important;
    align-items: center;
    gap: 8px;
}

.product-name-table .product_img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

.btn-product-view {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #f3f4f5;
    color: #0e233c;
    cursor: pointer;

    padding: 10px 16px;
}

.btn-product-view .new-ldicon-box {
    width: 16px !important;
    height: 16px !important;
}

.table-custom th:first-child,
.table-custom td:first-child {
    min-width: 40px;
}

.publish-redirect-after .ladiui.dropdown-text {
    width: 116px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0px;
}

.table-tr-th {
    color: #566577;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
}

.ticket-table {
    min-width: 1110px
}

.btn-delete-variant-image {
    /* position: absolute; */
    /* right: 0px; */
    /* z-index: 2; */

    border-radius: 3px;
    background: var(--Store-Color-store-button-primary-default, #EE1D52);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12), 0px -1px 38px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 8px !important;
    width: fit-content;
    margin: 16px auto;

    color: #ffffff;

}

.btn-delete-variant-image .new-ldicon-delete {
    background-color: #ffffff !important;
}

.btn-change-image {
    border-radius: 3px !important;
    background: var(--Course-Color, #1C00C2) !important;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.12), 0px -1px 38px 0px rgba(0, 0, 0, 0.05);
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    gap: 4px;
    padding: 8px !important;
    width: fit-content;
    margin: auto;
    color: #FFFFFF !important;
    font-family: Roboto;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal !important;
    border: none !important;
}

.btn-change-image .new-ldicon-refresh {
    background-color: #ffffff !important;
}

.setting-block-image input {
    display: none;
}

.product-highlight .group-type-highlight {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.product-highlight .block-content-highlight .ladiui.form-group {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;

    flex-direction: column;
}

.flas-sale-dayly {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
}

.flas-sale-dayly .ladiui.form-group {
    width: fit-content !important;
}

.group-show-time {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.react-time-picker__wrapper {
    border: 1px solid #CFD3D8 !important;
    border-radius: 4px;
}