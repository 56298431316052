.ladisales-location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.ladisales-location .country-district {
    width: 50%;
}
.ladisales-location .state-ward {
    width: 50%;
}

.content-customer-create .customer-email-phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.content-customer-create .customer-location {
    padding-top: 20px;
    border-top: 1px solid var(--border-02);
}
.content-customer-create .customer-email-phone .item {
    width: 50%;
}

.customer-page .action-record-table {
    left: 30px;
}
