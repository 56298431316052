.page-integrate {
    background-color: #fff;
    padding: 24px;
}

.page-integrate .ladiui-tab-content {
    padding: 0px;
}

.page-integrate .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#modal-form-account .list-integrate {
    display: grid;
    /* grid-template-columns: auto auto auto; */
    grid-template-columns: 240px 240px 240px;
    column-gap: 16px;
    row-gap: 16px;
}

#modal-form-account .list-integrate .item-integrate {
    padding: 16px;
    cursor: pointer;
    border: 1px solid #f1f3f4;
}

#modal-form-account .list-integrate .item-integrate .img-title {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

#modal-form-account .list-integrate .item-integrate .description {
    font-size: 14px;
    color: #6d6d6d;
}

#modal-form-account .list-integrate .item-integrate .description p {
    line-height: 20px;
}

#modal-form-account .list-integrate .item-integrate .img-title .icon-form-account {
    margin-right: 16px;
}

.page-report-content {
    background-color: #fff;
    padding: 24px;
}

#page-customer {
    padding: 24px;
    background-color: #fff;
}

#page-customer .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#page-customer .block-title-action .block-left {
    display: flex;
    align-items: center;
}

#page-customer .block-title-action .block-left .title {
    margin-right: 26px;
}

#page-checkout .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#page-checkout .block-title-action .block-left {
    display: flex;
    align-items: center;
}

#page-checkout .block-title-action .block-left .title {
    margin-right: 26px;
}

#page-tags {
    padding: 24px;
}

#page-tags .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#page-tags .block-title-action .block-left {
    display: flex;
    align-items: center;
}

#page-tags .block-title-action .block-left .title {
    margin-right: 26px;
}