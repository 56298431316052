.ladiui.list.inline {
    margin: 0;
    padding: 0;
    display: inline-block;
}

.ladiui.list.inline>li {
    display: inline-block;
}

.ladiui.list.inline>li>a {
    padding: 10px;
}

.ladiui.breadcrumbs {
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 15px 0;
}

.ladiui.breadcrumbs>li {
    display: inline-block;
    padding: 5px;
}

.ladiui.breadcrumbs>li:first-child:before {
    content: '';
    margin: 0;
}

.ladiui.breadcrumbs>li:before {
    font: normal normal normal 1em/1 'LadiIcons';
    content: '\ea21';
    margin-left: 0;
    margin-right: 15px;
    font-size: 8px;
    color: #8697a8;
}

.ladiui.breadcrumbs>li,
.ladiui.breadcrumbs>li>a {
    color: #313942;
    font-size: 14px;
}

.ladiui.breadcrumbs>li.active {
    font-weight: 700;
}

.ladiui-input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

/* .ladiui-input-group-prepend {
	margin-left: -1px
} */

.ladiui-input-group .ladiui.input {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.ladiui-input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 26px;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    border-right: 0;
}

.ladiui-input-group>.ladiui-input-group-append:last-child>.ladiui-input-group-text:not(:last-child),
.ladiui-input-group>.ladiui-input-group-append:not(:last-child)>.ladiui-input-group-text,
.ladiui-input-group>.ladiui-input-group-prepend>.ladiui-input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ladiui-input-group>.ladiui.input:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ladiui.select {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    height: 40px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    transition: border-color 0.15s ease-out;
}

.ladiui.textarea {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    min-height: 120px;
    border-radius: 4px;
    -webkit-transition: border-color 0.15s ease-out, -webkit-box-shadow 0.15s ease-out;
    transition: border-color 0.15s ease-out, -webkit-box-shadow 0.15s ease-out;
    transition: border-color 0.15s ease-out, box-shadow 0.15s ease-out;
    transition: border-color 0.15s ease-out, box-shadow 0.15s ease-out, -webkit-box-shadow 0.15s ease-out;
}

.ladiui.form-group {
    /* margin-bottom: 24px; */
    width: 100%;
}

.clearfix {
    clear: both;
}

/* .ladiui.input.focus,
.ladiui.input:focus,
.ladiui.select.dropdown.focus,
.ladiui.textarea:focus {
	border-color: #1853fa;
	outline: 0
} */

.ladiui.dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: 0;
    text-align: left;
    -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
    -webkit-tap-highlight-color: transparent;
}

.ladiui.select.dropdown .menu {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    width: auto;
    outline: 0;
    margin: 2px -1px;
    border-radius: 0 0 0.28571429rem 0.28571429rem;
    -webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    /* box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15); */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
}

.ladiui.dropdown>.text {
    display: inline-block;
    -webkit-transition: none;
    transition: none;
    padding: 6px 30px 6px 12px;
    width: 100%;
    z-index: 100;
    position: relative;
}

.ladiui.select.dropdown {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    left: 0;
    top: 1px;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    background: #fff;
    display: inline-block;
    line-height: 27px;
    border: 1px solid #ebedf2;
    border-radius: 0.28571429rem;
    -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
    position: relative;
}

.ladiui.select.dropdown:after {
    content: '\ea4a';
    width: 30px;
    height: 30px;
    position: absolute;
    font-family: 'LadiIcons';
    top: 5px;
    right: -8px;
    font-size: 13px;
}

.ladiui.dropdown .menu.open {
    display: block;
    left: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scaleY(1);
    -o-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top;
    -o-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transition: -webkit-transform 0.4s ease-out;
    -o-transition: -o-transform 0.4s ease;
    -ms-transition: -ms-transform 0.4s ease;
    transition: transform 0.4s ease;
}

.ladiui.dropdown .menu {
    cursor: auto;
    position: absolute;
    display: none;
    outline: 0;
    top: 100%;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    margin: 0;
    padding: 0 0;
    background: #fff;
    font-size: 1em;
    text-shadow: none;
    text-align: left;
    -webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
    z-index: 11;
    will-change: transform, opacity;
    -webkit-transition: opacity 0.6s, visibility 0.6s;
    transition: opacity 0.6s, visibility 0.6s;
}

.ladiui.dropdown .menu>.item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.78571429rem 1.14285714rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-touch-callout: none;
}

/* .ladiui.input,
.ladiui.select {
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	-webkit-box-shadow: 0 8px 16px 0 rgba(169, 194, 209, .1);
	color: #313942
} */

/* .ladiui.input.error,
.ladiui.select.error {
	border-color: #d50000;
} */

.ladiui.fixed-sidebar .ladiui-aside {
    position: fixed;
    width: 200px;
    top: 57px;
}

.ladiui.fixed-sidebar .ladiui-main {
    width: calc(100% - 200px);
    float: right;
    padding-bottom: 40px;
}

.ladiui.datepicker {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    left: 0;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    background: #fff;
    display: inline-block;
    line-height: 25px;
    border: 1px solid #ebedf2;
    border-radius: 0.28571429rem;
    -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    transition: box-shadow 0.1s ease, width 0.1s ease, -webkit-box-shadow 0.1s ease;
    position: relative;
    padding: 6px 30px 6px 12px;
}

.ladiui.datepicker:after {
    content: '\ea6b';
    width: 30px;
    height: 30px;
    position: absolute;
    font-family: 'LadiIcons';
    top: 5px;
    right: -5px;
    font-size: 16px;
}

.ladiui.datepicker .date-value {
    color: #313942;
}

/* .ladiui.dropdown-menu { */
/* position: relative;
	display: inline-block;
	background: #fff;
	border-radius: 5px;
	z-index: 1;
	top: 2px
} */

.ladiui.dropdown-menu.rounded {
    border: 1px solid #ebedf2;
}

.ladiui.dropdown-menu-item {
    color: #313942;
    height: 38px;
    padding: 0 12px;
    border: none;
    cursor: pointer;
    line-height: 38px;
}

.ladiui.dropdown-menu-item i {
    font-size: 13px;
    margin-left: 4px;
}

.ladiui.dropdown-submenu {
    display: none;
    position: absolute;
    border-radius: 5px;
    min-width: 160px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    background: #fff;
    top: 43px;
}

.ladiui.dropdown-submenu a {
    color: #313942;
    padding: 6px 12px;
    text-decoration: none;
    display: block;
    text-align: left;
    line-height: 34px;
}

.ladiui.dropdown-submenu a:hover {
    background-color: #ebedf2;
    border: none;
}

/* .ladiui-modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0px;
	left: 0px;
	overflow: hidden;
	opacity: 1;
	transition: all 150ms linear;
	z-index: 15;
	outline: 0;
	display: none;
	background-color: rgba(0, 0, 0, 0.1);
}

.ladiui-modal-content {
	top: calc(5% - 5px);
	left: 0;
	right: 0;
	max-width: 900px;
	margin: 0 auto;
	box-shadow: 0 5px 100px rgba(6, 21, 40, .5);
	background: #fff;
	border-radius: 8px;
	z-index: 21;
	position: relative;
}

.ladiui-modal-header {
	padding: 15px 20px;
	border-bottom: 1px solid rgba(0, 0, 0, .02);
	background: rgba(6, 21, 40, .05);
	position: relative
} */

.ladiui-modal-header i.icon-e-remove {
    font-size: 12px;
}

.ladiui-modal-title {
    font-size: 16px;
    font-weight: 600;
}

.ladiui-close-modal {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 18px;
    padding: 11px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 150ms linear;
    background: 0 0;
    border: 0;
}

.ladiui-modal-header h3 {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}

.ladiui-modal-nav {
    border-bottom: 1px solid rgba(6, 21, 40, 0.05);
    height: 55px;
    position: relative;
    padding-top: 10px;
}

.ladiui-modal-container {
    padding: 20px;
    vertical-align: top;
    background: #fff;
    display: inline-block;
    width: calc(100% - 225px);
}

.ladiui-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(53, 53, 53, 0.16);
    z-index: 14;
    height: 100%;
    width: 100%;
}

.ladiui-modal-backdrop.no-bg {
    background: transparent;
}

.visible {
    display: block !important;
}

.hide {
    display: none !important;
}

.ladiui-tabs {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.ladiui-tabs li {
    float: left;
    list-style: none;
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.ladiui-tabs li {
    display: inline-block;
}

/* .ladiui-tabs li.active a {
	color: #1853fa;
	border-bottom: 2px solid #1853fa;
	padding-bottom: 5px
} */

/* .ladiui-tabs li a {
	color: #313942;
	text-decoration: none
} */

.ladiui-tab-content {
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 72vh;
}

.ladiui-tab-item {
    display: none;
}

.ladiui-tab-item.active {
    display: block;
}

.tox .tox-notification {
    display: none !important;
}

.ladiui-col-4 {
    width: 40%;
    float: left;
}

.ladiui-col-8 {
    width: 60%;
    float: right;
}

.ladiui-widget {
    border-radius: 3px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.ladiui-widget .ladiui-widget-head {
    height: 40px;
    background: rgba(235, 240, 243, 0.1);
}

.ladiui-widget .ladiui-widget-head h3 {
    line-height: 40px;
    display: block;
    padding: 0;
    margin: 0;
    font-size: 15px;
    text-indent: 20px;
}

.ladiui-widget-body ul {
    margin: 0;
    padding: 0;
}

.ladiui-widget-body>ul>li:last-child {
    border-bottom: 0;
}

.ladiui-widget-body>ul>li {
    border-bottom: 1px solid #ebeef0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.ladiui-widget-body>ul>li>a {
    background: #fff;
    color: #313942;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    transition: none;
    display: block;
    padding: 10px 12px;
    font-size: 15px;
    font-weight: 500;
    line-height: 45px;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.ladiui-form-group>.ladi-col {
    display: block;
}

/* .ladiui-button-upload input[type=file] {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 100%;
	height: 40px
} */

.ladiui-select-options {
    height: 40px;
    border-radius: 4px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
    color: #313942;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    position: relative;
    min-width: 90px;
    z-index: 5;
}

.ladiui-select-options>ul {
    display: none;
    position: absolute;
    top: 41px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    background: #fff;
    border-radius: 4px;
    z-index: 5;
    min-width: 90px;
    width: 100%;
}

.ladiui-select-options>.ladiui-select-init {
    display: block;
    cursor: pointer;
    width: 100%;
    text-indent: 15px;
    border-radius: 4px;
    padding-right: 25px;
    line-height: 38px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ladiui-select-options>.ladiui-select-init:hover,
.ladiui-select-options>.ladiui-select-init:focus,
.ladiui-select-options:hover,
.ladiui-select-options:focus {
    border-color: #1853fa;
    outline: 0;
    transition: border-color 0.15s ease-out;
}

.ladiui-select-options>.ladiui-select-init:after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    top: 0px;
    right: 10px;
    position: absolute;
    z-index: -1;
}

.ladiui-dropdown>a {
    position: relative;
}

.ladiui-select-options>ul {
    padding: 0px;
    margin: 0px;
}

.ladiui-select-options>ul>li {
    list-style: none;
    cursor: pointer;
}

.ladiui-select-options>ul>li>a {
    cursor: pointer;
    display: block;
    color: #313942;
    width: 100%;
    padding: 0px 12px;
    line-height: 40px;
}

.ladiui-select-options>ul>li>a:hover {
    background: #f8fafb;
}

/* Animations to fade the snackbar in and out */

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

.ladiui-row>.col-50:first-child {
    margin-left: 0px;
}

.ladiui-row>.col-50:last-child {
    margin-right: 0px;
    float: right;
}

.ladiui-alert.error {
    font-size: 12px;
    color: #d50000;
    margin-top: 2px;
}

.ladiui-alert.error::before {
    content: '*';
    color: #d50000;
    padding-right: 5px;
}

.ladiui-spinner {
    margin: 2px auto 0;
    width: 70px;
    text-align: center;
}

.ladiui-spinner>div {
    width: 12px;
    height: 12px;
    background-color: #1853fa;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.8s infinite ease-out both;
    animation: sk-bouncedelay 1.8s infinite ease-out both;
}

.ladiui-spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.ladiui-spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/*---------------LADIUI CARD-----------------*/

/* .ladiui.card {
	box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
	background-color: #ffffff;
	border-radius: 4px;
}

.ladiui.card .card-header {
	padding: 15px 20px;
	border-bottom: 1px solid #eaedf0;
	background: rgba(169, 194, 209, 0);
	;
	position: relative;
}

.ladiui.card .card-header h3 {
	padding: 0px;
	margin: 0px;
	font-size: 15px;
	font-weight: 500;
} */

.flex {
    display: flex;
}

.ladiui-row>.col {
    width: 100%;
    margin: 24px;
}

.ladiui-row>.col:first-child {
    margin-left: 0px;
}

.ladiui-row>.col:last-child {
    margin-right: 0px;
}

.fluid {
    padding: 0px !important;
    margin: 0px;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* [type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
} */

/* [type="radio"]:not(:checked)+label:before {
	content: '';
	position: absolute;
	left: -1px;
	top: -1px;
	width: 18px;
	height: 18px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
	color: #fff;
} */

/* [type="radio"]:checked+label:before {
	content: '';
	position: absolute;
	left: -1px;
	top: -1px;
	width: 18px;
	height: 18px;
	border: 1px solid #2977dd;
	border-radius: 100%;
	background: #2977dd;
	color: #fff;
} */

/* [type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
	content: '';
	width: 5px;
	height: 5px;
	position: absolute;
	background: #fff;
	top: 6px;
	left: 7px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
} */

/* [type="radio"]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

[type="radio"]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
} */

.loader {
    position: relative;
}

/* .loader:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
} */

body.loader::before {
    top: calc(50vh - 20px);
    position: fixed;
    z-index: 100;
}

.ladiui-modal-body {
    padding: 15px 20px;
}

.ladiui-modal-content.confirm {
    max-width: 400px;
}

.ladiui-modal-content.confirm .button {
    min-width: 90px;
}

.ladiui-page-loader .background {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: 104px;
    position: relative;
    margin-bottom: 15px;
}

.ladiui-page-loader {
    padding: 12px;
    max-width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 3px;
    padding-bottom: 20px;
}

.ladiui-page-loader .background div {
    background: #fff;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
}

.ladiui-page-loader div.a {
    height: 40px;
    left: 40px;
    right: auto;
    top: 0;
    width: 8px;
}

.ladiui-page-loader div.b {
    height: 8px;
    left: 48px;
    top: 0;
}

.ladiui-page-loader div.c {
    left: 90%;
    top: 8px;
}

.ladiui-page-loader div.d {
    height: 12px;
    left: 48px;
    top: 14px;
}

.ladiui-page-loader div.e {
    left: 95%;
    top: 26px;
}

.ladiui-page-loader div.f {
    height: 10px;
    left: 48px;
    top: 32px;
}

.ladiui-page-loader div.g {
    height: 20px;
    top: 40px;
}

.ladiui-page-loader div.h {
    left: 85%;
    top: 60px;
}

.ladiui-page-loader div.i {
    height: 13px;
    top: 66px;
}

.ladiui-page-loader div.j {
    left: 440px;
    top: 79px;
}

.ladiui-page-loader div.k {
    height: 13px;
    top: 85px;
}

.ladiui-page-loader div.l {
    left: 178px;
    top: 98px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.ladiui.icon::before {
    font-family: 'LadiIcons';
    font-size: 12px;
    padding-right: 5px;
    top: 1px;
    position: relative;
}

/* .ladiui-link {
	color: #1853fa !important;
}

.ladiui-link:hover {
	text-decoration: underline;
} */

.no-shadow {
    box-shadow: none;
    -webkit-box-shadow: none;
}

::placeholder {
    color: #b3b3b3;
    opacity: 1;
    color: #9FA7B1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

:-ms-input-placeholder {
    color: #b3b3b3;
    font-weight: 300;
}

::-ms-input-placeholder {
    color: #b3b3b3;
    font-weight: 300;
}