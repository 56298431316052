.page-course-edit {
    background: #E7E9EB;
    /* background: #fff; */
}

.page-course-edit .menu-course-header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
}

.page-course-edit .menu-course-header .title-action{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1220px;
    /* padding: 16px 24px; */
    margin: 0px auto;
    /* border-bottom: 1px solid #E7E9EB; */
}

.ls-header-course-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1220px;
    padding: 16px 24px;
    margin: 0px auto;
    border-bottom: 1px solid #E7E9EB;
    padding-bottom: 0;
}

.ls-header-course-menu h1 {
    font-size: 20px;
    font-weight: 500;
}

.block-featured-course label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.setting-content-domain-course .action-record-table,
.table-list-course .action-record-table {
    left: 32px;
}


.page-setting-course .right-content {
    height: 100vh;
    overflow: auto;
    padding-top: 0px !important;
}

.page-setting-course .right-content .block-title-action {
    padding-top: 24px;
    padding-bottom: 24px;
}

.page-setting-course .title {
    color: var(--Text-text-primary, #0E233C);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.block-custom-interface .list-item .item .item-content {
    padding: 11px 12px;
    background-color: #f1f3f4;
    border-radius: 2px;
}

.ls-field-header {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 8px;
}

#tabProductCourseEdit.open {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail {
    width: 100%;
    height: 316px;
    border-radius: 2px;
    position: relative;
    background-color: #f1f3f4;
}

#tabProductCourseEdit .ladiui-product-course-left .ls-custom-thumnail {
    width: unset;
    height: 400px;
    aspect-ratio: 800 / 800;
}

#tabProductCourseEdit .ladiui-product-course-right .image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

#tabProductCourseEdit .ladiui-product-course-left .image {
    width: 100%;
    height: 100%;
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
    border-radius: 4px;
}

#tabProductCourseEdit .thumbnail .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay {
    margin-top: 60px;
    text-align: center;
}

#tabProductCourseEdit .ladiui-product-course-left .thumbnail:hover .block-upload-image .block-overlay {
    /* margin-top: 130px; */
    margin-top: unset;
    margin: 50% auto;
    text-align: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#tabProductCourseEdit .thumbnail:hover .block-upload-image .block-overlay .ldicon-upload {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-upload.svg);
    background-color: var(--main-white);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#tabProductCourseEdit .ladiui-product-course-left-fixed {
    width: 340px !important;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}

#tabProductCourseEdit .ladiui-product-course-right-fixed {
    width: calc(100% - 340px) !important;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}

.choose-content-custom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.choose-content-custom .list-box-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.choose-content-custom .list-box-content .box-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background-color: #F3F4F5;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
}

.choose-content-custom .list-box-content .box-content.active {
   border: 1px solid var(--main-primary);
}

#modal-choose-type-lesson .modal-footer-custom {
    border-top: 1px solid var(--border-02);
    padding: 20px 24px;
}

.ls-thumbnail-lesson {
    min-width: 240px;
    max-width: 240px;
    aspect-ratio: 240 / 135;
    height: auto;
    position: relative;
    background-color: #F3F4F5;
    border-radius: 4px;
}

.ls-thumbnail-lesson img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
}

.ls-thumbnail-lesson .block-upload-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
}

.ls-thumbnail-lesson:hover .block-upload-image {
    display: block;
    background: rgba(0, 0, 0, 0.6);
}

.ls-thumbnail-lesson:hover .block-upload-image .block-overlay {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}

#tabProductCourseEdit .chapter-list-item:last-child {
    margin-bottom: 0;
}

/* css lai phan danh sach khoa hoc */
.block-content-product-course-custom .icon-title {
    font-weight: 500;
    font-size: 16px;
}

.block-content-product-course-custom .chapter-list-item {
    border: none !important;
}

.block-content-product-course-custom .title-chapter {
    background-color: #fff !important;
    padding: 0 !important;
    margin-bottom: 16px;
}

.block-content-product-course-custom .ls-add-lesson {
    border: 1px dashed #CFD3D8;
    padding: 8px;
    border-radius: 4px;
}

.block-content-product-course-custom .ls-add-lesson:hover {
    border: 1px dashed #1C00C2;
    background: var(--layer-brand);
}

.block-content-product-course-custom .ls-lesson-image {
    width: 112px;
    height: 63px;
    background-color: gray;
    border-radius: 4px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.block-content-product-course-custom .ls-lesson-image img{
    width: -webkit-fill-available;
    object-fit: cover;
    border-radius: 4px;
}

.block-content-product-course-custom .list-lesson {
    gap: 12px;
    display: flex !important;
    flex-direction: column;
}


.block-content-product-course-custom .lesson-item {
    padding: 0 !important;
    border-bottom: none !important;
}

.block-content-product-course-custom .ls-link-hover:hover {
    font-weight: 500;
}

.block-content-product-course-custom .ls-btn-add-module {
    border: 1px dashed #1C00C2 !important;
}

.block-content-product-course-custom .ladiui-product-course-left {
    width: 300px;
}

.block-content-product-course-custom .ladiui-product-course-right {
    width: calc(100% - 300px);
}

.block-content-product-course-custom .ladiui-product-course-left.minimize {
    width: 224px;
}

.block-content-product-course-custom .ladiui-product-course-right.minimize {
    width: calc(100% - 224px);
}

.block-content-product-course-custom .ls-course-right-col {
    height: -webkit-fill-available;
}

.block-content-product-course-custom .search-group-right-search input{
    padding-right: 32px !important;
    padding-left: 12px !important;
}

.block-content-product-course-custom .search-group-right-search i.icon-search{
    right: 5px !important;
    left: unset !important;
}

.block-content-product-course-custom  .list-chapters .chapter-list-item:last-child {
    margin-bottom: 0 !important;
}

.block-content-product-course-custom .list-chapters .chapter-list-item .title-chapter {
    align-items: flex-start !important;
}

.block-content-product-course-custom .list-chapters {
    max-height: unset !important;
    overflow: unset !important;
}

.ls-component-loading {
    position: absolute !important;
    background-size: 80px 80px !important;
    background-repeat: no-repeat;
    left: 0px !important;
    top: 0;
    width: 100% !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30%;
}

.block-content-product-course-custom .lesson-item:not(.selected) {
    opacity: 0.7;
}

.block-content-product-course-custom .lesson-item.selected {
    font-weight: 500;
}

.block-content-product-course-custom .lesson-item:hover {
    opacity: 1;
    font-weight: 500;
    cursor: pointer;
}

.block-content-product-course-custom .lesson-item:hover .ls-link-hover{
    opacity: 1;
    font-weight: 500;
    cursor: pointer;
    color: var(--main-primary);
}

.ls-uploaded-video {
    display: flex;
    background-color: #F3F4F5;
    border-radius: 4px;
    height: 72px;
    width: 100%;
    justify-content: start;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
}

.ls-uploaded-video-1 {
    display: flex;
    background-color: #F4F2FC;
    border-radius: 4px;
    height: 72px;
    width: 100%;
    justify-content: start;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    border: 1px dashed #A895F8;
}

.ls-uploaded-video-2 {
    display: flex;
    background-color: #000;
    opacity: 0.6;
    border-radius: 4px;
    height: 72px;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    text-align: center;
}

.ls-uploaded-video-3 {
    display: flex;
    background-color: #F3F4F5;
    border-radius: 4px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px 16px;
    gap: 8px;
}

.ls-icon-video {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
}

.ls-processing {
    height: 4px;
    border-radius: 4px;
    background-color: #D3C9FB;
    width: 100%;
}

.ls-current-processing {
    height: 4px;
    border-radius: 4px;
    background-color: #5036DA;
    width: 0%;
}

.ls-fill-primary-btn {
    padding: 4px 8px;
    background-color: #F4F2FC;
    color: #1C00C2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    #tabProductCourseEdit.open {
        display: flex;
        flex-direction: column;
    }

    #tabProductCourseEdit .ladiui-product-course-left-fixed {
        width: 100% !important;
    }
    
    #tabProductCourseEdit .ladiui-product-course-right-fixed {
        width: 100% !important;
        /* padding-left: 0; */
        padding-top: 24px;
    }

    .ls-filter-bar {
        flex-wrap: wrap;
    }
}