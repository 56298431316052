#modal-colume-condition .ladiui.modal-content {
    animation: none;
}
.content-column-condition {
    display: flex;
}

.content-column-condition .column-selected {
    width: 320px;
    padding-right: 24px;
    border-right: 1px solid var(--border-02);
}

.content-column-condition .column-sort {
    padding-left: 24px;
    width: 480px;
}
.content-column-condition .column-selected .title,
.content-column-condition .column-sort .title {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.content-column-condition .column-selected .sub-title,
.content-column-condition .column-sort .sub-title {
    color: var(--text-secondary);
    font-size: 14px;
    line-height: 20px;
}
.content-column-condition .column-selected .search-column {
    width: 100%;
    height: 40px;
    border-radius: var(--Border, 4px);
    border: 1px solid var(--border-border-border-01, #cfd3d8);
    background: var(--layer-layer-01, #fff);
    padding: 10px 10px 10px 36px;
}

.content-column-condition .column-selected .search-column::placeholder {
    color: var(--text-placeholder);
    font-size: 14px;
    line-height: 20px;
}

.content-column-condition .column-selected .search {
    position: relative;
}

.content-column-condition .column-selected .search .new-ldicon-search {
    position: absolute;
    top: 10px;
    left: 10px;
}
.content-column-condition .column-selected .columns {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.content-column-condition .column-selected .columns li {
    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;
}
.content-column-condition .column-selected .columns .ladiui.checkbox {
    width: 14px;
    height: 14px;
}

.content-column-condition .column-selected .columns .form-check-input-checkbox:checked::before {
    /* top: 3.63px; */
    width: 3px;
    height: 7px;
}

.column-sort .column-list-sort {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.column-sort .column-list-sort .column-item-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    background: var(--background-background-02, #f3f4f5);
}

.column-sort .column-list-sort .column-item-sort .column-title {
    display: flex;
    align-items: center;
    gap: 8px;
}
