.page-content.order-create-v2 {
    padding: 0;
}

.order-create-v2 .order-breadcrumbs {
    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--text-secondary);
    font-size: 16px;
    line-height: 24px;
}

.order-create-v2 .header {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1220px;
    margin: 0px auto;
}

.order-create-v2 .header-o {
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 100;
    border-bottom: 0.5px solid var(--border-01-50p);
}

.order-create-v2 .order-create {
    background: #E7E9EB;
}

.order-create-v2 .content .order-info {
    display: flex;
    align-items: flex-start;
    /* justify-content: space-between; */
    margin-top: 20px;
}

.order-create-v2 .content .order-info .order-product-payment {
    /* min-width: 775px; */
    margin-right: 20px;
}

.order-create-v2 .content .order-info .order-product-payment .ladiui.container-place {
    width: 710px;
}

.order-create-v2 .content .order-info .order-customer-info-tags {
    width: 375px;
}

/* .order-create-v2 .content .order-info .order-customer-info-tags .order-customer-info,
.order-create-v2 .content .order-info .order-customer-info-tags .order-tags,
.order-create-v2 .content .order-info .order-customer-info-tags .order-note,
.order-create-v2 .content .order-info .order-product-payment .order-products,
.order-create-v2 .content .order-info .order-product-payment .order-payment {
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    border: 0.5px solid var(--border-02);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
} */

.order-products .product-search {
    margin: 12px 0px;
    z-index: 15;
}

.order-products .ladiui.icon.icon-search {
    top: 2px !important;
    left: 0px !important;
    right: unset !important;
    margin: 10px 10px;
}

.order-products .ladiui.search-field {
    padding-left: 36px !important;
    position: unset;
    height: 40px;
}

.product-variant-search {
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(32, 32, 32, 0.15);
    width: 100%;
    position: absolute;
    max-height: 0px;
    overflow: auto;
    z-index: 10;
}

.product-variant-search.show {
    max-height: 540px;
}

/* .product-variant-search.hide {
    display: block;
} */
.product-variant-search .variant-page.footer {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;
}

.product-variant-search .variant-page.footer .ldicon-chevron-right,
.product-variant-search .variant-page.footer .ldicon-chevron-left {
    position: unset !important;
    width: 16px;
    height: 16px;
}

.product-variant-search .variant-page.footer i.disable {
    opacity: 0.3;
}

.product-variant-search .product-custom-btn {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.product-variant-search .product-custom-btn .new-ldicon-add-circle {
    position: unset !important;
}

.product-variant-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: block;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    height: calc(500px - 80px);
    overflow: auto;
}

.product-variant-list .product-variant-item {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    border-top: 0.5px solid var(--border-border-border-02, #e7e9eb);
}

.product-variant-list .product-variant-item:hover {
    background: var(--layer-layer-brand-layer-brand, #f4f2fc);
}

.product-variant-list .product-variant-item:hover .variant-name,
.product-variant-list .product-variant-item:hover .variant-price {
    color: var(--text-brand);
}

.product-variant-list .product-variant-item .image-name-option {
    display: flex;
    align-items: center;
}

.product-variant-list .product-variant-item .image-name-option .name-option {
    max-width: 500px;
}

.product-variant-list .product-variant-item .image-name-option img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}

.order-products .product-empty {
    width: 176px;
    margin: 40px auto;
    text-align: center;
}

.order-products .product-empty i {
    width: 64px;
    height: 64px;
}

.variant-selected-list {}

.variant-selected-list .variant-selected-item {
    padding: 12px 0px;
}

.variant-selected-list .variant-selected-item .variant-image-name-option {
    width: 40%;
    padding-right: 24px;
}

.variant-selected-list .variant-selected-item .variant-image-name-option .variant-image {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}

.variant-selected-list .variant-selected-item .variant-quatity {
    width: 20%;
    padding-right: 24px;
    display: flex;
    justify-content: center;
}

.variant-quantity-up-down {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid var(--border-01);
    width: 80px;
    height: 40px;
}

.variant-quantity-up-down input {
    width: 28px;
}

.variant-quantity-up-down .variant-up-down {
    display: flex;
    flex-direction: column;
}

.variant-selected-list .variant-selected-item .variant-price-discount {
    width: 17%;
    padding-right: 24px;
    text-align: right;
}

.variant-selected-list .variant-selected-item .variant-total {
    width: 20%;
    text-align: right;
    padding-right: 24px;
    color: var(--text-primary);
}

.variant-selected-list .variant-selected-item .variant-delete {
    width: 3%;
    text-align: right;
}

.variant-selected-list .variant-selected-item.boder-top {
    border-top: 0.5px solid var(--border-02);
}

.price-box .popover-discount {
    padding: 20px 16px;
}

.price-box .popover-discount .popover-discount-type-value {
    display: flex;
    align-items: center;
    gap: 16px;
    text-align: left;
}

.price-box .popover-discount .popover-discount-type-value .discount-type {
    width: 140px;
}

.price-box .popover-discount .popover-discount-type-value .discount-type .ladiui.dropdown-toggle {
    min-width: 140px;
}

.price-box .popover-discount .popover-discount-type-value .discount-value {
    width: 212px;
}

.price-box .actions {
    padding: 12px 16px;
    border-top: 0.5px solid var(--border-02);

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-assign .dropdown-toggle {
    background: transparent;
    border: unset !important;
    min-width: unset;
    color: var(--text-secondary);
}

.order-assign .ladiui.btn-outline-light:hover {
    background-color: transparent;
}

.order-assign .ladiui.dropdown-toggle::after {
    background: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down.svg) no-repeat center;
    right: -10px;
    top: 2px !important;
}

.order-payment .payment-title {
    padding-bottom: 12px;
}

.order-payment .payment-info {
    padding: 12px 0px 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.order-payment .payment-info .paymnet-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.price-box .popover-shipping {
    padding: 20px;
}

.price-box .popover-shipping .list-item-v2 {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.price-box .popover-shipping .list-item-v2 .item {
    display: flex;
    align-items: center;
}

.price-box .popover-shipping .shiping-fee {
    display: flex;
    align-items: center;
    gap: 16px;
}

.price-box .popover-shipping .shipping-fee-name {
    width: 65%;
}

.order-customer-info .order-customer-title {
    padding-bottom: 12px;
}

.order-customer-info .customer-search {
    margin: 12px 0px;
}

.customers-search-list {
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(32, 32, 32, 0.15);
    width: 100%;
    position: absolute;
    max-height: 0px;
    overflow: auto;
    z-index: 15;
}

.customers-search-list.show {
    max-height: 500px;
}

/* .product-variant-search.hide {
    display: block;
} */

.customers-search-list .customer-create-btn {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.customers-search-list .customer-create-btn i {
    position: unset !important;
}

.customer-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: block;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    max-height: calc(500px - 50px);
    overflow: auto;
}

.customer-list .customer-item {
    padding: 12px 16px !important;
    display: flex;
    flex-direction: column;
    gap: 4px;

    width: 100%;
    cursor: pointer;
    border-top: 0.5px solid var(--border-02);
}

.customer-list .customer-item:hover {
    background: var(--layer-brand);
}

.customer-list .customer-item:hover div {
    color: var(--text-brand);
}

.order-customer-info .customer-name {
    color: var(--text-brand) !important;
    padding: 12px 0px 20px;
}

.order-customer-info .customer-info-detail .customer-item {
    padding: 20px 0px;
    border-top: 0.5px solid var(--border-02);
}

.order-customer-info .customer-info-detail .customer-item.customer-billing {
    padding-bottom: 0px;
}

.order-customer-info .customer-info-detail .customer-item .customer-action {
    cursor: pointer;
}

.order-customer-info .customer-info-detail .customer-item .item-detail {
    display: flex;
    align-items: start;
}

.order-customer-info .customer-info-detail .customer-item .item-detail .item-label {
    width: 100px;
}

.order-customer-info .customer-info-detail .customer-item .item-detail .item-value {
    width: 226px;

    color: var(--text-primary);
    font-size: 14px;
    line-height: 20px;
}

.order-customer-info .customer-empty {
    width: 190px;
    margin: 40px auto;
    text-align: center;
}

.order-customer-info .customer-empty i {
    width: 64px;
    height: 64px;
}

.customer-address .customer-address-label {
    position: relative;
}

.search-order-tag {
    margin: 12px 0px;
}

.order-tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.order-tag-list .order-tag-list-item {
    border-radius: 4px;
    padding: 4px 8px;

    display: flex;
    align-items: center;
    gap: 8px;
}

.order-note-value {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--border-01);
    width: 100%;
}

.order-head-actions {
    display: flex;
    align-items: center;
    gap: 16px;
}

.order-head-actions .ldicon-link {
    rotate: -45deg;
    width: 16px !important;
    height: 16px !important;
}

.price-box.popover-checkout {
    top: 42px !important;
    width: 600px;
}

.popover-checkout .url-checkout {
    border-radius: 4px;
    padding: 10px 12px;
    border: 1px solid var(--border-01);
}

.popover-checkout .url-checkout .url {
    width: 300px;
    line-break: anywhere;
}

.popover-checkout .checkout-config-list {
    width: 100% !important;
}

.popover-checkout .checkout-config-list .ladiui.dropdown-text {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 16px !important;
}

.order-custom-fields {
    margin-top: 16px;
    border-top: 1px solid var(--border-02);
    padding-top: 16px;
}

.custom-fields .ladiui-label {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 4px;
}

.order-note {
    position: relative;
}

.modal-refund-content {
    background: var(--background-02);
    padding: 20px 24px;
}