.page-discount {
    background-color: #fff;
    padding: 24px;
}

.page-discount .block-title-action .block-left {
    display: flex;
    align-items: center;
}

.page-discount .block-title-action .block-left .title {
    margin-right: 26px;
}

.ladiui.table {
    box-shadow: none;
}

.page-discount .ladiui.pagination-block {
    display: block;
    overflow: hidden;
}

#modal-discount .condition-group {
    border-top: none;
    margin-top: 0px;
}

#modal-discount .condition-group>label {
    position: inherit;
    top: 0px;
    padding-bottom: 16px;
}

/* .page-discount .ladiui.content-main-tool {
    padding: 14px 0px;
} */

.page-discount .ladiui.content-main-tool .btn-group {
    padding: 0px;
}

#modal-discount .ladiui.form-group {
    display: block;
    margin-bottom: 24px;
}

#modal-discount .ladiui.form-group .ladiui.checkbox {
    margin-right: 8px;
}
#modal-discount .ladiui.form-group .coupon-create-types {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}
#modal-discount .ladiui.form-group .coupon-create-types .item {
    display: flex;
    align-items: center;
}
#modal-discount .line-title-condition {
    display: block;
    width: 100%;
    overflow: hidden;
}
#modal-discount .line-title-condition h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
    margin: 24px 0px;
}