.package-vriant {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.package-vriant .package-variant-lb {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}

.package-vriant .package-unit-lb {
    white-space: nowrap;
}

/* .option-variant-items{
    display: flex;
    flex-direction: column;
    gap: 20px;
} */

.option-variant-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    width: 100%;
    padding: 12px 0px;
}

.option-variant-item.option-variant-header {
    padding: 20px 0px 12px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px solid var(--Border-Border-border-02, #E7E9EB);
}

.option-variant-item .option-variant-sku {
    width: 180px;
}

.option-variant-item .option-variant-num-unit {
    width: 80px;
}

.option-variant-item .option-variant-price {
    width: 130px;
}

.option-variant-item .option-variant-add {
    width: 140px;
}

.option-variant-item .option-variant-inventory {
    width: 140px;
    display: flex;
    align-items: center;
}

.btn-add-new {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 0px;
    cursor: pointer;
    color: #1C00C2;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: fit-content;
}