.block-content-item .item-header {
    padding-bottom: 20px;
    border-bottom: 0.5px solid var(--border-02);
}

.version-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 48px;
}

.btn-add-version {
    width: 600px !important;
    padding: 10px 16px !important;
    border-radius: 4px !important;
    border: 1px dashed var(--border-border-brand-border-brand, #5036da) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    gap: 8px;
}

.btn-add-version .icon-ldp-add {
    background-color: #5036da;
}

.btn-add-version span {
    color: #5036da;
}

.btn-add-version:hover {
    background-color: #5036da;
}

.btn-add-version:hover .icon-ldp-add {
    background-color: #ffffff;
}

.btn-add-version:hover span {
    color: #ffffff;
}

#modal-product-digital-create .popover-image-product {
    top: unset !important;
    bottom: 20px;
}

.group-upload-image {
    /* padding: 48px 24px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--full-color-grey-50, #f3f4f5);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    border-radius: 8px;
    min-height: 100px;
}

.list-version {
    display: flex;
    flex-direction: column;
}

.list-version .version-item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 0px;
}

.list-version .version-item .head {
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
}

.list-version .version-item .version-move {
    width: 32px;
}

.list-version .version-item .version-name {
    width: calc(100% - 394px);
}

.list-version .version-item .version-name.not-id {
    width: calc(100% - 328px);
}

.list-version .version-item .version-price {
    width: 100px;
}

.list-version .version-item .version-download-num {
    width: 100px;
}

.list-version .version-item .version-id {
    width: 50px;
    cursor: context-menu;
    padding: 2px 4px;
}

.list-version .version-item .version-action {
    width: 32px;
}

.version-file-content {
    gap: 16px;
    padding: 12px 16px;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    box-shadow: 0px 0px 0px 3px #fff inset;
}

.version-file-content .version-action-file-icon {
    background: var(--layer-layer-brand-layer-brand, #f4f2fc);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
}

.version-file-content .version-file-url {
    width: calc(100% - 192px);
    cursor: pointer;
}

.version-file-content .version-file-url .version-url {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.version-file-content .version-action-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: 32px;
    height: 32px;

    border-radius: 8px;
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: var(--layer-layer-01, #fff);
}

.version-file-content .popover-version-file {
    top: 32px !important;
    right: 0px;
    left: unset !important;

    background-color: #fff;
    padding: 20px 16px 12px 16px;
    border-radius: 8px;
    width: 400px;
    z-index: 99999;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

/* .product-degital .version-upload-image .group-upload-image {
    width: 100%;
    height: 300px;
}

.product-degital .version-upload-image {
    width: 100%;
    height: 300px;
} */

/* .product-degital .version-upload-image img {
    width: 100%;
} */

.version-upload-image {
    position: relative;
    width: 400px;
    height: 400px;
}

.version-upload-image .builder-loading {
    position: absolute !important;
}

.version-upload-image .group-upload-image {
    position: absolute;
    top: 0px;
    width: 400px;
    height: 400px;
    background: #f3f4f5e6;
    /* padding-top: 120px; */
    visibility: hidden;
}

.version-upload-image:hover .group-upload-image,
.version-upload-image.active .group-upload-image {
    visibility: visible;
}