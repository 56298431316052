#sidebar-left .ladiui.flex-row-menu-item {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

#sidebar-left .ladiui.menu-item {
    padding: 4px;
    border-left: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sidebar-left .logo {
    display: flex;
    margin: 16px auto;
    align-items: center;
    justify-content: center;
}

#sidebar-left .menu .tooltip {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

#sidebar-left .ladiui.menu-item.active>.tooltip,
#sidebar-left .ladiui.menu-item:hover>.tooltip {
    background-color: #f4f2fc;
}

#sidebar-left .ladiui.nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-left: 1px solid #e7e9eb;
    border-right: 1px solid #e7e9eb;
    width: 60px;
}

#sidebar-left .tooltip .tooltiptext.right:after {
    top: 9px !important;
}

#sidebar-left .account-tool-setting {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: start;
    flex-direction: column;
    padding-bottom: 24px;
}

#sidebar-left .account-tool-setting .tool-help-fix-top .list-tool {
    left: 60px !important;
    bottom: 75px !important;
}

#sidebar-left .account-tool-setting .ladiui.account.dropdown-menu {
    left: 60px !important;
    bottom: 20px !important;
}

/* -------------------------------------------tyle phần tích hợp------------------------------------------------------------------ */
.ladiui.content-main-full.max-w-1220 {
    max-width: 1220px;
    padding: 0;
}

.ladiui.content-list-landingpage {
    display: block !important;
}

.content-list-landingpage.content-list-landingpage-v3 {
    padding: 24px;
    min-height: calc(100vh + 50px);
}

.sticky-header-template {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4;
}

.header-actions {
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    padding: 24px 24px 0;
}

.header-actions,
.header-actions-top {
    display: flex;
    justify-content: flex-start;
}

.header-actions-top {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
}

.header-actions-top .widget-left {
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
}

.ladiui.alert.create-flow-alert {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.header-actions-top .widget-left .title-heading h3 {
    display: flex;
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.header-actions-top .widget-left .title-desc p {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #566577;
}

.tabs-v3 {
    padding-bottom: 5px;
}

.w-full {
    width: 100% !important;
}

.tabs-v3 .ladiui.nav-tabs {
    border-bottom: 1px solid #e7e9eb;
}

.ladiui.tab-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.flex-row-start-start {
    align-items: flex-start;
}

.flex-row-center-start,
.flex-row-start-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.list-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-row-gap: 12px;
    row-gap: 12px;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
    column-gap: 12px;
    flex-wrap: wrap;
}

.list-block-item {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    padding: 4px 8px;
    align-items: center;
    border-radius: 4px;
    background-color: #f4f4f4;
    cursor: pointer;
    color: #566577;
    color: var(--text-text-secondary, var(--text-text-secondary, #566577));
}

.list-block-item.active {
    border: 1px solid #a895f8;
    padding: 3px 7px;
}

.list-block-item.active,
.list-block-item:not([class*='title']):hover {
    background-color: #f4f2fc;
    color: var(--main-primary);
}

.grid-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-columns: var(--column, 1fr 1fr);
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
    column-gap: 8px;
    grid-row-gap: 8px;
    row-gap: 8px;
}

.select-box-item,
.select-box-item-none-hover {
    display: flex;
    width: 100%;
    padding: 16px;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    border-radius: 8px;
    border-radius: var(--spacing-2, 8px);
}

.select-box-item:hover {
    cursor: pointer;
}

.select-box-item-border,
.select-box-item:hover {
    background: #f4f2fc;
    background: var(--full-color-primary-50, #f4f2fc);
}

#system-template .select-box-item-icon,
.select-box-item-icon {
    border: 0.5px solid #cfd3d8;
    background-color: #fff;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    border-radius: var(--spacing-2, 8px);
}

#system-template .select-box-item-icon {
    width: 64px;
    height: 64px;
}

.select-box-item:hover .select-box-item-icon {
    background: #fff;
}

#system-template .select-box-item-icon img {
    width: 40px;
    height: 40px;
}

.select-box-item-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.ladiui.caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.ladiui.modal-header.custom-v4 {
    padding: 20px 24px;
    border-bottom: 1px solid #e7e9eb;
    justify-content: flex-start;
    align-items: flex-start;
}

.no-data-wrapper {
    border-radius: 4px;
    height: 334px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon-form-integration {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 8px;
}

.select-box-item-icon img {
    width: 24px;
    height: 24px;
}

.ladiui.table-vertical th {
    vertical-align: middle;
    height: 20px;
    padding: 16px 12px;
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    white-space: nowrap;
}

.ladiui.table-vertical td {
    padding: 16px 12px;
}

.ladiui.table-vertical.header .checkall {
    width: 40px;
}

.line-dotted {
    width: 100%;
    border-top: 1px dashed #566577;
    border-top: 1px dashed var(--text-text-secondary, #566577);
}

.connect-count:hover div {
    color: #1c00c2;
}

.list-steps-integrate {
    margin-bottom: 24px;
}

.list-steps-integrate ul {
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 24px; */
}

.list-steps-integrate ul>li {
    display: flex;
    align-items: center;
}

.list-steps-integrate ul>li::after {
    content: '';
    background-image: url('https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg');
    border-radius: 16px;
    margin: 0px 24px;
    width: 16px;
    height: 16px;
    background-size: cover;
}

.list-steps-integrate ul>li:last-child::after {
    display: none;
}

.list-steps-integrate ul>li .step-number {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #e7e9eb;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #566577;
}

#modal-form-account .block-info-connect-account {
    margin-bottom: 24px;
}

#modal-form-account .block-info-connect-account .icon-logo-connect {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

#modal-form-account .block-info-connect-account h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #0e233c;
    text-align: center;
    margin-top: 24px;
}

#modal-form-account .block-info-connect-account p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #566577;
    text-align: center;
    margin-top: 4px;
}

/* #modal-form-account, #modal-setting-form-config-account {
    animation: none;
} */
#modal-form-account .ladiui.modal-content,
#modal-setting-form-config-account .ladiui.modal-content {
    animation: none;
}

/* -----------------------------------------------------------------style trang sản phẩm----------------------------------------------------------------------------------------------- */
.page-product-v2 {}

.page-product-v2 .page-content .header-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 24px 24px;
}

.page-product-v2 .page-content .header-page .heading h3 {
    color: var(--text-primary);
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

.page-product-v2 .page-content .header-page .heading p {
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.btn.btn-import-export {
    padding: 10px 16px;
    min-width: 60px;
    border-radius: 4px;
    border: 1px solid var(--button-primary) !important;
    background: #ffffff;
    cursor: pointer;
    color: var(--text-brand);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
}

.btn.btn-import-export:hover {
    background: var(--layer-brand);
}

.page-product-v2 .page-content .block-filter-search {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0;
}

.page-product-v2 .page-content .block-filter-search .ladiui.search-width {
    min-width: 314px;
}

.page-product-v2 .page-content .block-filter-search .btn-advanced-filter {
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--background-02);
    width: 140px;
    color: var(--text-primary);
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    cursor: pointer;
}

#page-content-product .block-content .product-tab ul li {
    display: flex;
    align-items: center;
}

.block-content .product-tab .item-filter-new {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    position: relative;
}

#page-product-new {
    display: block;
}

.menu-header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
}

.menu-header .content {
    max-width: 1220px;
    padding: 16px 24px 0px;
    margin: 0 auto;
}

.top-menu-header {
    padding-top: 24px;
}

.menu-header .top-menu-header ul {
    display: flex;
    align-items: center;
}

.menu-header .top-menu-header ul>li {
    display: flex;
    align-items: center;
}

.menu-header .top-menu-header ul>li:first-child::after {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url('https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg');
    background-size: cover;
    margin: 0 8px;
}

.menu-header .title-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-header .title-action h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.menu-header .title-action button {
    position: relative;
    /* top: 12px; */
}

#page-product-new .page-content-product-new {
    background-color: #E7E9EB;
}

#page-product-new .page-content-product-new .page-content {
    background-color: transparent;
}

.page-content-product-new .page-product-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

@media (min-width: 978px) {
    .page-content-product-new .page-product-info .content-left {
        width: calc(100% - 395px);
    }

    .page-content-product-new .page-product-info .content-right {
        width: 100%;
        max-width: 375px;
    }
}

@media (max-width: 977px) {
    .page-content-product-new .page-product-info .content-left {
        width: calc(100%);
    }

    .page-content-product-new .page-product-info .content-right {
        width: 100%;
    }

}



.block-content-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.block-content-item h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ladiui.form-group .ladiui-label.new {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
    padding-bottom: 4px !important;
}

.block-content-item .block-info-help {
    padding-top: 16px;
    border-top: 0.5px solid var(--border-border-border-02, #e7e9eb);
    margin-top: 12px;
}

.block-content-item .block-info-help span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
}

/* .list-image-product-v2 .image-none .ladiui-button-image input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
} */

.block-content-item .block-list-images .list-image-product-v2 .image-none .ladiui-button-image {
    width: 100%;
    padding: 48px 24px;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    background: var(--full-color-grey-50, #f3f4f5);
    box-shadow: 0px 0px 0px 3px #fff inset;
}

.block-content-item .block-list-images .list-image-product-v2 .image-none .ladiui-button-image .text-default {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-content-item .block-list-images .list-image-product-v2 .image-none .ladiui-button-image .rec-size {
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
    color: var(--text-secondary);
}

.block-content-item .block-list-images .list-image-product-v2 .list-images {
    /* display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px; */
    display: grid;
    grid-template-columns: repeat(auto-fill, 134px);
    /* grid-auto-rows: 50px; */
    grid-gap: 12px;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image {
    width: 134px;
    height: 134px;
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 0px 4px #fff inset;
    overflow: hidden;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image img {
    width: 100%;
    object-fit: cover;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .ladiui-button-upload-image {
    width: 134px;
    height: 134px;
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 0px 4px #fff inset;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .block-icon-star {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 40px;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image:hover .block-icon-star {
    display: block;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image:hover .block-delete-img {
    display: block;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image:hover .block-reup-img {
    display: block;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .item-image:first-child {
    grid-column: span 2;
    width: 100%;
    height: 280px;
    grid-row: span 2;
}

.block-content-item .block-list-images .list-image-product-v2 .list-images .block-delete-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 3px;
}


.block-content-item .block-list-images .list-image-product-v2 .list-images .block-reup-img {
    position: absolute;
    background-color: rgba(32, 32, 32, 0.7);
    top: 3px;
    padding: 3px 5px;
    display: none;
    border-radius: 2px;
    right: 75px;
}


.block-content-item .block-list-images .item-image:hover .ladi-icon.icon-bin {
    background-color: var(--main-white);
}

.block-content-item .advanced-settings {
    display: block;
    margin-top: 12px;
}

.block-content-item .advanced-settings .item {
    display: flex;
    align-items: center;
    padding: 12px 0;
}

.block-content-item .advanced-settings .item span {
    color: var(--text-text-primary, var(--text-text-primary, #0e233c));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ladiui.dropdown-toggle.custom-weight::after {
    top: 0px !important;
    margin: 14px 8px 14px 12px;
}

.page-content-product-variants .block-content-item .product-variant-none {
    padding: 48px;
    display: flex;
    flex-direction: column;
    text-align: center;
    grid-gap: 20px;
    gap: 20px;
    border-top: 1px solid #f1f3f4;
    margin-top: 20px;
}

.page-content-product-variants .block-content-item .product-variant-none h3 {
    color: var(--text-text-secondary, #566577);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.page-content-product-variants .block-content-item .product-variant-none p {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.page-content-product-variants .block-content-item .product-variant-none button {
    display: flex;
    width: 600px;
    min-width: 60px;
    padding: 10px var(--spacing-16px, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-8px, 8px);
    border-radius: var(--Border, 4px);
    border: 1px dashed var(--border-border-brand-border-brand, #5036da);
    background: var(--layer-layer-01, #fff);
    margin: 0 auto;
    color: #1c00c2;
    cursor: pointer;
}

.page-content-product-variants .block-content-item .product-variant-none button .icon-ldp-add {
    width: 18px !important;
    height: 18px !important;
    background-color: #1c00c2;
}

.page-content-product-variants .block-content-item .product-variant-none {
    padding: 48px;
    display: flex;
    flex-direction: column;
    text-align: center;
    grid-gap: 20px;
    gap: 20px;
    border-top: 1px solid #f1f3f4;
    margin-top: 20px;
}

.page-content-product-variants .block-content-item .product-variant-none h3 {
    color: var(--text-text-secondary, #566577);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.page-content-product-variants .block-content-item .product-variant-none p {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.block-content-item .block-table-list-variants {
    margin-top: 12px;
    border-top: 1px solid #f1f3f4;
}

.block-content-item .block-list-selected-product-upsells .none-product {
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-content-item .block-list-selected-product-upsells .none-product .img-text span {
    color: var(--text-text-secondary, #566577);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
}

.block-content-item .block-list-selected-product-upsells .block-list table .image-product .img img {
    object-fit: cover;
    margin-left: 12px;
}

.popover-image-product {
    background-color: #fff;
    left: 0 !important;
    padding: 20px 16px;
    border-radius: 8px;
    width: 400px;
    z-index: 99999;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.popover-image-product .action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-top: 24px;
    border-top: 1px solid #f1f3f4;
}

.product-tag-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.product-tag-list .product-tag-list-item {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    background-color: #f3f4f5;
    border-radius: 8px;
}

.ladiui.btn {
    height: 42px;
}

.block-panel-ticket-v4 {
    margin-top: 20px;
    border-top: 1px solid var(--border-border-border-02, #e7e9eb);
}

.block-panel-ticket-v4 .block-no-ticket {
    padding: 45px 0;
    text-align: center;
}

.block-panel-ticket-v4 .block-no-ticket {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.block-panel-ticket-v4 .block-no-ticket h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-secondary);
}

.block-panel-ticket-v4 .block-no-ticket button {
    display: flex;
    width: 100%;
    padding: 10px var(--spacing-16px, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-8px, 8px);
    border-radius: var(--Border, 4px);
    border: 1px dashed var(--border-border-brand-border-brand, #5036da);
    background: var(--layer-layer-01, #fff);
    color: #1c00c2;
    cursor: pointer;
}

.block-panel-ticket-v4 .block-no-ticket button .icon-ldp-add {
    width: 18px !important;
    height: 18px !important;
    background-color: #1c00c2;
}

.block-panel-ticket-v4 #modal-event .ladiui.modal-body {
    background-color: #f3f4f5;
}

#tab-event-code-management .block-content-item .header-top {
    padding-bottom: 16px;
    border-bottom: 1px solid #f3f4f5;
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .header-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .header-action .pull-left {
    width: 100%;
    margin-right: 16px;
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .header-action .pull-left .ladiui.search-keyword {
    width: 100% !important;
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .ladi-card .block-user-info p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-secondary);
}

#tab-event-code-management .block-content-item .block-content-ticket-seat .ladi-card .block-user-info .user-name {
    color: #0e233c;
}

.block-panel-package-v4 {
    margin-top: 20px;
    border-top: 1px solid var(--border-border-border-02, #e7e9eb);
}

.block-panel-package-v4 .block-no-package {
    padding: 45px 0;
    text-align: center;
}

.block-panel-package-v4 .block-no-package {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.block-panel-package-v4 .block-no-package h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-secondary);
}

.block-panel-package-v4 .block-no-package button {
    display: flex;
    width: 100%;
    padding: 10px var(--spacing-16px, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-8px, 8px);
    border-radius: var(--Border, 4px);
    border: 1px dashed var(--border-border-brand-border-brand, #5036da);
    background: var(--layer-layer-01, #fff);
    color: #1c00c2;
    cursor: pointer;
}

.block-panel-package-v4 .block-no-package button .icon-ldp-add {
    width: 18px !important;
    height: 18px !important;
    background-color: #1c00c2;
}

.block-panel-package-v4 #modal-event .ladiui.modal-body {
    background-color: #f3f4f5;
}

.block-ckeck-ticket-status .sold::before {
    content: '';
    display: inline-block;
    background: #248a00;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(36, 138, 0, 0.2);
    background-clip: padding-box;
}

.block-ckeck-ticket-status .not-sold::before {
    content: '';
    display: inline-block;
    background: #bcbcbc;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(188, 188, 188, 0.2);
    background-clip: padding-box;
}

.block-package-cancel::before {
    content: '';
    display: inline-block;
    background: #e01a1a;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid #ef930033;
    background-clip: padding-box;
}

#modal-import-package-seat-v4 {
    width: calc(100% - 48px) !important;
}

.ldicon-drag-drop {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.icon-product-sub-menu {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.page-product-v2 .menu-list .menu-item.active i {
    background-color: #1c00c2;
}

.block-nodata-search-product {
    padding: 174px 0 !important;
}

.block-nodata-search-product .icon-search {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-search.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-search.svg);
    background-color: #e7e9eb;
    width: 90px;
    height: 90px;
    display: inline-block;
    margin-bottom: 24px;
}

.block-nodata-search-product h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-secondary);
    margin-bottom: 12px;
}

.block-nodata-search-product p {
    font-weight: 400;
    font-size: 14px;
    margin-top: 12px;
    line-height: 24px;
    color: var(--text-secondary);
}

.ladiui.tab-pane {
    margin-top: 0 !important;
}

.product-status-v4 .active::before {
    content: '';
    display: inline-block;
    background: #248a00;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(36, 138, 0, 0.2);
    background-clip: padding-box;
}

.product-status-v4 .inactive::before {
    content: '';
    display: inline-block;
    background: #bcbcbc;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(188, 188, 188, 0.2);
    background-clip: padding-box;
}

.new-ldicon-custom-field {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-custom-field.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-custom-field.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-tag {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-tag.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-tag.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-review {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-review.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-review.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-template {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-template.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-template.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-warehouse {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-warehouse.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-warehouse.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-box-sub-menu {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-box.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-box.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#modal-export-data-by-type .block-content {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}

#modal-export-data-by-type .block-content .content-item .block-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

#modal-export-data-by-type .block-content .content-item .block-list .item {
    display: flex;
    align-items: center;
}

#modal-export-data-by-type .block-content .content-item h3 {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
    line-height: 20px;
}

#modal-import-data-by-type .block-content {
    /* display: grid;
    grid-template-columns: auto auto;
    gap: 20px; */
}

#modal-import-data-by-type .block-content .content-item .block-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

#modal-import-data-by-type .block-content .content-item .block-list .item {
    display: flex;
    align-items: center;
}

#modal-import-data-by-type .block-content .content-item h3 {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
    line-height: 20px;
}

.add-quantity-inventory-v4 .qty-popup {
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #f3f4f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
}

.new-ldicon-control {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-control.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-control.svg);
    background-color: var(--main-secondary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.add-quantity-inventory-v4 {
    display: flex;
    justify-content: end;
}

.add-quantity-inventory-v4 .qty-popup .icon-show-popup {
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;
}

.add-quantity-inventory-v4 .qty-popup .icon-show-popup:hover {
    background-color: #e7e9eb;
}

.popover-qty-inventory {
    background-color: #fff;
    left: auto !important;
    right: 0 !important;
    padding: 20px 16px;
    border-radius: 8px;
    width: 400px;
    z-index: 99999;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.popover-qty-inventory .popover-add-qty-inventory .content-header {
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
}

.popover-add-qty-inventory .content-header .btn-group {
    padding: 4px;
    border-radius: 4px;
    background-color: #f3f4f5;
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
}

.popover-add-qty-inventory .content-header .btn-group .btn-item {
    padding: 6px auto;
    width: 100%;
    cursor: pointer;
}

.popover-add-qty-inventory .content-header .btn-group .btn-item.active {
    background-color: #fff;
    color: #0e233c;
}

.popover-add-qty-inventory .product-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.popover-add-qty-inventory .action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid #e7e9eb;
}

.page-category-v4 .menu-header {
    padding: 24px 24px 24px 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
}

.page-category-v4 .menu-header .content {
    max-width: 1220px;
    padding: 0 24px;
    margin: 0 auto;
}

.page-category-v4 .menu-header .top-menu-header ul {
    display: flex;
    align-items: center;
}

.page-category-v4 .menu-header .top-menu-header ul li {
    display: flex;
    align-items: center;
}

.page-category-v4 .menu-header .top-menu-header ul>li:first-child:after {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    background-size: cover;
    margin: 0 8px;
}

.page-category-v4 .menu-header .title-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.page-category-v4 .menu-header .title-action h3 {
    color: var(--text-text-primary, #0e233c);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}

.page-category-v4 .page-content-category {
    background-color: #f3f4f5;
}

.page-category-v4 .page-content-category .page-category-info {
    display: flex;
    flex-direction: row;
}

.page-category-v4 .page-content-category .page-category-info .content-left {
    width: calc(100% - 395px);
}

.page-category-v4 .page-content-category .page-category-info .content-right {
    width: 100%;
    max-width: 375px;
    margin-left: 20px;
}

.block-list-products-category-v4 .no-products {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 64px 0;
}

.block-list-products-category-v4 .no-products .new-ldicon-template {
    width: 64px;
    height: 64px;
}

.block-list-products-category-v4 .list-products {
    margin-top: 8px;
}

.block-content-item .block-upload-image-category {
    display: flex;
    padding: 48px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    /* flex: 1 0 0; */
    align-self: stretch;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    background: var(--full-color-grey-50, #f3f4f5);
    box-shadow: 0px 0px 0px 3px #fff inset;
}

.block-content-item .block-upload-image-category .image {
    width: 24px;
    height: 24px;
}

.block-content-item .block-upload-image-category .image img {
    width: 100%;
    object-fit: contain;
}

.new-ldicon-photo {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-photo.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-photo.svg);
    background-color: var(--main-secondary);
    width: 24px;
    height: 24px;
    display: inline-block;
}

.block-content-item .block-upload-image-category .text-add-image {
    display: flex;
    align-items: center;
}

.block-content-item .list-image-product-reviews .image-none .ladiui-button-image {
    width: 100%;
    padding: 48px 24px;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    background: var(--full-color-grey-50, #f3f4f5);
    box-shadow: 0px 0px 0px 3px #fff inset;
}

.block-content-item .list-image-product-reviews .image-none .ladiui-button-image .text-default {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-content-item .list-image-product-reviews .image-none .ladiui-button-image .rec-size {
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
    color: var(--text-secondary);
}

.block-content-item .list-image-product-reviews .list-images {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}

.block-content-item .list-image-product-reviews .list-images .image-item {
    width: 134px;
    height: 134px;
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 0px 4px #fff inset;
    overflow: hidden;
    position: relative;
}

.block-content-item .list-image-product-reviews .list-images .image-item .img {
    width: 100%;
    height: 100%;
}

.block-content-item .list-image-product-reviews .list-images .image-item .action-group {
    align-items: center;
    gap: 16px;
    position: absolute;
    top: 10px;
    display: none;
    right: 10px;
}

.block-content-item .list-image-product-reviews .list-images .image-item:hover .action-group {
    display: flex;
}

.block-content-item .list-image-product-reviews .list-images .image-item .action-group .block-delete-img {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
}

.block-content-item .list-image-product-reviews .action-group .ladi-icon.icon-bin {
    background-color: #fff;
}

#modal-add-product-review .ladiui.modal-content .ladiui.modal-body {
    background-color: #f3f4f5;
}

.block-content-item .list-image-product-reviews .list-images .image-item .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.block-content-item .list-image-product-reviews .list-images .ladiui-button-upload-image {
    width: 134px;
    height: 134px;
    border-radius: var(--spacing-8px, 8px);
    border: 1px solid var(--border-border-border-02, #e7e9eb);
    background: url(<path-to-image>), #d3d3d3 50% / cover no-repeat;
    box-shadow: inset 0 0 0 4px #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.popover-image-category {
    background-color: #fff;
    right: 0 !important;
    left: auto !important;
    padding: 20px 16px;
    border-radius: 8px;
    width: 400px;
    z-index: 99999;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.popover-image-category .action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-top: 24px;
    border-top: 1px solid #f1f3f4;
}

.block-content-item .block-show-image-category .image {
    width: 100%;
    height: 132px;
    position: relative;
    border-radius: var(--border-radius-08, 8px);
    border: 1px dashed var(--border-border-border-01, #cfd3d8);
    background: var(--full-color-grey-50, #f3f4f5);
    box-shadow: 0px 0px 0px 3px #fff inset;
    overflow: hidden;
}

.block-content-item .block-show-image-category .image .action-group {
    align-items: center;
    gap: 16px;
    position: absolute;
    top: 10px;
    display: none;
    right: 10px;
}

.block-content-item .block-show-image-category .image:hover .action-group {
    display: flex;
}

.block-content-item .block-show-image-category .image .action-group .block-icon-upload {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
}

.block-content-item .block-show-image-category .image .action-group .block-delete-img {
    background-color: rgba(32, 32, 32, 0.7);
    padding: 3px 5px;
    border-radius: 2px;
}

.block-show-image-category .action-group .ladi-icon.icon-bin {
    background-color: #fff;
}

#modal-add-product-review .ladiui.modal-content .ladiui.modal-body {
    background-color: #f3f4f5;
}

#modal-update-batch .content-header {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 16px;
    gap: 16px;
}

#modal-update-batch .content-header .btn-group {
    padding: 4px;
    border-radius: 4px;
    background-color: #f3f4f5;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 8px;
    gap: 8px;
}

#modal-update-batch .content-header .btn-group .btn-item.active {
    background-color: #fff;
    color: #0e233c;
}

#modal-update-batch .content-header .btn-group .btn-item {
    padding: 6px auto;
    width: 100%;
    cursor: pointer;
}

#page-report-new .menu-header {
    padding: 24px 24px 24px 16px;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
    /* min-width: 1110px; */
}

#page-report-new .menu-header .content {
    max-width: 1220px;
    padding: 0 24px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#page-report-new .menu-header .content .title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

#page-report-new .menu-header .content .title span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    color: #566577;
}

#page-report-new .menu-header .actions {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

#page-report-new .page-content-report {
    background-color: #e7e9eb;
}

#page-report-new .page-content-report .list-block-overviews {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

#page-report-new .page-content-report .list-block-overviews .block-item {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    /* flex: 1 0 0; */
    width: 25%;
    border-radius: var(--border-radius-08, 8px);
    border: 0.5px solid var(--border-border-border-02, #e7e9eb);
    background: var(--layer-layer-01, #fff);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.new-ldicon-money-fill {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-money-fill.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-money-fill.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-wallet-fill {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-wallet-fill.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-wallet-fill.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-arrow-up-fill {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-up-fill.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-up-fill.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-ldicon-arrow-down-fill {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down-fill.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-arrow-down-fill.svg);
    background-color: var(--main-primary);
    width: 16px;
    height: 16px;
    display: inline-block;
}

#page-report-new .page-content-report .list-block-overviews .block-item .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
}

#page-report-new .page-content-report .list-block-overviews .block-item .title .icon {
    margin-right: 8px;
    padding: 4px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f2fc;
}

#page-report-new .page-content-report .list-block-overviews .block-item .total-overview {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    gap: 16px;
}

#page-report-new .page-content-report .list-block-overviews .block-item .total-overview span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-secondary);
}

#page-report-new .page-content-report .page-content .content-overview {
    display: flex;
    flex-direction: row;
}

#page-report-new .page-content-report .page-content .content-overview .content-left {
    width: calc(100% - 395px);
}

#page-report-new .page-content-report .page-content .content-overview .content-right {
    width: 100%;
    max-width: 375px;
    margin-left: 20px;
}

#page-report-new .page-content-report .page-content .content-overview .content-right .block-list-staffs {
    display: block;
}

.block-content-item .block-list-staffs .staff-item {
    padding: 12px 0px;
    border-bottom: 1px solid #f3f4f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.block-content-item .block-list-staffs .staff-item:last-child {
    border-bottom: none;
}

.block-content-item .block-list-staffs .staff-item .info {
    display: flex;
    align-items: center;
    gap: 16px;
}

.block-content-item .block-list-staffs .staff-item .info .image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
}

.block-content-item .block-list-staffs .staff-item .info .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.block-content-item .block-list-staffs .staff-item .info .name p {
    color: var(--link-link-primary, #2b57d6);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.block-content-item .block-list-staffs .staff-item .info .name span {
    color: var(--text-text-secondary, #566577);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.block-content-item .block-list-staffs .staff-item .total-price {
    display: flex;
    align-items: end;
    flex-direction: column;
}

.block-content-item .block-list-staffs .staff-item .total-price strong {
    color: var(--text-text-primary, #0e233c);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.block-content-item .block-list-staffs .staff-item .total-price span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.percent-up {
    color: var(--full-color-success-600, #35a500);
}

.percent-down {
    color: var(--support-support-danger, #e01a1a);
}

.page-discount-v4 .menu-header .content {
    max-width: 1220px;
    /* padding: 0 24px; */
    margin: 0 auto;
}

.page-discount-v4 .ladiui.page-content {
    padding: 0px;
}

.page-discount-v4 .menu-header .content .title-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-discount-v4 .menu-header .content .title-actions .title-desc h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.page-discount-v4 .menu-header .content .title-actions .title-desc p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.page-discount-v4 .menu-header .content .title-actions .actions {
    display: flex;
    align-items: center;
    gap: 20px;
}

.page-discount-v4 .menu-header .content .block-filter-search {
    display: flex;
    gap: 16px;
    padding: 16px 0px 20px;
}

.page-discount-v4 .menu-header .content .block-filter-search .ladiui.search-group {
    width: calc(100% - 638px);
}

.page-discount-v4 .menu-header .content .block-filter-search .list-filter {
    display: flex;
    align-items: center;
    gap: 16px;
}

.block-filter-search .list-filter .date-filter {
    width: 250px;
}

/* ------------------------------------------style tạo mới, chỉnh sửa mã khuyến mãi----------------------------------------------------------------- */
.page-discount-code-v4 {
    background-color: #E7E9EB;
}

.page-discount-code-v4 .page-header {
    padding: 0px 24px 24px 16px;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 20;
}

.page-discount-code-v4 .page-header .content-header {
    max-width: 1220px;
    padding: 0 24px;
    margin: 0 auto;
}

.page-discount-code-v4 .page-header .content-header .sub-menu ul {
    display: flex;
    align-items: center;
}

.page-discount-code-v4 .page-header .content-header .sub-menu ul li {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.page-discount-code-v4 .page-header .content-header .sub-menu ul li:first-child::after {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-right.svg);
    background-size: cover;
    margin: 0 8px;
}

.page-discount-code-v4 .page-header .content-header .title-action {
    display: flex;
    align-items: center;
    padding-top: 24px;
    justify-content: space-between;
}

.page-discount-code-v4 .page-header .content-header .title-action h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.page-discount-code-v4 .page-content {
    display: flex;
    gap: 20px;
    overflow: initial !important;
}

.page-discount-code-v4 .page-content .content-left {
    width: calc(100% - 416px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.page-discount-code-v4 .page-content .content-right {
    width: 100%;
    max-width: 396px;
}

.page-discount-code-v4 .page-content .content-right .content {
    position: sticky;
    top: 154px;
}

.page-discount-code-v4 .page-content .content-left .block-content-item .group-item-check {
    display: flex;
    align-items: center;
    gap: 16px;
}

.page-discount-code-v4 .page-content .content-left .block-discount-type .group-item-check .item {
    width: 50%;
}

.page-discount-code-v4 .page-content .content-left .block-content-item .group-item-check .item {
    padding: 12px 16px;
    border: 1px solid #e7e9eb;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.page-discount-code-v4 .page-content .content-left .block-generation-form .group-item-check .item {
    width: 33%;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value .option {
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 4px;
    background-color: #f3f4f5;
    width: 100%;
    max-width: 210px;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value .option .item {
    padding: 10px 24px;
    border-radius: 4px;
    cursor: pointer;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value .option .item.active {
    background-color: #fff;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .option-discount-value .value {
    width: calc(100% - 226px);
}

.page-discount-code-v4 .page-content .content-left .block-conditions .list-conditions {
    margin-top: 20px;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .list-conditions span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #566577;
    display: block;
    margin-bottom: 16px;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .list-conditions .discount-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
}

.page-discount-code-v4 .page-content .content-left .block-conditions .list-conditions .discount-item:last-child {
    margin-bottom: 0;
}

.page-discount-code-v4 .page-content .content-right .block-over-view .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}

.page-discount-code-v4 .page-content .content-right .block-over-view .block-item {
    display: flex;
    align-items: center;
}

.page-discount-code-v4 .page-content .content-right .block-over-view .block-item label {
    padding-bottom: 0 !important;
}

.page-discount-code-v4 .page-content .content-right .block-detail-discount {
    margin-top: 24px;
}

.page-discount-code-v4 .page-content .content-right .block-detail-discount .overview-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}

.discount-status-v4 {
    display: flex;
}

.discount-status-v4 .active:before {
    content: '';
    display: inline-block;
    background: #248a00;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgba(36, 138, 0, 0.2);
    background-clip: padding-box;
}

.discount-status-v4 .expried:before {
    content: '';
    display: inline-block;
    background: #e01a1a;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid rgb(224, 26, 26, 0.2);
    background-clip: padding-box;
}

.discount-status-v4 .pending:before {
    content: '';
    display: inline-block;
    background: #bcbcbc;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    border: 3px solid hsla(0, 0%, 73.7%, 0.2);
    background-clip: padding-box;
}

/* ---------------------------------------------------------------style các màn trang cài đặt v4----------------------------------------------------------------- */

/* -----------Cài đặt chung--------------------------- */
.page-general-setting-v4 .content {
    margin-top: 24px;
}

.page-general-setting-v4 .header-title {
    padding: 0;
    position: sticky;
    top: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

.page-general-setting-v4 .header-title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0E233C;
}

.page-general-setting-v4 .header-title p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
    color: #566577;
}

.page-general-setting-v4 .content .list-blocks .block-item {
    display: flex;
    align-items: flex-start;
}

.page-general-setting-v4 .content .list-blocks .block-item .title-block {
    width: 100%;
    max-width: 200px;
    margin-right: 24px;
}

.page-general-setting-v4 .content .list-blocks .block-item .title-block h3 {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-general-setting-v4 .content .list-blocks .block-item .title-block p {
    margin-top: 8px;
    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.page-general-setting-v4 .content .list-blocks .block-item .content-block {
    width: calc(100% - 200px);
}

.line-space-block {
    width: 100%;
    height: 1px;
    background-color: #e7e9eb;
    margin: 24px 0;
}

.page-general-setting-v4 .action-footer {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
}

/* --------------------------------style trang chính sách cửa hàng------------------------------------------- */


.page-store-policy .content .list-blocks .block-item {
    display: flex;
    align-items: flex-start;
}

.page-store-policy .content .list-blocks .block-item .title-block {
    width: 100%;
    max-width: 200px;
    margin-right: 24px;
}

.page-store-policy .content .list-blocks .block-item .title-block h3 {
    color: var(--Text-text-primary, #0E233C);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.page-store-policy .content .list-blocks .block-item .title-block p {
    margin-top: 8px;
    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.page-store-policy .content .list-blocks .block-item .content-block {
    width: calc(100% - 200px);
}

.page-store-policy .action-footer {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
}

#modal-address .ladisales-location .ladiui.dropdown {
    position: initial !important;
}

#modal-address .ladisales-location .ladiui.dropdown .ladiui.dropdown-menu {
    width: calc(50% - 24px) !important;
}

/* -------------------------------style trang tracking---------------------------------------------- */
.page-setting-tracking-v4 {}

.page-setting-tracking-v4 .header-title {
    /* padding: 24px 0; */
    position: sticky;
    top: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

.page-setting-tracking-v4 .header-title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.page-setting-tracking-v4 .header-title p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.page-setting-tracking-v4 .content .list-blocks .block-item {
    display: flex;
    align-items: flex-start;
}

.page-setting-tracking-v4 .content .list-blocks .block-item .title-block {
    width: 100%;
    max-width: 200px;
    margin-right: 24px;
}

.page-setting-tracking-v4 .content .list-blocks .block-item .title-block h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.page-setting-tracking-v4 .content .list-blocks .block-item .title-block p {
    margin-top: 8px;
    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.page-setting-tracking-v4 .content .list-blocks .block-item .content-block {
    width: calc(100% - 200px);
}

.page-setting-tracking-v4 .action-footer {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
}

/* ------------------------style trang quản lý domain------------------------------- */
#page-setting-api .ladiui.table {
    border: 1px solid #f3f4f5;
}

#page-setting-api .ladiui.table thead {
    background-color: #f3f4f5;
}

#page-setting-api .ladiui.table thead>tr>th:first-child {
    padding-left: 12px !important;
}

#page-setting-api .ladiui.table tbody>tr>td:first-child {
    padding-left: 12px !important;
}

/* ----------------------------------icon menu setting------------------------------------------- */
.setting-page-general .setting-content .setting-item .text {
    display: flex;
    align-items: center;
}

.setting-page-general .setting-content .setting-item.active .text i {
    background-color: #1c00c2;
}

/* -----------------------------style trang cài đặt tài khoản----------------------------------- */
#modal-info-create-account .block-list-scopes {
    margin-top: 24px;
}

#modal-info-create-account .block-list-scopes h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
}

#modal-info-create-account .block-list-scopes .lists {
    display: flex;
    flex-direction: column;
    border: 1px solid #e7e9eb;
}

#modal-info-create-account .block-list-scopes .lists .list-item {
    border-bottom: 1px solid #e7e9eb;
}

#modal-info-create-account .block-list-scopes .lists .list-item .scope-name {
    padding: 16px;
    background-color: #f3f4f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#modal-info-create-account .block-list-scopes .lists .list-item .list-permissions .list-permission-item {
    padding: 16px 16px 16px 32px;
    background-color: #fff;
    border-bottom: 1px solid #e7e9eb;
    cursor: pointer;
}

/* -------------------------------------------style trang cài đặt quản lý thông báo----------------------------------------------------------------------- */

.page-setting-notification-v4 .header-title {
    /* padding-bottom: 24px; */
}

.page-setting-notification-v4 .header-title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.page-setting-notification-v4 .header-title p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.page-setting-notification-v4 .content .list-blocks .block-item {
    display: flex;
    align-items: flex-start;
}

.page-setting-notification-v4 .content .list-blocks .block-item .title-block {
    width: 100%;
    max-width: 200px;
    margin-right: 24px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .title-block h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .title-block p {
    margin-top: 8px;
    color: var(--Text-text-secondary, #566577);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block {
    width: calc(100% - 200px);
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table {
    border: 1px solid #f1f3f4;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table thead {
    background-color: #f3f4f5;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table thead th:first-child {
    padding-left: 12px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table tbody td:first-child {
    padding-left: 12px;
}

.page-setting-notification-v4 .content .list-blocks .block-item .content-block table tbody tr:last-child {
    border-bottom: none !important;
}